import useTheory from './useTheory';
import useLectures from './useLectures';

const hooksHandlerObject = {
  'theory': useTheory,
  'lectures': useLectures,
}

const useGroup = (productType, setTitle, setUnpaidTitle, order) => {
  return hooksHandlerObject[productType]?.(setTitle, setUnpaidTitle, order);
}

export default useGroup;