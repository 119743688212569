import {useEffect} from "react";
import useQueryParamWithDefault from "./useQueryParamWithDefault";
import {NumberParam} from "use-query-params";
import {IArrival, IPractice, TOrder} from "../types";

type TPicked = IPractice | IArrival | undefined
export function usePartialPay(picked: TPicked, order: TOrder | undefined): [number, (num: number) => void] {
    const [partPay, setPartPay] = useQueryParamWithDefault<number>('partPay', NumberParam, 100);

    let partial20: boolean
    let partial50: boolean
    if (picked && "partialPaymentOption20" in picked) {
        partial20 = picked.partialPaymentOption20
        partial50 = picked.partialPaymentOption50
    }
    if (picked && "partial_payment_option_20" in picked) {
        partial20 = picked.partial_payment_option_20
        partial50 = picked.partial_payment_option_50
    }

    useEffect(() => {
        if (order?.id) {
            setPartPay(order?.payments?.payment_size)
        } else if (!(partial20 && partPay === 20
            || partial50 && partPay === 50
            || partPay === 100)) {
            setPartPay(100)
        }
    }, [partPay, order])

    return [partPay, setPartPay]
}