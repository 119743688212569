import "./style.scss";
import { useEffect, useState } from 'react';
import { useSportApi } from '../../../lib/api/useSportApi';
import {BooleanParam, NumberParam, StringParam, useQueryParam, withDefault} from 'use-query-params';
import { useLoaderState } from '../../../lib/api/loaderState';
import { usePublicEndpoints } from '../../../lib/api/usePublicEndpoints';
import { calculateDiscount } from '../../../lib/utils/helpers';
import { t } from 'i18next';
import { parseErrors } from '../../../lib/utils/errors';
import { schedulePages } from './pages';
import { Constants } from 'sv-common';
import useEvent from './hooks/useEvent';
import useSlot from './hooks/useSlot';
import useSport from './hooks/useSport';
import useWithOrder from './hooks/useWithOrder';
import useSportByEvents from './hooks/useSportByEvents';
import useQueryParamWithDefault from '../../../lib/utils/hooks/useQueryParamWithDefault';
import usePickBoat from './hooks/usePickBoat';
import usePickBase from './hooks/usePickBase';
import usePickDate from './hooks/usePickDate';
import usePickSlot from './hooks/usePickSlot';
import useFullBookDiscount from './hooks/useFullBookDiscount';
import useCoachlessDiscount from './hooks/useCoachlessDiscount';
import BaseSelector from "../selectors/BaseSelector";
import BoatSelector from "../selectors/BoatSelector";
import DateSelector from "../selectors/DateSelector";
import SlotSelector from "../selectors/SlotSelector";
import InstructorSelector from "../selectors/InstructorSelector";
import { Input, Label } from "reactstrap";
import FormButton from "../../generic/buttons/main";
import Divider from "../../generic/Divider";
import PriceBlock from "../Price/";
import { isProductNotExpired } from "../../../lib/utils/helpers";
import TimoutRedirect from '../../common/TimoutRedirect';
import _ from "lodash";
import useSportByCorp from './hooks/useSportByCorp';
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';

const PRODUCT_AVAILABILITY = Constants.STATUSES.PRODUCT_AVAILABILITY;

const Schedule = (props) => {
  const { sendData, setPeopleAmount, onSubmit, eventData, setSlot, basesList, setGlobalEvent, peopleAmount,
    isDataEntered, eventURL, slotURL, orderId, order, promocodeApplied, setFetchedBases, fetchedBases,
    currencies, setEventData, page, tablet = false } = props;

  const initialDropdowns = {
    base: false,
    boat: false,
    date: false,
    time: false,
    instructor: false
  };

  const [dropdowns, setDropdowns] = useState(initialDropdowns);

  const handleDropdowns = (entity) => {
    setDropdowns(() => ({ ...initialDropdowns, [entity]: true }));
  };

  const storagedData = localStorage.getItem('storagedData') !== 'undefined'
    ? JSON.parse(localStorage.getItem('storagedData'))
    : null;

  const [isHandleInstructorPicked, setIsHandleInstructorPicked] = useState(true);
  const isButtonDisabled = !isHandleInstructorPicked || eventData?.instructor?.available_sits < peopleAmount || !eventData?.slot?.id;
  const [queryEvents] = useQueryParamWithDefault("eventsList", StringParam, '');

  const [bases, setBases] = useState();
  const [boats, setBoats] = useState();
  const [dates, setDates] = useState();
  const [slots, setSlots] = useState();

  const [serverError, setServerError] = useState('');

  const [isLoadingLocal, setIsLoadingLocal] = useState(true);
  const [isSingleBase, setIsSingleBase] = useState(false);
  const [isCoachlessVisible, setIsCoachlessVisible] = useState(true);
  const [discount, setDiscount] = useState(0);
  const [isEnoughSeatsToFull, setIsEnoughSeatsToFull] = useState(true);

  const {checkSlotAvailability} = useSportApi();
  const [eventsList] = useQueryParam("events", withDefault(StringParam, ''));
  const [boatsList] = useQueryParam("boats", withDefault(StringParam, ''));
  const [withoutInstructorParam, setWithoutInstructorParam] = useQueryParamWithDefault('withoutInstructor', BooleanParam);
  const [fullBookParam, setFullBookParam] = useQueryParamWithDefault('fullBook', BooleanParam);
  const [, setInstructorParam] = useQueryParamWithDefault('instructor', NumberParam);
  const {setIsLoading} = useLoaderState();

  const { getAvailableDates, getSlots, getProductPlaces } = usePublicEndpoints();

  const fetchSlots = (base, boat, date, eventURL, allowEventsList) => {
    setIsLoading(true);
    getSlots(base.id, boat.class, date, eventURL, allowEventsList ? eventsList : null, queryEvents)
      .then((res) => {
        if (!res.slots) throw res;
        setSlots(() => res.slots);
        sendData({ priceCurrency: res.base.price_currency });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingLocal(false);
      });
  }

  const initialGlobalState = { isFullBookChecked: false, isFullBookVisible: false, instructor: null, eventId: null,
    priceValue: 0, full_book_discount: {active: false}, coachless_discount: {active: false} };

  const bookFullBoat = () => {
    setPeopleAmount(calculateBookAvailability());
  }

  const calculateBookAvailability = () => {
    // если людей меньше, чем лодка, то заполняем до лодки, иначе скидка применится к полным лодкам
    const boatCapacity = eventData?.boat?.places;
    const sitsPerBoat = Math.ceil(peopleAmount / boatCapacity);
    const expectedSits = sitsPerBoat > 1 ? peopleAmount : sitsPerBoat * boatCapacity;
    return expectedSits <= eventData?.slot?.available_sits ? expectedSits : null;
  }

  const getSlotCallback = (res) => {
    setBases(() => [res.base]);
    setFetchedBases([res.base]);
    setBoats(() => [res.boat]);
    setDates(() => res.date);
    setGlobalEvent(res.slot.event);
    sendData(
      {
        base: res.base,
        boat: res.boat,
        date: res.date[0],
        slot: res.slot,
        eventId: res.slot.event.id,
        priceCurrency: res.slot?.event.price_currency,
        priceValue: res.slot?.event.price_value,
        teamOnly: res.slot?.team_only,
        peopleAmount: res.slot?.team_only ? res.boat.places : 1,
        preloadEntity: 'slot',
        full_book_discount: {
          active: res.slot?.team_only && res.slot?.full_book_discount_size,
          size: res.full_book_discount?.size || res.slot?.full_book_discount_size,
          text: res.full_book_discount?.text || res.slot?.full_book_discount_info || t('discounts.fullBook'),
          type: res.full_book_discount?.type || res.slot?.full_book_discount_type
        }
      });
    setSlot(res.slot);
    setSlots(() => [res.slot]);
  }

  const { getEvent } = useEvent(eventURL, setIsLoading, setIsLoadingLocal, setEventRelatedEntities);
  const { getSlot } = useSlot(slotURL, setBases, setFetchedBases, setBoats, setDates, sendData, setGlobalEvent, setSlot, setSlots, setIsLoading, setIsLoadingLocal, getSlotCallback,);
  const { loadDefault } = useSport(sendData, eventsList, boatsList, basesList, setIsSingleBase, setBoats, setFetchedBases, setBases, setIsLoading, setIsLoadingLocal);
  const { initializeOrder } = useWithOrder(sendData, setBases, setBoats, isDataEntered, setGlobalEvent, page, getEvent, getAvailableDates, setDates, fetchedBases, fetchSlots, setIsLoadingLocal, eventURL);
  const { loadSportEvent } = useSportByEvents(queryEvents, setDates, setBases, setFetchedBases, setBoats, setIsLoading, setIsLoadingLocal);
  const { loadEventsByCorp } = useSportByCorp(setBases, setFetchedBases, setBoats, setDates, setIsLoading, setIsLoadingLocal);

  const { pick: pickBoat } = usePickBoat(eventData, initialGlobalState, sendData, resetEvent);
  const { pick: pickBase } = usePickBase(eventData, initialGlobalState, setBoats, resetEvent);
  const { pick: pickDate } = usePickDate(eventData, initialGlobalState, fetchSlots, eventURL, page, resetEvent);
  const { pick: pickSlot } = usePickSlot(setGlobalEvent, setSlot);

  const { handle: handleFullBookDiscount } = useFullBookDiscount(sendData, bookFullBoat, calculateBookAvailability);
  const { handle: handleCoachlessDiscount } = useCoachlessDiscount(setIsCoachlessVisible, bookFullBoat, sendData, calculateBookAvailability);

  function resetEvent() {
    setEventData({...eventData, slot: null})
    setGlobalEvent({});
    setSlots(() => []);
    setSlot(null);
  }

  function setEventRelatedEntities(res) {
    setDates(() => res.availableDates.sort());
    setGlobalEvent(res.event);
    sendData({
      base: res.base,
      boat: res.boat,
      teamOnly: res.teamOnly,
    });
  }

  const onHandleInstructorPick = (value) => {
    setIsHandleInstructorPicked(value);
  }

  function calculateFullBookDiscount() {
    let fullBookDiscount;
    if (
      (eventData?.slot?.team_only || peopleAmount === calculateBookAvailability() || eventData?.isFullBookChecked)
      && eventData?.boat?.places > 1 && eventData?.slot?.full_book_discount_size) {
      fullBookDiscount = {
        active: true,
        size: eventData?.slot?.full_book_discount_size,
        text: eventData?.slot?.full_book_discount_info,
        type: eventData?.slot?.full_book_discount_type
      }
    } else {
      fullBookDiscount = {
        active: false
      }
    }
    return fullBookDiscount;
  }

  function updateFullBookDiscountVisible() {
    if (eventData?.slot?.team_only || order?.teamOnly) {
      sendData({ isFullBookVisible: false })
    } else {
      sendData({ isFullBookVisible: true })
    }
  }

  const validateOverbooking = async (base, boat, date) => {
    const resetSlot = () => {
      sendData({ slot: null });
      fetchSlots(base, boat, date, eventURL);
    }

    if (eventData?.slot?.id) {
        const slotStatus = await checkSlotAvailability(eventData?.slot?.id);
        const { available_sits } = await getProductPlaces('slots', eventData?.slot.id);

        if (available_sits < peopleAmount) {
            setServerError(t(parseErrors(PRODUCT_AVAILABILITY.OVERBOOKED)))
            resetSlot();
        }
        else if (slotStatus === PRODUCT_AVAILABILITY.EXPIRED) {
            setServerError(t(parseErrors(PRODUCT_AVAILABILITY.EXPIRED)))
            resetSlot()
        }
        else {
            onSubmit();
        }
    }
  }

  const noVacantSeats = eventData?.slot?.available_sits === 0 && !eventData?.slot?.is_closed;
  const isClosed = (eventData?.slot?.available_sits !== 0 && !isProductNotExpired(eventData?.slot?.start_datetime, !!serverError)) || eventData?.slot?.is_closed;
  const isUnavailable = noVacantSeats || isClosed;

  const handleSubmit = () => {
    if (isUnavailable) {
      document.location.href = '/sport';
    } else {
      const {base, boat, date} = eventData;
      validateOverbooking(base, boat, date);
    }
  }

  const handleDiscountChange = (e) => {
    const { checked, name, value } = e.target;
    if (name === 'full_book_discount') {
      setFullBookParam(checked);
      handleFullBookDiscount(checked);
    } else if (name === 'coachless_discount') {
      setInstructorParam(0);
      sendData({instructor: null});
      handleCoachlessDiscount(checked);
      setWithoutInstructorParam(checked);
    }
    sendData({
      [name]: {
        text: value,
        active: checked && (name === 'full_book_discount' ? eventData?.boat.places > 1 : true),
        size: (eventData?.slot)[name + "_size"],
        type: (eventData?.slot)[name + "_type"],
      },
    });
  };
  /**
   * Первая загрузка
   * -- Заполняем форму из имеющихся входных данных (storagedData, url, ...)
   */
  useEffect(() => {
    setIsLoading(true);

    if (orderId || isDataEntered) {
      initializeOrder(order);
    } else if (page === schedulePages.slot) {
      getSlot();
    } else if (page === schedulePages.event) {
      getEvent((res) => {
        sendData({ preloadEntity: 'event' })
        setBases(() => [res.base]);
        setFetchedBases([res.base])
        setBoats(() => [res.boat]);
      });
    } else if (page === schedulePages.sportByEvents) {
      loadSportEvent();
    } else if(page === schedulePages.sportByCorp) {
      loadEventsByCorp();
    } else {
      loadDefault();
    }
    localStorage.removeItem('promocodeSize')
  }, []);


  useEffect(() => {
    sendData({ full_book_discount: calculateFullBookDiscount(), isFullBookChecked: !(peopleAmount < eventData?.boat?.places) });
  }, [peopleAmount, eventData?.boat?.places])
  useEffect(() => {
    sendData({ full_book_discount: eventData?.isFullBookChecked && calculateFullBookDiscount() });
  }, [eventData?.isFullBookChecked])

  useEffect(() => {
    if (eventData?.slot?.instructors_ids?.length && !eventData?.instructor && withoutInstructorParam) sendData({instructor: 'any'})
    setIsEnoughSeatsToFull(() => calculateBookAvailability());
    const discountData = calculateDiscount(eventData?.coachless_discount, eventData?.full_book_discount,
      eventData?.slot?.price_value, peopleAmount, eventData?.boat?.places);
    setDiscount(() => (discountData.active ? {...discountData} : null));
    localStorage.setItem('storagedData', JSON.stringify(eventData));
  }, [eventData]);

  useEffect(() => {
    if  (eventData?.base && eventData?.boat) {
      const { base, boat } = eventData;
      if (!orderId && page !== schedulePages.event && !schedulePages.isByEvents(page) && !schedulePages.isByCorp(page)) {
        setIsLoading(true);
        getAvailableDates(base.id, boat.class, eventsList)
          .then((res) => {
            setDates(() => res.availableDates.sort());
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            setIsLoading(false);
            setIsLoadingLocal(false)
          });
      } else if (schedulePages.isByEvents(page) || schedulePages.isByCorp(page)) {
        setDates(eventData?.boat?.availableDates.sort());
      }
    }
    sendData({ isFullBookVisible: eventData?.boat?.places !== 1 && !order?.teamOnly })
  }, [eventData?.base?.id, eventData?.boat?.places]);

  useEffect(() => {
    if (eventData?.base && eventData?.boat && eventData?.date) {
      const {base, boat, date} = eventData;
      if (!orderId && !isDataEntered && eventData?.preloadEntity !== 'slot') delete eventData.slot;
      if (eventData?.preloadEntity !== 'slot' && date) {
        if (page !== schedulePages.slot) {
          setIsLoading(true);
          fetchSlots(base, boat, date, eventURL, true);
        }
      }
    }
  }, [eventData?.date]);

  useEffect(() => {
    sendData({
      full_book_discount: calculateFullBookDiscount(),
    });
    updateFullBookDiscountVisible();

    if (eventData?.slot?.id) {
        if (fullBookParam) {
            handleDiscountChange({ target: { name: 'full_book_discount', value: t('discounts.fullBook'), checked: fullBookParam } });
        }

        if (withoutInstructorParam) {
            handleDiscountChange({ target: { name: 'coachless_discount', value: t('discounts.coachless'), checked: withoutInstructorParam } });
        }
    }
  }, [eventData?.slot?.id])

  useEffect(() => {
    if (eventData?.slot?.single_booking) {
      setPeopleAmount(1);
    }
  }, [eventData?.slot?.single_booking, peopleAmount])

  const handlePick = (pickedData, dataType) => {
    // если сменилась база/лодка/дата - очищаем текущее событие
    let preparedData = null;
    if (dataType === "base") {
      preparedData = pickBase(dataType, pickedData);
    } else if (dataType === "boat") {
      preparedData = pickBoat(pickedData);
    } else if (dataType === "date") {
      preparedData = pickDate(dataType, pickedData);
    } else if (dataType === "slot") {
      preparedData = pickSlot(dataType, pickedData);
    } else if (dataType === "instructor") {
      preparedData = {
        [dataType]: pickedData || 'any'
      }
    }
    sendData(preparedData);
  };

  const resetBlocks = () => {
    setServerError('')
  }

  const isShowNotAvailableTitle = (!bases?.length || (page === schedulePages.event && _.isEmpty(dates))) && page !== schedulePages.url && !isLoadingLocal;

  return (
    <form className={"schedule sport"}>
      {(!!bases?.length || eventData?.base) && !tablet && (
        <BaseSelector
          storagedData={storagedData}
          isDataEntered={isDataEntered}
          isSingleBase={isSingleBase}
          bases={bases}
          orderId={orderId}
          preloadEntity={eventData.preloadEntity}
          handleDropdownClick={(entity) => handleDropdowns(entity)}
          isDropdownActive={dropdowns.base}
          clickable={!eventData.preloadEntity}
          setPickedBase={(base) => handlePick(base, "base")}
          setIsSlotOverbooked={resetBlocks}
        />
      )}
      {(!!boats?.length || eventData?.boat) && (
        <BoatSelector
          isDataEntered={isDataEntered}
          boats={boats}
          storagedData={storagedData}
          orderId={orderId}
          preloadEntity={eventData.preloadEntity}
          handleDropdownClick={(entity) => handleDropdowns(entity)}
          isDropdownActive={dropdowns.boat}
          clickable={!eventData.preloadEntity}
          disabled={!eventData?.base}
          setPickedBoat={(boat) => handlePick(boat, "boat")}
          setIsSlotOverbooked={resetBlocks}
        />
      )}
      {(!!dates?.length || eventData?.date) && (
        <DateSelector
          dates={dates}
          orderId={orderId}
          isDataEntered={isDataEntered}
          selectedDate={isDataEntered || orderId ? order?.slot?.start_date : eventData?.date}
          preloadEntity={eventData.preloadEntity}
          handleDropdownClick={(entity) => handleDropdowns(entity)}
          isDropdownActive={dropdowns.date}
          clickable={!eventData.preloadEntity}
          disabled={!eventData?.boat}
          setPickedDate={(date) => handlePick(date, "date")}
          setIsSlotOverbooked={resetBlocks}
        />
      )}
      {(!!slots?.length || eventData?.slot) && (
        <SlotSelector
          slots={slots}
          orderId={orderId}
          peopleAmount={peopleAmount}
          isDataEntered={isDataEntered}
          selectedSlot={isDataEntered || orderId ? JSON.parse(localStorage.getItem('storagedData')).slot : null}
          preloadEntity={eventData.preloadEntity}
          handleDropdownClick={(entity) => handleDropdowns(entity)}
          isDropdownActive={dropdowns.slot}
          clickable={!eventData.preloadEntity}
          disabled={!eventData?.date}
          setPickedData={(slot) => handlePick(slot, "slot")}
          isSlotOverbooked={serverError}
          setIsSlotOverbooked={resetBlocks}
        />
      )}
      <ElementWithAccessInCountries hideInCountries={['EN']}>
        {(eventData?.slot && eventData.slot.is_coach_selectable) && (
          <InstructorSelector
            instructors={eventData.slot.instructors_ids}
            orderId={orderId}
            isDataEntered={isDataEntered}
            isCoachlessActive={eventData?.coachless_discount?.active}
            handleDropdownClick={(entity) => handleDropdowns(entity)}
            isDropdownActive={dropdowns.instructor}
            selectedInstructor={isDataEntered || orderId || eventData.instructor ? eventData.instructor || JSON.parse(localStorage.getItem('storagedData')).instructor : null}
            disabled={!eventData?.slot || !isCoachlessVisible}
            setPickedInstructor={(instructor) => handlePick(instructor, "instructor")}
            onHandleInstructorPick={onHandleInstructorPick}
            isHandleInstructorPicked={isHandleInstructorPicked}
          />
        )}
      </ElementWithAccessInCountries>
      {eventData?.slot && (
        <>
          <Divider />
          {!!eventData?.slot?.is_coach_required && (
            <div className="schedule__input-wrapper radio-input">
              <Input
                type="checkbox"
                id="coachless"
                disabled={!isEnoughSeatsToFull}
                checked={eventData?.coachless_discount?.active}
                name="coachless_discount"
                onChange={handleDiscountChange}
                value={t('discounts.coachless')}
              />
              <Label htmlFor="coachless">{t('sport.schedule.selectors.bookWithoutInstructor')}</Label>
            </div>
          )}
          {eventData?.isFullBookVisible && eventData?.boat?.places > 1
          && !eventData?.coachless_discount?.active
          && eventData?.slot?.is_full_book_available
          && peopleAmount < eventData?.boat?.places && (
            <>
              <div className="schedule__input-wrapper radio-input">
                <Input
                  type="checkbox"
                  id="fullBook"
                  disabled={!isEnoughSeatsToFull}
                  checked={eventData.isFullBookChecked}
                  name="full_book_discount"
                  onChange={handleDiscountChange}
                  value={t('discounts.fullBook')}
                />
                <Label htmlFor="fullBook">{t('sport.schedule.selectors.bookWholeBoat')}</Label>
              </div>
              <span className={isEnoughSeatsToFull ? "error-label d-none" : "error-label"}>
                {t('sport.schedule.selectors.noEnoughSeatsToFull')}
              </span>
            </>
          )}
        </>
      )}
      {eventData?.slot && (
        <PriceBlock
          onSubmit={onSubmit && (
            (e) => {
              e.preventDefault()
              onSubmit()
            }
          )}
          price={eventData?.slot?.price_value}
          currency={eventData?.priceCurrency}
          discount={discount?.size}
          eventData={eventData}
          order={order}
          promocodeApplied={promocodeApplied}
          peopleAmount={peopleAmount}
          currencies={currencies}
          isButtonDisabled={isButtonDisabled}
          buttonValue={isProductNotExpired(eventData?.slot?.start_datetime,  !!serverError) ? t('common.next') : t('common.bookingClosed')}
        />
      )}
      {!!serverError &&
      <p className="member">
        {serverError}
      </p>
      }
      {noVacantSeats &&
      <TimoutRedirect text={t('sport.schedule.selectors.noVacantSeats')} seconds={60} href='/sport' />
      }
      {isClosed &&
      <TimoutRedirect text={`${t('common.bookingClosed')}. ${t('common.redirect')}`} seconds={60} href='/sport' />
      }
      {isShowNotAvailableTitle &&
      <p className={`mb-4 text-center`}>
        {t('common.noProductsAvailable')}
      </p>
      }

      <FormButton
        disabled={isButtonDisabled}
        value={!isUnavailable ? t('common.next') : t('common.chooseOtherSlot')}
        onClick={e => {
          e.preventDefault();
          handleSubmit();
        }}
        className={"d-none d-lg-block"}
        price=""
      />
    </form>
  );
}

export default Schedule;