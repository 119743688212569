import {TUserFields} from "../../../../lib/api/useTypedPublicEndpoint";
import {TPeopleData} from "../../../../lib/utils/types";
import {useLogin} from "../../../../lib/utils/hooks/useLogin";
import {useEffect, useState} from "react";
import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {NumberParam} from "use-query-params";

//@ts-ignore
const useStep = ({orderId, setPayingUser, setOrderId}) => {
    const {ensureLoggedIn} = useLogin()

    const [isDataEntered, setIsDataEntered] = useState<boolean>(false);
    const [step, setStep] = useQueryParamWithDefault<number>('step', NumberParam, 1, "pushIn");
    const [peopleData, setPeopleData] = useState<TPeopleData[]>([]);

    const toStepTwo = () => {
        ensureLoggedIn((isUserAlreadyLoggedIn: boolean, user: TUserFields) => {
            if (!isUserAlreadyLoggedIn) {
                setPayingUser(user)
            }
            setStep(2);
            setIsDataEntered( true);
        })
    };
    const toStepThree = (newData: TPeopleData[], orderId: number | string) => {
        setPeopleData(() => newData);
        orderId && setOrderId(orderId);
        setStep( 3);
    };

    useEffect(() => {
        !orderId && setStep( 1);
    }, []);

    return {isDataEntered, step, setStep, toStepThree, toStepTwo, peopleData}
}

export default useStep;