import React, {useEffect, useState} from 'react';
import Divider from '../../generic/Divider';
import './style.scss';
import {usePublicEndpoints} from "../../../lib/api/usePublicEndpoints";
import {localizeDate} from '../../../lib/utils/format';
import {useApi} from '../../../lib/api/useApi';
import PropTypes from 'prop-types';
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params';
import {useParams} from "react-router-dom";
import {features} from "../../../lib/api/globalState";
import {genitivePlaces} from "../../../lib/utils/helpers";
import Feedback from "../../common/Feedback";
import {useLoaderState} from "../../../lib/api/loaderState";
import i18next from "i18next";
import {COUNTRY} from "../../../lib/utils/constants";
import CalendarButton from '../../common/CalendarEvents/Button';

const Final = ({updateData}) => {
    const {materials} = useApi();
    const {getOrder} = usePublicEndpoints();
    const {arrivalURL = undefined} = useParams();
    const [query, setQuery] = useQueryParams({
        orderId: NumberParam,
        step: withDefault(NumberParam, 1),
        arrival: StringParam
    })
    const [order, setOrder] = useState(null);

    const {setIsLoading} = useLoaderState();

    const {t, language} = i18next;

    const successText = COUNTRY === 'RU'
        ? <>
            {order?.installment ? t('final.regattaSuccessWithInstallmentRU') : t('final.regattaSuccessRU')}
            <a target="_blank" href="https://t.me/silavetrahelp">@silavetrahelp</a> <br/> <br/>
            {t('final.regattaSuccessBye')}
        </>
        : t('final.regattaSuccess', {date: localizeDate(order?.arrival?.start_date, language)});

    useEffect(() => {
        if (query.orderId) {
            setIsLoading(() => true);
            getOrder(query.orderId)
                .then(res => {
                    setOrder(() => res)
                    if (!query.arrival || !arrivalURL) {
                        setQuery((query) => ({
                            ...query,
                            arrival: res.arrival.url
                        }))
                        updateData(res.arrival)
                    }
                })
                .catch(e => console.log(e))
                .finally(() => setIsLoading(false))
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        order &&
        <div className="final" data-testid='final-container'>
            {features['SV-1110'] ? <div className="final__title">
                    <p>{t('final.yourPayment')}{order.participants?.length}{genitivePlaces(order.participants?.length, language)}
                        {t('final.to')}{order.arrival?.name}{t('final.from')}
                        <nobr>{order.arrival?.start_date}</nobr>{t('final.on')}<nobr>{order.arrival?.end_date}</nobr>
                        {order.hasPaid ? t('final.paymentSuccess'): t('final.paymentProcessing')}</p>
                        <p>{t('final.waitEmail')}</p>
                        <p>{t('final.infoOnEmail')}</p>
                    <p>
                        {t('final.questions')}
                        <a href="tel:+74951081503">+7&nbsp;(495)&nbsp;108&nbsp;15&nbsp;03</a>
                        {t('final.orContactEmail')}
                        <a href="mailto:allo@silavetra.com">allo@silavetra.com</a>
                    </p>
                </div>
                : <>
                    {!order.hasPaid
                        ? <div className="final__title">{order?.installment ? t('final.processingWithInstallment') : t('final.processing')}</div>
                        : <div className="final__title">{successText}</div>}

                </>
            }
            {order && order.arrival && <CalendarButton
              name={order.arrival.name}
              startDate={order.arrival.start_date}
              endDate={order.arrival.end_date}
              startTime={order.arrival.start_time}
              endTime={order.arrival.end_time}
            />}
            {!!materials?.length && <>
                <div className="final__title">
                    {t('final.materials')}
                </div>
                <div className="final__docs-list">
                    {materials.map(material => (
                        <a href={material.link} target="_blank" rel="noopener noreferrer" className="final__docs-item">
                            {material.name}
                        </a>
                    ))}
                </div>
            </>}
            <Divider/>
            <Feedback />
        </div>
    )
}

Final.propTypes = {
    updateData: PropTypes.func.isRequired
}

export default Final;

