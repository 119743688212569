import {useApi} from "./useApi";
import {ICurrency} from "../utils/types";

export type TUserFields = {
    first_name: string,
    last_name: string,
    phone: string,
    email: string,
    sailing_experience: string,
    deposit_sum: number,
    subscription_type_id: number,
    keel_all_luch_group_discount_size: number,
    luch_individual_discount_size: number,
    gender: string,
    telegram?: string,
    birthdate?: Date,
}

export type TSubscription = {
    id: number,
    name: string,
    key: string,
    active: boolean,
    price_value: number,
    nominal_value: number,
    price_currency: string,
    nominal_currency: string,
    race_discount_size: number,
    cup_discount_size: number,
    is_available_for_sale: boolean,
    keel_all_luch_group_discount_size: number,
    luch_individual_discount_size: number,
};

export type TSubscriptionOptions = {
    subscriptionCanBeApplied: boolean,
    discountedPrice: number ,
    subscriptionCurrentValue: number,
    discountValue: number,
    subscription: TSubscription | {[key: string]: any}
};

export const useTypedPublicEndpoints = () => {
    const { request, rawRequest } = useApi();

    /**
     * Method to get currencies
     * @return {Promise<*>}
     */
    const getCurrencies = async (): Promise<[ICurrency]> => rawRequest('get', '/public/currencies');

    /**
     * Method to get logged user's fields (without params in request)
     * Getting fields:
     * Personal: first_name, last_name, phone, email, sailing_experience,
     * Subscription: deposit_sum, subscription_type_id, keel_all_luch_group_discount_size, luch_individual_discount_size
     * @return {Promise<*>}
     */
    const getUserCleanFields = async (isRedirectWithoutLastName = true, stateToUse = undefined): Promise<TUserFields> => {
        return request('get', `/public/user/`, undefined, stateToUse)
    }

    const getSubscriptions = async ():
        Promise<{status: string, subscriptions: [TSubscription]}> =>
        rawRequest('get', `/public/subscriptions/`)


    const calculateSubscription = async (orderId: string | number): Promise<TSubscriptionOptions> =>
        request('get', `/public/subscriptions/calculate/`, {orderId})

    return {getCurrencies, getUserCleanFields, getSubscriptions, calculateSubscription}
}