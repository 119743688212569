import { t } from 'i18next';

const DropzoneButtonComponent = (file) => {
  return (
    <aside>
      <h4>{t('common.file')}</h4>
      {file.name} - {file.size} kBytes
    </aside>
  )
}

export default DropzoneButtonComponent;