import { useApi } from './useApi';

export const useEmailAuth = () => {
  const { request } = useApi();

  const check = async (email) => {
    return request('post', '/public/email-auth/check', { email });
  }

  const login = async (code, email) => {
    return request('post', `/public/email-auth/login`, { code, email });
  }

  const checkCode = async (code, email) => {
    return request('post', `/public/email-auth/check-code`, { code, email });
  }

  return {
    check,
    login,
    checkCode,
  }
}