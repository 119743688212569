import {useApi} from "../../api/useApi";
import {usePublicEndpoints} from "../../api/usePublicEndpoints";
import { CarrotEventTypes } from 'sv-common/constants/carrotEventTypes';
import { AUTH_EVENT_TYPE } from '../constants';
import { useInjection } from 'brandi-react';
import { AuthModelStoreToken } from '../../../entities/auth/model';

export const useLogin = () => {
    const authM = useInjection(AuthModelStoreToken);
    const { userLoggedIn, updateGlobalState, getGlobalState } = useApi()
    const { getUserCleanFields } = usePublicEndpoints()

    const globalState = getGlobalState()

    const closeLoginPopup = () => updateGlobalState({isLoginPopupOpen: false})

    const openLoginPopup = (other) => updateGlobalState({isLoginPopupOpen: true, ...other})

    const ensureLoggedIn = (userLoggedInCallback) => {
        if (!userLoggedIn && !authM.isLoggedIn()) {
            updateGlobalState({isLoginPopupOpen: true, userLoggedInCallback, [AUTH_EVENT_TYPE]: CarrotEventTypes.AUTH_FROM_STEP_EVENT })
        } else {
            return userLoggedInCallback(true)
        }
    }

    const onUserAuth = (stateToUse) => {
        getUserCleanFields(false, stateToUse)
            .then(res => globalState.userLoggedInCallback(false, res, stateToUse))
            .catch(e => console.error(e))
    }

    return { ensureLoggedIn, closeLoginPopup, onUserAuth, isLoginPopupOpen: globalState.isLoginPopupOpen,
            isUserLoggedIn: userLoggedIn, openLoginPopup }
}