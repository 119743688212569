import React, {useState, useEffect} from 'react';
import './style.scss';
import {t} from "i18next";
import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {NumberParam} from "use-query-params";

const BaseSelector = ({bases, preloadEntity, storagedData, isSingleBase, isDataEntered, orderId, setPickedBase, handleDropdownClick, isDropdownActive, setIsSlotOverbooked}) => {
  const [base, setBase] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [baseParam, setBaseParam] = useQueryParamWithDefault('base', NumberParam);
  const handleClick = (e) => {
    const {id} = e.currentTarget;
    const selectedBase = bases?.filter(item => item.id == id)[0]

    setBase(() => selectedBase);
    setDropdownVisible(() => false);
    setPickedBase(selectedBase);
    setBaseParam(id);
  }

  useEffect(() => {
    if (isSingleBase || preloadEntity || orderId || isDataEntered) {
        setBase(() => bases?.filter(item => item.name === storagedData?.base?.name)[0]);
    }
    if (!bases?.length) {
        setBase(() => null);
    }
  }, [bases, storagedData])

  useEffect(() => {
    const onSetBase = (value) => {
        setBase(value);
        setPickedBase(value);
    }

    if (baseParam && bases?.length) {
        const entity = bases.find(i => i.id === baseParam);

        if (entity) {
            onSetBase(entity);
        }
    }

    if (bases?.length === 1 && !baseParam) {
        onSetBase(bases?.[0]);
    }
  }, []);

  useEffect(() => {
    setDropdownVisible(isDropdownActive);
  }, [isDropdownActive]);
  const handleDropdown = () => {
    if (preloadEntity === 'base' || preloadEntity === 'event' || preloadEntity === 'slot' || isSingleBase) return;
    setDropdownVisible(isDropdownVisible => !isDropdownVisible);
    handleDropdownClick('base');
    setIsSlotOverbooked(false);
  } 
  const baseList = bases?.map((item) => {
    return (
      <li className="selector__item" onClick={handleClick} id={item.id} key={item.id}>{item.name}</li>
    )
  })
  return (
    <>
      <span>{!preloadEntity && !isSingleBase ? t('sport.schedule.selectors.pickBase') : t('sport.schedule.selectors.base')}</span>
      <div className="selector">
        <div className={!preloadEntity && !isSingleBase ? "selector__trigger" : "selector__trigger disabled"} onClick={handleDropdown}>{base?.name || t('sport.schedule.selectors.pickBase')}</div>
        <ul className={isDropdownVisible ? "selector__list" : "selector__list d-none"}>
          {baseList}
        </ul>
      </div>
    </>
  )
}

export default BaseSelector;