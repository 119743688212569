import React, {useEffect} from 'react';
import jwt_decode from "jwt-decode";
import {useInjection} from "brandi-react";
import {GlobalModelStoreToken} from "../../lib/models/global";
import {eraseCookie} from "../../lib/utils/cookies";

const RefreshToken = ({}) => {

    const { userAccessToken, userRefreshToken } = useInjection(GlobalModelStoreToken).get();

    useEffect(() => {
        try {
            userAccessToken && jwt_decode(userAccessToken);
            userRefreshToken && jwt_decode(userRefreshToken);
        } catch (e) {
            eraseCookie('SV_LOGIN');
            eraseCookie('sv_booking_refresh_token');
        }
    }, [])

    return(
        <></>
    )
}

export default RefreshToken;
