import cookie from "js-cookie";

export const setCookie = (key, value) => {
    cookie.set(key, value, {
        expires: 28,
        path: "/"
    });
};

export const setAllContextCookie = (key, value) => {
    cookie.set(key, value, {
        expires: 28,
        path: "/",
        secure: true,
        sameSite: 'none',
    });
};

export const eraseCookie = key => {
    cookie.remove(key, {
        expires: 28
    });
};

export const getCookie = (key) => {
    return cookie.get(key);
};