import React from "react";
import {useLoaderState} from "../../../lib/api/loaderState";
import loadImage from "../../../styles/assets/images/sv/loader.svg";
import "./style.scss";
import { useInjection } from 'brandi-react';
import { GlobalModelStoreToken } from '../../../lib/models/global';

const Loader = () => {
    const {isLoading} = useLoaderState();
    const globalStateM = useInjection(GlobalModelStoreToken);

    const globalM = globalStateM.get();

    return (
        (isLoading || globalM.isLoading) &&
            <div className="loader">
                <img className="loader__image" src={loadImage} alt="" />
            </div>
    );
};

export default Loader;
