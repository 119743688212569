import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import Divider from "../../../../generic/Divider";
import {t} from "i18next";

// TODO заменить на реальный опыт, когда придет задача

const getSailExperience = (value) => {
    const sailExperiences = {
        "0/7": t('school.0/7'),
        "1/7": t('school.1/7'),
        "2/7": t('school.2/7'),
        "3/7": t('school.3/7'),
        "4/7": t('school.4/7'),
    }

    return sailExperiences[value]
};

const MemberCheckoutCard = ({member, index}) => {
    const {
        first_name,
        last_name,
        phone,
        sailing_experience,
        email,
    } = member


    return (
        <div key={index}>
            <div className="checkout__member-info" key={index}>
                <Row className={"flex-wrap"}>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.firstName.label')}</div>
                        <div className="checkout__member-field">{first_name}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.lastName.label')}</div>
                        <div className="checkout__member-field">{last_name}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.phone.label')}</div>
                        <div className="checkout__member-field">{phone}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.sailingExperience.label')}</div>
                        <div className="checkout__member-field">
                            {getSailExperience(sailing_experience)}
                        </div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.email.label')}</div>
                        <div className="checkout__member-field">{email}</div>
                    </Col>
                </Row>
            </div>
            <Divider/>
        </div>
    );
};

MemberCheckoutCard.propTypes = {
    index: PropTypes.number,
    member: PropTypes.object,
};

export default MemberCheckoutCard;

