import {useApi} from "./useApi";

export const PracticesApi = () => {
    const { rawRequest } = useApi();

    const getPractices = async () =>
        rawRequest('get', '/public/practices');

    const getPracticesByAdventureUrl = async (url) =>
        rawRequest('get', `/public/practices/${url}`);

    const getPracticeByUrl = async (url) =>
        rawRequest('get', `/public/practice/${url}`);

    return {
        getPractices,
        getPracticesByAdventureUrl,
        getPracticeByUrl,
    }
}
