import { isDateExpired } from "../format"
import {COUNTRY} from "../constants";

export const isFieldEmpty = (field, participants, setError) => {
    let result = false
    participants.map((participant, index) => {
        if (!participant[field]) {
            setError && setError(`${field}-${index}`, {type: 'required'})
            result = true
        }
    })
    return result
}

export const validateFields = (participants, form, additionalData) => {
    const {setError, getValues} = form;

    let result = true;
    participants.map((participant, index) => {
        if (getValues()[`passport_date_foreign-${index}`] && participant.passport_num_foreign !== '0000' && isDateExpired(participant.passport_date_foreign, additionalData.endDate)) {
            setError(`passport_date_foreign-${index}`, {
                type: 'validate',
                message: 'Паспорт недействителен в период путешествия, укажите в поле номер паспорта 0000',
            });
            result = false;
        }
        if (!participant.phone) {
            setError && setError(`phone-${index}`, {type: 'required'})
            result = false;
        }
    });
    return result;
}

export const getCountryCode = () => {
    if (COUNTRY === 'EN') {
        return 'ae'
    } else {
        return 'ru'
    }
}