import React from 'react';
import { Input, Label } from 'reactstrap';
import external from "../../../../../styles/assets/images/sv/external.svg";
import PropTypes from "prop-types";

const Consent = ({handleChange, register, consentURL, defaultChecked, error, text, errorMessage, name}) => {
    return (
        <>
            <div className="checkout__input-wrapper">
                <Input type="checkbox" id={name} defaultChecked={defaultChecked}
                        name={name} onChange={handleChange} innerRef={register({required: true})}
                />
                <Label htmlFor={name} className="offer-label">
                    <a href={consentURL} target="_blank" rel="noopener noreferrer">
                        {text}
                        <img src={external} alt=""/>
                    </a>
                </Label>
                <span className={error ? "error-label" : "error-label d-none"}>
                    {error?.type === "required" ? errorMessage : ""}
                </span>
            </div>

        </>
    )
}

Consent.propTypes = {
    handleChange: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    consentURL: PropTypes.string.isRequired,
    defaultChecked: PropTypes.bool.isRequired,
    error: PropTypes.object,
    text: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default Consent
