import React, { useEffect } from 'react';
import './style.scss';
import { useHistory, useLocation } from "react-router-dom";
import cookie from 'js-cookie';
import {useRedirect} from "../../lib/utils/hooks/useRedirect";
import {BASE_SILAVETRA_URL} from "../../lib/api/globalState";
import Logo from "../layout/ Logo";
import {t} from "i18next";

const NotFound = () => {
    const history = useHistory();
    const location = useLocation();
    const {redirectTo, setRedirect, pushWithRedirect} = useRedirect()
    useEffect(() => {
        const { state } = location;
        if (state?.query) {
            const urlParams = new URLSearchParams(state.query);
            if (urlParams.has('redirect')) {
                setRedirect(urlParams.get('redirect'));

                if (urlParams.get('redirect').includes('/corp/')) return pushWithRedirect('/corp-layer')

                return redirectTo();
            }
        }

        if (history.location.pathname === '/' && cookie.get('SV_LOGIN')) {
            if (state?.prev?.includes('login') || state?.prev?.includes('restore')) {
                if(state?.prev.includes('/m')) {
                    history.push('/m/school/')
                } else {
                    window.location.href = BASE_SILAVETRA_URL
                }
            }
            if (state?.prev?.includes('new-password')) {
                if (state?.query) {
                    history.push('/new-password/' + state?.query)
                } else {
                    if(state?.prev.includes('/m')) {
                        history.push('/m/school/')
                    } else {
                        window.location.href = BASE_SILAVETRA_URL
                    }
                }

            }
            if (!state) setTimeout(() => history.push('/sport/'), 3000)
        }
        if (history.location.pathname.includes('restore') && cookie.get('SV_LOGIN')) {
            window.location.href = BASE_SILAVETRA_URL
        }
    }, [])
    return (
        <div id={'login'} className={'overlay w-100 h-100 d-flex justify-content-center flex-column login'}>
            <div className={'login-container'}>
                <Logo />
                <div className="error">
                    <div className="error-status">
                        404
                    </div>
                    <div className="error-description">
                        {t('notFound.pageNotFound')}
                    </div>
                    <a href={BASE_SILAVETRA_URL} className="error-link">
                        {t('notFound.backToMain')}
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NotFound;
