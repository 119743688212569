import { IGroup, IGroupBookingCard } from '../../../../lib/utils/types';
import ProductBookingCard from "../../ProductBookingCard";
import {localizeDate} from "../../../../lib/utils/format";
import lecturesBookingCardBg from "../../../../styles/assets/images/lectures/lectures-bookingCard.jpg";
import theoryBookingCardBg from "../../../../styles/assets/images/theory/theory-bookingCard.jpg";
import React from "react";
import i18next, { t } from "i18next";

const getDateLabel = (group: IGroup) => {
  const {language} = i18next;

  const startDateLocalize = localizeDate(group.startDate, language);
  const endDateLocalize = localizeDate(group.endDate, language);

  let dateRange = `${startDateLocalize} - ${endDateLocalize}`;

  if (startDateLocalize === endDateLocalize) {
    dateRange = startDateLocalize;
  }

  return `${dateRange} : ${t('bookingCard.startTimeAt')} ${group.startTime?.slice(0, -3)}`
}


const GroupBookingCard = (props: IGroupBookingCard) => {
    const dateLabel = props.group.startDate && props.group.endDate && props.group.startTime ? getDateLabel(props.group) : '';

    return <ProductBookingCard product={{...props.group, productType: 'group'}} label={props.groupType === 'lectures' ? t('bookingCard.title') : t('bookingCard.name')} order={props.order}
                               discountedPrice={props.discountedPrice} nonDiscountedPrice={props.nonDiscountedPrice}
                               lowerBadge={props.lowerBadge} priceCurrency={props.priceCurrency}
                               dateLabel={dateLabel}
                               img={props.groupType === 'lectures' ? lecturesBookingCardBg : theoryBookingCardBg} >
        <div>
            <div className='card-label'>{props.groupType === 'lectures' ? t('bookingCard.lecturer') : t('bookingCard.teacher')}</div>
            {!!props.group.teacherName && <div className='card-caption'>
                {props.group.teacherName}
            </div>}
            {!!props.group.extraTeachers && <div className='card-caption mt-1'>
                {props.group.extraTeachers.map(i => (<p className='h6 mb-0'>{i}</p>))}
            </div>}
            {!props.group.teacherName && !props.group.extraTeachers && <div className='card-caption'>
                {t('sport.schedule.selectors.anyInstructor')}
            </div>}
        </div>
        <div className='mt-3'>
            <div className='card-label'>{t('bookingCard.place')}</div>
            <div className='card-caption'>
                {props.group.place}
            </div>
        </div>
        <div className='mt-3'>
            <div className='card-label'>{t('school.address')}</div>
            <div className='card-caption'>
                {props.group.address}
            </div>
        </div>
    </ProductBookingCard>
}

export default GroupBookingCard