import {IApiModel} from "../../../lib/api/index.interface";
import {injected, token} from "brandi";
import container from "../../../lib/ioc";
import {ApiModelStoreToken} from "../../../lib/api/index.model";

export interface IGlobalConfigService {
    getConfig: () => Promise<Record<string, boolean>>
}

export default class GlobalSettingsService implements IGlobalConfigService {
    private api: IApiModel;
    constructor(api: IApiModel) {
        this.api = api;
    }

    getConfig(): Promise<Record<string, boolean>> {
        return this.api.request('get', '/public/global-settings/get-config');
    }
}

export const GlobalSettingsServiceStoreToken = token<IGlobalConfigService>('GlobalSettingsServiceStoreToken');

container.bind(GlobalSettingsServiceStoreToken).toInstance(GlobalSettingsService).inSingletonScope();

injected(GlobalSettingsService, ApiModelStoreToken);