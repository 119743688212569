import React from 'react';
import {t} from "i18next";
import { isProductNotExpired } from '../../../../lib/utils/helpers';

const ChosenSlot = ({slot}) => {
  const isUnavailable = !isProductNotExpired(slot?.start_datetime) || slot?.available_sits === 0 || slot?.is_closed;

  return (
    <div className="item__container d-flex">
      <div className="item__element">
        <span className="item__time">
          {slot?.start_time.slice(0, -3)}
        </span>
      </div>
      <div className="item__element"> 
        <span className="item__places">
          {isUnavailable ? t('sport.noPlaces') : slot?.available_sits}
        </span>
        {!isUnavailable && <span className="item__places-label">
          {t('common.freeSits')}
        </span>}
      </div>
      <div className="item__element">
        <span className="item__event">{slot?.comment}</span>
      </div>
    </div>
  )
}

export default ChosenSlot