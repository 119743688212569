import React, { CSSProperties, FC, useEffect, useState } from 'react';

interface ITimoutCallbackProps {
  seconds: number,
  text: string,
  textStyle?: CSSProperties,
  callbackComponent: JSX.Element,
  depsForReload?: [],
  callback?: () => void,
}

const TimoutCallback: FC<ITimoutCallbackProps> = ({ seconds, text, callbackComponent, depsForReload, callback, textStyle }) => {
  const [time, setTime] = useState(seconds);

  useEffect(() => {
    setTime(seconds);
    setInterval(() => {
      if (time > 0) {
        setTime(time => time - 1);
      }
    }, 1000);
  }, depsForReload || []);

  useEffect(() => {
    if (time < 0) {
      callback && callback();
    }
  }, [time]);

  return (
    <>
      {time > 0 ? <p style={textStyle}>{text}: {time || 0}</p> : callbackComponent}
    </>
  )
}

export default TimoutCallback;