import {PRODUCT_AVAILABILITY} from "sv-common/constants/statuses";

export const RESPONSE_ERROR_MESSAGES = {
    OVERBOOKED: 'errors.someoneAlready',
    EXPIRED: 'errors.expired',
    COACH_NOT_AVAILABLE: 'errors.coachNotAvailable',
    PHONE_TAKEN: 'errors.phoneAlreadyUses'
}

export const parseErrors = (message) => {
    if (!message) return ''
    if (message.includes(PRODUCT_AVAILABILITY.OVERBOOKED) || message.includes('Недостаточно свободных мест'))
        return RESPONSE_ERROR_MESSAGES.OVERBOOKED
    if (message.includes(PRODUCT_AVAILABILITY.EXPIRED))
        return RESPONSE_ERROR_MESSAGES.EXPIRED
    if (message.includes(RESPONSE_ERROR_MESSAGES.PHONE_TAKEN))
        return RESPONSE_ERROR_MESSAGES.PHONE_TAKEN
    if (message.includes('instructorBusy'))
        return RESPONSE_ERROR_MESSAGES.COACH_NOT_AVAILABLE
    return ''
}