import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from "react-router-dom";
import {useApi} from "../../../lib/api/useApi";
import {useRedirect} from "../../../lib/utils/hooks/useRedirect";
import { setCookie } from 'lib/utils/cookies';

const LoginRedirect = () => {
    const {authThroughSocialMedia} = useApi()
    const location = useLocation();
    const params = useParams();
    const history = useHistory();
    const {redirectTo, setRedirect, pushWithRedirect} = useRedirect();

    useEffect(() => {
        authThroughSocialMedia(params.providerName, location)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
                }
                setRedirect(res.data.redirect);
                redirectTo();
                return res;
            })
            .catch((err, res) => {
                if (err?.response?.data?.data?.message === "Email was not available.") {
                    const searchParams = new URLSearchParams(history.location.search);

                    setCookie('sv_booking_vk_email_popup', true);
                    setRedirect(searchParams.get('redirect'));
                    redirectTo();
                }
                if (err?.response?.data?.message[0]?.messages[0]?.id === 'Auth.form.error.email.taken') {
                    pushWithRedirect('/login', {error: err.response.data.message[0].messages[0].id})
                    ;
                }
            });
    }, [history, location.search, params.providerName]);

    return <p>
    </p>
};

export default LoginRedirect;
