import './style.scss';
import {useState} from "react";
import PropTypes from "prop-types";
import { Label } from 'reactstrap';
import { Controller } from 'react-hook-form';
import PhoneInput from "react-phone-input-2";
import { regexes } from '../../../lib/utils/helpers';
import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import i18next from "i18next";
import {getCountryCode} from "../../../lib/utils/helpers/formsHelpers";

const PhoneFieldRHF = ({label, name, form, error, className, withValidationImg = true}) => {
    const [minPhoneLength, setMinPhoneLength] = useState(11);

    const {control, watch} = form;
    const phoneValue = watch(name);

    const { t } = i18next;

    return (
        <>
            <Label className="input-label" htmlFor="phoneNumber">
                {label}
                {
                    (error.message || phoneValue) && withValidationImg &&
                        <span className="validation-img"><img src={error.message ? invalid : valid} alt="" /></span>
                }
            </Label>
            <Controller 
                name={name}
                control={control}
                defaultValue={phoneValue || ''}
                rules={{
                    required: t('common.fields.phone.validation.required'),
                    pattern: {
                        value: regexes.phoneRegex,
                        message: t('common.fields.phone.validation.format')
                    },
                    minLength: {
                        value: minPhoneLength,
                        message: t('common.fields.phone.validation.format')
                    }
                }}
                render={({name, onChange, value, error}) => {
                    return (
                        <PhoneInput
                            inputClass={className ? className : ''}
                            onChange={(value, country) => {
                                onChange(value)
                                if (country.countryCode === 'ru')
                                    setMinPhoneLength(11)
                                else
                                    setMinPhoneLength(null)
                            }}
                            value={value}
                            country={getCountryCode()}
                            inputProps={{ name }}
                            isValid={!(error && error.message)}
                        />
                    )
                }}
            />

            <span className={error.message ? "error-label" : "error-label d-none"}>
                {error?.message}
            </span>
        </>
    )
}

PhoneFieldRHF.propTypes = {
    name: PropTypes.string,
    form: PropTypes.object,
    error: PropTypes.object,
    label: PropTypes.string,
}

export default PhoneFieldRHF