const usePickBase = (eventData, initialGlobalState, setBoats, resetEvent) => {
  const pick = (dataType, pickedData) => {
    const {slot, date, eventId, boat, priceCurrency, priceValue, ...rest} = eventData;
    const preparedData = { ...rest, ...initialGlobalState, shouldResetPeople: true, boat: null, date: null, [dataType]: pickedData };
    const boatsInBase = pickedData?.boats || [];
    setBoats(boatsInBase);

    if (boatsInBase.length === 1) {
      preparedData.boat = boatsInBase[0];
    }

    resetEvent();

    return preparedData;
  }

  return { pick };
}

export default usePickBase;