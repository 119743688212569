import {useApi} from "./useApi";

const PaymentsApi = () => {
    const { request } = useApi();

    const getActualInvoice = async (orderId: number, captcha: string): Promise<{success: boolean, url: string}> =>
        request('post', `/public/payments/order/${orderId}`, {orderId, captcha});

    /**
     * Method to create payment with specified data
     * @param {string} id - id выбранного заказа
     * @param {string} url - URL вовзрата из Fondy
     * @param {object} stateToUse - стейт после авторизации, если globalState не успел обновиться
     * @return {Promise<*>}
     */
    const createPayment = async (id: number | string, url: string, stateToUse?: { [key: string]: any } | null):
        Promise<{status: string, url: string}> =>
            request('post', '/public/payment/', {id, url}, stateToUse);

    return {
        getActualInvoice, createPayment
    }
}

export {
    PaymentsApi,
    PaymentsApi as usePaymentsApi
}
