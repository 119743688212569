import React, {useState, createContext, useMemo, useEffect} from 'react';
import {getCookie, setAllContextCookie, setCookie} from '../utils/cookies';
import { useInjection } from 'brandi-react';
import { GlobalModelStoreToken } from '../models/global';
import {GlobalSettingsModelStoreToken} from "../../entities/globalConfig/model";
export const GlobalState = createContext({});

const parseFeatureFlags = () => {
    let features = [];
    if (process.env.REACT_APP_DEV_USER_FRONTEND_FEATURES)
        features = (process.env.REACT_APP_DEV_USER_FRONTEND_FEATURES || '').split(',')
    if (process.env.REACT_APP_PROD_USER_FRONTEND_FEATURES)
        features = (process.env.REACT_APP_PROD_USER_FRONTEND_FEATURES || '').split(',')
    return features.reduce((acc, curVal) => {
        if (curVal)
            acc[curVal] = true;
        return acc;
    }, {})
}

export const features = parseFeatureFlags()

const selectBaseUrl = () => {
    switch (process.env.REACT_APP_COUNTRY) {
        case 'RU':
            return 'https://silavetra.com';
        case 'EN':
            return 'https://dubai.windrises.com'
        default:
            return 'https://silavetra.com';
    }
};

export const BASE_SILAVETRA_URL = selectBaseUrl();

export const emptyGlobalState = {
    userId: 0,
    userEmail: '',
    userName: '',
    userAccessToken: '',
    userRefreshToken: '',
    userRole: 'Public',
    userConsent: false
};

let initialGlobalState = emptyGlobalState;

if (typeof window !== 'undefined') {
    initialGlobalState = {
        userId: parseInt(getCookie('sv_booking_uid'), 10),
        userEmail: getCookie('sv_booking_email'),
        userName: getCookie('sv_booking_name'),
        userAccessToken: getCookie('SV_LOGIN'),
        userRefreshToken: getCookie('sv_booking_refresh_token'),
        userRole: getCookie('sv_booking_role'),
        isLoginPopupOpen: false,
        isAddVkEmailPopupOpen: getCookie('sv_booking_vk_email_popup'),
        userLoggedInCallback: () => {return undefined},
        isLoading: false,
        clientRole: getCookie('sv_booking_client_role'),
        authProvider: getCookie('sv_booking_auth_provider'),
        userConsent: getCookie('sv_booking_consent')
    };
}

export const GlobalStateProvider = ({ children }) => {
    const globalState = useInjection(GlobalModelStoreToken);
    const globalSettings = useInjection(GlobalSettingsModelStoreToken);
    const [globalStateValue, _setGlobalStateValue] = useState(initialGlobalState);

    const setGlobalStateValue = (state) => {
        setAllContextCookie('sv_booking_uid', state.userId);
        setCookie('sv_booking_email', state.userEmail);
        setCookie('sv_booking_name', state.userName);
        setCookie('SV_LOGIN', state.userAccessToken);
        setCookie('sv_booking_refresh_token', state.userRefreshToken, 58);
        setCookie('sv_booking_role', state.userRole);
        setCookie('sv_booking_client_role', state.clientRole);
        setCookie('sv_booking_auth_provider', state.authProvider);
        setCookie('sv_booking_consent', state.userConsent);
        

        globalState.set(state);
        _setGlobalStateValue(oldState => ({...oldState, ...state}));
    };

    useEffect(() => {
        globalSettings.init();
    }, []);

    useMemo(() => {
      globalState.set(initialGlobalState);
    }, []);

    return (
        <GlobalState.Provider value={[globalStateValue, setGlobalStateValue]}>
            {children}
        </GlobalState.Provider>
    );
};
