import currency from "currency.js";
import {subtract} from "lodash";

import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

dayjs.extend(utc);
dayjs.extend(isBetween);

export const priceFormat  = (price) => {
    const priceFormated = currency(price, {precision : 2, separator: ' ', symbol: ''}).format()

    if (priceFormated.slice(-3) === '.00') return priceFormated.slice(0, -3)
    if (priceFormated.slice(-1) === '0') return priceFormated.slice(0, -1)

    return priceFormated
}

export const getPartPayPrice = (price, partPay) => currency(price).multiply(partPay).divide(100).value;

export const getSubscriptionPrice = (actualPrice, subscriptionCurrentValue, orderPaidValue) => {
    if (typeof orderPaidValue === "number") {
        return orderPaidValue
    }
    const subscriptionPrice = subtract(actualPrice, subscriptionCurrentValue)

    return subscriptionPrice > 0 ? subscriptionPrice : 0
}