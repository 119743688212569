import React from 'react';
import { Col } from 'reactstrap';
import DatePickerInput from '../../../../../generic/DatePickerInput';
import PropTypes from "prop-types";
import { localizeDate, regexes } from '../../../../../../lib/utils/helpers';
import ValidationIcon from '../../../../../generic/ValidationIcon';
import { currentDate, minPassportDate } from "../../../../../../lib/utils/calendar";


const LocalPassportDate = ({id, name, label, error, value, required, defaultValue, handleInputChange, control}) => (
    <Col xs={12} md={6} className={"control-wrapper"}>
        <span className={"input-label"}>{label}</span>
        <ValidationIcon isDirty={!!(error || value)} isValid={!error} />
        <DatePickerInput
            defaultValue={localizeDate(defaultValue)}
            error={error}
            name={name}
            id={id}
            blurFunc={handleInputChange}
            placeholder="01.01.2023"
            control={control}
            rules={{
                required: required,
                pattern: regexes.birthdate,
            }}
            minDate={minPassportDate}
            maxDate={currentDate}
        />
    </Col>
);

LocalPassportDate.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.object,
    value: PropTypes.instanceOf(Date),
    required: PropTypes.bool,
    defaultValue: PropTypes.string,
    handleInputChange: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired
};


export default LocalPassportDate;
