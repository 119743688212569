import React from "react";
import {Col, Row} from "reactstrap";
import PropTypes from "prop-types";
import {localizeDate} from "../../../../../lib/utils/format";
import { helpers } from "../../../../../lib/utils/helpers";
import {StringParam, useQueryParam} from "use-query-params";
import i18next from "i18next";

const { pluralizeWord } = helpers();

const ArrivalSelector = ({ updateData, arrivals }) => {
    const handleChange = (e) => {
        const { id } = e.target;
        updateData(arrivals.find((item) => item.id === +id));
    };
    const [arrivalUrlParam] = useQueryParam("arrival", StringParam);

    const {t, language} = i18next;

    return arrivals &&
        arrivals
            .filter(arrival => arrival.isOpen)
            .map((arrival, index) =>
                (<div className="schedule__item" key={index}>
                    <input type="radio" name="sched-item" onChange={handleChange} id={arrival.id} defaultChecked={arrivalUrlParam === arrival.url} />
                    <label htmlFor={arrival.id} className={"container"}>
                        <Row className={"schedule-item"}>
                            <Col xs={12} sm={9} className="schedule-item__main-info">
                                <div className="schedule-item__title">
                                    {`${localizeDate(arrival.startDate, language)} - ${localizeDate(arrival.endDate, language)}`}
                                </div>
                                <div className="schedule-item__description">
                                    <span className={"schedule-item__places-info--mobile d-inline d-sm-none"}>
                                        {arrival.availableReservedSits <= 0
                                            ? t('travel.noPlaces')
                                            : `${arrival.availableReservedSits} 
                                                ${pluralizeWord(t('common.pluralizePlaces'), arrival.availableReservedSits, 
                                                    language)} 
                                       `}
                                    </span>{" "}
                                    {arrival.name}
                                    <div className="schedule-item__comment">
                                        {arrival.clientComment}
                                    </div>
                                </div>
                            </Col>
                            <Col xs={3} sm={3} className="schedule-item__places-info text-center d-none d-sm-block">
                                {arrival.availableReservedSits <= 0
                                    ? t('travel.noPlaces')
                                    : `${arrival.availableReservedSits} ${pluralizeWord(t('common.pluralizePlaces'), arrival.availableReservedSits,
                                        language)}`
                                }
                            </Col>
                        </Row>
                    </label>
                </div>)
    );
};

ArrivalSelector.propTypes = {
    updateData: PropTypes.func.isRequired,
    arrivals: PropTypes.array.isRequired,
};

export default ArrivalSelector;


