import './style.scss';
import {Input} from "reactstrap";
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import FormButton from "../../generic/buttons/main";
import star from '../../../styles/assets/images/sv/star.svg';
import {useFeedbacksApi} from "../../../lib/api/useFeedbacksApi";
import {NumberParam, useQueryParams} from "use-query-params";
import {getNonEmpty} from "sv-common/functions/formatters";
import {t} from "i18next";

const RATING = [5,4,3,2,1];
const RATING_TYPES = ['feedback.poorly', 'feedback.unsatisfactory', 'feedback.satisfactorily', 'feedback.good', 'feedback.perfectly',];

const Feedback = () => {
    const { handleSubmit, register, watch, formState: {errors} } = useForm({reValidateMode: "onChange"});
    const {feedback} = watch(['feedback']);

    const {getFeedback, sendFeedback} = useFeedbacksApi();

    const [rating, setRating] = useState(null);
    const [sentFeedback, setSentFeedback] = useState(null);

    const [query] = useQueryParams({orderId: NumberParam,});

    useEffect(() =>{
      if (query.orderId) {
        getFeedback(query.orderId).then(res => {
          if (res?.rating) setSentFeedback(res);
        })
      }
    }, [query.orderId])

    const onSubmit = () => {
        if (getNonEmpty(rating) && query.orderId)
            sendFeedback(getNonEmpty(rating), feedback || '', query.orderId)
                .then(res => setSentFeedback({rating: getNonEmpty(rating), comment: feedback}))
    }

    return <>
        {
            !sentFeedback ?
                <form className="review" onSubmit={handleSubmit(onSubmit)}>
                    <p className="input-label">{t('feedback.ratePlease')}</p>
                    <div className="rating">
                        <div className="d-flex justify-content-center flex-row-reverse">
                            {RATING.map((item) => (
                                <React.Fragment key={item}>
                                    <input type="radio" name='rating' id={`rating-${item}`}
                                           onChange={() => {
                                               setRating(item)
                                           }}
                                    />
                                    <label htmlFor={`rating-${item}`}><img alt="" key={item} src={star}/></label>
                                </React.Fragment>
                            ))}
                        </div>
                        <span className="ratingType">{t(RATING_TYPES[rating - 1])}</span>
                    </div>
                    <label htmlFor="feedback" className="input-label">{t('inputs.comment.label')}</label>
                    <Input
                        id="feedback"
                        name="feedback"
                        type="textarea"
                        className={"inputFeedback"}
                        placeholder={t('feedback.commentPlaceholder')}
                        innerRef={register()}
                    />
                    <div className="sendButton">
                        <FormButton className="success-page-button-link" value={t('feedback.send')}
                                    disabled={rating === null}/>
                    </div>
                </form>
            :
                <div className="d-flex justify-content-center">
                    {t('feedback.thanks')}
                </div>
        }
    </>
}

export default Feedback