import "./style.scss";
import React from "react";
import PropTypes from "prop-types";
import { convertPrice } from "sv-common/price/calculate";
import { normalizeCurrency } from "../../../../../../lib/utils/helpers";
import {t} from "i18next";

const PriceBlock = ({
    price,
    people,
    currencies,
    selectedCurrency,
}) => {

    return (
        <div className="price-info d-block d-lg-none price-info-noBg">
            <div className="price-left-col">
                <div className="price-label card-label">{t('common.price')}</div>
                <div className="price-row">
                    <div className="price">
                        {`${Math.round(people * price) || 0} ${normalizeCurrency(selectedCurrency, currencies) || ''}`}
                    </div>
                </div>
                <div className="price-right-col ">
                    {selectedCurrency === "EUR" && (
                        <div className="price-localized price-mobile">
                            {convertPrice(price, currencies.find((currency) => currency.key === "EUR").conversion_rate) * people}{" "}
                            <span>P</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

PriceBlock.propTypes = {
    price: PropTypes.number,
    people: PropTypes.number,
    currencies: PropTypes.array,
    selectedCurrency: PropTypes.string,
}

export default PriceBlock;
