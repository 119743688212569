import React, {useEffect} from 'react';

import {
    NumberParam,
    ObjectParam,
    StringParam, useQueryParam,
    ArrayParam,
    BooleanParam,
    DateParam,
    DateTimeParam,
    JsonParam,
    NumericObjectParam
} from "use-query-params";

type TAllowedParams = typeof NumberParam | typeof StringParam | typeof ObjectParam | typeof ArrayParam
    | typeof JsonParam | typeof DateParam | typeof DateTimeParam | typeof BooleanParam | typeof NumericObjectParam

type TUpdateType = 'replaceIn' | 'pushIn';

const UseQueryParamWithDefault = <T>(name: string, paramType: TAllowedParams, defaultValue?: any, updateType?: TUpdateType): [T, (v: T | undefined) => void ] => {
    // @ts-ignore
    const [param, setParam] = useQueryParam(name, paramType);

    useEffect(() => {
        defaultValue && !param && setParam(defaultValue, 'replaceIn')
    }, []);


    const setParamWithReplace = (newValue: any) => setParam(newValue, updateType || 'replaceIn')
    return [param, setParamWithReplace];

};

export default UseQueryParamWithDefault;