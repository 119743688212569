import Timer from "./Timer";
import PropTypes from "prop-types";
import {t} from "i18next";

const PaymentTimer = ({elapsedTime, setIsSubmitDisabled, className}) => (
    <Timer elapsedTime={elapsedTime} setIsSubmitDisabled={(bool) => bool && setIsSubmitDisabled(() => bool)}
           timerText={(time) => t('paymentTimer.haveTime', {time})}
           className={className}
    >
        {t('paymentTimer.expired')}
    </Timer>
);

PaymentTimer.propTypes = {
    className: PropTypes.string,
    elapsedTime: PropTypes.number,
    setIsSubmitDisabled: PropTypes.func,
};

export default PaymentTimer