import {React} from 'react';
import {Button} from 'reactstrap';
import './style.scss';

const FormButton = ({type, onClick, disabled, className, value, ...rest}) => {
    return (
        <Button color={'primary'} type={type ? type : "submit"} onClick={onClick}
                disabled={disabled} className={'form-button ' + className} {...rest}>{value}</Button>
    )
}

export default FormButton;