import { useApi } from './useApi';
import { JSONToMultipart } from 'sv-common/helpers/jsonToMultipart';

export const usePrivateFileApi = () => {
  const {request} = useApi();

  const upload = async (data) => request('post', `/public/private-file`, JSONToMultipart(data));
  const history = async ({ type, owner }) => request('post', '/public/private-file/history', { type, owner });
  const deleteEntry = async (id) => request('delete', `/public/private-file/${id}`);

  return { history, upload, deleteEntry };
}