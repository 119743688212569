import React, {useEffect, useState} from "react";

const Selector = ({options = [], value, onChange, placeholder, label, optionRender}) => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [valueName, setValueName] = useState('')

    const handleClick = (item) => {
        onChange(item);
        setDropdownVisible(() => false);
    }

    const itemsList = options.map(((item, id) => {
        return (
            <li data-testid={`product-selector-item-${id}`} className="selector__item" onClick={() => handleClick(item)} key={id}>
                {optionRender ? optionRender(item) : item.name}
            </li>
        )
    }))

    const selectedOption = options.find(({id}) => id === value.id)

    useEffect(() => {
        if(selectedOption?.name){
            setValueName(optionRender ? optionRender(selectedOption) : selectedOption?.name)
        } else {
            setValueName(placeholder)
        }
    }, [selectedOption?.id])

    return (
        <>
            <span>{label}</span>
            <div className="selector">
                <div data-testid='product-selector-trigger' className={"selector__trigger"} onClick={() => setDropdownVisible(!isDropdownVisible)}>
                    {valueName}
                </div>
                <ul data-testid='product-selector-list' className={isDropdownVisible ? "selector__list" : "selector__list d-none"}>
                    {itemsList}
                </ul>
            </div>
        </>
    )
}

export default Selector