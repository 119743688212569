import React from "react";
import SVLogo from "../../../styles/assets/images/sv/sv_logo.svg";
import WRLogoBlack from "../../../styles/assets/images/menu/WRLogoBlack.svg";
import {isRus} from "../../../lib/utils/country";

const Logo = () => {
    const logo = isRus ? SVLogo : WRLogoBlack
    return (
        <div className="login-logo">
            <img src={logo} alt=""/>
        </div>
    )
}

export default Logo