import React, {useEffect} from 'react';
import veter from '../../styles/assets/images/sv/veter.svg';
import wind from '../../styles/assets/images/sv/windy.svg';
import './style.scss';
import {useRedirect} from "../../lib/utils/hooks/useRedirect";
import {delay} from "bask-promise";
import {t} from "i18next";
import {isRus} from "../../lib/utils/country";
import Logo from "../layout/ Logo";

const RegisterSuccess = () => {
  const {redirectTo} = useRedirect();

  const image = isRus ? veter : wind

  useEffect(() => delay(2000).then(() => redirectTo()), []);
  return(
    <div id={'login'} className={'overlay w-100 h-100 d-flex justify-content-center flex-column login'}>
      <div className={'login-container'}>
        <Logo />
        <img src={image} alt="" className={'reg-image'} />
        <div className="login-sub-logo login-text reg-title">
            {t('common.success')}
        </div>
      </div>
    </div>
  )
}

export default RegisterSuccess;
