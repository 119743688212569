import React, { FC } from 'react';
import { t } from 'i18next';
import { useInjection } from 'brandi-react';
import { ICertificate, TOrder } from '../../../../lib/utils/types';
import { observer } from 'mobx-react-lite';
import { PayBlockModelStoreToken } from '../models/PayBlockModel/index.model';
import valid from '../../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../../styles/assets/images/sv/invalid.svg';

interface IPaymentCertComponentProps {
  setCertificateApplied: (value?: ICertificate) => void,
  order: TOrder,
  placeholder?: string,
  labelText?: string,
}

const PaymentCertComponent: FC<IPaymentCertComponentProps> = observer((props) => {
  const {
    setCertificateApplied,
    order,
    placeholder,
    labelText,
  } = props;

  const payBlockModel = useInjection(PayBlockModelStoreToken);

  const onClear = () => {
    setCertificateApplied(undefined);
    payBlockModel.resetAppliedDiscounts('certificateApplied');
  };

  return (
    <div data-testid="certificate-input" className='payment-extra-block'>
      <div className='title-label'>{labelText || t('common.certificate')} 
      {(payBlockModel.cert.error || payBlockModel.cert.value) && <span className="validation-img"><img src={payBlockModel.cert.error ? invalid : valid} alt="" /></span>}
      </div>
      <div className='promo-input-block'>
        <input
          type="text"
          value={payBlockModel.cert.query}
          onChange={e => payBlockModel.cert.setQuery(e.target.value)}
          placeholder={placeholder || t('inputs.enterPromocode.placeholderCert') as string}
          className={`bordered-input mb-0 ${payBlockModel.cert.value?.status === "invalid" ? "has-error" : ""}`}
          disabled={order?.hasPaid}
        />
        {!!payBlockModel.cert.error && <span className='text-danger fs-12'>{payBlockModel.cert.error}</span>}
        {!order?.hasPaid && <button className='clear-button' type="button" onClick={onClear} />}
      </div>
    </div>
  )
});

export default PaymentCertComponent;