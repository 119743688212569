import React from "react";
import Timer from "../../../../Timer";
import {useForm} from "react-hook-form";
import styles from "./style.module.scss";
import {Button, Input, Label, Row} from "reactstrap";
import {useCaptcha} from "../../../../../../lib/utils/hooks/useCaptcha";
import i18next from "i18next";
import { useInjection } from 'brandi-react';
import { FORM_STEPS } from '../../../../../../lib/utils/constants';
import { observer } from 'mobx-react-lite';
import { EnterCodeFormModelStoreToken } from './model';
import { FormRouterModelStoreToken } from '../../../model';
import { PhoneLoginModelStoreToken } from '../model';

const EnterCodeForm = ({ onLoginSuccess }) => {
    const model = useInjection(EnterCodeFormModelStoreToken);
    const formRouterModel = useInjection(FormRouterModelStoreToken);
    const phoneLoginModel = useInjection(PhoneLoginModelStoreToken);

    const {register, errors, handleSubmit, setError} = useForm();

    const { t } = i18next;

    const [generateCaptcha, CaptchaDiv] = useCaptcha();

    const onSubmit = ({code}) => {
      model.onSubmit(code, onLoginSuccess, setError);
    };

    return <>
        {CaptchaDiv}
        <form onSubmit={handleSubmit(onSubmit)} action="">
            <span className={styles.sentCode_phone}>{t('common.logIn.phone.codeSent')} +{phoneLoginModel.phone}</span>
            <button className={styles.button_changePhone} onClick={() => phoneLoginModel.setStep(1)}>{t('common.helpers.change')}</button>
            <div className={'control-wrapper'}>
                <Row className={'no-gutters mt-3'}>
                    <Label htmlFor="code" className="login-label">
                        {t('common.fields.phoneCode.label')}
                    </Label>
                    <Input
                        id="code"
                        type="text"
                        name="code"
                        className={`login-input ${errors.code ? "has-error" : ""}`}
                        autoComplete="one-time-code"
                        innerRef={register({
                            required: t('common.fields.phoneCode.validation.required')
                        })}
                    />
                    <span className={"error-label"}>{errors.code?.message}</span>
                </Row>
            </div>
            <Row className={'no-gutters login-button-group'}>
                <Button type="submit" className={'w-100'} color={'primary'}>
                    {t('common.buttons.send')}
                </Button>
                <Timer seconds={60} timerText={(time) => `${t('common.logIn.phone.requestCodeText')} ${time}`} key={model.timerRerender}
                       className={styles.timer}
                >
                    <button
                        type="button"
                        className={styles.button_getNewCode}
                        onClick={() => model.onNewCode(generateCaptcha, setError)}
                    >
                        {t('common.buttons.getNewCode')}
                    </button>
                </Timer>
                <div className="login-separator">{t('common.helpers.or')}</div>
                <Button className={'w-100 btn btn-secondary mt-2'} onClick={() => formRouterModel.setStep(FORM_STEPS.PASSWORD_LOGIN)}>
                    {t('common.buttons.loginViaEmail')}
                </Button>
            </Row>
        </form>
    </>
}

export default observer(EnterCodeForm)