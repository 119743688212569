import {delay} from 'bask-promise';
import React, { useEffect } from 'react';
import {useApi} from '../../lib/api/useApi';
import './style.scss';
import {useRedirect} from "../../lib/utils/hooks/useRedirect";
import Logo from "../layout/ Logo";
import {t} from "i18next";

const Logout = () => {
    const {logout} = useApi();
    const {redirectTo} = useRedirect();

    useEffect(() => {
        logout();
        delay(1000).then(redirectTo);

    }, [])

    return <div id={'login'} className={'overlay w-100 h-100 d-flex justify-content-center flex-column login'}>
        <div className={'login-container'}>
            <Logo />
            <div className="error">
                <div className="error-description">
                    {t('logout')}
                </div>
            </div>
        </div>
    </div>
}

export default Logout;