import {
    FormSteps,
    ICertificateCurrencyInfo,
    ICountry,
    ICurrency,
    ILanguageValidateFields,
    IProductType,
    TCountriesSigns
} from "./types";
// @ts-ignore
import {currencies, CURRENCIES_KEYS} from "sv-common/constants/currencies";

export const COUNTRIES_KEYS = {
    RUSSIA: 'russia',
    EMIRATES: 'uae'
}

export const COUNTRIES_LIST_RU: Array<ICountry> = [
    {
        key: COUNTRIES_KEYS.RUSSIA,
        label: 'Россия'
    },
]

export const COUNTRIES_LIST_EN: Array<ICountry> = [
  {
    key: COUNTRIES_KEYS.EMIRATES,
    label: 'United Arab Emirates'
  }
]

export const COUNTRIES_LIST: Record<string, Array<ICountry>> = {
  RU: COUNTRIES_LIST_RU,
  EN: COUNTRIES_LIST_EN,
}

export const PRODUCT_TYPES_LIST: Array<IProductType> = [
    {
        key: 'slots',
        label: 'Яхтинг в городе'
    },
    {
        key: 'arrivals',
        label: 'Путешествия и лагеря'
    },
    {
        key: 'groups',
        label: 'Школа капитанов'
    },
    {
        key: 'children',
        label: 'Детский клуб'
    },
    {
        key: 'subscriptions',
        label: 'Абонемент'
    },
    {
        key: 'shop',
        label: 'Магазин'
    },
    {
        key: 'glamping',
        label: 'Глэмпинг'
    },
]

export const PRODUCT_TYPES_LIST_EN: Array<IProductType> = [
  {
    key: 'slots',
    label: 'Yachting in the city'
  },
  {
    key: 'arrivals',
    label: 'Adventures'
  },
]

export const PRODUCT_TYPES_LIST_ADAPTER: Record<string, Array<IProductType>> = {
  RU: PRODUCT_TYPES_LIST,
  EN: PRODUCT_TYPES_LIST_EN,
}


export const CYRILLIC_PATTERN = /^[а-яА-ЯёЁ0-9-.,/()\s]+$/

export const LATIN_PATTERN = /^[a-zA-Z0-9-.,/()\s]+$/

export const LANGUAGE_VALIDATE_FIELDS: ILanguageValidateFields = {
    'last_name_en': LATIN_PATTERN,
    'first_name_en': LATIN_PATTERN,
    'passport_authorities_ru': CYRILLIC_PATTERN,
}

export const FORM_STEPS: FormSteps = {
    REGISTER: 'register',
    PHONE_LOGIN: 'phoneLogin',
    PASSWORD_LOGIN: 'passwordLogin',
    PASSWORD_RESTORE: 'passwordRestore',
}

export const CERTIFICATE_CURRENCIES_INFO: Array<ICertificateCurrencyInfo> = [
    {
        key: CURRENCIES_KEYS.RUB,
        sign: currencies.find((currency: ICurrency) => currency.key === CURRENCIES_KEYS.RUB).sign,
        min_range: 1000,
        max_range: 200000,
        step_size: 1000,
        country_key: COUNTRIES_KEYS.RUSSIA
    },
    {
        key: CURRENCIES_KEYS.AED,
        sign: currencies.find((currency: ICurrency) => currency.key === CURRENCIES_KEYS.AED).sign,
        min_range: 100,
        max_range: 5000,
        step_size: 50,
        country_key: COUNTRIES_KEYS.EMIRATES
    },
]

export const DEFAULT_COUNTRY: Record<string, string> = {
  EN: COUNTRIES_KEYS.EMIRATES,
  RU: COUNTRIES_KEYS.RUSSIA,
}

export const COUNTRY: TCountriesSigns = process.env.REACT_APP_COUNTRY || 'RU';
export const LANGUAGE: TCountriesSigns = process.env.REACT_APP_LANGUAGE || 'RU';
export const OTHER_LANGUAGES: string = process.env.REACT_APP_OTHER_LANGUAGES;
export const LANGUAGE_SWITCH: boolean = process.env.REACT_APP_LANGUAGE_SWITCH === '1' || process.env.REACT_APP_LANGUAGE_SWITCH === 'true';
export const IS_RUSSIA = COUNTRY === 'RU';
export const FILE_MAX_SIZE = process.env.REACT_APP_FILE_MAX_SIZE || 5000000;

export const AUTH_EVENT_TYPE = 'authEventType';