import ProfileData from '../ProfileData';
import {NavLink} from 'react-router-dom';
import CertificatesSubscriptions from '../CertificatesSubscriptions'
import './style.scss'
import {t} from "i18next";
import useUserData from "../../../lib/utils/hooks/useUserData";

const Profile = () => {
    const userData = useUserData();

    return (
        <>
            <div className="link_button_wrapper">
                <NavLink to="/logout" className="link_button">{t('profile.exit')}</NavLink>
            </div>
            <ProfileData userData={userData} />
            <span className="sepatator"></span>
            <CertificatesSubscriptions userData={userData} />
        </>
    )
}

export default Profile