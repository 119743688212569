import React from "react";
import { Col, Input, Label } from "reactstrap";
import PropTypes from 'prop-types';
import {t} from "i18next";

const Gender = ({error, name, maleId, femaleId, register, label, handleInputChange, defaultValue}) => (
    <Col xs={12} md={6} className={"control-wrapper"}>
        <div className={"input-label radio"}>{label}</div>
        <Input
            id={maleId}
            name={name}
            type="radio"
            value="m"
            defaultChecked={defaultValue === "m"}
            onChange={handleInputChange}
            innerRef={register({
                required: true,
            })}
        />
        <Label htmlFor={maleId}>{t('inputs.gender.male')}</Label>
        <Input
            id={femaleId}
            name={name}
            type="radio"
            value="f"
            defaultChecked={defaultValue === "f"}
            onChange={handleInputChange}
            innerRef={register({
                required: true,
            })}
        />
        <Label htmlFor={femaleId}>{t('inputs.gender.female')}</Label>
        <div className={error ? "error-label w-100" : "error-label d-none"}>
            {error?.type === "required" ? t('inputs.gender.error') : ""}
        </div>
    </Col>
);

Gender.propTypes = {
    error: PropTypes.object,
    maleId: PropTypes.string.isRequired,
    femaleId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    handleInputChange: PropTypes.func.isRequired
};

export default Gender;
