import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';
import { IApiModel } from '../../../lib/api/index.interface';

export interface IProductsService {
  getProductPlaces: (productType: string, productId: number) => Promise<{ available_sits: number }>
}

export default class ProductsService implements IProductsService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  getProductPlaces(productType: string, productId: number): Promise<{ available_sits: number }> {
    return this.api.rawRequest('get', `/public/${productType}/${productId}/places`);
  }
}

export const ProductsServiceStoreToken = token<IProductsService>('ProductsServiceStoreToken');

container.bind(ProductsServiceStoreToken).toInstance(ProductsService).inSingletonScope();

injected(ProductsService, ApiModelStoreToken);