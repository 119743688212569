import { injected, token } from 'brandi';
import { makeAutoObservable } from 'mobx';
import { GlobalModelStoreToken, IGlobalStateModel } from '../../../../lib/models/global';
import { AuthModelStoreToken, IAuthModel } from '../../../../entities/auth/model';
import { t } from 'i18next';
import { FieldName, FieldValues } from 'react-hook-form/dist/types/fields';
import { ErrorOption } from 'react-hook-form/dist/types/errors';
import container from '../../../../lib/ioc';
import { ICheckAuthCodeRes, ILoginRes } from '../../../../entities/auth/types';

export interface IRegisterFormModel {
  isPassInvisible: boolean,
  isSubmitActive: boolean,
  customError: string,
  passDifficult: string,

  setPassInvisible: (v: boolean) => void,
  setSubmitActive: (v: boolean) => void,
  setCustomError: (v: string) => void,
  setPassDifficult: (v: string) => void,
  onHandleChange: (v: string) => void,

  onSubmit: (data: any, completeUserJwt: string, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void) => Promise<ILoginRes | ICheckAuthCodeRes>,
}

export default class RegisterFormModel implements IRegisterFormModel {
  isPassInvisible = true;
  isSubmitActive = false;
  customError = '';
  passDifficult = ''

  constructor(
    private globalM: IGlobalStateModel,
    private authM: IAuthModel,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPassInvisible(v: boolean) {
    this.isPassInvisible = v;
  }

  setSubmitActive(v: boolean) {
    this.isSubmitActive = v;
  }

  setCustomError(v: string) {
    this.customError = v;
  }

  setPassDifficult(v: string) {
    this.passDifficult = v;
  }

  onHandleChange(value: string) {
    const easy = /^[a-z]+$/;
    const medium = /^[a-zA-Z0-9]+$/;
    const hard = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^]+$/;

    const isEasy = easy.test(value);
    const isMedium = medium.test(value);
    const isHard = hard.test(value);

    if (value.length === 0 ) {
      this.passDifficult = "empty";
      return;
    }
    if (isEasy && value.length < 8) {
      this.passDifficult = "easy";
      return;
    } else if (isMedium) {
      this.passDifficult = "medium";
      return;
    } else if (isHard ) {
      this.passDifficult = "hard";
      return;
    }
  }

  onSubmit(data: any, completeUserJwt: string, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void): Promise<ILoginRes | ICheckAuthCodeRes> {
    this.globalM.set({ isLoading: true });

    const {email, password, consent, name, phone} = data;
    if (!completeUserJwt) {
      return this.authM.register(email, password, consent, name.trim(), phone).catch((e) => {
        const eData = e.response?.data;
        if (eData?.message === t('errors.phoneAlreadyUses')) {
          this.customError = 'phoneTakenError';
        } else if (eData?.message?.[0]?.messages?.[0]?.id === 'Auth.form.error.email.taken') {
          this.customError = 'emailError';
        }
        throw e;
      }).finally(() => {
        this.globalM.set({ isLoading: false });
      });
    } else {
        return this.authM.complete({...data, completeUserJwt}).catch((e) => {
          const eData = e.response?.data;
          if (e.response?.data?.message === "Email already taken" || eData?.message?.[0]?.messages?.[0]?.id === 'Auth.form.error.email.taken') {
            this.customError = 'emailError';
          }
          if (eData?.message?.[0]?.messages?.[0]?.id === 'Auth.form.error.email.format') {
            setError('email', {type: 'pattern'});
          }

          throw e;
        }).finally(() => {
            this.globalM.set({ isLoading: false });
        });
    }
  }
}

export const RegisterFormModelStoreToken = token<IRegisterFormModel>('RegisterFormModelStoreToken');

container.bind(RegisterFormModelStoreToken).toInstance(RegisterFormModel).inSingletonScope();

injected(RegisterFormModel, GlobalModelStoreToken, AuthModelStoreToken);