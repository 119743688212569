import {React} from "react";
import {Col, Row} from "reactstrap";
import Divider from "../../generic/Divider";
import "./style.scss";
import PropTypes from "prop-types";
import {localizeFullDate} from "../../../lib/utils/format";
import i18next from "i18next";
import { TG_OPTIONS } from 'sv-common/constants/statuses';

const MemberCheckoutCard = ({member, index, chosenArrival}) => {
    const {language, t} = i18next;

    // TODO заменить на реальный опыт, когда придет задача
    const sailExperience = {
        "0/7": t('inputs.sailingExperience.travel0/7'),
        "1/7": t('inputs.sailingExperience.travel1/7'),
        "2/7": t('inputs.sailingExperience.travel2/7'),
        "3/7": t('inputs.sailingExperience.travel3/7'),
        "4/7": t('inputs.sailingExperience.travel4/7')
    };

    const {
        first_name,
        last_name,
        gender,
        birthdate,
        phone,
        sailing_experience,
        city,
        email,
        telegram,
        ...additionalFields
    } = member

    const region = member.region;

    const tgOptions = chosenArrival?.tg_options || chosenArrival?.adventure_id?.tg_options;

    return (
        <div key={index}>
            <div className="checkout__member-info" key={index}>
                <Row className={"flex-wrap"}>
                  <Col xs={12} sm={12} className="checkout__member-field-group">
                    <div className="title-label">{t('inputs.email.label')}</div>
                    <div className="checkout__member-field">{email}</div>
                  </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.firstName.label')}</div>
                        <div className="checkout__member-field">{first_name}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.lastName.label')}</div>
                        <div className="checkout__member-field">{last_name}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.gender.label')}</div>
                        <div className="checkout__member-field">
                            {gender === "m" ? t('inputs.gender.male') : gender === '-' ? '-' : t('inputs.gender.female')}
                        </div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.birthdate.label')}</div>
                        <div className="checkout__member-field">{localizeFullDate(birthdate, language)}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.phone.label')}</div>
                        <div className="checkout__member-field">{phone}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.sailingExperience.label')}</div>
                        <div className="checkout__member-field">
                            {sailExperience[sailing_experience]}
                        </div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.city.label')}</div>
                        <div className="checkout__member-field">{city}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.region.label')}</div>
                        <div className="checkout__member-field">{region}</div>
                    </Col>
                    {tgOptions !== TG_OPTIONS.HIDDEN && <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">Telegram</div>
                        <div className="checkout__member-field">{telegram}</div>
                    </Col>}

                    {Object.keys(additionalFields).map((fieldKey, i) =>
                        !!chosenArrival.adventure_id?.req_fields[fieldKey]?.label && 
                            <Col xs={12} sm={6} className="checkout__member-field-group" key={i}>
                                <div className="title-label">{chosenArrival.adventure_id?.req_fields[fieldKey]?.label}</div>
                                <div className="checkout__member-field">{additionalFields[fieldKey]}</div>
                            </Col>
                        
                    )}
                </Row>
            </div>
            <Divider/>
        </div>
    );
};

MemberCheckoutCard.propTypes = {
    chosenArrival: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired
};

export default MemberCheckoutCard;

