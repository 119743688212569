import { IApiModel } from '../../../lib/api/index.interface';
import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';
import { IArrival } from '../../../lib/utils/types';

export interface IArrivalsService {
  getByUrl: (url: string) => Promise<IArrival>,
  listByAdventureUrl: (url: string) => Promise<{arrivals: IArrival[], status: string}>,
}

export default class ArrivalsService implements IArrivalsService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  getByUrl(url: string): Promise<IArrival> {
    return this.api.rawRequest('get', `/public/arrival/${url}`);
  }

  listByAdventureUrl(url: string): Promise<{arrivals: IArrival[], status: string}> {
    return this.api.rawRequest('get', `/public/arrivals/${url}`);
  }
}

export const ArrivalsServiceStoreToken = token<IArrivalsService>('ArrivalsServiceStoreToken');

container.bind(ArrivalsServiceStoreToken).toInstance(ArrivalsService).inSingletonScope();

injected(ArrivalsService, ApiModelStoreToken);