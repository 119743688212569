import { IPaymentDiscountModel } from './PaymentDiscount.interface';
import { makeAutoObservable } from 'mobx';
import container from '../../../../../lib/ioc';
import { token } from 'brandi';

export default class PaymentDiscountModel<T extends unknown> implements IPaymentDiscountModel<T> {
  value?: T;
  query = '';
  error = '';

  constructor() {
    makeAutoObservable(this, {}, {autoBind: true});
  }

  setQuery = (data: string) => {
    this.query = data;
  }

  setValue = (data?: T) => {
    this.value = data;
  }

  clear = () => {
    this.value = undefined;
    this.query = '';
    this.error = '';
  }

  setError = (error: string) => {
    this.error = error;
  }
}

export const PaymentModelStoreToken = token<IPaymentDiscountModel<any>>('PaymentModelStoreToken');

container.bind(PaymentModelStoreToken).toInstance(PaymentDiscountModel).inTransientScope();
