import { getPartialPaymentMarkup } from 'sv-common/price/calculate';
import getCountryValue from './getCountryValue';

const getCountryPartialPaymentMarkup = (partPay) => {
  const countrySkip = getCountryValue({ en: 1 });

  if (countrySkip) {
    return countrySkip;
  }

  return getPartialPaymentMarkup(partPay);
}

export default getCountryPartialPaymentMarkup;