import React, {useMemo} from 'react';
import human from "../../../styles/assets/images/card/human.svg";
import Discount from "../badges/GenericDiscount";
import {priceFormat} from "../../../lib/utils/helpers/priceHelpers";
import PriceReminder from "../../generic/PriceReminder";
import './style.scss';
// @ts-ignore
import {convertPrice} from 'sv-common/price/calculate';
import {IProductBookingCard} from "../../../lib/utils/types";
import {NumberParam, useQueryParam} from "use-query-params";
import currency from "currency.js";
import useQueryParamWithDefault from "../../../lib/utils/hooks/useQueryParamWithDefault";
import {t} from "i18next";
import {isRus} from "../../../lib/utils/country";

export const ProductBookingCard = ({
    order,
    product,
    img,
    label,
    nonDiscountedPrice,
    discountedPrice,
    children,
    upperBadge,
    lowerBadge,
    dateLabel,
    priceCurrency,
    extraDiscountLabel,
    isPromocodeApplied,
}: IProductBookingCard) => {
    const imgUrl: string = useMemo<string>(
        () => `url(${img || "https://via.placeholder.com/200x100"})`, [img]
    );

    const [peopleAmount] = useQueryParamWithDefault<number>('people', NumberParam, 1);
    const [step] = useQueryParam<number>('step');
    const [partPay] = useQueryParamWithDefault<number>('partPay', NumberParam, 100);

    const items = Array.from(Array(peopleAmount).keys());
    const isDiscount = lowerBadge || upperBadge || extraDiscountLabel;
    const paidEn = isRus ? '': 'paid_en'

    const discountedPriceFormat = priceFormat(discountedPrice);

    return <div className="booking-card">
        <div className="primary-info" style={img ? {backgroundImage: imgUrl} : {}}>
            <div className="booking-card-linearBg"></div>
            <div className="card-hole"></div>
            <div className="main-info-label card-label">{label}</div>
            <div className="card-course card-caption">{product?.name}</div>
            <div className="course-date">{dateLabel}</div>
            <div className="promo-label"></div>
            {upperBadge?.active && !isPromocodeApplied && <Discount discount={upperBadge} currency={priceCurrency?.sign}/>}
        </div>
        <div className="secondary-info">
            {children} {/* Fully custom for every product */}
        </div>
        <div className="participants-info">
            {lowerBadge ?
                <div className="applied-promocode">
                    <div className="promo-text">
                        {t('bookingCard.discount')}
                        <br/>
                        {lowerBadge}
                        %
                    </div>
                </div>
                : ''
            }
            <div className="participants-label card-label">{t('bookingCard.members')}</div>
            <div className="participants-icons">
                {items.map((item, index) =>
                    index < 26 ? (<img src={human} alt="" key={index}/>) : (<></>))}
            </div>
        </div>
        <div className={order?.hasPaid ? `price-info paid ${paidEn}` : "price-info"}>
            <div className="price-left-col">
                <div className="price-label card-label">{t('bookingCard.price')}</div>
                <div className="price-row mb-2">
                    {isDiscount && <div className="old-price">
                        {priceFormat(nonDiscountedPrice)}
                        {" "}
                        {priceCurrency?.sign}
                    </div>}
                    <div className="price" data-testid='price-block'>
                      {!isDiscount && discountedPriceFormat === '0' ? <span className="free">{t('bookingCard.free')}</span> :
                        <>
                          {discountedPriceFormat}
                          {" "}
                          {discountedPriceFormat ? priceFormat(order) && priceCurrency?.sign : ''}
                        </>}
                    </div>
                </div>
                {extraDiscountLabel && !isPromocodeApplied && <div className="price-discount-label">
                    {extraDiscountLabel}
                </div>}
            </div>
            <div className="price-right-col">
                {priceCurrency?.key === "EUR" &&
                    <div className="price-localized">
                        {priceFormat(convertPrice(discountedPrice, priceCurrency?.conversion_rate))}
                        {" "}
                        <span>₽</span>
                    </div>
                }
            </div>
            {partPay && partPay !== 100 &&
                <PriceReminder
                    oldPrice={currency(discountedPrice, {precision: 4}).divide(partPay).multiply(100).value}
                    multiplier={partPay}
                    currencyValue={priceCurrency?.sign || ''}
                    step={step}
                />
            }
        </div>
    </div>
};

export default ProductBookingCard;