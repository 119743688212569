import React from "react";
import {
    IGroupBookingCard,
    IPracticeBookingCard,
    ISportBookingCard,
    Products,
    TAdventureBookingCard
} from "../../../../lib/utils/types";
import AdventureBookingCard from "./AdventureBookingCard";
import PracticeBookingCard from "./PracticeBookingCard";
import GroupBookingCard from "./GroupBookingCard";
import SportBookingCard from "./SportBookingCard";


export const BookingCardFactory = (
        product: Products,
        cardProps: TAdventureBookingCard | IPracticeBookingCard | IGroupBookingCard | ISportBookingCard
) => {
    if (cardProps.product === 'practice')
        return <PracticeBookingCard {...cardProps} />
    else if (cardProps.product === 'travel')
        return <AdventureBookingCard {...cardProps} />
    else if (cardProps.product === 'group')
        return <GroupBookingCard {...cardProps} />
    else if (cardProps.product === 'sport')
        return <SportBookingCard {...cardProps} />
}