import React, { useState, useEffect } from "react";
import "./style.scss";
import FormButton from "../../generic/buttons/main";
import { convertPrice } from "sv-common/price/calculate";
import useDiscountCalcEffect from "../useDiscountCalcEffect";
import {BASE_SILAVETRA_URL} from "../../../lib/api/globalState";
import {t} from "i18next";
import useQueryParamWithDefault from '../../../lib/utils/hooks/useQueryParamWithDefault';
import { NumberParam } from 'use-query-params';
import { useInjection } from 'brandi-react';
import { AdventureContainerModelStoreToken } from '../AdventureContainer/model';
import { ArrivalModelStoreToken } from '../../../entities/arrivals/model';
import { GlobalSaleModelToken } from '../../../lib/models/availablePriceFromSale';

const PriceBlock = ({
    onSubmit,
    selectedCurrency,
    isButtonDisabled = false,
    noSeatsAvailable = false,
    buttonValue,
    isShowOnMainButton,
}) => {
    const model = useInjection(AdventureContainerModelStoreToken);
    const arrivalM = useInjection(ArrivalModelStoreToken);
    const globalSaleM = useInjection(GlobalSaleModelToken);

    const arrival = arrivalM.item;
    const price = arrivalM.item?.price_value;
    const currencies = model.currencies;

    const [people] = useQueryParamWithDefault('people', NumberParam);

    const normalizeCurrency = (currencyKey) =>
        currencies.length && currencies.filter((item) => item.key === currencyKey)[0];
    const calculatedDiscount = useDiscountCalcEffect(arrival, undefined, undefined, model.setIsTeamBookActive, currencies);
    const [teamDiscountSize, setTeamDiscountSize] = useState(0);

    useEffect(() => {
        setTeamDiscountSize(() => calculatedDiscount?.value ? calculatedDiscount?.value : 0);
    }, [calculatedDiscount?.value])

    return (
        <div className="price-info d-block d-lg-none stickyBlock">
            <div className="price-left-col">
                <div className="price-label card-label">{t('common.price')}</div>
                <div className="price-row">
                    {!!teamDiscountSize && (
                        <div className="old-price">
                            {`${(people * ((globalSaleM.calculatePrice(arrival, people)) || price)) || 0} ${normalizeCurrency(selectedCurrency)?.sign || ''}`}
                        </div>
                    )}
                    <div className="price">
                        {`${Math.round((people * ((globalSaleM.calculatePrice(arrival, people)) || price)) - teamDiscountSize) || 0} ${normalizeCurrency(selectedCurrency)?.sign || ''}`}
                    </div>
                </div>
                {!!teamDiscountSize && <div className="price-discount-label">
                    {t('discounts.peopleFull', {peopleCount: arrival?.team_book_discount_condition})}
                </div>}
                {false && <div className="price-discount-label">Скидка за покупку билета заранее</div>}
                <div className="price-right-col ">
                    {selectedCurrency === "EUR" && (
                        <div className="price-localized price-mobile">
                            {convertPrice(price, currencies.find((currency) => currency.key === "EUR")?.conversion_rate) * people}{" "}
                            <span>P</span>
                        </div>
                    )}
                </div>
            </div>
            {!noSeatsAvailable && <FormButton value={buttonValue} onClick={onSubmit} disabled={isButtonDisabled} className="mobile-width-100" />}
            {isShowOnMainButton && <>
                <p className="mt-3">{t('common.placesEnded')}</p>
                <FormButton
                    value={t('common.toMain')}
                    onClick={() => window.location.href = BASE_SILAVETRA_URL + "/travel"}
                    className={"d-lg-block"}
                />
            </>}
        </div>
    );
};

export default PriceBlock;
