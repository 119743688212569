import React from "react";
import { Col, Input } from "reactstrap";
import { regexes } from "../../../../../../lib/utils/helpers";
import PropTypes from 'prop-types';
import ValidationIcon from "../../../../../generic/ValidationIcon";
import {t} from "i18next";


const TextField = ({
                       id, name, label, placeholder, error, value, defaultValue, register, handleInputChange,
                       disabled = false, pattern = regexes.personalDataRegex, patternError,
                       required = true
                   }) => {
    const errorMessage = patternError
        || (name.includes('email') ? t('inputs.email.pattern') : t('inputs.pattern'));
    return (
        <Col xs={12} md={6} className={"control-wrapper"}>
            <span className={"input-label"}>{label}</span>
            <ValidationIcon isDirty={!!(error || value)} isValid={!error} />
            <Input
                type="text"
                id={id}
                name={name}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={e => handleInputChange(e)} 
                innerRef={register({
                    pattern: name.includes('email') ? regexes.emailRegex : pattern,
                    required: required,
                })}
                disabled={disabled}
            />
            <span className={error ? "error-label" : "error-label d-none"}>
                {error?.type === "required" ? t('inputs.required') : ""}
                {error?.type === "pattern" ? errorMessage : ""}
            </span>
        </Col>
    )
};

TextField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    error: PropTypes.object,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    register: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    pattern: PropTypes.instanceOf(RegExp),
    patternError: PropTypes.string,
    required: PropTypes.bool
};

export default TextField;
