import {useForm} from "react-hook-form";
import {Button, Label, Row} from "reactstrap";
import PhoneFieldRHF from "../../../../PhoneFieldRHF";
import {useCaptcha} from "../../../../../../lib/utils/hooks/useCaptcha";
import i18next from "i18next";
import { useInjection } from 'brandi-react';
import { FormRouterModelStoreToken } from '../../../model';
import { FORM_STEPS } from '../../../../../../lib/utils/constants';
import { observer } from 'mobx-react-lite';
import { EnterPhoneFormModelStoreToken } from './model';
import { PhoneLoginModelStoreToken } from '../model';

const EnterPhoneForm = () => {
    const model = useInjection(EnterPhoneFormModelStoreToken);
    const formRouterModel = useInjection(FormRouterModelStoreToken);
    const phoneLoginModel = useInjection(PhoneLoginModelStoreToken);

    const [generateCaptcha, CaptchaDiv] = useCaptcha();

    const form = useForm({
        mode: "onBlur",
        defaultValues: {phoneDefaultValue: phoneLoginModel.phone}
    });
    const {errors, handleSubmit, setError} = form;

    const { t } = i18next;

    const onSubmit = ({phone}) => model.onSubmit(phone, generateCaptcha, setError);

    return <>
        {CaptchaDiv}
        <form onSubmit={handleSubmit(onSubmit)} action="">
            <div className={'control-wrapper'}>
                <Label htmlFor="phone" className="login-label">
                    {t('common.fields.phone.label')}
                </Label>
                <PhoneFieldRHF form={form} name='phone' error={errors.phone || {}} withValidationImg={false}
                               className={`login-phone-input ${errors.phone ? "has-error" : ""}`}
                />
            </div>
            <Row className={'no-gutters login-button-group'}>
                <Button type="submit" className={'w-100'} color={'primary'}>
                    {t('common.buttons.getCode')}
                </Button>
                <Button className={'w-100 btn btn-secondary'} onClick={() => formRouterModel.setStep(FORM_STEPS.REGISTER)}>
                    {t('common.buttons.signUp')}
                </Button>
                <div className="login-separator">
                    {t('common.helpers.or')}
                </div>
                <Button className={'w-100 btn btn-secondary mt-2'} onClick={() => formRouterModel.setStep(FORM_STEPS.PASSWORD_LOGIN)}>
                    {t('common.buttons.loginViaEmail')}
                </Button>
            </Row>
        </form>
    </>
}

export default observer(EnterPhoneForm);