import { injected, token } from 'brandi';
import container from '../../../../../../lib/ioc';
import { GlobalModelStoreToken, IGlobalStateModel } from '../../../../../../lib/models/global';
import { IPhoneAuthModel, PhoneAuthModelStoreToken } from '../../../../../../entities/auth/model/phoneModel';
import { FORM_STEPS } from '../../../../../../lib/utils/constants';
import { IPhoneLoginModel, PhoneLoginModelStoreToken } from '../model';
import { FormRouterModelStoreToken, IFormRouterModel } from '../../../model';
import { ICheckAuthCodeRes, ILoginRes } from '../../../../../../entities/auth/types';
import { FieldName, FieldValues } from 'react-hook-form/dist/types/fields';
import { ErrorOption } from 'react-hook-form/dist/types/errors';
import { t } from 'i18next';
import { makeAutoObservable } from 'mobx';

interface IEnterCodeFormModel {
  onSubmit: (code: string, onLoginSuccess: (v: ILoginRes | ICheckAuthCodeRes) => void, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void) => void,
  onNewCode: (generateCaptcha: (cb: (token: string) => void) => void, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void) => void,
  timerRerender: number,
}

export default class EnterCodeFormModel implements IEnterCodeFormModel {
  timerRerender = 0;
  constructor(
    private globalM: IGlobalStateModel,
    private phoneAuthM: IPhoneAuthModel,
    private phoneLoginM: IPhoneLoginModel,
    private formRouterM: IFormRouterModel,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onSubmit(code: string, onLoginSuccess: (v: ILoginRes | ICheckAuthCodeRes) => void, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void) {
    this.globalM.set({ isLoading: true });
    this.phoneAuthM.checkCode(code, this.phoneLoginM.token || '')
      .then(res => {
        if (res.data.completeUserJwt) {
          this.formRouterM.setCompleteUserJwt(res.data.completeUserJwt);
          this.formRouterM.setStep(FORM_STEPS.REGISTER);
        } else {
          onLoginSuccess(res)
        }
      })
      .catch((e) => {
        if (e.response?.data?.message === 'Invalid Code') {
          setError('code', {
            type: 'invalidCode',
            message: t('common.fields.phoneCode.validation.invalidCode') as string,
          });
        } else {
          setError('code', {
            type: 'unknownError',
            message: t('common.fields.phoneCode.validation.unknownError') as string,
          });
        }
      })
      .finally(() => this.globalM.set({ isLoading: false }));
  }

  onNewCode(generateCaptcha: (cb: (token: string) => void) => void, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void) {
    this.globalM.set({ isLoading: true });
    generateCaptcha((captcha) => {
      this.phoneAuthM.sendCode((this.phoneLoginM.phone || '').toString(), captcha)
        .then(() => this.timerRerender += 1)
        .catch(error => this.phoneAuthM.checkCodeErrorHandler(error, setError, 'code'))
        .finally(() => this.globalM.set({ isLoading: false }));
    });
  }
}

export const EnterCodeFormModelStoreToken = token<IEnterCodeFormModel>('EnterCodeFormModelStoreToken');

container.bind(EnterCodeFormModelStoreToken).toInstance(EnterCodeFormModel).inSingletonScope();

injected(EnterCodeFormModel, GlobalModelStoreToken, PhoneAuthModelStoreToken, PhoneLoginModelStoreToken, FormRouterModelStoreToken);