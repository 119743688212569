import Header from '../layout/Header';
import { Row, Col, Container } from 'reactstrap';
import CertificateRegister from './CertificateRegister';
import CertificateRender from './CertificateRender'
import {useEffect, useState} from 'react'
import CertificateSuccessPage from './CertificateSuccessPage';
import './style.scss';
import { usePublicEndpoints } from "../../lib/api/usePublicEndpoints";
import {NumberParam} from "use-query-params";
import {useLogin} from "../../lib/utils/hooks/useLogin";
import useQueryParamWithDefault from "../../lib/utils/hooks/useQueryParamWithDefault";
import { t } from 'i18next';

const CertificateContainer = () => {
    const { getCurrencies, getOrder} = usePublicEndpoints();

    const [step, setStep] = useQueryParamWithDefault("step", NumberParam, 1);
    const [orderId, setOrderId] = useQueryParamWithDefault("orderId", NumberParam);

    const {isUserLoggedIn} = useLogin();

    const [currencies, setCurrencies] = useState([])
    const [certificate, setCertificate] = useState({});
    const [isPaid, setIsPaid] = useState(false)

    const fetchCertificate = async (orderId) => {
        const {certificate, ...order} = await getOrder(orderId)
        setIsPaid(order?.hasPaid);
        setCertificate({
            certificateNum: certificate.name,
            toName: certificate.assigneeName,
            toSurname: certificate.assigneeSurname,
            currency: certificate.currency,
            selectedProducts: certificate.productType,
            country: certificate.country,
            comment: certificate.comment,
            certificateSum: certificate.value,
        })
    }

    useEffect(() => {
        if (!isUserLoggedIn) {
            setStep(1)
            setOrderId(undefined)
            setIsPaid(false)
        }
    }, [step])

    useEffect(() => {
        getCurrencies().then(currencies => setCurrencies(currencies))
        if (step === 2 && orderId)
            fetchCertificate(orderId).catch((e) => {return e})
    }, [])

    return (
        <div className="certificate-container">
            <Container className="pl-0 pr-0">
                <Row className={'min-vh-100 no-gutters'}>
                    <Col xs={12} lg={6} className="certificate-register">
                        <Header label={t("certificate.headerLabel")}  />
                        {step === 1 && <CertificateRegister />}
                        {step === 2 && isPaid && <CertificateSuccessPage certificateNum={certificate.certificateNum} />}
                    </Col>
                    <Col xs={6} className={`offset-sm-6 min-vh-100 fixed-top d-none d-lg-flex align-items-start
                                            justify-content-center certificate-render
                                            ${step === 2 && isPaid && 'certificate-render-height-auto'}`}>
                            <CertificateRender certificate={certificate} currencies={currencies} step={isPaid ? step : 1} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CertificateContainer