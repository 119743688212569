// eslint-disable-next-line
import React, {useRef} from 'react';
import {NODE_ENV, RECAPTCHA_KEY} from "../../api/useApi";
import ReCAPTCHA from "react-google-recaptcha";

export const useCaptcha = (cb) => {
    const recaptchaRef = useRef(undefined);
    const cbWithCaptcha = async (insideCallback) => {
        if (NODE_ENV === 'development' || NODE_ENV === 'test' || !NODE_ENV) {
            cb && cb('test');
            insideCallback && insideCallback('test')
            return;
        }
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset()
        cb && cb(token)
        insideCallback && insideCallback(token)
    }
    return [
        cbWithCaptcha,
        (NODE_ENV === 'development' || NODE_ENV === 'test' || !NODE_ENV)
            ? <></>
            :  <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RECAPTCHA_KEY}
            />
    ]
}