import React from "react";
import { Col, Row } from 'reactstrap';
import PropTypes from "prop-types";
import {trimOnChange} from '../../../../lib/utils/helpers';
import Gender from "./FormFields/Gender";
import BirthDate from "./FormFields/BirthDate";
import PhoneNumber from "./FormFields/PhoneNumber";
import SailingExperience from "./FormFields/SailingExperience";
import AdditionalField from "./FormFields/AdditionalField";
import ForeignPassportDate from "./FormFields/ForeignPassportDate";
import LocalPassportDate from "./FormFields/LocalPassportDate";
import ShirtSize from "./FormFields/ShirtSize";
import TextField from "./FormFields/TextField";
import AddressFieldWithAutocomplete from "../../../common/AddressFieldWithAutocomplete";
import { usePublicEndpoints } from '../../../../lib/api/usePublicEndpoints';
import {Link} from "react-router-dom";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';
import PrivateFileComponent from '../../../common/PrivateFile';
import { Constants } from 'sv-common';
import { DropZoneTypes } from '../../../generic/Dropzone';

const SPECIAL_FIELDS = ['shirt_size', 'passport_date_foreign', 'passport_date_ru']

const MemberCard = ({practice, peopleData, index, form, updatePeopleData, shirtSizes, firstUserFields}) => {

    const {
        birthdate, city, email, first_name, gender, last_name, phone, sailing_experience,
        ...additionalFields
    } = practice.adventureData.reqFields;

    const {register, errors, clearErrors, getValues, control} = form;
    let values = getValues();

    const getCommonProps = key => {
        const currentMemberData = peopleData[index] || {};
        const defaultValue = index === 0 ? firstUserFields[key] : currentMemberData[key];
        const disabled = key === "first_name" && index === 0 && defaultValue
            || key === "last_name" && index === 0 && defaultValue
            || key === "email" && index === 0 && defaultValue;
        const patternRules = disabled ? {pattern: false} : {};

        return {
            id: `${key}-${index}`,
            name: `${key}-${index}`,
            defaultValue: defaultValue,
            disabled: disabled,
            error: errors[`${key}-${index}`],
            value: values[`${key}-${index}`],
            handleInputChange,
            register,
            ...patternRules,
        }
    }

    const handleInputChange = e => {
        const {name, value} = e.target;
        const index = name.split('-')[1];
        if (name.startsWith('passport_num_foreign') && value === '0000') {
            clearErrors(`passport_num_foreign-${index}`);
        } else if (name.startsWith('email')) {
            trimOnChange(e)
        }
        updatePeopleData(e)
    }
    const {getAddressSuggestions} = usePublicEndpoints();

    const isFirst = index === 0;

    return (
        <div className={"member"}>
            <div className={"member__title"}>{t('common.member')} #{index + 1}</div>
            <Row>
                {/* Здесь и дальше к name и id полей добавляется index конкретного участника для того, чтобы React Hook Form смог корректно валидировть каждое поле в отдельности. Если index убрать, одинаковые поля у участников будут валидироваться как одно */}
                <TextField label={t('inputs.firstName.label')} placeholder={t('inputs.firstName.placeholder')} {...getCommonProps('first_name')}
                           patternError={t('inputs.cyrillicAndCapitalError')} />
                <TextField label={t('inputs.lastName.label')} placeholder={t('inputs.lastName.placeholder')} {...getCommonProps('last_name')}
                           patternError={t('inputs.cyrillicAndCapitalError')} />
                <Gender label={t('inputs.gender.label')} {...getCommonProps('gender')} maleId={`gender-m-${index}`} femaleId={`gender-f-${index}`} />
                <BirthDate label={t('inputs.birthdate.label')} control={control} {...getCommonProps('birthdate')} />
                <PhoneNumber label={t('inputs.phone.label')} control={control} index={index} {...getCommonProps('phone')} />
                <SailingExperience label={t('inputs.sailingExperience.label')} {...getCommonProps('sailing_experience')} control={control} />
                <AddressFieldWithAutocomplete label={t('inputs.city.label')} placeholder={t('inputs.city.placeholder')}
                    {...getCommonProps('city')} required fieldName={'city'}
                    onChange={value => updatePeopleData({target: {name: `city-${index}`, value}})}
                    language={practice.adventureData?.type === 'russia' ? 'ru' : 'en'}
                    getAddressSuggestions={getAddressSuggestions} />
                <AddressFieldWithAutocomplete label={t('inputs.region.label')} placeholder={t('inputs.region.placeholder')}
                    {...getCommonProps('region')} required fieldName={'region'}
                    onChange={value => updatePeopleData({target: {name: `region-${index}`, value}})}
                    language={practice.adventureData?.type === 'russia' ? 'ru' : 'en'}
                    getAddressSuggestions={getAddressSuggestions} />
                <TextField label={'Telegram'} placeholder={'silavetrasila'} {...getCommonProps('telegram')}
                           pattern={/^([^А-ЯЁа-яё])+$/} patternError={t('inputs.telegram.pattern')} required={false}/>
                <TextField label={t('inputs.email.label')} placeholder={'example@mail.com'} {...getCommonProps('email')} />
                {Object.keys(additionalFields)
                    .filter(key => !SPECIAL_FIELDS.includes(key))
                    .map(key =>
                        <AdditionalField label={additionalFields[key].label} key={`${key}-${index}`} fieldKey={key} required={additionalFields[key].required}
                            placeholder={additionalFields[key].label} {...getCommonProps(key)} />
                )}
                {additionalFields.passport_date_foreign &&
                    <ForeignPassportDate control={control} label={additionalFields.passport_date_foreign?.label}
                        required={additionalFields.passport_date_foreign?.required}
                        endDate={practice.end_date}
                        passportNumber={peopleData[index]?.passport_num_foreign ||
                            firstUserFields?.passport_num_foreign}
                        {...getCommonProps('passport_date_foreign')}
                    />
                }
                {additionalFields.passport_date_ru &&
                    <LocalPassportDate label={additionalFields.passport_date_ru?.label}
                        required={additionalFields.passport_date_ru?.required}
                        {...getCommonProps('passport_date_ru')} control={control}
                    />
                }
                {additionalFields.shirt_size && (
                    <ShirtSize shirtSizes={shirtSizes} label={additionalFields.shirt_size.label}
                        required={additionalFields.shirt_size.required} {...getCommonProps('shirt_size')}
                    />
                )}
                {isFirst && <ElementWithAccessInCountries hideInCountries={['RU']}>
                  <Col xs={12} md={6} className={'control-wrapper'}>
                    <span className={'input-label'}>{t('inputs.passportFile')}</span>
                    <PrivateFileComponent withSlider={false} type={Constants.PRIVATE_FILE_TYPES.PASSPORT} dropZoneType={DropZoneTypes.common} />
                  </Col>
                </ElementWithAccessInCountries>}
            </Row>
            {isFirst &&
                <small>{t('common.canEditData')}&nbsp;<Link to="/me" target="_blank">{t('common.personalAccount')}</Link></small>
            }
        </div>
    );
};

MemberCard.propTypes = {
    updatePeopleData: PropTypes.func.isRequired,
    practice: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    form: PropTypes.object.isRequired,
    firstUserFields: PropTypes.object.isRequired,
};

export default MemberCard;
