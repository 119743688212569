import { Alert } from 'reactstrap';
import { useInjection } from 'brandi-react';
import { observer } from 'mobx-react-lite';
import { t } from 'i18next';
import { GlobalSaleModelToken } from '../../../lib/models/availablePriceFromSale';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

const AlertDismissibleExample = observer(() => {
  const globalSaleM = useInjection(GlobalSaleModelToken);

  const [query] = useQueryParams({
    people: withDefault(NumberParam, 1),
  });

  return globalSaleM.excess.isExcess && <Alert color="danger">
    {t('common.placesWithDiscountEnded1', { places: query.people, remainder: globalSaleM.excess.max })} <a target='_blank' href='https://t.me/silavetrasila'>@silavetrahelp</a> {t('common.placesWithDiscountEnded2')}
  </Alert> || null;
});

export default AlertDismissibleExample;