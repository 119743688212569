import { makeAutoObservable, toJS } from 'mobx';
import { injected, token } from 'brandi';
import container from '../../ioc';
// @ts-ignore
import { parseOffsetFromString } from 'sv-common/helpers/frontend/time';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IExcessObj {
  isExcess?: boolean,
  max?: number,
}

export interface IGlobalSaleModel {
  calculatePrice: (product: any, currentCount: number) => number | undefined,
  excess: IExcessObj,
}

export default class GlobalSaleModel implements IGlobalSaleModel {
  excess: IExcessObj = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  calculatePrice(product: any, currentCount: number): number | undefined {
    if (!product) {
      return;
    }

    const sale = product.global_event_sale;
    if (sale && sale.count) {
      const offset = parseOffsetFromString(product.timezone);

      const start = dayjs(sale.start_date).utcOffset(offset);
      const end = dayjs(sale.end_date).utcOffset(offset);
      const curr = dayjs(dayjs().utcOffset(offset));

      if (curr.isBetween(start, end, 'minute', '[]')) {
        if (sale.count >= currentCount) {
          this.excess.isExcess = false;
          return sale.discounted_price;
        } else {
          this.excess.isExcess = true;
        }

        this.excess.max = sale.count;
      }
    }
  }
}

export const GlobalSaleModelToken = token<IGlobalSaleModel>('GlobalSaleModelToken');

container.bind(GlobalSaleModelToken).toInstance(GlobalSaleModel).inSingletonScope();

injected(GlobalSaleModel);