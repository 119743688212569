import React, {MouseEventHandler} from 'react';
import {Button} from 'reactstrap';
import './style.scss';

type TFormButton = {
    type?: "button" | "submit" | "reset" | undefined,
    onClick?: MouseEventHandler<HTMLButtonElement> | (() => void),
    disabled?: boolean,
    className?: string,
    value: string,
    role?: string,
}

const FormButton = ({type, onClick, disabled, className, value, ...rest}: TFormButton) => {
    return (
            <Button color={'primary'} type={type ? type : "submit"} onClick={onClick}
                    disabled={disabled} className={'form-button ' + className} {...rest}>{value}</Button>
    )
}

export default FormButton;