import { useContext, useEffect } from 'react';
import { useApi } from './useApi';
import { GlobalState } from './globalState';

const CarrotProvider = ({ children }) => {
  const {initCarrotUser} = useApi()
  const [globalStateValue] = useContext(GlobalState);

  useEffect(() => {
    if (globalStateValue.userId) {
      initCarrotUser();
    }
  }, [globalStateValue.userId]);
  return <>{children}</>;
}

export default CarrotProvider;