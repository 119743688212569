import React, {useState, useEffect} from 'react';
import ChosenSlot from '../ChosenSlot';
import './style.scss';
import {t} from "i18next";
import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {NumberParam} from "use-query-params";

const SlotSelector = ({slots, disabled, orderId, isDataEntered, selectedSlot, setPickedData, handleDropdownClick, isDropdownActive, peopleAmount, preloadEntity,
    isSlotOverbooked, setIsSlotOverbooked}) => {
  const [slot, setSlot] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [slotParam, setSlotParam] = useQueryParamWithDefault('slot', NumberParam);

  const handleClick = (e) => {
    const {id} = e.currentTarget;
    const slotValue = slots?.filter(item => item.id == id)[0]
    setSlot(slotValue);
    setDropdownVisible(() => false);
    setPickedData(slotValue);
    setSlotParam(id);
  }
  useEffect(() => {
    if (preloadEntity === 'slot' || isDataEntered || orderId) {
        const slotValue = isDataEntered ? selectedSlot : slots?.[0]
        setSlot(() => slotValue);
    } else {
        setSlot(() => null);
    }
}, [slots]);
  useEffect(() => {
    if (isSlotOverbooked) setSlot(() => null)
  }, [isSlotOverbooked]);
  useEffect(() => {
    setDropdownVisible(isDropdownActive);
  }, [isDropdownActive]);

   useEffect(() => {
       if (slotParam && slots) {
           const entity = slots.find(i => i.id === slotParam);

           if (entity) {
               setSlot(entity);
               setPickedData(entity);
           }
       }
   }, [slots]);

  const handleDropdown = () => {
    if (preloadEntity === 'slot') return;
    setDropdownVisible(isDropdownVisible => !isDropdownVisible);
    handleDropdownClick('slot');
    setIsSlotOverbooked(false);
  }

  return (
    <>
      <span className={disabled ? 'd-none' : ''}>{preloadEntity !== 'slot' ? t('sport.schedule.selectors.pickTime') : t('sport.schedule.selectors.time')}</span>
      <div className={disabled ? 'selector time d-none' : 'selector time'}>
        <div className={preloadEntity !== 'slot' ? "selector__trigger" : "selector__trigger disabled"} onClick={handleDropdown}>{slot ? <ChosenSlot slot={slot} /> : t('sport.schedule.selectors.pickTime')}</div>
        <ul className={isDropdownVisible ? "selector__list" : "selector__list d-none"}>
          {slots?.filter(item => item?.available_sits >= peopleAmount).map((item, index) => {
            return (
                <li className="selector__item" onClick={handleClick} id={item.id} key={index}>
                  <div className="item__container d-flex">
                    <div className="item__element">
                        <span className="item__time">
                            {item.start_time.slice(0, -3)}
                        </span>
                    </div>
                    <div className="item__element">
                        <span className="item__places">
                            {item.available_sits}
                        </span>
                        <span className="item__places-label">
                            {t('common.freeSits')}
                        </span>
                    </div>
                    <div className="item__element">
                      <span className="item__event">{item.comment}</span>
                    </div>
                  </div>
                </li>
            )
          })}
        </ul>
          <div className='mt-2'>{slot?.explanation}</div>
      </div>
    </>
  )
}

export default SlotSelector;