import { injected, token } from 'brandi';
import { AUTH_EVENT_TYPE } from '../../../../../lib/utils/constants';
import container from '../../../../../lib/ioc';
import { GlobalModelStoreToken, IGlobalStateModel } from '../../../../../lib/models/global';
import { AuthModelStoreToken, IAuthModel } from '../../../../../entities/auth/model';
import { t } from 'i18next';
import { FieldName, FieldValues } from 'react-hook-form/dist/types/fields';
import { ErrorOption } from 'react-hook-form/dist/types/errors';
import { ICheckAuthCodeRes, ILoginRes } from '../../../../../entities/auth/types';

interface ISubmitData {
  email: string,
  password: string,
}

interface IPasswordLoginModel {
  onSubmit: (data: ISubmitData, generateCaptcha: (cb: (token: string) => void) => void, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void, clearErrors: (name?: (FieldName<FieldValues> | FieldName<FieldValues>[])) => void, onLoginSuccess: (v: ILoginRes | ICheckAuthCodeRes) => void) => void,
}

export default class PasswordLoginModel implements IPasswordLoginModel {
  constructor(
    private globalM: IGlobalStateModel,
    private authM: IAuthModel,
  ) {
  }

  onSubmit(data: ISubmitData, generateCaptcha: (cb: (token: string) => void) => void, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void, clearErrors: (name?: (FieldName<FieldValues> | FieldName<FieldValues>[])) => void, onLoginSuccess: (v: ILoginRes | ICheckAuthCodeRes) => void) {
    const globalState = this.globalM.get();
    generateCaptcha((captcha) => {
      this.globalM.set({ isLoading: true });
      this.authM.authorize(data.email, data.password, captcha, globalState[AUTH_EVENT_TYPE])
        .then((res) => {
          clearErrors('password');
          onLoginSuccess(res);
        })
        .catch(e => {
          const errorMessage = e.response?.data?.message;
          if (typeof errorMessage === 'object') {
            const errorId = errorMessage[0]?.messages[0]?.id;
            if (errorId === 'Auth.form.error.invalid' || errorId === 'Auth.form.error.password.local')
              setError('password', {
                type: 'passError', message: t('common.fields.password.validation.passError') as string,
              });
          }
          else if (errorMessage) {
            if (errorMessage === 'Captcha не пройдена')
              setError('captcha', {
                type: 'passError', message: t('common.fields.captcha.validation.wrongCaptcha') as string,
              });
          }

        })
        .finally(() => {
          this.globalM.set({ isLoading: false });
        });
    });
  }
}

export const PasswordLoginModelStoreToken = token<IPasswordLoginModel>('PasswordLoginModelStoreToken');

container.bind(PasswordLoginModelStoreToken).toInstance(PasswordLoginModel).inSingletonScope();

injected(PasswordLoginModel, GlobalModelStoreToken, AuthModelStoreToken)
