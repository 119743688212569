import React, { useState, useEffect } from "react";
import {t} from "i18next";
import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {StringParam} from "use-query-params";

const BoatSelector = ({
    boats,
    disabled,
    orderId,
    setPickedBoat,
    preloadEntity,
    storagedData,
    handleDropdownClick,
    isDropdownActive,
    isDataEntered,
    setIsSlotOverbooked
}) => {
    const isSingle = boats?.length === 1;
    const autoLoaded = preloadEntity === 'boat' || preloadEntity === 'event' || preloadEntity === 'slot' || isSingle;
    const [boat, setBoat] = useState(null);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [classParam, setClassParam] = useQueryParamWithDefault('boat', StringParam);

    const handleClick = (e) => {
        setBoat(() => e.target.textContent);
        const classValue = e.target.attributes.boatClass.value;
        setDropdownVisible(() => false);
        setPickedBoat(boats?.find(boat => boat.class === classValue));
        setClassParam(classValue);
    };
    const handleDropdown = () => {
        if (autoLoaded) return;
        setDropdownVisible((isDropdownVisible) => !isDropdownVisible);
        handleDropdownClick("boat");
        setIsSlotOverbooked(false);
    };
    useEffect(() => {
      if (boats) {
        if (preloadEntity === 'boat' || preloadEntity === 'event' || preloadEntity === 'slot' || orderId || isDataEntered) {
          setBoat(() => boats?.[0]);
        } else if (isSingle) {
          setBoat(boats?.[0]);
          setPickedBoat(boats?.[0]);
        } else {
          setBoat(() => null);
        }
      } else {
        setBoat(() => null);
      }
    }, [boats]);

    useEffect(() => {
        if (classParam && boats) {
            const entity = boats.find(i => i.class === classParam);

            if (entity) {
                setBoat(entity);
                setPickedBoat(entity);
            }
        }
    }, [boats]);

    useEffect(() => {
        if (preloadEntity === 'boat' || preloadEntity === 'event' || preloadEntity === 'slot' || orderId || isDataEntered) {
            setBoat(() => storagedData?.boat?.label || storagedData?.boat?.class)
        }
    }, [storagedData])

    useEffect(() => {
        setDropdownVisible(isDropdownActive);
    }, [isDropdownActive]);
    const boatList = boats?.map((item, index) => {
        return (
            <li className="selector__item" onClick={handleClick} key={index} boatclass={item.class}>
                {item.label || item.class}
            </li>
        );
    });

    return (
        <>
            <span className={disabled ? "d-none" : ""}>{preloadEntity !=='boat' ? t('sport.schedule.selectors.pickBoat') : t('sport.schedule.selectors.boat')}</span>
            <div className={disabled ? "selector d-none" : "selector"}>
                <div className={autoLoaded ? "selector__trigger disabled" : "selector__trigger"} onClick={handleDropdown}>
                    {boat?.class || boat || t('sport.schedule.selectors.pickBoat')}
                </div>
                <ul className={isDropdownVisible ? "selector__list" : "selector__list d-none"}>{boatList}</ul>
            </div>
        </>
    );
};

export default BoatSelector;
