import React, {useEffect, useState, useMemo} from 'react';
import {NumberParam, StringParam, useQueryParams, withDefault} from 'use-query-params';
import Steps from "../../../layout/Steps/index2";
import Counter from "../../../generic/Counter/index_2";
import Divider from '../../../generic/Divider';
import {useParams} from 'react-router-dom';
import {useCarrotAuth} from "../../../../lib/utils/hooks/useCarrotAuth";
import Schedule from '../Schedule';
import { usePublicEndpoints } from '../../../../lib/api/usePublicEndpoints';
import { useApi } from '../../../../lib/api/useApi';
import {PracticesApi} from "../../../../lib/api/usePracticesApi";
import MembersCheckout from '../MembersCheckout';
import MembersScreen from '../MembersScreen';
import {calculatePrice, calculatePromoAndCertDiscount} from "../../../../lib/utils/helpers";
import Final from "../Final";
import ProductContainer from "../../../common/ProductContainer";
import {
    ICertificate,
    IPractice,
    IPromocode,
    ICurrency,
    TOrder,
    TPromoAndCertDiscount,
    TProductsBookingCards, IInstallment,
} from "../../../../lib/utils/types";
// @ts-ignore
import {currencies as currenciesFallback} from "sv-common/constants/currencies";
import { getPartPayPrice } from '../../../../lib/utils/helpers/priceHelpers';
import {useLoaderState} from "../../../../lib/api/loaderState";
import {t} from "i18next";
import {usePartialPay} from "../../../../lib/utils/hooks/usePartialPay";
import { useInjection } from 'brandi-react';
import { GlobalSaleModelToken } from '../../../../lib/models/availablePriceFromSale';
import getCountryPartialPaymentMarkup from '../../../../lib/utils/getPartialPaymentMarkup';
// @ts-ignore
import {calculateMarkUp} from "sv-common/price/calculate";

const PracticeContainer = () => {
    // @ts-ignore
    const {practiceURL, url} = useParams();

    const [order, setOrder] = useState<TOrder>();
    const [practices, setPractices] = useState([]);
    const [currencies, setCurrencies] = useState<[ICurrency]>(currenciesFallback);
    const [peopleData, setPeopleData] = useState([]);
    const [peopleLimit, setPeopleLimit] = useState(0);
    const [pickedPractice, setPickedPractice] = useState<IPractice>();
    const [pickedPracticeInSchedule, setPickedPracticeInSchedule] = useState({});
    const [firstUserFields, setFirstUserFields] = useState([]);
    const [promocodeApplied, setPromocodeApplied] = useState<IPromocode>();
    const [isPromocodeValid, setPromocodeValid] = useState(false);
    const [isPracticePreset, setIsPracticePreset] = useState(false);
    const [isTeamBookActive, setIsTeamBookActive] = useState(false);
    const [certificateApplied, setCertificateApplied] = useState<ICertificate>();
    const [promoAndCertDiscount, setPromoAndCertDiscount] = useState<TPromoAndCertDiscount>();
    const [partPay, setPartPay] = usePartialPay(pickedPractice, order);
    const [installment, setInstallment] = useState<IInstallment>();

    const [query, setQuery] = useQueryParams({
        orderId: NumberParam,
        practice: StringParam,
        step: withDefault(NumberParam, 1),
        people: withDefault(NumberParam, 1),
    });

    const globalSaleM = useInjection(GlobalSaleModelToken);

    const {userLoggedIn, updateGlobalState, getGlobalState} = useApi();

    const {setIsLoading} = useLoaderState();

    const globalState = getGlobalState();

    const {getPractices, getPracticesByAdventureUrl, getPracticeByUrl} = PracticesApi();
    const {getUserCleanFields, getOrder, getCurrencies, getDifficultyLevels} = usePublicEndpoints();

    useCarrotAuth();

    const onPromocodeInput = (promocodeStatus:string) => setPromocodeValid(() => promocodeStatus === "valid");

    const onPracticePick = (pickedPractice:IPractice) => {
        setPickedPractice(() => pickedPractice);
        setQuery({practice: pickedPractice.url});
        setPeopleLimit(() => pickedPractice.availableSits);
        setPartPay(100)
    }

    const resetPromoStates = (type: string) => {
        if (type === 'promocodeApplied') {
            setPromocodeApplied(undefined)
        }
        if (type === 'certificateApplied') {
            setCertificateApplied(undefined)
        }
    }

    useEffect(() => {
        getCurrencies().then(res => setCurrencies(res))

        !query.orderId && setQuery({step: 1})

        if (practiceURL) {
            setIsLoading(true)
            setIsPracticePreset(true)
            getPracticeByUrl(practiceURL)
                .then((response) => {
                    setPickedPractice(response.practice)
                    if (query.step === 1) {
                        setPickedPracticeInSchedule(response.practice)
                    }
                })
        } else if (url) {
            getPracticesByAdventureUrl(url)
                .then((response) => setPractices(response.practices))
        } else {
            getPractices()
                .then((response) => setPractices(response.practices))
        }
        if (userLoggedIn) {
            getUserCleanFields()
                .then(res => setFirstUserFields(() => res))
        }

        getDifficultyLevels().then(levels => updateGlobalState({levels}))
            .finally(() => setIsLoading(false))

        if (query.step && !userLoggedIn)
            setQuery({step: 1, orderId: undefined})
    }, [])

    useEffect(() => {
        if(query.orderId){
            setIsLoading(true)
            getOrder(query.orderId).then(res => {
                setPickedPractice(res.practice)
                if (res.contactPhone) res.participants[0].phone = res.contactPhone;
                setPeopleData(res.participants)
                setIsLoading(false)
                setOrder(res)
                setInstallment(res.installment);
            })
        }

    }, [query.orderId])

    useEffect(() => {
        setPromoAndCertDiscount(calculatePromoAndCertDiscount(
                certificateApplied,
                promocodeApplied,
                order,
                pickedPractice
        ))
    }, [promocodeApplied?.id, certificateApplied?.id, pickedPractice?.id, order?.id])

    useEffect(() => {
        getUserCleanFields()
            .then(setFirstUserFields)
            .catch(e => console.log(e))
    }, [globalState.userId])

    const priceValue = installment ?
            pickedPractice?.priceValue + calculateMarkUp(pickedPractice?.priceValue, installment.markup):
            (globalSaleM.calculatePrice(pickedPractice, query.people) || pickedPractice?.priceValue || 0) * getCountryPartialPaymentMarkup(partPay);
    const actualPrice = getPartPayPrice(calculatePrice(order, promocodeApplied, priceValue, query.people,
            promoAndCertDiscount?.percent, certificateApplied), partPay);

    const isPromocodeApplied = promocodeApplied?.status === "valid" && promocodeApplied.extraInfo === 'promocode'
            || order?.promocodeAppliedDiscount

    const isCertificateApplied = certificateApplied?.status === 'valid' || order?.certificateApplied

    const bookingCardProps: TProductsBookingCards = useMemo(() => (pickedPractice ? {
        product: 'practice',
        practice: pickedPractice,
        order:order,
        priceCurrency: currencies.find(c => c.key === pickedPractice.priceCurrency)!,
        levels: globalState.levels,
        discountedPrice: actualPrice,
        lowerBadge:(isPromocodeApplied || isCertificateApplied) ? promoAndCertDiscount?.percent : undefined,
        nonDiscountedPrice: calculatePrice(null, null, priceValue, query.people,
                null, null)
    } : null), [pickedPractice?.id, order?.id, JSON.stringify(currencies), JSON.stringify(globalState.levels),
        isPromocodeApplied, isCertificateApplied, promoAndCertDiscount?.percent, query.people, actualPrice, installment])

    return (
            <ProductContainer productType="practice" bookingCardProps={bookingCardProps}>
                <Steps
                    resetData={() => {
                        setQuery({orderId: null});
                        setOrder(undefined);
                        setInstallment(undefined);
                    }}
                />
                {query.step < 2 && !!practices && <>
                    <div className="book-title">
                        {t('practice.register')}<br />
                        {t('travel.pickMembersCount')}
                    </div>
                    <Counter limit={peopleLimit} />
                    <Divider/>
                    {/* @ts-ignore */}
                    <Schedule arrivalUrlParam={query.practice}
                              people={query.people} practices={practices} currencies={currencies}
                              isPracticePreset={isPracticePreset} pickedPractice={pickedPractice}
                              isTeamBookActive={isTeamBookActive}
                              setIsTeamBookActive={((bool:any) => setIsTeamBookActive(() => bool))}
                              setFirstUserFields={setFirstUserFields}
                              updateData={(pickedPractice) => {
                                  onPracticePick(pickedPractice)
                                  setPickedPracticeInSchedule(pickedPractice)
                              }}
                    />
                </>}
                {query.step === 2 && (pickedPractice?.id)
                        && <MembersScreen practice={pickedPractice} firstUserFields={firstUserFields} peopleData={peopleData}
                                          setPeopleData={setPeopleData} setInstallment={setInstallment} installmentPromo={installment?.promocode} />}
                {query.step === 3
                        && <MembersCheckout
                                // @ts-ignore
                                sendStatus={(promocodeStatus) => onPromocodeInput(promocodeStatus)}
                                isPromocodeValid={isPromocodeValid}
                                practice={pickedPractice} membersData={peopleData}
                                promocodeApplied={promocodeApplied}
                                setPromocodeApplied={setPromocodeApplied}
                                certificateApplied={certificateApplied}
                                promoAndCertDiscount={promoAndCertDiscount}
                                setCertificateApplied={setCertificateApplied}
                                order={order}
                                pickedPracticeInSchedule={pickedPracticeInSchedule}
                                resetPromoStates={resetPromoStates}
                                setOrder={setOrder}
                        />}
                {query.step === 5 && <Final order={order}/>}
            </ProductContainer>
    );
}

export default PracticeContainer