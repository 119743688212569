import {NumberParam, StringParam} from "use-query-params";
import {LecturesApi} from "../../../../../lib/api/useLecturesApi";
import {GroupsApi} from "../../../../../lib/api/useGroupsApi";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {currencies} from "sv-common/constants/currencies";
import FormButton from "../../../../generic/buttons/main";
import React, {useEffect, useState} from "react";
import Selector from '../../../../generic/Selector';
import PresetProduct from "./PresetProduct";
import {useParams} from 'react-router-dom'
import PriceBlock from "./MobilePrice";
import PropTypes from 'prop-types';
import dayjs from "dayjs";
import _ from 'lodash';
import './style.scss';
import useQueryParamsWithDefault from "../../../../../lib/utils/hooks/useQueryParamsWithDefault";
import {useLoaderState} from "../../../../../lib/api/loaderState";
import {t} from "i18next";
import useGroup from './hooks/useGroup';
import AlertDismissibleExample from '../../../../common/GlobalSaleAlert';
import { useInjection } from 'brandi-react';
import { GlobalSaleModelToken } from '../../../../../lib/models/availablePriceFromSale';
import DefaultErrorButtons from '../DefaultErrorButtons';
import { SchoolContainerModelToken } from '../model';
dayjs.extend(isSameOrBefore)

const Schedule = ({setStep, selectedGroup, setSelectedGroup, productType, setPayingUser}) => {
    const {productURL} = useParams();

    const {getGroups} = GroupsApi();
    const {getLectures} = LecturesApi();

    const globalSaleM = useInjection(GlobalSaleModelToken);
    const schoolM = useInjection(SchoolContainerModelToken);

    const [query, setQuery] = useQueryParamsWithDefault({
        groupUrl: StringParam,
        people: NumberParam,
        format: StringParam,
        types: StringParam,
        cities: StringParam,
        category: StringParam
    })

    const queryParams = {
        format: query.format || undefined,
        types: query.types?.split(',') || undefined,
        cities: query.cities?.split(',') || undefined,
        category: query.category || undefined
    }

    const getEntities = () => productType === 'lectures' ? getLectures() : getGroups(queryParams)

    const [groups, setGroups] = useState([]);

    const {setIsLoading} = useLoaderState();
    const { submit, checkAvailability } = useGroup(selectedGroup, schoolM.setServerError, query, setPayingUser, setStep, setSelectedGroup);

    const handleSubmit = (e) => {
        e.preventDefault()
        submit();
    }
    useEffect(() => {
        setIsLoading(true);
        getEntities().then(res => {
            if (!res?.groups?.length) {
              schoolM.setServerError(t('school.noGroupsError'));
              setIsLoading(false);
              return;
            }

            setGroups(res.groups.filter(group => (
                dayjs().isBefore(dayjs(`${group.startDate}_${group.startTime}`, 'YYYY.MM.DD_hh:mm'),
                            'minute')
            )));
            query.groupUrl && setSelectedGroup(res.groups.find(group => group.url === query.groupUrl) || {});
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {
      selectedGroup.id && checkAvailability();
    }, [selectedGroup.id]);

    return (
        <>
            <form className="schedule school">
                {
                    productURL
                    ?
                        <PresetProduct setSelectedGroup={setSelectedGroup} />
                    :
                      !schoolM.serverError && <Selector
                            options={groups}
                            value={selectedGroup}
                            label={t('school.pickGroup')}
                            placeholder={t('school.pickGroup')}
                            onChange={group => {
                                setSelectedGroup(group);
                                schoolM.setServerError('');
                                setQuery({groupUrl: group.url});
                            }}
                            optionRender={group => (
                                `${group.name} 
                        [${dayjs(group.startDate).format('DD.MM.YYYY')}] -
                        [${dayjs(group.endDate).format('DD.MM.YYYY')}]`
                            )}
                        />
                }
                {(query.groupUrl || productURL) && (
                    <PriceBlock
                        people={query.people}
                        currencies={currencies}
                        price={globalSaleM.calculatePrice(selectedGroup, query.people) || selectedGroup.price}
                        selectedCurrency={selectedGroup.currency}
                    />
                )}
                {!!schoolM.serverError &&
                    <p className="member">
                        {schoolM.serverError}
                    </p>
                }
                <AlertDismissibleExample />
                {!schoolM.serverError ? <FormButton
                    data-testid='next-submit'
                    value={t('common.next')}
                    onClick={handleSubmit}
                    disabled={_.isEmpty(selectedGroup)}
                    className="stickyButton z-index-1 mobile-width-100"
                /> : <DefaultErrorButtons />}
            </form>
        </>
    )
}

Schedule.propTypes = {
    setStep: PropTypes.func.isRequired,
    productType: PropTypes.string.isRequired,
    selectedGroup: PropTypes.object.isRequired,
    setSelectedGroup: PropTypes.func.isRequired,
}

export default Schedule