import Error from "../Error";
import {FunctionComponent} from "react";
import {useRedirect} from "../../lib/utils/hooks/useRedirect";
import {useLocation} from "react-router-dom";
import useUserData from "../../lib/utils/hooks/useUserData";
import {t} from "i18next";

const CorpLayer: FunctionComponent = () => {
    const userData = useUserData();

    const {redirectTo} = useRedirect()

    const {search} = useLocation();
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (userData.length !== 0 && params.redirect) redirectTo()

    return <Error customText={t('constructor.redirectText')} />
}

export default CorpLayer;