import './style.scss'
import currency from 'currency.js';
import {t} from "i18next";
import {priceFormat} from "../../../lib/utils/helpers/priceHelpers";

const PriceReminder = ({ oldPrice, multiplier, currencyValue, step}) => {
    // сумма, которую осталось оплатить:
    // из изначальной цены, вычитаем сумму оплаты
    const price = priceFormat(currency(oldPrice).subtract(currency(oldPrice).multiply(multiplier).divide(100)).value)
    return (
        <div className={step === 5 ? "priceReminder col-12 p-0" : "priceReminder col-6 p-0"}>
            <div className="priceReminder__title">{t('bookingCard.outstandingPay')}</div>
            <div className="priceReminder__value">{`${price} ${currencyValue}`}</div>
        </div>
    )
}

export default PriceReminder