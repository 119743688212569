import { Button, Row } from 'reactstrap';
import { t } from 'i18next';
import React from 'react';
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';

const DefaultErrorButtons = () => {
  const onClick = (entity) => {
    window.location.href = `/school/${entity}?partPay=100&people=1&step=1`;
  }

  return (
    <Row className='justify-content-center'>
      <Button onClick={() => onClick('lectures')} color={'primary'} className='form-button'>
        {t('school.showAllLectures')}
      </Button>
      <ElementWithAccessInCountries hideInCountries={['EN']}>
        <Button onClick={() => onClick('theory')} color={'primary'} className='form-button'>
          {t('school.showAllTheories')}
        </Button>
      </ElementWithAccessInCountries>
    </Row>
  )
}

export default DefaultErrorButtons;