import {Button, Row} from 'reactstrap';
import './style.scss';
import {useRedirect} from "../../../../lib/utils/hooks/useRedirect";
import Logo from "../../../layout/ Logo";
import PasswordLogin from "./PasswordLogin";
import PhoneLogin from "./PhoneLogin";
import {features} from "../../../../lib/api/globalState";
import {FORM_STEPS} from "../../../../lib/utils/constants";
import ElementWithAccessInCountries from "../../../../lib/utils/hocs/ElementWithAccessInCountries";
import i18next from "i18next";
import { useApi } from '../../../../lib/api/useApi';
import { useInjection } from 'brandi-react';
import { LoginModelStoreToken } from './model';
import cross from '../../../../styles/assets/images/sv/crossBlack.svg';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { getUpdatedRedirectUrl } from 'lib/utils/helpers/urlParseHelpers';


const Login = ({isRedirectAfterLogin, formStep}) => {
    const loginModel = useInjection(LoginModelStoreToken);
    const {generateSocialMediaLink} = useApi();
    const {redirectTo} = useRedirect();
    const { t } = i18next;
    const history = useHistory()

    const isCorp = useMemo(() => new URLSearchParams(history.location.search).get('is_corp'), [history]);
    const redirectWithoutAuthUrl = useMemo(() => new URLSearchParams(history.location.search).get('redirect') || '', [history]);


    const onLoginSuccess = (v) => loginModel.onLoginSuccess(v, isRedirectAfterLogin, redirectTo);

    return <div id={'login'} className="login">
        <div className={'login-container'}>
            {isCorp && <div className="d-flex justify-content-end">
                <button onClick={() => 
                                window.location.replace(getUpdatedRedirectUrl(redirectWithoutAuthUrl))}
                                className="button_close">
                            <img src={cross} alt=""/>
                </button>            
            </div>}
            <div className="login-logo">
                <Logo />
            </div>
            {formStep === FORM_STEPS.PASSWORD_LOGIN || !features['SV-1817'] ? <PasswordLogin onLoginSuccess={onLoginSuccess} /> : <PhoneLogin onLoginSuccess={onLoginSuccess} />}
            <div className="login-text login-social-text">
                {t('common.logInVia')}
            </div>
            <Row className={'no-gutters justify-content-center'}>
                <ElementWithAccessInCountries hideInCountries={['EN']}>
                    <Button tag={'a'} className="social-btn social-tinkoffid" onClick={() => window.location.href = generateSocialMediaLink('tbank')} href='#'/>
                </ElementWithAccessInCountries>
                <ElementWithAccessInCountries hideInCountries={['EN']}>
                  <Button tag={'a'} className="social-btn social-vk" onClick={() => window.location.href = generateSocialMediaLink('vk', false)} href='#'/>
                </ElementWithAccessInCountries>
                <Button tag={'a'} className="social-btn social-google" onClick={() => window.location.href = generateSocialMediaLink('google')} href='#'/>
                <ElementWithAccessInCountries hideInCountries={['EN']}>
                  <Button tag={'a'} className="social-btn social-ya" onClick={() => window.location.href = generateSocialMediaLink('yandexid')} href='#'/>
                </ElementWithAccessInCountries>
            </Row>
        </div>
    </div>
}

export default Login;
