// eslint-disable-next-line
import {useApi} from "./useApi";

export const useInsuranceApi = () => {
    const { request } = useApi();

    /**
     * Method to create an insurance
     * @param {object} data
     * @return {Promise<*>}
     */
    const createInsurance = async (
        data: { [key: string]: any },
    ): Promise<{status: string}> => request('post', `/public/insure`, data);


    return {createInsurance}
}