import './style.scss'
import {getWeekDay, localizeDate, normalizeDate, weekdaysLocalized} from "../../../../lib/utils/format";
import React from "react";
import i18next from "i18next";

const PresetArrival = ({ arrival = {} }) => {
    const {t, language} = i18next;

    return <div className="arrivalFromUrl">
        <div className="arrival_item">
            <span className="arrival_item_title">{t('travel.title')}</span>
            <span className="arrival_item_value">{arrival.adventure_id?.name || ''}</span>
        </div>
        <div className="arrival_item">
            <span className="arrival_item_title">{t('travel.startPlace')}</span>
            <span className="arrival_item_value">{arrival.city || ''}</span>
        </div>
        <div className="arrival_item">
            <span className="arrival_item_title">{t('travel.startDate')}</span>
            <span className="arrival_item_value">
                    {`${localizeDate(arrival.start_date, language) || ''} | ${weekdaysLocalized[language][getWeekDay(normalizeDate(arrival.start_date))] || ''}`}
                </span>
            {arrival.client_comment && <span>{arrival.client_comment}</span>}
        </div>
        <div className="arrival_item">
            <span className="arrival_item_title">{t('travel.freeSits')}</span>
            <span className="arrival_item_value">
                    {`${arrival.available_reserved_sits <= 0 ? '0' : arrival.available_reserved_sits} / ${arrival.total_sits || ''}`}
                </span>
        </div>
    </div>
}

export default PresetArrival;
