import { previewImage } from '../../preview';
import { t } from 'i18next';
import React from 'react';

const DropzonePreviewComponent = (file) => {
  return (
    <div className={'dropify-preview'} style={{display: file ? 'block' : 'none'}}>
      <span className={'dropify-render'}>
        {previewImage({ ext: file.ext, src: file.preview})}
      </span>
      <div className="dropify-infos">
        <div className="dropify-infos-inner">
          <p className="dropify-filename">
            <span className="file-icon"></span>
            <span className="dropify-filename-inner">{file.name}</span>
          </p>
          <p className="dropify-infos-message">{t('generic.dropzone.dropFile')}</p></div>
      </div>
    </div>
  )
}

export default DropzonePreviewComponent;