import React from 'react';
import { Col } from 'reactstrap';
import DatePickerInput from '../../../../../generic/DatePickerInput';
import { localizeDate, regexes } from '../../../../../../lib/utils/helpers';
import { isDateExpired, datetimeToISODate } from "../../../../../../lib/utils/format";
import ValidationIcon from '../../../../../generic/ValidationIcon';
import PropTypes from 'prop-types';
import {maxForeignPassportDate, minForeignPassportDate} from "../../../../../../lib/utils/calendar";
import {t} from "i18next";

const ForeignPassportDate = ({id, name, label, error, value, required, defaultValue, passportNumber, handleInputChange, control, endDate}) => {
    const isDateFormat = (str) => /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/.test(str);
    return (
        <Col xs={12} md={6} className={"control-wrapper"}>
            <span className={"input-label"}>{label}</span>
            <ValidationIcon isDirty={!!(error || value)} isValid={!error} />
            <DatePickerInput
                defaultValue={localizeDate(defaultValue)}
                error={error}
                name={name}
                id={id}
                blurFunc={handleInputChange}
                placeholder="01.01.2025"
                control={control}
                rules={{
                    required,
                    pattern: regexes.birthdate,
                    validate: (value) => {
                        const preparedValue = isDateFormat(value) ? value : new Date(value).toISOString();
                        const passportDate = preparedValue || value || defaultValue;
                        return isDateExpired(datetimeToISODate(passportDate), endDate) && passportNumber !== "0000"
                            ? t('inputs.passportDateForeign.error')
                            : undefined;
                    },
                }}
                minDate={minForeignPassportDate}
                maxDate={maxForeignPassportDate}
            />
            <span className={error ? "error-label" : "error-label d-none"}>
                {error?.type === "validate" ? error?.message : ""}
            </span>
        </Col>
    )
};

ForeignPassportDate.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    defaultValue: PropTypes.string,
    passportNumber: PropTypes.string,
    handleInputChange: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    endDate: PropTypes.string
};

export default ForeignPassportDate;
