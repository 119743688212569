const useCoachlessDiscount = (setIsCoachlessVisible, bookFullBoat, sendData, calculateBookAvailability) => {
  const handle = (checked) => {
    setIsCoachlessVisible(() => !checked)
    if (checked) {
      bookFullBoat();
      sendData({
        peopleAmount: calculateBookAvailability(),
        isFullBookChecked: true,
        instructor: null,
      });
    }
    else {
      sendData({ isFullBookChecked: false });
    }
  }

  return { handle };
}

export default useCoachlessDiscount;