import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { IProductsService, ProductsServiceStoreToken } from '../../../entities/products/api/service';
import { ArrivalModelStoreToken, IArrivalModel } from '../../../entities/arrivals/model';

interface IAdventureScheduleModel {
  isArrivalOverbooked: boolean,
  setIsArrivalOverbooked: (v: boolean) => void,
  submitWithValidation(onSubmit: () => void, peopleAmount: number): Promise<void>,
}

export default class AdventureScheduleModel implements IAdventureScheduleModel {
  isArrivalOverbooked = false;

  constructor(
    private productsS: IProductsService,
    private arrivalM: IArrivalModel,
  ) {
  }

  setIsArrivalOverbooked(v: boolean) {
    this.isArrivalOverbooked = v;
  }

  async submitWithValidation(onSubmit: () => void, peopleAmount: number) {
    const places = this.arrivalM.item?.id && await this.getPlaces() || 0;

    if (places < peopleAmount) {
      this.setIsArrivalOverbooked(true);
      return;
    }
    onSubmit();
  }

  private async getPlaces() {
    const { available_sits } = await this.productsS.getProductPlaces('arrivals', this.arrivalM.item!.id);
    return available_sits;
  }
}

export const AdventureScheduleModelStoreToken = token<IAdventureScheduleModel>('AdventureScheduleModelStoreToken');

container.bind(AdventureScheduleModelStoreToken).toInstance(AdventureScheduleModel).inSingletonScope();

injected(AdventureScheduleModel, ProductsServiceStoreToken, ArrivalModelStoreToken);
