import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {FaFileImport} from "react-icons/fa";
import {t} from "i18next";
import DropzonePreviewComponent from './components/preview';
import DropzoneButtonComponent from './components/common';

export const DropZoneTypes = {
  preview: 'preview',
  common: 'common',
}

const dropZoneHandler = (type = DropZoneTypes.preview, file) => {
  const handler = {
    [DropZoneTypes.preview]: DropzonePreviewComponent,
    [DropZoneTypes.common]: DropzoneButtonComponent,
  }

  return handler[type](file);
}

const Dropzone = ({extFile, callback, name, accept, onError, maxSize, type, ...rest}) => {

    const [file, setFile] = useState(extFile);
    const {getRootProps, getInputProps} = useDropzone({
        maxSize,
        maxFiles: 1,
        accept: accept || 'image/*',
        onDrop: (acceptedFiles, fileRejections) => {
            fileRejections.forEach((file) => {
              file.errors.forEach((err) => {
                if (file.file.type.includes('image')) {
                  acceptedFiles.push(file.file);
                } else {
                  if (err.code === "file-too-large") {
                    onError(t('generic.dropzone.formatTooLarge'));
                  }

                  if (err.code === "file-invalid-type") {
                    onError(t('generic.dropzone.invalidType'));
                  }
                }
              });
            });

            const file = acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file), fieldName: name ? name: 'file'}))[0]

            if (file) {
              setFile(() => file);
              if (callback) {
                callback(file);
              }
            }
        },
    });

    const onDelete = () => {
      callback({fieldName: name, preview: null, file });
      setFile(null);
    }

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        if (file)
            URL.revokeObjectURL(file.preview);
    }, [file]);

    useEffect(() => {
        setFile(() => extFile)
    }, [extFile]);

    const isCommon = type === DropZoneTypes.common;

    return (
        <div
          className={"dropify-wrapper" + (file ? " has-preview" : "")}
          {...getRootProps()}
          {...rest}
          style={isCommon && { height: 'initial'} || {}}
        >
            <input className="dropify" {...getInputProps()} />
            <button type="button" className="dropify-clear" onClick={onDelete}>{t('generic.dropzone.delete')}</button>
            {!file && <div
              className={"dropify-message"}
              style={isCommon && { transform: 'initial' } || {}}
            >
                <FaFileImport size={'2em'}/>
                <p>{t('generic.dropzone.uploadFile')}</p>
            </div>}
            {file && dropZoneHandler(type, file)}
        </div>

    );
}

export default Dropzone;