import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { IApiModel } from '../../../lib/api/index.interface';
import { ApiModelStoreToken } from '../../../lib/api/index.model';
import { ICheckAuthCodeRes, ICompleteUserRes, ILoginRes } from '../types';

export interface IAuthService {
  authorize: (identifier: string, password: string, captcha: string, eventType: string) => Promise<ILoginRes | ICheckAuthCodeRes>,
  forgotPassword: (email: string) => Promise<void>,
  register: (email: string, password: string, consent: string, name: string, phone: string) => Promise<ILoginRes | ICheckAuthCodeRes>,
  complete: (data: any) => Promise<ICompleteUserRes>,
}

export default class AuthService implements IAuthService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  authorize(identifier: string, password: string, captcha: string, eventType: string): Promise<ILoginRes | ICheckAuthCodeRes> {
    return this.api.request('POST', `/public/auth`, {identifier, password, captcha, eventType});
  }

  forgotPassword(email: string): Promise<void> {
    return this.api.request('POST', `/public/forgot-password`, {email});
  }

  register(email: string, password: string, consent: string, name: string, phone: string): Promise<ILoginRes | ICheckAuthCodeRes> {
    return this.api.request('POST', `/public/reg`, {email, password, consent, name, phone});
  }

  complete(data: any): Promise<ICompleteUserRes> {
    return this.api.rawRequest('POST', '/public/completeUser', data);
  }
}

export const AuthServiceStoreToken = token<IAuthService>('AuthServiceStoreToken');

container.bind(AuthServiceStoreToken).toInstance(AuthService).inSingletonScope();

injected(AuthService, ApiModelStoreToken);