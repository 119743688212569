import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Input, Col } from 'reactstrap';
import ValidationIcon from '../../generic/ValidationIcon';
import {t} from "i18next";
import SuggestionsList from "../../generic/SuggestionsList";

function AddressFieldWithAutocomplete({label, error, placeholder, fieldName, register, 
                                       required, onChange, defaultValue, language,
                                       id, name, getAddressSuggestions}) {
    const [value, setValue] = useState('');
    const [isAddressPicked, setIsAddressPicked] = useState(true);
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const handleChange = e => {
        setValue(e.target.value)
        debouncedHandleInput(e.target.value)
    }

    const handleAddress = (address) => {
        setValue(() => address);
        onChange(address)
        setIsAddressPicked(() => true);
    }

    const debouncedHandleInput = useCallback(_.debounce((e) => handleAddressInputChange(e), 800), [language]);

    const handleAddressInputChange = (e) => {
        const query = e;
        const data = {
            language,
            query,
            from_bound: {
                value: fieldName
            },
            to_bound: {
                value: fieldName
            },
        }
        if (query) getAddressSuggestions(data)
            .then(res => {
                setAddressSuggestions(() => res.suggestions);
                setIsAddressPicked(() => false);
            })
    }

    useEffect(() => {
        setValue(() => defaultValue)
    }, [defaultValue])

    return (
        <Col xs={12} md={6} className={"control-wrapper"}>
            <span className={"input-label"}>{label}</span>
            <ValidationIcon isDirty={!!(error || value)} isValid={!error} />
            <Input
                data-testid={id}
                id={id}
                name={name}
                label={label}
                value={value}
                onChange={handleChange}
                error={error}
                placeholder={placeholder}
                innerRef={register({
                    required: required
                })}
                className={fieldName}
                autoComplete={"chrome-off"}
            />
            {(!!addressSuggestions?.length) && !isAddressPicked &&
                <SuggestionsList suggestions={addressSuggestions} setAddress={handleAddress} /> }
            <span className={error ? "error-label" : "error-label d-none"}>
            {error?.type === "required" ? error?.message || t('inputs.required') : ""}
            </span>
        </Col>
    );
}

AddressFieldWithAutocomplete.propTypes = {
    label: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    language: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    getAddressSuggestions: PropTypes.func
};

export default AddressFieldWithAutocomplete;
