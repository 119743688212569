import React from "react";
import check from "../../../styles/assets/images/sv/check.svg";
import cross from "../../../styles/assets/images/sv/cross.svg";
import PropTypes from "prop-types";

const ValidationIcon = ({isDirty, isValid}) => {
    return (
        isDirty 
        ? <span>
            <img src={isValid ? check : cross} alt="" />
        </span>
        : <></>
    )
};

ValidationIcon.propTypes = {
    isDirty: PropTypes.bool,
    isValid: PropTypes.bool
};

export default ValidationIcon;
