import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types'
import './style.scss'
import cross from '../../../styles/assets/images/sv/crossBlack.svg';
const Popup = ({
    isOpen,
    toggle,
    popupTitle,
    cancelButtonText,
    successButtonText,
    successButtonClick,
    children,
    withoutButtons,
    customClassName,
}) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} className={`popup ${customClassName ? customClassName : ''}`} style={{maxWidth: '450px'}}>
            <div className="d-flex justify-content-end">
                <button onClick={toggle} className="button_close"><img src={cross} alt=""/></button>
            </div>
            {popupTitle &&
                <ModalHeader>{popupTitle}</ModalHeader>
            }
            <ModalBody>
                {children}
            </ModalBody>
            {!withoutButtons && <>
                <ModalFooter className="justify-content-center">
                    <Button className="popup_button" color="primary" onClick={successButtonClick}>{successButtonText}</Button>{' '}
                    <Button color="secondary" onClick={toggle}>{cancelButtonText}</Button>
                </ModalFooter>
            </>}
        </Modal>
    );
}

Popup.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    cancelButtonText: PropTypes.string,
    successButtonText: PropTypes.string,
    popupText: PropTypes.string,
    popupTitle: PropTypes.string,
    successButtonClick: PropTypes.func,
    customClassName: PropTypes.string,
}

export default Popup;