import React, {JSXElementConstructor, useCallback, useState} from "react";
import {t} from "i18next";
import {BASE_SILAVETRA_URL} from "../../api/globalState";
// @ts-ignore
import {PRODUCT_IN_ORDER_DELETED_ERROR} from "sv-common/constants/statuses";

const withOrderProductExistCheck = (Component: JSXElementConstructor<any>) => (props: any) => {
    const [orderProductNotExistLink, setOrderProductNotExistLink] = useState<undefined | string>(undefined);

    const checkOrderProduct = useCallback((e: any, link: string) => {
        if (e?.response?.data?.message === PRODUCT_IN_ORDER_DELETED_ERROR) setOrderProductNotExistLink(link)
    }, [])

    if (orderProductNotExistLink)
        return <div className={"checkout"}>
                    <div className={"checkout__main-info"}>
                        {Math.random() > 0.5
                                ? <div className="checkout__description">{t('common.orderDeletedDescription')}</div>
                                : <div className="checkout__description">{t('common.orderDeletedDescription2')}</div>}
                        <a className={'form-button btn btn-primary'} href={BASE_SILAVETRA_URL + orderProductNotExistLink}>{t('common.goToCalendar')}</a>
                    </div>
                </div>

    return <Component {...props} checkOrderProduct={checkOrderProduct} />
}

export default withOrderProductExistCheck