import PaymentPromoComponent from '../components/promocode';
import { FC } from 'react';
import { IPromocode, TOrder } from '../../../../lib/utils/types';

interface IPaymentMethodCashComponent {
  verifyPromocode: (value: string, type?: string) => void,
  setPromocodeApplied: (value?: IPromocode) => void,
  isShowPromocode: boolean,
  order: TOrder,
}

const PaymentMethodCashComponent: FC<IPaymentMethodCashComponent> = (props) => {
  const {
    isShowPromocode,
  } = props;

  return isShowPromocode && <PaymentPromoComponent applyButtonVisible {...props} /> || <></>
}

export default PaymentMethodCashComponent;