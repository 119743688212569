import {useEffect, useState} from "react";
import {ICurrency} from "../../../../lib/utils/types";
import {useTypedPublicEndpoints} from "../../../../lib/api/useTypedPublicEndpoint";

const useCurrencies = () => {
    const [currencies, setCurrencies] = useState<ICurrency[]>([]);
    const {getCurrencies} = useTypedPublicEndpoints();

    useEffect(() => {
        getCurrencies()
                .then(res => setCurrencies(res));
    }, []);

    return currencies
}

export default useCurrencies;