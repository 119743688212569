import {Input} from "reactstrap";
import PropTypes from 'prop-types';
import FormButton from "../buttons/main";
import PromocodeInfo from './PromocodeInfo';
import {t} from "i18next";
import getCountryValue from '../../../lib/utils/getCountryValue';
import { observer } from 'mobx-react-lite';

const EnterPromocode = ({
    promocodeInput,
    promocodeChange,
    verifyPromocode,
    promocodeApplied,
    promocodeDiscount,
    certificateApplied,
    setPromocodeApplied,
    setCertificateApplied,
    invalidPromocodeReason,
    promoAndCertDiscount,
    isCorpCert,
    disableRemoveButton,
}) => {

    return (
        <div className={`checkout__input-wrapper promocode-input ${getCountryValue({ en: 'en' })}`} role="promocode-input">
            <div className="title-label">{t('inputs.enterPromocode.label')}</div>
            {!isCorpCert && <Input
                type="text"
                id="promocode"
                data-testid="promocode-input"
                value={promocodeInput}
                onChange={promocodeChange}
                placeholder={t('inputs.enterPromocode.placeholder')}
                className={promocodeApplied?.status === "invalid" ? "has-error" : ""}
            />}
            <PromocodeInfo
              certificate={certificateApplied}
              promocode={promocodeApplied}
              verifyPromocode={verifyPromocode}
              setPromocodeApplied={setPromocodeApplied}
              setCertificateApplied={setCertificateApplied}
              disableRemoveButton={disableRemoveButton}
            />
            {!isCorpCert && <FormButton data-testid="submit-promo-button" className="small-button" value={t('inputs.enterPromocode.submitButton')}
                        disabled={!promocodeInput.trim()} onClick={() => {
                            verifyPromocode(promocodeInput)
                        }}
            />}
            {isCorpCert && <div style={{ maxWidth: 400 }} className='checkout__description'>
              {t('inputs.enterPromocode.corpCertApplied')} <br/> {t('inputs.enterPromocode.corpCertAppliedDesc')}
            </div>}
            {(promocodeApplied?.status === "valid" || certificateApplied?.status === 'valid') && (
                <div className="applied-promocode">
                    {" "}
                    <div className="promo-text">
                        {t('common.discount')}
                        <br/> {promoAndCertDiscount?.percent}%
                    </div>
                </div>
            )}
            {(invalidPromocodeReason || promocodeDiscount?.exceed) &&
            <span className={"error-label"}>
                {promocodeDiscount?.exceed ? '' : invalidPromocodeReason}
            </span>}
        </div>
    )
}

export default observer(EnterPromocode);