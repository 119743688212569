import React, {useState, useEffect} from 'react';
import './style.scss'
import {API_URL} from "../../../../lib/api/useApi";
import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {NumberParam} from "use-query-params";
import {t} from "i18next";
import { useInjection } from 'brandi-react';
import { GlobalModelStoreToken } from "lib/models/global";
import { getCookie } from "lib/utils/cookies";

const InstructorSelector = ({instructors, setPickedInstructor, isCoachlessActive, disabled, selectedInstructor, isDropdownActive, handleDropdownClick, isDataEntered, orderId, onHandleInstructorPick, isHandleInstructorPicked}) => {
  const [renderedInstructor, setRenderedInstructor] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [peopleAmount] = useQueryParamWithDefault('people', NumberParam);
  const [instructorParam, setInstructorParam] = useQueryParamWithDefault('instructor', NumberParam);

  const onRenderInstructor = (value) => {
      let rendererValue = 'Любой классный';

      if (value?.first_name) rendererValue = `${value?.first_name} ${value?.last_name}`;

      setRenderedInstructor(() => rendererValue)
  }

  const handleClick = (e) => {
    let {id} = e.currentTarget;
    id = +id;
    const selectedInstructor = instructors.find(item => item.id === id);
    if (selectedInstructor) {
        onRenderInstructor(selectedInstructor);
    } else {
        setRenderedInstructor(() => t('sport.schedule.selectors.anyInstructor'))
    }
    setPickedInstructor(selectedInstructor);
    setDropdownVisible(() => false);
    setInstructorParam(id);
    onHandleInstructorPick(true);
  }

  useEffect(() => {
    if (orderId || isDataEntered || isCoachlessActive || selectedInstructor?.id) {
        const instructorValue = isDataEntered || selectedInstructor?.id ? selectedInstructor : instructors[0];
        onRenderInstructor(instructorValue)
    } else {
       !instructorParam && setRenderedInstructor(() => null);
    }

    if (!instructors.length) {
        setRenderedInstructor(() => null);
    }

    if (peopleAmount > selectedInstructor?.available_sits) {
        setRenderedInstructor(() => null);
        setPickedInstructor(undefined);
        setInstructorParam(0);
    }
  }, [instructors, selectedInstructor, isCoachlessActive, peopleAmount])

  const globalM = useInjection(GlobalModelStoreToken);
  const globalState = globalM.get();

  useEffect(() => {    
    if (globalState.authProvider === 'vk') onHandleInstructorPick(false)
  }, [globalState.userId])

  useEffect(() => {
    setDropdownVisible(isDropdownActive);
  }, [isDropdownActive]);

  useEffect(() => {
      if (instructorParam) {
          const entity = instructors.find(item => item.id === instructorParam);

          if (entity) {
                onRenderInstructor(entity);
                setPickedInstructor(entity);
          }
      }
  }, [instructors]);
  const handleDropdown = () => {
    setDropdownVisible(isDropdownVisible => !isDropdownVisible);
    handleDropdownClick('instructor');
  }

  const instructorList = instructors?.filter(i => i.available_sits >= peopleAmount)?.map((item) => instructorSelectorElement(item, handleClick)) || []

  return (
    <>
      <span className={disabled ? 'd-none' : ''}>{t('sport.schedule.selectors.pickInstructor')}</span>
      <div className={disabled ? 'selector d-none' : 'selector'}>
        <div className={`selector__trigger ${!isHandleInstructorPicked ? 'selector__clicked' : ''}`} onClick={handleDropdown}>{renderedInstructor || t('sport.schedule.selectors.anyInstructor')}</div>
        <ul className={isDropdownVisible ? "selector__list" : "selector__list d-none"}>
        <li className="selector__item" onClick={handleClick} id={0} key={0}>{t('sport.schedule.selectors.anyInstructor')}</li>
          {instructorList}
        </ul>
        <div className='mt-2'>{t('sport.schedule.selectors.instructorWarning')}</div>
      </div>
    </>
  )
}

const instructorSelectorElement = (item, handleClick) =>
    <li className="selector__item" onClick={handleClick} id={item.id} key={item.id}>
        <div className="item__container d-flex">
            <span className={'item__element item__skip_next'}>{`${item.first_name} ${item.last_name}`}</span>
            <span className={'item__element d-flex justify-content-end'}>
                {item.avatar && <img className={'item__element-image mr-3'} src={API_URL + (item.avatar.formats?.thumbnail?.url || item.avatar.url)} />}
            </span>
            <span className={'item__element'}>
                <span className={'item__places'}>{`${item?.available_sits} / ${item?.total_sits}`}</span>
                <span className="item__places-label">
                    {`${item?.total_sits ? t('sport.schedule.selectors.availableSits') : ''}`}
                </span>
            </span>

        </div>

    </li>

export default InstructorSelector;