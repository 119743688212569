import React, { Fragment } from 'react';
import { Col, Input, Label } from 'reactstrap';
import ValidationIcon from '../../../../../generic/ValidationIcon';
import PropTypes from 'prop-types';
import {t} from "i18next";

const ShirtSize = ({shirtSizes, id, name, error, value, required, label, defaultValue, handleInputChange, register}) => (
    <Col xs={12} className={"control-wrapper"}>
        <span className={"input-label radio"}>{label}</span>
        <ValidationIcon isDirty={!!(error || value)} isValid={!error} />
        <div>
            {shirtSizes.map((item, key) => (
                <Fragment key={key}>
                    <Input
                        key={`${key}-${name}-1`}
                        id={`${id}-${key}`}
                        name={name}
                        type="radio"
                        value={item.tag}
                        defaultChecked={defaultValue?.shirt_size === item.tag}
                        innerRef={register({
                            required,
                        })}
                        onChange={handleInputChange}
                    />
                    <Label key={`${key}-${name}-2`} htmlFor={`${id}-${key}`}>
                        {item.tag}
                    </Label>
                </Fragment>
            ))}
        </div>
        <div className={error ? "error-label w-100" : "error-label d-none"}>
            {error?.type === "required" ? t('inputs.shirtSize.required') : ""}
        </div>
    </Col>
);

ShirtSize.propTypes = {
    shirtSizes: PropTypes.array,
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    error: PropTypes.object,
    value: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    handleInputChange: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
};

export default ShirtSize;
