import React from 'react';
import { Col } from 'reactstrap';
import Select from 'react-select';
import ValidationIcon from "../../../../../generic/ValidationIcon";
import { Controller } from 'react-hook-form';
import PropTypes from "prop-types";
import { customStyles } from './styles';
import {t} from "i18next";

const SailingExperience = ({name, label, error, value, defaultValue, control, handleInputChange}) => {
    const experienceLevels = [
        { value: '0/7', label: t('inputs.sailingExperience.travel0/7') },
        { value: '1/7', label: t('inputs.sailingExperience.travel1/7') },
        { value: '2/7', label: t('inputs.sailingExperience.travel2/7') },
        { value: '3/7', label: t('inputs.sailingExperience.travel3/7') },
        { value: '4/7', label: t('inputs.sailingExperience.travel4/7') },
    ]
    
    return (
        <Col xs={12} md={6} className={"control-wrapper"}>
            <span className={"input-label"}>{label}</span>
            <ValidationIcon isDirty={!!(error || value)} isValid={!error} />
            <Controller
                name={name}
                isSearchable={false}
                placeholder={t('inputs.sailingExperience.defaultValue')}
                control={control}
                render={({onChange, ref }) => (
                    <Select
                        defaultValue={experienceLevels.filter(option =>
                            option.value === defaultValue)}
                        options={experienceLevels}
                        inputRef={ref}
                        styles={customStyles}
                        onChange={(e) => {
                            handleInputChange({target: {value: e.value, name}})
                            onChange(e)
                        }}
                    />
                )}
            />
            <span className={error ? "error-label" : "error-label d-none"}>
                {error?.type === "required" ? t('inputs.sailingExperience.required') : ""}
            </span>
        </Col>
    )
}

SailingExperience.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    value: PropTypes.array,
    defaultValue: PropTypes.string.isRequired,
    control: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired
};

export default SailingExperience;
