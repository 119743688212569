import {useEffect, useState} from "react";
import {usePublicEndpoints} from "../../api/usePublicEndpoints";
import {getCookie} from "../cookies";

const useUserData = () => {
    const [userData, setUserData] = useState([])

    const { getUserCleanFields } = usePublicEndpoints();

    useEffect(() => {
        if (getCookie('sv_booking_email'))
            getUserCleanFields()
                .then(res => setUserData(res))
                .catch(e => console.log(e))
    }, []);

    return userData
}

export default useUserData;