import "./style.scss";
import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";
import Divider from "../../../generic/Divider";
import {localizeFullDate} from "../../../../lib/utils/format";
import i18next from "i18next";

// TODO заменить на реальный опыт, когда придет задача
const sailExperience = {
    "0/7": 'inputs.sailingExperience.travel0/7',
    "1/7": 'inputs.sailingExperience.travel1/7',
    "2/7": 'inputs.sailingExperience.travel2/7',
    "3/7": 'inputs.sailingExperience.travel3/7',
    "4/7": 'inputs.sailingExperience.travel4/7'
};

const MemberCheckoutCard = ({member, practice}) => {
    const {t, language} = i18next;

    const {
        first_name, last_name, gender, birthdate, phone,
        sailing_experience, city, email, telegram, ...additionalFields
    } = member;

    const region = member.region;

    return (
        <div>
            <div className="checkout__member-info">
                <Row className={"flex-wrap"}>
                    <Col xs={12} sm={12} className="checkout__member-field-group">
                      <div className="title-label">{t('inputs.email.label')}</div>
                      <div className="checkout__member-field">{email}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.firstName.label')}</div>
                        <div className="checkout__member-field">{first_name}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.lastName.label')}</div>
                        <div className="checkout__member-field">{last_name}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.gender.label')}</div>
                        <div className="checkout__member-field">
                            {gender === "m" ? t('inputs.gender.male') : t('inputs.gender.female')}
                        </div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.birthdate.label')}</div>
                        <div className="checkout__member-field">{localizeFullDate(birthdate, language)}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.phone.label')}</div>
                        <div className="checkout__member-field">{phone}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.sailingExperience.label')}</div>
                        <div className="checkout__member-field">
                            {t(sailExperience[sailing_experience])}
                        </div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.city.label')}</div>
                        <div className="checkout__member-field">{city}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">{t('inputs.region.label')}</div>
                        <div className="checkout__member-field">{region}</div>
                    </Col>
                    <Col xs={12} sm={6} className="checkout__member-field-group">
                        <div className="title-label">Telegram</div>
                        <div className="checkout__member-field">{telegram}</div>
                    </Col>

                    {Object.keys(additionalFields).map((fieldKey, i) =>
                        !!practice.adventure_id?.req_fields[fieldKey]?.label &&
                            <Col xs={12} sm={6} className="checkout__member-field-group" key={i}>
                                <div className="title-label">{practice.adventure_id?.req_fields[fieldKey]?.label}</div>
                                <div className="checkout__member-field">{additionalFields[fieldKey]}</div>
                            </Col>

                    )}
                </Row>
            </div>
            <Divider/>
        </div>
    );
};

MemberCheckoutCard.propTypes = {
    index: PropTypes.number.isRequired,
    member: PropTypes.object.isRequired,
    practice: PropTypes.object.isRequired,
};

export default MemberCheckoutCard;
