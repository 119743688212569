import {ILevel, TAdventureBookingCard} from "../../../../lib/utils/types";
import ProductBookingCard from "../../ProductBookingCard";
import {localizeDate} from "../../../../lib/utils/format";
import {API_URL} from "../../../../lib/api/useApi";
import _ from "lodash";
import DifficultyLevel from "../../ProductBookingCard/DifficultLevel";
import WeatherInfo from "../../ProductBookingCard/WeatherInfo";
import React from "react";
import i18next from "i18next";

const AdventureBookingCard = (props: TAdventureBookingCard) => {
    const {t, language} = i18next;

    const difficulty = props.order?.difficulty
            || props.levels?.find((item: ILevel) => item.id === props.arrival.adventure_id.difficulty_level_id)

    return <ProductBookingCard product={props.arrival} label={t('bookingCard.direction')} order={props.order}
                               discountedPrice={props.discountedPrice} nonDiscountedPrice={props.nonDiscountedPrice}
                               upperBadge={props.upperBadge} lowerBadge={props.lowerBadge}
                               extraDiscountLabel={props.extraDiscountLabel}
                               dateLabel={`${localizeDate(props.arrival.start_date, language)} - ${localizeDate(props.arrival.end_date, language)}`}
                               priceCurrency={props.priceCurrency}
                               img={props.arrival.img ? API_URL + props.arrival.img : undefined} >
        { !_.isEmpty(difficulty) && <DifficultyLevel difficulty={difficulty}/> }
        <WeatherInfo airTemperature={props.arrival.air_temperature} waterTemperature={props.arrival.water_temperature}
                     hoursSailing={props.arrival.hours_sailing} hoursSailingLimit={props.arrival.hours_sailing_limit}
        />
    </ProductBookingCard>
}

export default AdventureBookingCard