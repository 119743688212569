import {useEffect} from "react";
import {useTypedPublicEndpoints} from "../../../../lib/api/useTypedPublicEndpoint";
import {useLogin} from "../../../../lib/utils/hooks/useLogin";
import { useInjection } from 'brandi-react';
import { AuthModelStoreToken } from '../../../../entities/auth/model';
import { GlobalModelStoreToken } from '../../../../lib/models/global';

//@ts-ignore
const useUser = ({setPayingUser, setStep, resetData}) => {
    const authM = useInjection(AuthModelStoreToken);
    const globalM = useInjection(GlobalModelStoreToken);
    const {isUserLoggedIn} = useLogin()

    const globalState = globalM.get();

    const {getUserCleanFields} = useTypedPublicEndpoints()

    const userFieldsSetter = () => {
        getUserCleanFields()
                .then(res => setPayingUser(res))
                .catch(e => console.log(e))
    }

    useEffect(() => {
        userFieldsSetter();
    }, [globalState.userId])

    useEffect(() => {
        if (authM.isLoggedIn() || isUserLoggedIn)
            userFieldsSetter()
        else {
            setStep(1)
            resetData()
        }
    }, [])
}

export default useUser;