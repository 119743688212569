import { usePublicEndpoints } from '../../../../lib/api/usePublicEndpoints';

const useSport = (sendData, eventsList, boatsList, basesList, setIsSingleBase, setBoats, setFetchedBases, setBases, setIsLoading, setIsLoadingLocal) => {
  const { getSpecificBases, getBases } = usePublicEndpoints();
  const loadDefault = () => {
    sendData({ preloadEntity: '' })
    let query = {};
    if (eventsList) query.event_types = eventsList
    if (boatsList) query.boats = boatsList
    if (basesList) {
      getSpecificBases(basesList, query)
        .then((res) => {
          if (res.bases.length === 1) {
            setIsSingleBase(() => true)
            sendData({ base: res.bases[0], preloadEntity: 'base' });
            setBoats(() => res.bases[0].boats)
          } else {
            setFetchedBases(res.bases)
          }
          setBases(() => res.bases);

        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
          setIsLoadingLocal(false)
        });
    } else {
      getBases(query)
        .then((res) => {
          setBases(() => res.bases);
          setFetchedBases(res.bases)
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
          setIsLoadingLocal(false)
        });
    }
  }

  return { loadDefault };
}

export default useSport;