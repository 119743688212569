import { usePublicEndpoints } from '../../../../lib/api/usePublicEndpoints';

const useSportByEvents = (eventsList, setDates, setBases, setFetchedBases, setBoats, setIsLoading, setIsLoadingLocal) => {
  const { getEventsByUrls } = usePublicEndpoints();
  const loadSportEvent = () => {
    getEventsByUrls(eventsList)
      .then(res => {
        setBases(res.bases);
        setFetchedBases(res.bases);
      }).catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
        setIsLoadingLocal(false);
      });
  }

  return { loadSportEvent };
}

export default useSportByEvents;