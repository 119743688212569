export const getUpdatedRedirectUrl = (url) => {
    const urlObj = new URL(url, window.location.origin);

    if (!urlObj.searchParams.has('without_auth')) {
        urlObj.searchParams.append('without_auth', 'true');
    } else {
        urlObj.searchParams.set('without_auth', 'true');
    }

    return urlObj.toString(); 
}