import './style.scss'
import PropTypes from "prop-types";
import {NumberParam, StringParam, useQueryParams, withDefault} from "use-query-params";
import {
  COUNTRIES_LIST,
  COUNTRY,
  DEFAULT_COUNTRY,
  PRODUCT_TYPES_LIST_ADAPTER
} from '../../../lib/utils/constants';
import i18next from 'i18next';
import cert_en from '../../../styles/assets/images/qr/cert_en.png';
import React from 'react';
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';
import { DEFAULT_CURRENCY } from 'sv-common/constants/currencies';

const CertificateRender = ({certificate, currencies, step,}) => {
    const { t } = i18next;

    let {certificateNum, certificateSum, toName, toSurname, currency, selectedProducts, country, comment} = certificate;

    const [certificateURL] = useQueryParams({
        name: StringParam,
        surname: StringParam,
        comment: StringParam,
        currency: withDefault(StringParam, DEFAULT_CURRENCY[COUNTRY]),
        country: withDefault(StringParam, DEFAULT_COUNTRY[COUNTRY]),
        certificateSum: withDefault(NumberParam, 1000),
        productTypes: withDefault(StringParam, ""),
    })

    if (!toName) toName = certificateURL.name
    if (!comment) comment = certificateURL.comment
    if (!country) country = certificateURL.country
    if (!currency) currency = certificateURL.currency
    if (!toSurname) toSurname = certificateURL.surname
    if (!certificateSum) certificateSum = certificateURL.certificateSum
    if (!selectedProducts) selectedProducts = certificateURL.productTypes

    const normalizeCurrency = () => currencies?.find((item) => item.key === currency)?.sign || '-';

    return (
        <div className="certificate">
            <div className="certificate-wrapped">
                <div className="certificate-title-block">
                  <h3 className="certificate-title">{t('certificate.label')}</h3>
                  <ElementWithAccessInCountries hideInCountries={['RU']}>
                    <div className="certificate-graphic-block">
                      <div className="certificate-graphic second">
                        {'>>>'}
                      </div>
                    </div>
                  </ElementWithAccessInCountries>
                </div>
                <div className="field">
                    <span className="field-label">
                      {t('certificate.ownerName')}
                    </span>
                    <span className="field-value">
                        {toName || '-'} {toSurname || '-'}
                    </span>
                </div>
                <div className="field">
                  <span className="field-label">
                    {t('certificate.productType')}
                  </span>
                  <span className="field-value">
                    {PRODUCT_TYPES_LIST_ADAPTER[COUNTRY].find(productType => productType.key === selectedProducts)?.label || '-'}
                  </span>
                </div>
                <div className="field">
                    <span className="field-label">
                      {t('certificate.country')}
                    </span>
                    <span className="field-value">
                        {COUNTRIES_LIST[COUNTRY].find(countryItem => countryItem.key === country)?.label || '-'}
                    </span>
                </div>
                {comment &&
                    <div className="field">
                        <span className="field-label">
                            {t('certificate.wish')}
                        </span>
                            <span className="field-value">
                            {comment}
                        </span>
                    </div>
                }
            </div>
                <div className={`certificate-code ${step === 2 && 'certificate-code-success-page'}`}>
                    {step === 2 &&
                    <div className="field">
                        <span className="field-label">
                            {t('certificate.code')}
                        </span>
                        <span className="certificate-code-value">
                            {certificateNum || '-'}
                        </span>
                    </div>
                    }
                    <div className="certificate-desc">
                        {t('certificate.extra')}
                    </div>
                </div>
            <div className="certificate-sum">
                <ElementWithAccessInCountries hideInCountries={['EN']}>
                  <div className="certificate-graphic second">
                    {'>>>'}
                  </div>
                </ElementWithAccessInCountries>
                <span className="certificate-sum-title">
                    {t('certificate.price')}
                </span>
                <span className="certificate-sum-value">
                     {certificateSum || '-'} {normalizeCurrency()}
                </span>
                <ElementWithAccessInCountries hideInCountries={['RU']}>
                  <img src={cert_en} className='certificate-qr_en' alt='' />
                </ElementWithAccessInCountries>
            </div>
        </div>
    )
}

CertificateRender.propTypes = {
    certificate: PropTypes.object,
    currencies: PropTypes.array,
    step: PropTypes.number,
};

export default CertificateRender