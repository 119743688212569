import React, {useEffect} from "react";
import {NumberParam, StringParam} from "use-query-params";
// @ts-ignore
import {useParams} from "react-router-dom";
import useQueryParamWithDefault from "../../../lib/utils/hooks/useQueryParamWithDefault";
import {IArrival} from "../../../lib/utils/types";
import "./style.css";
import { useInjection } from 'brandi-react';
import { observer } from 'mobx-react-lite';
import { AdventureScheduleModelStoreToken } from './model';
import ScheduleUI from './ui';
import { ArrivalModelStoreToken } from '../../../entities/arrivals/model';

type TSchedule = {
    onSubmit: () => void,
    updateData: (selectedArrival: IArrival) => void,
}

const Schedule = ({
                      updateData,
                      onSubmit,
                  }: TSchedule) => {
    const arrivalM = useInjection(ArrivalModelStoreToken);
    const adventureScheduleModel = useInjection(AdventureScheduleModelStoreToken);

    const pickedArrival = arrivalM.item;
    const arrivals = arrivalM.list;

    const {arrivalURL} = useParams<{ arrivalURL: string }>();
    const isArrivalPreset = !!arrivalURL;

    const [arrivalUrlParam] = useQueryParamWithDefault("arrival", StringParam);
    const [peopleAmount] = useQueryParamWithDefault<number>('people', NumberParam);
    
    useEffect(() => {
        pickedArrival && adventureScheduleModel.setIsArrivalOverbooked(pickedArrival.available_reserved_sits < peopleAmount);
    }, [arrivalURL, arrivalUrlParam]);

    const isButtonDisabled = !arrivalUrlParam && !isArrivalPreset || adventureScheduleModel.isArrivalOverbooked;
    const noSeatsAvailable = pickedArrival?.available_reserved_sits === 0;
    const isShowOnMainButton = (!arrivals?.length && !isArrivalPreset) || (isArrivalPreset && noSeatsAvailable);

    return <ScheduleUI
      isArrivalPreset={isArrivalPreset}
      arrivals={arrivals}
      noSeatsAvailable={noSeatsAvailable}
      isButtonDisabled={isButtonDisabled}
      pickedArrival={pickedArrival}
      updateData={updateData}
      onSubmit={onSubmit}
      peopleAmount={peopleAmount}
      isShowOnMainButton={isShowOnMainButton}
      arrivalUrlParam={arrivalUrlParam}
    />;
};

export default observer(Schedule);