import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import {Input, Label} from 'reactstrap';
import PropTypes from 'prop-types'
import './style.scss'
import {t} from "i18next";
import { Controller } from "react-hook-form";
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';


const GenderRHF = ({
                       register,
                       error,
                       value: initialValue,
                       setValue,
                       fieldName = "gender",
                       label = t('inputs.gender.label'),
                       required = false,
                        control,
                   }) => {


    return (
        <div className="field">
            <Label className="mt-2 input-label" htmlFor={fieldName}>
                {label}:
                {(error || initialValue) &&
                    <span className="validation-img"><img src={error ? invalid : valid} alt=""/></span>}
            </Label>
            <Controller
              name={fieldName}
              control={control}
              defaultValue={initialValue}
              render={({ value, onChange, name }) => {
                return (
                  <>
                    <Input
                      type="hidden"
                      defaultValue={value}
                      name={name}
                      id={name}
                      innerRef={register && register({required})}
                    />
                    <Input
                      id={`gender-m ${fieldName}`}
                      name={name}
                      type="radio"
                      value="m"
                      onChange={e => {
                        onChange(e);
                        setValue(e.target.value);
                      }}
                      checked={initialValue === 'm' && true}
                    />
                    <Label htmlFor={`gender-m ${fieldName}`}>{t('inputs.gender.male')}</Label>
                    <Input
                      id={`gender-f ${fieldName}`}
                      name={name}
                      type="radio"
                      value="f"
                      onChange={e => {
                        onChange(e);
                        setValue(e.target.value);
                      }}
                      checked={initialValue === 'f' && true}
                    />
                    <Label htmlFor={`gender-f ${fieldName}`}>{t('inputs.gender.female')}</Label>
                    <ElementWithAccessInCountries hideInCountries={['RU']}>
                      <Input
                        id={`gender-n ${fieldName}`}
                        name={name}
                        type="radio"
                        value="-"
                        onChange={e => {
                          onChange(e);
                          setValue(e.target.value);
                        }}
                        checked={initialValue === '-' && true}
                      />
                      <Label htmlFor={`gender-n ${fieldName}`}>{t('inputs.gender.none')}</Label>
                    </ElementWithAccessInCountries>
                  </>
                )
              }}
            />
            <div>
                <span className={error ? "error-label" : "error-label d-none"}>
                    {error?.type === "required" && t('inputs.required')}
                </span>
            </div>
        </div>
    )

}

GenderRHF.propTypes = {
    label: PropTypes.string,
    error: PropTypes.object,
    value: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    isEmailField: PropTypes.bool,
    isBirthdateField: PropTypes.bool,
    isGenderField: PropTypes.bool,
}

export default GenderRHF