import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import "dayjs/locale/en";
import "dayjs/locale/ru";
import _ from 'lodash';
import currency from 'currency.js';
import {Price} from 'sv-common'
import {t} from "i18next";
import {calculateMarkUp} from "sv-common/price/calculate";

dayjs.extend(isSameOrAfter)

/**
 * @param {[{}]} members
 * @param {number} peopleAmount
 * @return {[{}]}
 */
export const prepareParticipantsList = (members, peopleAmount) =>
    members.length !== peopleAmount ? new Array(peopleAmount).fill(members[0]) : members;

export const pluralizeWord = (word, placesCount, language = 'ru') => {
    if (language === 'ru'){
        if (placesCount === 1) return (word === 'мест') ? word + "о" : word + 'ка';
        if (placesCount % 10 >= 2 && placesCount % 10 <= 4 && (placesCount > 20 || placesCount < 10)) return (word === 'мест') ? word + "а" : word + 'ки';
        return (word === 'мест') ? word : word + 'ок';
    }

    if (language === 'en'){
        if (placesCount === 1) return word;

        if (placesCount > 1) return word + 's'
    }
};

export const genitivePlaces = (placesCount, language) => {
    if  (language === 'ru') {
        if (
            placesCount === 1 ||
            (placesCount % 10 === 1 && placesCount > 20)
        ) return  " местa";
        return ' мест';
    } else if (language === 'en') {
        if (placesCount === 1)
            return ' place'
        if (placesCount > 1)
            return ' places'
    }
};

export const dativePlaces = (placesCount, language) => {
    if  (language === 'ru') {
        if (
            placesCount === 1 ||
            (placesCount % 10 === 1 && placesCount > 20)
        ) return  "место";
        return 'мест';
    } else if (language === 'en') {
        if (placesCount === 1)
            return ' place'
        if (placesCount > 1)
            return ' places'
    }
};

export const accusativePlaces = (placesCount, language = 'ru') => {
    if  (language === 'ru') {
        if (
            placesCount === 1 ||
            (placesCount % 10 === 1 && placesCount > 20)
        ) return  "места";
        return 'мест';
    } else if (language === 'en') {
        if (placesCount === 1)
            return ' place'
        if (placesCount > 1)
            return ' places'
    }

};

export const helpers = () => {
    const transformBoatToPlaces = (boatClass) => {
        const boatClassToPlaces = {
            'Луч': 1,
            'Elan 210': 4,
            'Оптимист': 1,
            'Катер': 3,
            'Platu 25': 5,
            'SV20': 4
        }
        return boatClassToPlaces[boatClass];
    }

    return {
        transformBoatToPlaces, pluralizeWord
    }
}

export const getDeclension = number => {
    if (number === 1) return 'helpers.anHour';
    if (number % 10 >= 2 && number % 10 <= 4 && Math.floor((number / 10) % 10) !== 1) return 'helpers.hours2';
    return 'helpers.hours';
}



// TODO заменить регулярку телефона на новую на всем проекте
export const regexes = {
    personalDataRegex: /^([A-ZА-ЯЁa-zа-яё])+(\s)*$/,
    addressRegex: /^([A-ZА-ЯЁa-zа-яё.,/\-()\s])+(\s)*$/,
    phoneRegex: /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/,
    phoneNewRegex: /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/,
    emailRegex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/,
    birthdate: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    tg: /^([^А-ЯЁа-яё])+$/,
};

export const calculateDiscount = (coachlessDiscount, fullBookDiscount, priceValue, peopleAmount, boatSize) => {
    let discounts = [{active: false, size: 0}];
    const fullValue = priceValue * peopleAmount;


    if (coachlessDiscount?.active)
        discounts.push({
            value: coachlessDiscount.size,
            active: true,
            size: coachlessDiscount.type === 'summ'
                ? coachlessDiscount.size
                :  fullValue * coachlessDiscount.size / 100,
            text: t('discounts.coachless'),
            type: coachlessDiscount.type,
        });

    if (fullBookDiscount?.active) {
        const discountedPeopleAmount = Math.floor(peopleAmount / boatSize) * boatSize
        const priceToDiscount = priceValue * discountedPeopleAmount
        discounts.push({
            value: fullBookDiscount.size,
            active: true,
            size: fullBookDiscount.type === 'summ'
                ? fullBookDiscount.size
                : priceToDiscount * fullBookDiscount.size / 100,
            text: fullBookDiscount.text || t('discounts.fullBook'),
            type: fullBookDiscount.type,
        })
    }

    const discountValues = discounts.filter(({active}) => active).map(({size}) => size)

    let absoluteDiscountSize = Math.max(
        0,
        ...discountValues,
        discountValues.reduce((discountSum, curVal) => discountSum + curVal, 0)
    )


    let finalDiscount = {};
    if (fullBookDiscount?.active && coachlessDiscount?.active) {
        finalDiscount.active = true;
        finalDiscount.text = t('discounts.fullBookInstructor');
        finalDiscount.type = "summ";
        const maxDiscountedValue = currency(fullValue)
        finalDiscount.size = absoluteDiscountSize > maxDiscountedValue.value ? maxDiscountedValue.value: absoluteDiscountSize;
        finalDiscount.value = finalDiscount.size;
    } else finalDiscount = discounts.find(({active}) => active)


    return finalDiscount || {};
}

export const calculatePrice = (order, promocodeApplied, priceValue, peopleAmount, discountSize, certificateApplied, installment) => {
    if (!_.isEmpty(order)) {
        let {totalValue, discountedValue} = order;

        if (promocodeApplied?.status === 'valid'){
            const subtractedValue = currency(totalValue).subtract(promocodeApplied.actualAbsoluteSize).value;
            discountedValue = subtractedValue < 1 ? Math.ceil(subtractedValue) : subtractedValue
        }
        if (certificateApplied?.status === 'valid') {
            let subtractedValue;
            if (order.certificateApplied && (discountedValue === order.discountedValue)) {
                subtractedValue = discountedValue
            } else {
                subtractedValue = currency(discountedValue).subtract(certificateApplied.convertedSizeLeft).value;
            }
            if (subtractedValue < 0) subtractedValue = 0;
            discountedValue = subtractedValue;
        }
        return discountedValue
    } else if (priceValue) {
        const price = installment ? priceValue + calculateMarkUp(priceValue, installment.markup) : priceValue;

        return price * peopleAmount - (discountSize || 0);
    }
    return 0;
}

// функция округляет цисло до ближайшего, кратного
// первый параметр - цисло которое нужно округлить
// второй параметр - цисло к кратному которого, нужно округлить
export const roundToNum = (roundingNum, toRounding) => {
    return Math.round((roundingNum / toRounding)) * toRounding
}

export const getPhoneSettings = (country) => {
    return {
        placeholder: '+7 (000) 000-00-00',
        mask: '+7 (999) 999-99-99',
        countryCode: 'RU'
    }
}

export const trimOnChange = e => e.target.value = e.target.value.trim()

/**
 * Method to calculate promocode discount and show if this discount is reached general limit
 * @param {{actualAbsoluteSize: number, actualSize: number, currency: string, extraInfo: string, id: number, name: string, size: number, status: string, type: string, usesLeft: number}} - generic applied promocode object. It contains info about final calculated common discount and self discount parameters.
 * @param {number} totalValue - full order price
 * @param {number} singleValue - single slot price
 * @param {{active: boolean, size: number, text: string, type: string, value: number}} discount - generic applied discount object. It contains all calculated discounts except promocode (fullbook, coachless)
 * @returns {{size: number, exceed: boolean}} - calculated promocode discount to render and state if promocode exceeds limit
 */

export const calculatePromoDiscount = (promocodeApplied, totalValue, singleValue, discount) => {
    const calculatedSize = promocodeApplied?.actualSize;
    const promocodeSize = promocodeApplied?.type === 'percent' ? promocodeApplied?.size : Math.round(promocodeApplied?.size / singleValue * 100);
    const remainingSize = Math.round((totalValue * 0.25 - discount?.size) / totalValue * 100);
    if (calculatedSize > 25) {
        return {
            size: calculatedSize,
            exceed: false
        }
    } else {
        if (promocodeSize > remainingSize) {
            return {
                size: remainingSize,
                exceed: true
            }
        } else {
            return {
                size: promocodeSize,
                exceed: false
            }
        }
    }
}

export const localizeDate = (date) => {
    let dateObj = new Date(date);
    let day = dateObj.getDate();
    let month = dateObj.getMonth() + 1;
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return `${day}.${month}.${year}`;
};
export const normalizeCurrency = (currencyKey, currencies) => currencies.find(item => item.key === currencyKey)?.sign;

export const calculateSubscriptionBalance = (subscriptionBalance, currentPrice, isNegativeResult) => {
    const balance = currency(subscriptionBalance).subtract(currentPrice).value;
    return balance >= 0 ? balance : isNegativeResult ? balance : false;
}

// Inverted naming (true if not expired, false if expired)
export const isProductNotExpired = (date, forceClose) => {
    if (forceClose) return false;
    return !date ? true : dayjs().isBefore(dayjs(date));
}

export const verifyPromocodeOrCertificate = async (productType, value, slotId, checkPromocode, orderId, type, captcha) => {
    try {
        // the type is only available when the promocode or certificate is deleting
        if (value === null && type) {
            const discountTypes = {
                promocode: 'promocodeApplied',
                certificate: 'certificateApplied'
            }
            await checkPromocode(productType, slotId, null, orderId, captcha)
            return {deletePromocode: true, type: discountTypes[type]}
        }
        const res = await checkPromocode(productType, slotId, value, orderId, captcha)
        const {status, extraInfo, reason} = res;

        return status === 'invalid'
            ? {invalidPromocodeReason: reason || extraInfo || t('inputs.enterPromocode.wrongPromo')}
            : res
    } catch (error) {
        console.log(error)
    }
};

export const calculatePromoAndCertDiscount = (certificate, promocode, order, product) => {
    if (!order) return {
        price: product?.price_value || 0,
        percent: Price.math.convertAbsoluteToRelative(
            product?.price_value,
            product?.total_value) || 0
    }
    let promoAndCertPrice = calculatePrice(
        order,
        promocode,
        null, null, null,
        certificate
    )
    if (promoAndCertPrice < 0) promoAndCertPrice = 0;

    const discountPercent = Price.math.convertAbsoluteToRelative(
        currency(order.totalValue).subtract(promoAndCertPrice).value,
        order.totalValue
    )

    return {
        price: promoAndCertPrice,
        percent: discountPercent
    }
}

export const preparePeopleData = (name, value, peopleData) => {
    if (name && value) {
        let currentPeopleData = _.cloneDeep(peopleData);
        value = value.trim();
        const [propertyName, personOrder] = name.split('-');
        currentPeopleData[personOrder] = {
            ...currentPeopleData[personOrder],
            [propertyName]: value
        };

        return currentPeopleData
    }
    return peopleData
}