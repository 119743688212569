import {Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {t} from "i18next";
import Field from "../Field";
import './style.scss';
import InsuranceCheckbox from "./InsuranceCheckbox";
import FormButton from "../buttons/main";
import BirthdateRHF from "../BirthdateRHF";
import { useEffect, useState } from "react";
import { observer } from 'mobx-react-lite';
import { useInsuranceApi } from 'lib/api/useInsuranceApi';
import dayjs from "dayjs";



const InsuranceModal = ({handleModalVisible, defaultValues}) => {
    const {control, register, handleSubmit, setValue, formState: { errors }} = useForm({
        mode: "onSubmit",
        reValidateMode: "onChange",
        defaultValues: {
            firstName: defaultValues?.first_name || '',
            lastName: defaultValues?.last_name || '',
            birthDate: dayjs(defaultValues?.birthdate || '').format('DD.MM.YYYY') || '',
            agreeWithConcent: false,
            agreeWithPersonalData: false,
        }
    });

    useEffect(() => {
        if (defaultValues && Object.keys(defaultValues).length > 0) {
            if (defaultValues.birthdate) setValue('birthDate', dayjs(defaultValues?.birthdate || '').format('DD.MM.YYYY'));
            setValue('firstName', defaultValues?.first_name || '');
            setValue('lastName', defaultValues?.last_name || '');
        }
    }, [defaultValues, setValue])

    const { createInsurance } = useInsuranceApi();
    const [isInsuranceConsentAccepted, setIsInsuranceConsentAccepted] = useState(false);

    const onSubmit = (data) => {
        delete data.agreeWithConcent; 
        delete data.agreeWithPersonalData; 
        data.email = defaultValues?.email || '';
        data.birthDate = dayjs(data.birthDate, "DD.MM.YYYY").format('YYYY-MM-DD');

        createInsurance({orderId: defaultValues.orderId, userDetails: {...data}})
            .catch(e => {
                console.log(t(parseErrors(e.response?.data?.message)))
            })
            .finally(() => handleModalVisible(false))
    };

    return (
        <Modal
            isOpen
            className={'insurance-modal'}
            toggle={() => handleModalVisible()}
            centered
        >
            <ModalHeader>
                {t('common.insuranceTitle')}
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSubmit)} action="">
                {!isInsuranceConsentAccepted ? <Row>
                    <Col xs={12} md={12}>
                        <InsuranceCheckbox
                            register={register}
                            name="agreeWithConcentBefore"
                            required={t('common.fields.consent.validation.advertising')}
                            text=""
                            error={errors['agreeWithConcent']}
                            onClick={() => setIsInsuranceConsentAccepted(true)}
                        >
                            {`${t('common.fields.consent.label')}
                            ${t('common.fields.consent.linkAdvertising')}`}
                        </InsuranceCheckbox>
                    </Col>
                </Row> : <>
                <Row>
                    <Col xs={12} md={6} className={'control-wrapper'}>
                        <Field
                            label={t('inputs.firstName.label')}
                            error={errors['firstName']}
                            placeholder={t('inputs.firstName.label')}
                            fieldName="firstName"
                            register={register}
                            required
                            className="field"
                        />
                    </Col>
                    <Col xs={12} md={6} className={'control-wrapper'}>
                        <Field
                            label={t('inputs.lastName.label')}
                            error={errors['lastName']}
                            placeholder={t('inputs.lastName.label')}
                            fieldName="lastName"
                            register={register}
                            required
                            className="field"
                        />
                    </Col>
                    <Col xs={12} md={6} className={'control-wrapper'}>
                        <BirthdateRHF
                            register={register}
                            fieldName="birthDate"
                            control={control}
                            error={errors['birthDate']}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <InsuranceCheckbox
                            register={register}
                            name="agreeWithConcent"
                            required={t('common.fields.consent.validation.advertising')}
                            text=""
                            error={errors['agreeWithConcent']}
                        >
                            {`${t('common.fields.consent.label')}
                            ${t('common.fields.consent.linkAdvertising')}`}
                        </InsuranceCheckbox>
                    </Col>
                    <Col xs={12} md={12}>
                        <InsuranceCheckbox
                            register={register}
                            name="agreeWithPersonalData"
                            required="true"
                            error
                        >
                            {`${t('common.fields.consent.label')}
                            ${t('common.fields.consent.linkCaption')}`}
                        </InsuranceCheckbox>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <div class="warning">
                            {t('common.insuranceWarning')}
                        </div>
                    </Col>
                </Row>
                <FormButton type="submit" value={t('common.buttons.send')}/>
                </>}
                </form>
            </ModalBody>
        </Modal>
    )
}

export default observer(InsuranceModal);