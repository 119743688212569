const useFullBookDiscount = (sendData, bookFullBoat, calculateBookAvailability) => {
  const handle = (checked) => {
    if (checked) {
      bookFullBoat();
      sendData({
        peopleAmount: calculateBookAvailability(),
        isFullBookChecked: true,
      });
    } else {
      sendData({ isFullBookChecked: false });
    }
  }

  return { handle };
}

export default useFullBookDiscount;