import { makeAutoObservable } from 'mobx';
import { token } from 'brandi';
import container from '../../../../../lib/ioc';

export interface IPhoneLoginModel {
  step: number,
  setStep: (v: number) => void,
  phone?: string,
  setPhone: (v: string) => void,
  token?: string,
  setToken: (v: string) => void,
}

export class PhoneLoginModel implements IPhoneLoginModel {
  step = 1;
  phone?: string;
  token?: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setPhone(v?: string): void {
    this.phone = v;
  }

  setStep(v: number): void {
    this.step = v;
  }

  setToken(v?: string): void {
    this.token = v;
  }
}

export const PhoneLoginModelStoreToken = token<IPhoneLoginModel>('PhoneLoginModelStoreToken');

container.bind(PhoneLoginModelStoreToken).toInstance(PhoneLoginModel).inSingletonScope();

