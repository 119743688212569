import { schedulePages } from '../pages';

const usePickDate = (eventData, initialGlobalState, fetchSlots, eventURL, page, resetEvent) => {
  const pick = (dataType, pickedData) => {
    const {slot, eventId, priceCurrency, priceValue, base, boat, date, ...rest} = eventData
    const preparedData = { ...rest, ...initialGlobalState, shouldResetPeople: true, slot: null, [dataType]: pickedData };
    if (pickedData === eventData?.date) fetchSlots(base, boat, date, eventURL, true);
    if (page !== schedulePages.event) resetEvent();

    return preparedData;
  }

  return { pick };
}

export default usePickDate;