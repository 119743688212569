import React, { useEffect, useMemo } from 'react';
import {Row, Label, Input, Button} from 'reactstrap';
import './style.scss';
import invisible from '../../../../styles/assets/images/sv/pass-invisible.svg';
import visible from '../../../../styles/assets/images/sv/pass-visible.svg';
import {useForm} from "react-hook-form";
import emailTick from '../../../../styles/assets/images/sv/email-tick.svg';
import {useHistory, useLocation} from 'react-router-dom';
import Logo from "../../../layout/ Logo";
import {regexes} from "../../../../lib/utils/helpers";
import PhoneFieldRHF from "../../../common/PhoneFieldRHF";
import i18next from "i18next";
import ElementWithAccessInCountries from "../../../../lib/utils/hocs/ElementWithAccessInCountries";
import {useRedirect} from "../../../../lib/utils/hooks/useRedirect";
import { observer } from 'mobx-react-lite';
import { useInjection } from 'brandi-react';
import { RegisterFormModelStoreToken } from './model';
import { AuthModelStoreToken } from '../../../../entities/auth/model';
import { FormRouterModelStoreToken } from '../model';
import { FORM_STEPS } from '../../../../lib/utils/constants';
import cross from '../../../../styles/assets/images/sv/crossBlack.svg';
import { getUpdatedRedirectUrl } from 'lib/utils/helpers/urlParseHelpers';

const RegisterForm = ({completeUserJwt}) => {
    const model = useInjection(RegisterFormModelStoreToken);
    const authModel = useInjection(AuthModelStoreToken);
    const formRouterModel = useInjection(FormRouterModelStoreToken);
    let history = useHistory();

    const handleCheckbox = () => {
        model.setSubmitActive(!model.isSubmitActive);
    }

    const isCorp = useMemo(() => new URLSearchParams(history.location.search).get('is_corp'), [history]);
    const redirectWithoutAuthUrl = useMemo(() => new URLSearchParams(history.location.search).get('redirect') || '', [history]);

    const form = useForm({
        mode: "onChange",
        reValidateMode: "onChange"
    });
    const {setRedirect} = useRedirect();
    const {search, pathname} = useLocation();

    const {register, errors, handleSubmit, getValues, setError} = form;

    const values = getValues();

    const { t } = i18next;

    const onSubmit = async (data) => {
      model.onSubmit(data, completeUserJwt, setError).then(() => {
        setRedirect(pathname + search);
        history.push('/register/ok');
      });
    }

    useEffect(() => {
      return () => {
        model.setCustomError('');
        model.setSubmitActive(false);
        model.setPassDifficult('');
      }
    }, []);

    return (
        <div id={'login'} className={'login'}>
            <div className={'login-container'}>
                {isCorp && <div className="d-flex justify-content-end">
                    <button onClick={() => 
                                    window.location.replace(getUpdatedRedirectUrl(redirectWithoutAuthUrl))}
                                    className="button_close">
                                <img src={cross} alt=""/>
                    </button>            
                </div>}
                <div className="login-logo">
                    <Logo />
                </div>
                <div className="login-sub-logo login-text">
                    {t('common.signUp.title')}
                </div>
                <form onSubmit={handleSubmit(onSubmit)} action="">
                    <Row className="no-gutters login-row">
                        <Label htmlFor="name" className="login-label">
                            {t('common.fields.firstName.label')}
                        </Label>
                        <Input
                            className={errors.name || values.email === "exist@mail.com"
                                ? "login-input has-error"
                                : "login-input"
                            }
                            type="text"
                            placeholder={t('common.fields.firstName.placeholder')}
                            id="name"
                            name="name"
                            innerRef={register({
                                required: true,
                                pattern: /^[а-яёА-ЯЁa-zA-Z]+$/
                            })}
                        />
                        <span className={"error-label"}>
                          {errors.name?.type === "required" ? t('common.fields.firstName.validation.required') : ""}
                          {errors.name?.type === "pattern" ? t('common.fields.firstName.validation.pattern') : ""}
                        </span>
                    </Row>
                    <Row className="no-gutters login-row">
                        <img src={emailTick}
                             className={values.email && !errors.email && model.customError !== "emailError"
                                 ? "email-correct"
                                 : "email-correct d-none"
                            }
                             alt=""/>
                        <Label htmlFor="email" className="login-label">
                            {t('common.fields.email.label')}
                        </Label>
                        <Input
                            className={!!errors.email ? "login-input has-error" : "login-input"}
                            type="text"
                            placeholder={t('common.fields.email.placeholder')}
                            id="email"
                            name="email"
                            onChange={() => model.customError === 'emailError' && model.setCustomError('')}
                            innerRef={register({
                                pattern: regexes.emailRegex,
                                required: true,
                            })}
                        />
                        <span className={"error-label"}>
                            {errors.email?.type === "required" ? t('common.fields.email.validation.required') : ""}
                            {errors.email?.type === "pattern" ? t('common.fields.email.validation.pattern') : ""}
                            {model.customError === "emailError" && <>
                                <span>{t('common.fields.email.validation.regEmailTaken')} </span>
                                <button className={'login-link'} type="button" onClick={() => formRouterModel.setStep(FORM_STEPS.PASSWORD_RESTORE)}>
                                    {t('common.forgotPassword.linkCaption')}
                                </button>
                            </>}
                        </span>
                    </Row>
                    {!completeUserJwt &&
                        <Row className="no-gutters login-row">
                            <Label htmlFor="phone" className="login-label">{t('common.fields.phone.label')}</Label>
                            <PhoneFieldRHF className={'login-phone-input'} form={form} name='phone'
                                           error={errors.phone || {}}
                                           withValidationImg={false}/>
                            <span className={"error-label"}>
                                {model.customError === "phoneTakenError" && <>
                                    <span>{t('common.fields.phoneCode.validation.phoneTaken')}.</span>
                                    <button className={'login-link'} type="button" onClick={() => formRouterModel.setStep(FORM_STEPS.PASSWORD_RESTORE)}>
                                        {t('common.forgotPassword.linkCaption')}
                                    </button>
                                </>}
                            </span>
                        </Row>
                    }
                    <Row className={'justify-content-between no-gutters login-row pass-row'}>
                        <Label htmlFor="password" className="login-label">
                            {t('common.fields.password.signUpLabel')}
                        </Label>
                        <Input
                            type={model.isPassInvisible ? 'password' : 'text'}
                            id="password"
                            className={!!errors.password ? "password-input login-input has-error" : model.isPassInvisible ? "password-input login-input pass-invisible" : "password-input login-input"}
                            name="password"
                            onChange={e => model.onHandleChange(e.target.value)}
                            placeholder={t('common.fields.password.placeholder')}
                            innerRef={register({
                                required: true,
                                pattern:/^[a-zA-Z0-9!@#%\\/]+$/,
                                minLength: 5
                            })}
                        />
                        <div className={"error-label"}>
                            {errors.password?.type === "required" ? t('common.fields.password.validation.required') : ""}
                            {errors.password?.type === "pattern" ? t('common.fields.password.validation.pattern') : ""}
                            {errors.password?.type === "minLength" ? t('common.fields.password.validation.minLength') : ""}
                        </div>

                        {
                            model.passDifficult !== "empty" &&
                            <div className={"validation-message"}>
                                <div className={"validation-label"}>
                                    {model.passDifficult === "easy" &&
                                    <span className={"easy-text"}>
                                        {t('common.fields.password.strength.easy')}
                                    </span>}
                                    {model.passDifficult === "medium" &&
                                    <span className={"medium-text"}>
                                        {t('common.fields.password.strength.medium')}
                                    </span>}
                                    {model.passDifficult === "hard" &&
                                    <span className={"hard-text"}>
                                        {t('common.fields.password.strength.hard')}
                                    </span>}
                                </div>
                                <div>
                                    {model.passDifficult === "easy" &&
                                    <div>
                                      <span className={"valid easy"}/>
                                      <span className={"valid none"}/>
                                      <span className={"valid none"}/>
                                    </div>}
                                    {model.passDifficult === "medium" &&
                                    <div>
                                      <span className={"valid medium"}/>
                                      <span className={"valid medium"}/>
                                      <span className={"valid none"}/>
                                    </div>}
                                    {model.passDifficult === "hard" && <div>
                                        <div>
                                          <span className={"valid hard"}/>
                                          <span className={"valid hard"}/>
                                          <span className={"valid hard"}/>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        }

                        <img className={model.isPassInvisible ? 'login-pass-toggle' : 'login-pass-toggle visible'}
                             src={model.isPassInvisible ? invisible : visible} alt=""
                             onClick={() => model.setPassInvisible(!model.isPassInvisible)}/>
                    </Row>
                    <Row className="no-gutters login-row">
                        <Input
                            className="login-input"
                            type="checkbox"
                            id="consent"
                            name="consent"
                            onChange={handleCheckbox}
                            innerRef={register({
                                required: true
                            })}
                        />
                        <Label htmlFor="consent" className="login-label">
                            {t('common.fields.consent.label')} <a target='_blank' rel='noopener noreferrer' href={t('agreements.personalData')}>
                                {t('common.fields.consent.linkCaption')}
                            </a>
                        </Label>
                        <span className={"error-label"}>
                          {errors.consent?.type === "required" ? t('common.fields.consent.validation.consent') : ""}
                        </span>
                    </Row>
                    <Row className={'no-gutters'}>
                        <Button type="submit" disabled={!model.isSubmitActive} className={'w-100 reg-button'}
                                color={'primary'}>
                                    {t('common.buttons.signUp')}
                        </Button>
                    </Row>
                </form>
                <div className="login-row">
                    <div className="login-link-row">
                        {t('common.signUp.gotAnAccount')} <button onClick={() => formRouterModel.setStep(FORM_STEPS.PHONE_LOGIN)} className="login-link">{t('common.buttons.logIn')}</button>
                    </div>
                </div>
                <div className="login-text login-social-text">
                    {t('common.signUp.signUpVia')}
                </div>
                <Row className={'no-gutters justify-content-center'}>
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                        <Button tag={'a'} className="social-btn social-tinkoffid" href={authModel.generateSocialMediaLink('tbank')}/>
                    </ElementWithAccessInCountries>
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                        <Button tag={'a'} className="social-btn social-vk" href={authModel.generateSocialMediaLink('vk', false)}/>
                    </ElementWithAccessInCountries>
                    <Button tag={'a'} className="social-btn social-google" href={authModel.generateSocialMediaLink('google')}/>
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                      <Button tag={'a'} className="social-btn social-ya" href={authModel.generateSocialMediaLink('yandexid')}/>
                    </ElementWithAccessInCountries>
                </Row>
            </div>
        </div>
    )
}

export default observer(RegisterForm);
