import React, {useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {StringParam, useQueryParam} from "use-query-params";
import {BASE_SILAVETRA_URL, features} from "../../api/globalState";

const forbidden = ['/login', '/logout', '/connect', '/restore'];

export const useRedirect = () => {
    const defaultUrl = BASE_SILAVETRA_URL;

    const history = useHistory();
    const [redirect] = useQueryParam('redirect', StringParam);

    useEffect(() => {
        redirect && setRedirect(redirect)
    }, [redirect]);

    function redirectTo() {
        let redirect = getRedirect();
        if(!redirect || redirect === '/' || forbidden.find(x => redirect.startsWith(x))) redirect = defaultUrl;

        setRedirect();
        if(redirect.startsWith('/')) {
            history.push(redirect);
        } else {
            window.location.href = redirect;
        }
    }

    function isForbidden(redirect) {
        return !redirect || redirect === '/' || forbidden.find(x => redirect.startsWith(x));
    }

    function setRedirect(redirect) {
        if(!redirect || isForbidden(redirect)) localStorage.removeItem('redirect');
        else localStorage.setItem('redirect', redirect);
    }

    function getRedirect() {
        return redirect || localStorage.getItem('redirect');
    }

    function withRedirect(url) {
        if(!getRedirect()) return url;
        if(!url.includes('?')) url = url + '?';
        else url = url + '&';
        url += 'redirect=' + encodeURIComponent(getRedirect());
        return url;
    }

    function pushWithRedirect(url, data) {
        history.push(withRedirect(url), data);
    }
    function errorPageRedirect(withoutFlagFunction) {
        if (features['SV-1569']) {
            history.push('/error')
        } else {
            return withoutFlagFunction()
        }
    }

    return {redirectTo, pushWithRedirect, setRedirect, withRedirect, errorPageRedirect};
}