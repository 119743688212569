import {ISportBookingCard} from "../../../../lib/utils/types";
import ProductBookingCard from "../../ProductBookingCard";
import {localizeDate} from "../../../../lib/utils/format";
import React, {useMemo} from "react";
import {pluralizeWord} from "../../../../lib/utils/helpers";
import dayjs from "dayjs";
import i18next from "i18next";
import {API_URL} from "../../../../lib/api/useApi";
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';

const SportBookingCard = (props: ISportBookingCard) => {
    const {t, language} = i18next;

    const slotDuration = useMemo(() => {
        if (!props.product) return props.eventData?.duration || props.order?.duration
        const startDatetime = dayjs(props.slot.start_date + ' ' + props.slot.start_time, 'YYYY-MM-DD HH:mm:ss')
        const endDatetime = dayjs(props.slot.end_date + ' ' + props.slot.end_time, 'YYYY-MM-DD HH:mm:ss')
        const duration = dayjs.duration(endDatetime.diff(startDatetime));
        const hours = duration.asHours()
        const minutes = duration.asMinutes() % 60;
        return `${hours >= 1 ? Math.floor(hours) + `${t('bookingCard.h')} ` : ''}
            ${minutes > 0 ? minutes + t('bookingCard.m'): ''}`.trim();
    }, [props.slot.id, language])

    const renderedInstructor = () => {
        if ((props.eventData && Object.keys(props.eventData).length > 0 && !props.eventData?.coachless_discount?.active)
                || (props.order && props.order?.instructor)) {
            if (props.eventData?.instructor?.first_name || props.order?.instructor?.first_name) {
                return `${props.eventData?.instructor?.first_name || props.order?.instructor?.first_name} 
                    ${props.eventData?.instructor?.last_name || props.order?.instructor?.last_name}`
            } else {
                return t('sport.schedule.selectors.anyInstructor')
            }
        } else if (props.order && !props.order?.coachless_discount_applied && !props.order?.instructor) {
            return t('sport.schedule.selectors.anyInstructor')
        } else {
            return t('sport.schedule.selectors.withoutInstructor')
        }
    }

    const img = props.eventData?.base?.img?.url || props.order?.base?.img_url;

    return <ProductBookingCard product={{...props.slot, productType: 'sport'}} label={t('bookingCard.event')} order={props.order}
                               discountedPrice={props.discountedPrice} nonDiscountedPrice={props.nonDiscountedPrice}
                               lowerBadge={props.lowerBadge} priceCurrency={props.priceCurrency}
                               upperBadge={props.upperBadge} extraDiscountLabel={props.extraDiscountLabel}
                               img={img ? API_URL + img : undefined}
                               dateLabel={`${localizeDate(props.slot.start_date, language)}, ${props.slot.start_time.slice(0, -3)}`}
                               isPromocodeApplied={props?.isPromocodeApplied}
    >
        <div className="row no-gutters">
            <div className="col-7">
                <div className={`difficulty`}>
                    <div className="difficulty__label card-label">{t('sport.schedule.selectors.base')}</div>
                    <div className="difficulty__caption card-caption">
                        {props.eventData?.base?.name || props.order?.base?.name}
                    </div>
                </div>
            </div>
            <div className="col-5">
                <div className={`difficulty`}>
                    <div className="difficulty__label card-label">{t('bookingCard.duration')}</div>
                    <div className="difficulty__caption card-caption">
                        {slotDuration}
                    </div>
                </div>
            </div>
            <div className="col-7">
                <div className="weather">
                    <div className="time">
                        <div className="time__label card-label">{t('sport.schedule.selectors.boat')}</div>
                        <div className="time__value card-caption">
                            {props.eventData?.boat?.class || props.order?.boat?.class}
                        </div>
                        {(props.eventData?.boat?.places || props.order?.boat?.places)
                            && <div className="time__value card-caption card-caption--small">
                                ({t('bookingCard.max')} {props.eventData?.boat?.places || props.order?.boat?.places} {
                                    pluralizeWord(t('common.pluralizePlaces'), props.eventData?.boat?.places
                                    || props.order?.boat?.places, language)})
                            </div>
                        }
                    </div>
                </div>
            </div>
          <ElementWithAccessInCountries hideInCountries={['EN']}>
            <div className="col-5">
              <div className={`difficulty`}>
                <div className="difficulty__label card-label">{t('bookingCard.instructor')}</div>
                {<div className="difficulty__caption card-caption">{renderedInstructor()}</div>}
              </div>
            </div>
          </ElementWithAccessInCountries>
        </div>
    </ProductBookingCard>
}

export default SportBookingCard