import { useState, useEffect } from 'react';
import { normalizeTime } from '../../../lib/utils/format';

const Timer = ({ elapsedTime = 0, seconds = 900, setIsSubmitDisabled, className = '', timerText,
                children, key }) => {
    const [remainingTime, setRemainingTime] = useState(seconds - elapsedTime);

    useEffect(() => {
        const remainingTimer = setTimeout(() => {
            setRemainingTime(prevTime => prevTime - 1);
            setIsSubmitDisabled && setIsSubmitDisabled(false);
        }, 1000);

        if (!remainingTime) {
            clearTimeout(remainingTimer);
            setIsSubmitDisabled && setIsSubmitDisabled(true);
        }
    }, [remainingTime]);
  
    return <div key={key} className={className}>{remainingTime > 0 ? timerText(normalizeTime(Math.round(remainingTime))) : children}</div>
} 

export default Timer;
