import LoginForm from "./LoginForm";
import {FORM_STEPS} from "../../../lib/utils/constants";
import PasswordRestoreForm from "./PasswordRestoreForm";
import RegisterForm from "./RegisterForm";
import { useInjection } from 'brandi-react';
import { FormRouterModelStoreToken } from './model';
import { observer } from 'mobx-react-lite';

const FormsRouter = ({isRedirectAfterLogin}) => {
    const model = useInjection(FormRouterModelStoreToken);

    return <>
        {(model.step === FORM_STEPS.PASSWORD_LOGIN || model.step === FORM_STEPS.PHONE_LOGIN) && <LoginForm formStep={model.step} isRedirectAfterLogin={isRedirectAfterLogin} />}
        {model.step === FORM_STEPS.PASSWORD_RESTORE && <PasswordRestoreForm />}
        {model.step === FORM_STEPS.REGISTER && <RegisterForm completeUserJwt={model.completeUserJwt} />}
    </>
}

export default observer(FormsRouter)