import React from 'react';
import PropTypes from 'prop-types';
import Divider from '../../../generic/Divider';
import {useApi} from '../../../../lib/api/useApi';
import dayjs from "dayjs";
import Feedback from "../../../common/Feedback";
import i18next, {t} from "i18next";
import {accusativePlaces, dativePlaces} from "../../../../lib/utils/helpers";
import CalendarButton from '../../../common/CalendarEvents/Button';
import { Trans } from 'react-i18next';
import ElementWithAccessInCountries from 'lib/utils/hocs/ElementWithAccessInCountries';

const CommonInfoTheory = () => {
    const phoneText = t('final.contactPhone');
    const emailText = t('final.contactEmail');
    const telegramText = t('final.contactTelegramShort');

    return (
        <>
            <p className="py-3 py-md-4">
            {t('final.soonWillEmail')}{' '}
            <ElementWithAccessInCountries hideInCountries={['EN']}>
                <Trans i18nKey="final.eventSuccessEmail"
                    values={{
                        phone: phoneText,
                        email: emailText,
                        telegram: telegramText
                    }}
                    >
                        <a href={`tel:${phoneText}`}>{phoneText}</a>
                        <a href={`mailto:${emailText}`}>{emailText}</a>
                        <a href={`${t('final.contactTelegram')}`} target="_blank">
                            {telegramText}
                        </a>
                </Trans>
            </ElementWithAccessInCountries>
            <p className="py-3 py-md-4">
                {t('final.before')}
            </p>
            <p className="pb-3 pb-md-4">
                <a href={'https://silavetra.com/magazine/kak-kaifanut-na-yachte'}>{t('final.firstTravel')}</a><br/>
                <a href={'https://silavetra.com/magazine/3-captains'}>{t('final.advices')}</a><br/>
                <a href={'https://silavetra.com/magazine/gid-po-yahtennym-pravam'}>{t('final.aboutYachtLicence')}</a>
            </p>
            <ElementWithAccessInCountries hideInCountries={['RU']}>
                {t('final.ifHaveQuestions')}
            </ElementWithAccessInCountries>
            </p>
        </>
    )
}

const Final = ({order}) => {
    const {materials} = useApi();
    const {language} = i18next;

    return (
        order
            ? <div className="final">
                {!order.hasPaid
                    ? <div className="final__title">
                        {order?.installment ?
                            t('final.thanksForBookWithInstallment') :
                            t('final.thanksForBook', {
                            places: order?.participants?.length,
                            accusativePlaces: accusativePlaces(order?.participants?.length, language),
                            orderName: order?.practice?.name
                        })}
                        {!order?.installment &&
                        <>
                            {t('final.practiceFrom')}
                            <nobr> {dayjs(order.practice?.startDate).format('DD.MM')}</nobr>{t('final.on')}
                            <nobr> {dayjs(order.practice?.endDate).format('DD.MM')}</nobr>{t('final.inProcess')}
                        </>
                        }
                    </div>
                    :  <div className="final__title">
                        {order?.installment ?
                            t('final.successPaymentWithInstallment') :
                            t('final.successPayment', {places: order.participants?.length,
                            dativePlaces: dativePlaces(order.participants?.length, language), orderName: order.practice?.name})
                        }
                        <nobr> {dayjs(order.practice?.startDate).format('DD.MM')}</nobr>
                        {t('final.on')}
                        <nobr>{dayjs(order.practice?.endDate).format('DD.MM')}</nobr>.
                        <CommonInfoTheory/>
                    </div>
                }
                {order && order.practice && <CalendarButton
                  name={order.practice.name}
                  startDate={order.practice.startDate}
                  endDate={order.practice.endDate}
                  startTime={order.practice.startTime}
                  endTime={order.practice.endTime}
                />}
                {!!materials?.length && <>
                    <div className="final__title">
                        {t('final.materials')}
                    </div>
                    <div className="final__docs-list">
                        {materials.map(material => (
                            <a href={material.link} target="_blank" rel="noopener noreferrer" className="final__docs-item">
                                {material.name}
                            </a>)
                        )}
                    </div>
                </>}
                <Divider/>
                <Feedback />
            </div>
            : <></>
    )
}

Final.propTypes = {
    order: PropTypes.object,
}

export default Final;

