import { usePublicEndpoints } from '../../../../lib/api/usePublicEndpoints';
import { useParams } from 'react-router-dom';

const useSportByCorp = (setBases, setFetchedBases, setBoats, setDates, setIsLoading, setIsLoadingLocal) => {
  const {corpSlug} = useParams();

  const { getEventsByCorp } = usePublicEndpoints();

  const loadEventsByCorp = () => {
    getEventsByCorp(corpSlug).then(res => {
      setBases(res.bases);
      setFetchedBases(res.bases);
      setBoats(res.boats);
      setDates(res.availableDates.sort());
    }).catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
        setIsLoadingLocal(false);
      });
  }

  return { loadEventsByCorp };
}

export default useSportByCorp;