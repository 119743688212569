import React, { FC, useEffect, useState } from 'react';

interface ITimoutRedirectProps {
  seconds: number,
  text: string,
  href: string,
}

const TimoutRedirect: FC<ITimoutRedirectProps> = ({ seconds, text, href }) => {
  const [time, setSetTime] = useState(seconds);

  useEffect(() => {
    setTimeout(() => {
      document.location.href = href;
    }, seconds * 1000);

    const interval = setInterval(() => {
      setSetTime(time => time - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <p>{text}: {time || 0}</p>
  )
}

export default TimoutRedirect;