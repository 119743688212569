import React from 'react';
import SuggestionsItem from './SuggestionsItem';
import {t} from "i18next";

import './style.scss';
import {ISuggestion, ISuggestionsListProps} from "./types";

const SuggestionsList = ({suggestions, setAddress}: ISuggestionsListProps) => {
    return (
        <div className={"suggestions suggestions-top"}>
            <div className="suggestions__title title-label">
                {t('inputs.provideAddress')}
            </div>
            <div className="suggestions__list">
                {suggestions.map((item: ISuggestion ) =>
                    <SuggestionsItem address={item} setAddress={setAddress} />
                )}
            </div>
        </div>
    )
}

export default SuggestionsList
