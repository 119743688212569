import React from 'react';
import './style.scss';

const MAX_DISCOUNT_SYMBOLS = 28;

const GenericDiscount = ({discount, currency}) => {
    const discountLine = discount.type === 'percent'
        ?  <div>{`${discount?.relativeValue} % `}</div>
        :  <div>{`${discount?.value} ${currency} `}</div>
  return (
    <div className={"discount"}>
      <div className="discount__container d-flex justify-content-between">
          <div className="discount__text">
            {discount?.text.slice(0, MAX_DISCOUNT_SYMBOLS)}
            {discount?.text.length > MAX_DISCOUNT_SYMBOLS && '...'}
          </div>
        <div className="discount__amount">
            {discountLine}
            {discountLine}
            {discountLine}
            {discountLine}
        </div>
      </div>
    </div>
  )
}

export default GenericDiscount;