import { schedulePages } from '../pages';

const useWithOrder = (sendData, setBases, setBoats, isDataEntered, setGlobalEvent, page, getEvent, getAvailableDates, setDates, fetchedBases, fetchSlots, setIsLoadingLocal, eventURL) => {
  const initializeOrder = (order) => {
    const storagedData = JSON.parse(localStorage.getItem('storagedData'));
    const {base, boat, date, slot} = storagedData;
    sendData(storagedData);
    const selectedBases = order?.base ? [order.base] : fetchedBases;
    const selectedBoats = order ? order.base?.boats : selectedBases?.filter(item => item.name === base.name)[0]?.boats || [];
    setBases(() => selectedBases);
    setBoats(() => selectedBoats);
    if (isDataEntered) setGlobalEvent(slot.event);
    if (page === schedulePages.event) {
      getEvent();
    } else {
      getAvailableDates(base.id, boat.class)
        .then(res => {
          setDates(() => res.availableDates.sort());
          setIsLoadingLocal(false)
        })
      fetchSlots(base, boat, date, eventURL);
    }
  }

  return { initializeOrder }
}

export default useWithOrder;