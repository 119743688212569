import { regexes } from '../../../../../../lib/utils/helpers';
import { TUserFields } from '../../../../../../lib/api/useTypedPublicEndpoint';

export const makePeopleDataAdditional = (people: TUserFields[], payingUser: TUserFields) => {
  return people.map((item, index) => {
    const firstNameDefaultValue = index === 0 ? (item?.first_name || payingUser.first_name) : '';
    const lastNameDefaultValue = index === 0 ? (item?.last_name || payingUser.last_name) : '';
    const emailDefaultValue = index === 0 ? (item?.email || payingUser.email) : '';
    const tgDefaultValue = index === 0 ? (item?.telegram || payingUser.telegram) : '';

    return {
      firstName: {
        defaultValue: firstNameDefaultValue,
        disabled: !!firstNameDefaultValue,
        pattern: !!firstNameDefaultValue ? false : regexes.personalDataRegex,
      },
      lastName: {
        defaultValue: lastNameDefaultValue,
        pattern: !!lastNameDefaultValue ? false : regexes.personalDataRegex,
        disabled: !!lastNameDefaultValue,
      },
      email: {
        defaultValue: emailDefaultValue,
        pattern: !!emailDefaultValue ? false : regexes.emailRegex,
        disabled: !!emailDefaultValue,
      },
      telegram: {
        defaultValue: tgDefaultValue,
        pattern: !!tgDefaultValue ? false : regexes.tg,
      }
    }
  })
}