import { getCookie, setAllContextCookie, setCookie } from '../../../lib/utils/cookies';
import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { TUserFields } from '../../../lib/api/useTypedPublicEndpoint';
import { GlobalModelStoreToken, IGlobalStateModel } from '../../../lib/models/global';
import { AUTH_EVENT_TYPE } from '../../../lib/utils/constants';
// @ts-ignore
import { CarrotEventTypes } from 'sv-common/constants/carrotEventTypes';
import { IUsersService, UsersServiceStoreToken } from '../../users/api/service';
import { ICheckAuthCodeRes, ICompleteUserRes, ILoginRes } from '../types';
import { AuthServiceStoreToken, IAuthService } from '../api/service';
import { emptyGlobalState } from '../../../lib/api/globalState';
import { toast } from 'react-toastify';
import { API_URL } from '../../../lib/api/useApi';
import {makeAutoObservable} from 'mobx';

type TEnsureCallback = (isUserAlreadyLoggedIn: boolean, user?: TUserFields) => void;

export interface IAuthModel extends IAuthService {
  isLoggedIn: () => boolean,
  ensureLoggedIn: (callback: TEnsureCallback) => void
  onAuth: (v: ILoginRes) => void,
  generateSocialMediaLink: (providerName: string, encode: boolean) => string,
  complete: (data: any) => Promise<ICompleteUserRes>,
}

export default class AuthModel implements IAuthModel {
  constructor(
    private globalState: IGlobalStateModel,
    private userS: IUsersService,
    private authS: IAuthService,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  isLoggedIn(): boolean {
    const state = this.globalState.get();
    return !!state.userId && !!state.userAccessToken && !!getCookie('SV_LOGIN');
  }

  ensureLoggedIn(callback: TEnsureCallback) {
    if (!this.isLoggedIn()) {
      this.globalState.set({isLoginPopupOpen: true, callback, [AUTH_EVENT_TYPE]: CarrotEventTypes.AUTH_FROM_STEP_EVENT });
    } else {
      return callback(true);
    }
  }

  onAuth({ jwt, refresh }: ILoginRes) {
    setCookie('SV_LOGIN', jwt);
    setCookie('sv_booking_refresh_token', refresh);

    this.userS.getCleanFields().then(res => {
      this.globalState.get().userLoggedInCallback(false, res);
    }).catch(e => console.error(e));
  }

  authorize(identifier: string, password: string, captcha: string, eventType: string): Promise<ILoginRes | ICheckAuthCodeRes> {
    return this.authS.authorize(identifier, password, captcha, eventType)
      .then(this.parseLoginResponse)
      .catch((error) => {
        this.globalState.set(emptyGlobalState);
        toast('Something went wrong! Try again!', {type: 'error'});
        throw error;
    });
  }

  forgotPassword(email: string): Promise<void> {
    return this.authS.forgotPassword(email)
      .catch((error) => {
        toast('Something went wrong! Try again!', {type: 'error'});
        throw error;
    });
  }

  register(email: string, password: string, consent: string, name: string, phone: string): Promise<ILoginRes | ICheckAuthCodeRes> {
    return this.authS.register(email, password, consent, name, phone)
      .then(this.parseLoginResponse)
      .catch(error => {
        this.globalState.set(emptyGlobalState);
        toast('Something went wrong! Try again!', { type: 'error' });
        throw error;
    });
  }

  generateSocialMediaLink(providerName: string, encode = true) {
    return `${API_URL}/connect/${providerName}?redirect=${encode ? encodeURIComponent(window.location.href) : window.location.href}`
  }

  complete(data: any): Promise<ICompleteUserRes> {
    return this.authS.complete(data).then(this.parseLoginResponse);
  }

  private parseLoginResponse(res: any) {
    this.globalState.set({
      userAccessToken: res.jwt,
      userRefreshToken: res.refresh,
      userEmail: res.user.email,
      userName: res.user.username,
      userId: res.user.id,
      userRole: res.user.role.name,
      isLoginPopupOpen: false,
      clientRole: res.user.client?.role_name,
      authProvider: res.provider,
    });

    setAllContextCookie('sv_booking_uid', res.user.id);
    setCookie('sv_booking_email', res.user.email);
    setCookie('sv_booking_name', res.user.username);
    setCookie('SV_LOGIN', res.jwt);
    setCookie('sv_booking_refresh_token', res.refresh);
    setCookie('sv_booking_role', res.user.role.name);
    setCookie('sv_booking_client_role', res.user.client?.role_name);
    setCookie('sv_booking_consent', res.user.client?.is_safety_consent_accepted);
    setCookie('sv_booking_auth_provider', res.provider);

    return res;
  }
}

export const AuthModelStoreToken = token<IAuthModel>('AuthModelStoreToken');

container.bind(AuthModelStoreToken).toInstance(AuthModel).inSingletonScope();

injected(AuthModel, GlobalModelStoreToken, UsersServiceStoreToken, AuthServiceStoreToken);