import {useApi} from "./useApi";

export const LecturesApi = () => {
    const { rawRequest } = useApi();

    const getLectures = async (params) =>
        rawRequest('get', '/public/groups', {type: 'lecture', ...params});

    return {
        getLectures,
    }
}
