import { usePrivateFileApi } from '../../../lib/api/privateFile';
import { useEffect, useState } from 'react';
import { API_URL } from '../../../lib/api/useApi';
import Dropzone from '../../generic/Dropzone';
import CarouselComponent from '../Carousel';
import { FILE_MAX_SIZE } from '../../../lib/utils/constants';
import compressImage from 'sv-common/helpers/frontend/compressImage';

const PrivateFileComponent = ({ extra, withSlider = true, type, dropZoneType }) => {
  const { history, upload, deleteEntry } = usePrivateFileApi();

  const [files, setFiles] = useState([]);
  const [error, setError] = useState();

  const file = files[0];

  useEffect(() => {
    history({ type, ...extra }).then(setFiles);
  }, []);

  const onDelete = (item) => {
    const itemIndex = files.findIndex(i => i.id === item.id);
    if (itemIndex >= 0 ) {
      deleteEntry(item.id).then(() => {
        setFiles([
          ...files.slice(0, itemIndex),
          ...files.slice(itemIndex + 1),
        ]);
      });
    }
  }

  const onUpload = (value) =>  {
    value.preview && compressImage(value, 1000, 1000, 5_000_000, (file) => {
      upload({ files: file, type, ...extra }).then(res => {
        setFiles([res, ...files]);
        setError(null);
      }).catch(err => {
        setError(err.response.data.message);
      });
    });

    if (!value.preview && !value.fieldName && value.file) {
      onDelete(value.file);
    }
  }

  return (
    <>
      <Dropzone
        accept={{'image/*,.pdf': []}}
        maxSize={FILE_MAX_SIZE}
        extFile={file && { preview: API_URL+file.url, ext: file.ext, ...file }}
        callback={onUpload}
        onError={setError}
        type={dropZoneType}
      />
      {error && <span className={"error-label"}>{error}</span>}
      {withSlider && <CarouselComponent
        items={files.map(i => ({ ...i, src: API_URL+i.url }))}
        onDelete={onDelete}
      />}
    </>
  )
}

export default PrivateFileComponent;