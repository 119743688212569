import {Input, Label} from "reactstrap";
import './style.scss';

const InsuranceCheckbox = ({children, register, name, error, required = false, onClick}) => {

    return (
        <div className="insurance-input checkout__input-wrapper radio-input" onClick={onClick}>
            <Input
                type="checkbox"
                id={name}
                data-testid="name"
                name={name}
                innerRef={register({
                    required
                })}
            />
            <Label htmlFor={name}>
                { children }
            </Label>
        </div>
    )
}

export default InsuranceCheckbox;