import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { IPaymentsService, PaymentServiceStoreToken } from '../../../entities/payments/api/service';
import { makeAutoObservable } from 'mobx';
import { TOrder } from '../../../lib/utils/types';
import { features } from '../../../lib/api/globalState';
import { IOrdersModel, OrdersModelStoreToken } from '../../../entities/orders/model';
import { History } from 'history';

export interface IMembersCheckoutModel {
  handleUpdate: (setIsLoading: (value: boolean) => void, step: number, history: History, pathname: string) => Promise<void>,
  onOrderChange: (verifyPromo: (value: string, type?: string) => void) => void,
  setCurrentData: (v: TOrder) => void,
  currentData: TOrder,
}

export default class MembersCheckoutModel {
  currentData: TOrder = {} as TOrder;
  constructor(
    private ordersM: IOrdersModel,
    private paymentsS: IPaymentsService,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  handleUpdate(setIsLoading: (value: boolean) => void, step: number, history: History, pathname: string): Promise<void> {
    const dataToSend = {
      ...this.currentData,
      step,
    }

    setIsLoading(true);

    const id = this.ordersM.item?.id;

    if (!id) {
      return Promise.resolve();
    }

    return this.ordersM.update(id, dataToSend)
      .then((res) => {
        if (features['SV-1569']) {
          history.push('/error');
        } else {
          return this.paymentsS.create(res.orderId, pathname + `?orderId=${id}&step=5`);
        }
      })
      .then((res) => {
        if (res) {
          window.location.href = res.url;
        }
      })
      .finally(() => setIsLoading(false));
  }

  onOrderChange(verifyPromo: (value: string, type?: string) => void) {
    const id = this.ordersM.item?.id;
    if (id) {
      this.ordersM.getOrderTimeout(id);

      const promoApplied = this.ordersM.item?.promocodeApplied;
      const certApplied = this.ordersM.item?.certificateApplied;

      promoApplied && verifyPromo(promoApplied);
      certApplied && verifyPromo(certApplied);
    }
  }

  setCurrentData(v: TOrder) {
    this.currentData = { ...this.currentData, ...v };
  }
}

export const AdventureMembersCheckoutModelStoreToken = token<IMembersCheckoutModel>('AdventureMembersCheckoutModelStoreToken');

container.bind(AdventureMembersCheckoutModelStoreToken).toInstance(MembersCheckoutModel).inSingletonScope();

injected(MembersCheckoutModel, OrdersModelStoreToken, PaymentServiceStoreToken)