import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {Button, Input, Label, Row} from "reactstrap";
import {useCaptcha} from "../../../../../lib/utils/hooks/useCaptcha";
import visible from "../../../../../styles/assets/images/sv/pass-visible.svg";
import invisible from "../../../../../styles/assets/images/sv/pass-invisible.svg";
import {features} from "../../../../../lib/api/globalState";
import i18next from "i18next";
import { FORM_STEPS } from '../../../../../lib/utils/constants';
import { useInjection } from 'brandi-react';
import { FormRouterModelStoreToken } from '../../model';
import { PasswordLoginModelStoreToken } from './model';

const PasswordLogin = ({onLoginSuccess}) => {
    const formRouterModel = useInjection(FormRouterModelStoreToken);
    const model = useInjection(PasswordLoginModelStoreToken);

    const {register, errors, setError, handleSubmit, clearErrors} = useForm({
        mode: "onBlur",
        reValidateMode: "onBlur",
        defaultValues: {
            birthDate: ""
        }
    });
    const [generateCaptcha, CaptchaDiv] = useCaptcha();

    const location = useLocation();
    const {state} = location;

    const [isPassVisible, setPassVisible] = useState(false);

    const { t } = i18next;

    const onSubmit = (data) => model.onSubmit(data, generateCaptcha, setError, clearErrors, onLoginSuccess);

    useEffect(() => {
        if (state?.error === 'Auth.form.error.email.taken') {
            setError("email", {
                type: "exist",
                message: t('common.fields.email.validation.emailTaken')
            });
            state.next = '/';
        }
    }, []);

    return <>
        {CaptchaDiv}
        <div className="login-sub-logo login-text">
            {t('common.logIn.password.title')}
        </div>
        <form onSubmit={handleSubmit(onSubmit)} action="">
            <Row className="no-gutters login-row">
                <Label htmlFor="email" className="login-label">
                    {t('common.fields.email.label')}
                </Label>
                <Input
                    className={errors.email ? "login-input has-error" : "login-input"}
                    type="text"
                    id="email"
                    name="email"
                    innerRef={register({
                        required: t('common.fields.email.validation.required'),
                        pattern: {
                            value: /^\S+@\S+\.\S+$/,
                            message: t('common.fields.email.validation.pattern')
                        }
                    })}
                />
                <span className={"error-label"}>{errors.email?.message}</span>
            </Row>
            <Row className={'justify-content-between no-gutters login-row pass-row'}>
                <Label htmlFor="password" className="login-label">
                    {t('common.fields.password.label')}
                </Label>
                <button className="login-label login-link" type="button" onClick={() => formRouterModel.setStep(FORM_STEPS.PASSWORD_RESTORE)}>
                    {t('common.forgotPassword.linkCaption')}
                </button>
                <Input
                    type={isPassVisible ? 'text' : 'password'}
                    id="password"
                    className={isPassVisible ? 'login-input' : 'login-input pass-invisible'}
                    name="password"
                    innerRef={register({
                        required: t('common.fields.password.validation.required'),
                        minLength: {
                            value: 5,
                            message: t('common.fields.password.validation.minLength'),
                        }
                    })}
                />
                <span className={"error-label"}>{errors.password?.message}</span>
                <img className={isPassVisible ? 'login-pass-toggle visible' : 'login-pass-toggle'}
                     src={isPassVisible ? visible : invisible} alt="" onClick={() => setPassVisible(!isPassVisible)}
                />
            </Row>
            <Row className={'no-gutters login-button-group'}>
                <Button type="submit" className={'w-100'} color={'primary'}>
                    {t('common.buttons.logIn')}
                </Button>
                <Button className={'w-100 btn btn-secondary'} onClick={() => formRouterModel.setStep(FORM_STEPS.REGISTER)}>
                    {t('common.buttons.signUp')}
                </Button>
                {features['SV-1817'] && <>
                    <div className="login-separator">
                        {t('common.helpers.or')}
                    </div>
                    <Button className={'w-100 btn btn-secondary mt-2'} onClick={() => formRouterModel.setStep(FORM_STEPS.PHONE_LOGIN)}>
                    {t('common.buttons.loginViaPhone')}
                    </Button>
                </>}
                <span className={"error-label"}>{errors.captcha?.message}</span>
            </Row>
        </form>
    </>
}

export default PasswordLogin;