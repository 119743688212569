import {useEffect} from 'react';
import {
    QueryParamConfigMap,
    useQueryParams,
} from "use-query-params";

const UseQueryParamsWithDefault = (paramsConfigMap: QueryParamConfigMap, defaultValues?: { [key: string]: any }) => {
    const [query, setQuery] = useQueryParams(paramsConfigMap);
    useEffect(() => {
        if (defaultValues) {
            const valuesToSet: { [key: string]: any } = {};
            Object.keys(defaultValues)
                .filter(k => !query[k])
                .forEach((k: string) => {
                   if (defaultValues[k])
                       valuesToSet[k] = defaultValues[k]
                });
            setQuery(q => ({...q, ...valuesToSet}), 'replaceIn')
        }
    }, []);

    const setQueryWithReplace = (changes: { [key: string]: any }) => setQuery(q => ({...q, ...changes}), 'replaceIn')
    return [query, setQueryWithReplace];

};

export default UseQueryParamsWithDefault;