import React from "react";
import './style.scss';
// import CustomDiscount from "../../common/badges/CustomDiscount";
import PriceBlock from "../PriceBlock";
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';
import { t } from 'i18next';

const BookingCard = ({ entity, hasPaid, payingUser, currencies, oldPrice, price}) => {
    return (
        <>
            <div className="booking-card subscription-card">
                <div className="primary-info">
                    <div className="card-hole"></div>
                    <div className="main-info-label card-label"></div>
                </div>
                <div className="abonement__secondary-info row no-gutters">
                    <div className="field">
                    <div className="abonement__card-label difficulty__label card-label">Тип абонемента</div>
                    <div className="abonement__card-caption card-caption">
                        <ElementWithAccessInCountries hideInCountries={['EN']}>Парусный спорт</ElementWithAccessInCountries>
                        <ElementWithAccessInCountries hideInCountries={['RU']}>Saling Pass</ElementWithAccessInCountries>
                    </div>
                    </div>
                    <div className="col-7 field">
                        <div className="abonement__card-label time__label card-label">{t('subscriptions.countryOfUsage')}</div>
                        <div className="abonement__card-caption time__value card-caption">
                            <ElementWithAccessInCountries hideInCountries={['EN']}>Россия</ElementWithAccessInCountries>
                            <ElementWithAccessInCountries hideInCountries={['RU']}>UAE</ElementWithAccessInCountries>
                        </div>
                    </div>
                </div>
                <PriceBlock
                    hasPaid={hasPaid}
                    entity={entity}
                    currencies={currencies}
                    oldPrice={oldPrice}
                    price={price}
                />
            </div>
        </>
    );
};



export default BookingCard;
