import { IApiModel } from '../../../lib/api/index.interface';
import { ILevel } from '../../../lib/utils/types';
import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';

export interface IExperienceService {
  getDifficultyLevels: () => Promise<ILevel[]>,
}

export default class ExperienceService implements IExperienceService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  getDifficultyLevels(): Promise<ILevel[]> {
    return this.api.rawRequest('get', '/public/difficulty-levels');
  }
}

export const ExperienceServiceStoreToken = token<IExperienceService>('ExperienceServiceStoreToken');

container.bind(ExperienceServiceStoreToken).toInstance(ExperienceService).inSingletonScope();

injected(ExperienceService, ApiModelStoreToken);