import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { IPhoneAuthService, PhoneAuthServiceStoreToken } from '../api/phoneService';
import { t } from 'i18next';
import { FieldName, FieldValues } from 'react-hook-form/dist/types/fields';
import { ErrorOption } from 'react-hook-form/dist/types/errors';
import { ICheckAuthCodeRes } from '../types';
import { emptyGlobalState } from '../../../lib/api/globalState';
import { GlobalModelStoreToken, IGlobalStateModel } from '../../../lib/models/global';
import { makeAutoObservable } from 'mobx';

export interface IPhoneAuthModel extends IPhoneAuthService {
  checkCodeErrorHandler: (error: any, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void, inputName: string) => void,
}

export default class PhoneAuthModel implements IPhoneAuthModel {
  constructor(
    private service: IPhoneAuthService,
    private globalM: IGlobalStateModel,
  ){
    makeAutoObservable(this, {}, { autoBind: true });
  }

  checkCodeErrorHandler(error: any, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void, inputName: string) {
    if (error.response?.data?.message === 'SMS sending limit reached') {
      setError(inputName, {
        type: 'phoneLimit',
        message: t('api.usePhoneLogin.phoneLimit') as string,
      });
    } else {
      setError(inputName, {
        type: 'unknownError',
        message: t('api.usePhoneLogin.unknownError') as string,
      });
    }
  }

  checkCode(code: string, token: string): Promise<ICheckAuthCodeRes> {
    return this.service.checkCode(code, token).then((response) => {
      if (response.data.user) {
        this.globalM.set({
          userId: response.data.user.id,
          userAccessToken: response.data.jwt,
          userEmail: response.data.user.email,
          userName: response.data.user.username,
          userRole: response.data.user.role.name,
          userRefreshToken: response.data.refresh,
          isLoginPopupOpen: false,
        });

        return response;
      } else {
        return response
      }
    })
      .catch((error) => {
        this.globalM.set(emptyGlobalState);
        throw error;
      });
  }

  sendCode(phone: string, captcha: string): Promise<{ token: string }> {
    return this.service.sendCode(phone, captcha);
  }
}

export const PhoneAuthModelStoreToken = token<IPhoneAuthModel>('PhoneAuthModelStoreToken');

container.bind(PhoneAuthModelStoreToken).toInstance(PhoneAuthModel).inSingletonScope();

injected(PhoneAuthModel, PhoneAuthServiceStoreToken, GlobalModelStoreToken);