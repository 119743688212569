import './style.scss'
import {getWeekDay, localizeDate, normalizeDate, weekdaysLocalized} from "../../../../../lib/utils/format";
import React from "react";
import PropTypes from 'prop-types'
import i18next, {t} from "i18next";

const PresetArrival = ({arrival = {}}) => {
    const {language} = i18next;

    return <div className="arrival">
        <div className="arrival__item">
            <span className="arrival__item-title">{t('practice.label')}</span>
            <span className="arrival__item-value">{arrival.name || ''}</span>
        </div>
        <div className="arrival__item">
            <span className="arrival__item-title">{t('travel.startPlace')}</span>
            <span className="arrival__item-value">{arrival.city || ''}</span>
        </div>
        <div className="arrival__item">
            <span className="arrival__item-title">{t('travel.startDate')}</span>
            <span className="arrival__item-value">
                {`${localizeDate(arrival.startDate, language) || ''} | ${weekdaysLocalized[language][getWeekDay(normalizeDate(arrival.startDate))] || ''}`}
            </span>
        </div>
        <div className="arrival__item">
            <span className="arrival__item-title">{t('travel.freeSits')}</span>
            <span className="arrival__item-value">
                {`${arrival.availableReservedSits <= 0 ? '0' : arrival.availableReservedSits} / ${arrival.totalSits || ''}`}
            </span>
        </div>
    </div>
}

PresetArrival.propTypes = {
    arrival: PropTypes.object,
}

export default PresetArrival
