import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getNonEmpty} from "sv-common/functions/formatters";
import {GroupsApi} from "../../../../../../lib/api/useGroupsApi";
import {
    getWeekDay,
    localizeDate,
    normalizeDate,
    normalizeTimePart,
    weekdaysLocalized
} from "../../../../../../lib/utils/format";
import {useLoaderState} from "../../../../../../lib/api/loaderState";
import i18next from "i18next";

const PresetProduct = ({setSelectedGroup}) => {
    const [group, setGroup] = useState({})

    const {getGroupByUrl} = GroupsApi()
    const {productURL} = useParams()

    const {setIsLoading} = useLoaderState();
    const {t, language} = i18next;

    useEffect(() => {
        setIsLoading(true)
        getGroupByUrl(productURL).then(res => {
            setGroup(res.group)
            setSelectedGroup(res.group)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [productURL])

    return(
        <div className="arrivalFromUrl">
            <div className="arrival_item">
                <span className="arrival_item_title">{group.type === 'lecture' ? t('school.lecture') : t('school.group')}</span>
                <span className="arrival_item_value">{group.name || ''}</span>
            </div>
            <div className="arrival_item">
                <span className="arrival_item_title">{t('school.place')}</span>
                <span className="arrival_item_value">{group.place || ''}</span>
            </div>
            <div className="arrival_item">
                <span className="arrival_item_title">{t('school.address')}</span>
                <span className="arrival_item_value">{group.address || ''}</span>
            </div>
            <div className="arrival_item">
                <span className="arrival_item_title">{t('travel.startDate')}</span>
                <span className="arrival_item_value">
                    {`${localizeDate(group.startDate, language) || ''} | ${normalizeTimePart(group.startTime)} | ${weekdaysLocalized[language][getWeekDay(normalizeDate(group.startDate), language)] || ''}`}
                </span>
            </div>
            <div className="arrival_item">
                <span className="arrival_item_title">{t('school.freePlaces')}</span>
                <span className="arrival_item_value">
                    {`${getNonEmpty(group.availableSits) <= 0 ? '0' : getNonEmpty(group.availableSits)} / ${group.totalSits || ''}`}
                </span>
            </div>
        </div>
    )
}

export default PresetProduct