import { usePublicEndpoints } from '../../../../../../lib/api/usePublicEndpoints';

const useOrderTimeout = (query, setElapsedTime) => {
  const {getOrderTimeout} = usePublicEndpoints();

  const check = () => {
    getOrderTimeout(query.orderId)
      .then(res => setElapsedTime(() => res))
      .catch(e => console.log(e))
  }

  return { check }
}

export default useOrderTimeout;