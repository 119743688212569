import React, {useEffect, useState} from 'react';
import {NumberParam, StringParam, useQueryParam, withDefault} from 'use-query-params';
import {useParams} from 'react-router-dom';
import {calculateDiscount} from "sv-common/price/adventures";
import {currencies as currenciesFallback} from "sv-common/constants/currencies";

const useDiscountCalcEffect = (arrival, promocodeApplied, order, setIsTeamBookActive, currencies = []) => {
    const [people,] = useQueryParam('people', withDefault(NumberParam, 1));
    const [arrivalUrlParam,] = useQueryParam('arrival', StringParam);
    const {arrivalURL = undefined} = useParams();
    const [discount, setDiscount] = useState(null);
    const eurCurrency = (currenciesFallback || currencies).find(c => c.key === 'EUR')
    useEffect(() => {
        let discountsApplied = [];
        if (arrival?.id) {
            if (arrival?.team_book_discount_size && people >= (arrival?.team_book_discount_condition || 0)) {
                discountsApplied = [...discountsApplied, 'team_book_discount'];
                setIsTeamBookActive(true)
            } else {
                setIsTeamBookActive(false)
            }
            const discount = calculateDiscount(
                arrival,
                promocodeApplied,
                discountsApplied,
                arrival.price_value * people,
                people,
                arrival.has_loyal_discount ?
                    {
                        loyalCustomersAmount: order?.participants?.filter(client => client.has_loyal_discount).length,
                        conversionRate: arrival.price_currency !== 'EUR' ? eurCurrency.conversion_rate || 90 : 1
                    }
                    : undefined
            );
            setDiscount(() => discount)
        }
    }, [people, arrivalUrlParam, arrivalURL, arrival?.id, order?.id, currencies.length])

    const commonDiscountInfo = {
        relativeValue: discount?.discountSize,
        value: discount?.absoluteSize,
        type: discount?.type,
        currency: discount?.currency,
    };

    if (discount?.extraInfo === 'promocode')
        return null;
    if (discount?.extraInfo === 'team_book_discount')
        return {
            ...commonDiscountInfo,
            text: 'скидка за полную команду'
        }
    if (discount?.extraInfo === 'certificate') // TODO
        return {
            ...commonDiscountInfo,
            text: 'скидка за сертификат'
        }
    if (discount?.extraInfo === 'loyal')
        return {
            ...commonDiscountInfo,
            currency: 'EUR',
            value: order?.participants?.filter(client => client.has_loyal_discount).length * 50,
            text: 'скидка за лояльность',
            extraInfo: discount.extraInfo
        }
    return null;
}

export default useDiscountCalcEffect