import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import customParseFormat from "dayjs/plugin/customParseFormat";

import updateLocale from 'dayjs/plugin/updateLocale'
import isoWeek from 'dayjs/plugin/isoWeek'
import duration from 'dayjs/plugin/duration'

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(updateLocale);
dayjs.extend(isoWeek);

dayjs.updateLocale("ru", {
    weekdays: ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"],
});

dayjs.locale("en");
export const datetimeFull = (d) => (d ? dayjs(d).format("YYYY.MM.DD HH:mm:ss") : "");

export const datetime = (d) => (d ? dayjs(d).format("YYYY.MM.DD HH:mm") : "");

export const date = (d) => (d ? dayjs(d).format("MMM DD") : "");

export const datetimeToDate = (d) => (d ? dayjs(d).format("DD.MM.YYYY") : "");

export const datetimeToISODate = (d) => d ? dayjs(d, 'DD.MM.YYYY').format("YYYY-MM-DD") : "";

export const year = (d) => (d ? dayjs(d).format("MMM YYYY") : "");

export const time = (d) => (d ? dayjs(d).format("HH:mm") : "");

export const formatDate = (date) => (date ? dayjs(date, "DD.MM.YYYY").format("MM/DD/YYYY") : "");

export const dateToISO = (date) => (date ? dayjs(date, "DD.MM.YYYY").format("YYYY-MM-DD") : "");

export const isISODate = (str) => /^\d{4}-\d{2}-\d{2}$/.test(str);

export const convertToISO = (date) => {
  if (typeof date === 'object') {
    date = dayjs(date);
  }
  return !isISODate(date) ? dateToISO(date) : date
}

export const normalizeDate = (date) => (date ? dayjs(date, "YYYY-MM-DD").format("MM/DD/YYYY") : "");

export const normalizeTime = (time) => dayjs.duration(time * 1000).format('mm:ss');

export const normalizeTimePart = (time) => dayjs(time, 'HH:mm:ss').format('HH:mm');

export const localizeDate = (date, language, format) => (date ? dayjs(date).locale(language || "ru").format(format || "D MMMM") : "");

export const localizeFullDate = (date, language = 'ru') => (date ? dayjs(date).locale(language).format("D MMMM YYYY") : "");

export const getWeekDay = (date, language) => (date ? dayjs(date).locale(language || "ru").isoWeekday() : "");

export const isDateExpired = (currentDate, targetDate) => dayjs(targetDate).isAfter(dayjs(currentDate))

export const weekdays = ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"]

export const weekdaysLocalized = {
    ru: ["", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
    en: ["", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
}

export const number = (str) => {
    if (!str || str === "") return undefined;
    const parsed = parseInt(str);
    if (isNaN(parsed)) return undefined;
    return parsed;
};
