import { injected, token } from 'brandi';
import container from '../../../../../../lib/ioc';
import { FieldName, FieldValues } from 'react-hook-form/dist/types/fields';
import { ErrorOption } from 'react-hook-form/dist/types/errors';
import { GlobalModelStoreToken, IGlobalStateModel } from '../../../../../../lib/models/global';
import { IPhoneAuthModel, PhoneAuthModelStoreToken } from '../../../../../../entities/auth/model/phoneModel';
import { IPhoneLoginModel, PhoneLoginModelStoreToken } from '../model';

export interface IEnterPhoneFormModel {
  onSubmit: (phone: string, generateCaptcha: (cb: (token: string) => void) => void, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void) => void,
}

export default class EnterPhoneFormModel implements IEnterPhoneFormModel {
  constructor(
    private globalM: IGlobalStateModel,
    private phoneAuthM: IPhoneAuthModel,
    private phoneLoginM: IPhoneLoginModel,
  ) {

  }

  onSubmit(phone: string, generateCaptcha: (cb: (token: string) => void) => void, setError: (name: FieldName<FieldValues>, error: ErrorOption) => void) {
    generateCaptcha((captcha) => {
      this.globalM.set({ isLoading: true });

      this.phoneAuthM.sendCode(phone.toString(), captcha)
        .then(result => {
          this.phoneLoginM.setPhone(phone);
          this.phoneLoginM.setToken(result.token);
          this.phoneLoginM.setStep(2);
        })
        .catch(error => this.phoneAuthM.checkCodeErrorHandler(error, setError, 'phone'))
        .finally(() => this.globalM.set({ isLoading: false }));
    });
  }
}

export const EnterPhoneFormModelStoreToken = token<IEnterPhoneFormModel>('EnterPhoneFormModelStoreToken');

container.bind(EnterPhoneFormModelStoreToken).toInstance(EnterPhoneFormModel).inSingletonScope();

injected(EnterPhoneFormModel, GlobalModelStoreToken, PhoneAuthModelStoreToken, PhoneLoginModelStoreToken);