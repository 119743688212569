import { Modal, ModalBody, ModalHeader, Input, Alert } from 'reactstrap';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useEmailAuth } from '../../../lib/api/useEmailAuth';
import FormButton from '../../generic/buttons/main';
import TimoutCallback from '../../common/TimoutCallback';

const TwfaModal = ({ email, onSuccess, customHandler, toggle }) => {
  const [code, setCode] = useState('');
  const [error, setError] = useState();
  const [resendAvailable, setResendAvailable] = useState(false);
  const { check, checkCode } = useEmailAuth();

  const resend = () => {
    check(email);
    setResendAvailable(!resendAvailable);
  }

  useEffect(() => {
    resend();
  }, []);

  const confirm = () => {
    if (customHandler) {
      customHandler(code, email)?.catch(e => {
        setError(e?.response?.data?.message);
      });
    } else {
      checkCode(code, email)
        .then((res) => {
          setError(null);
          onSuccess(res);
        }).catch(e => {
        setError(e?.response?.data?.message);
      });
    }
  }

  return (
    <Modal
      isOpen={true}
      className={'edit-modal twofa_modal'}
      toggle={toggle}
    >
      <ModalHeader>{t('profile.twofa.modal')}</ModalHeader>
      <ModalBody>
        {!!error && <Alert color="danger">{error}</Alert>}
        <Input
          className='twofa_modal_input'
          type="text"
          onChange={e => setCode(e.target.value)}
          placeholder={t('profile.twofa.placeholder')}
        />
        <FormButton type="button" value={t('profile.twofa.submit')} onClick={() => confirm()} />
        <TimoutCallback
          seconds={60}
          text={t('profile.twofa.resendAgain')}
          depsForReload={[resendAvailable]}
          callbackComponent={<FormButton type="button" value={t('profile.twofa.resendSubmit')} onClick={() => resend()} />}
          textStyle={{ marginTop: 10 }}
        />
      </ModalBody>
    </Modal>
  )
}

export default TwfaModal;