import { usePublicEndpoints } from '../../../../lib/api/usePublicEndpoints';

const usePickBoat = (eventData, initialGlobalState, sendData, resetEvent) => {
  const { getBoatsPlaces } = usePublicEndpoints();

  const updateBoatPlaces = (boatClass) => {
    getBoatsPlaces(boatClass)
      .then(res => {
        return sendData({ boat: res });
      })
      .catch(e => console.error(e));
  }

  const pick = (pickedData) => {
    const {slot, date, eventId, priceCurrency, priceValue, ...rest} = eventData
    const preparedData = { ...rest, ...initialGlobalState, shouldResetPeople: true, date: null, boat: pickedData };
    if (eventData?.boat?.class && pickedData.class === eventData?.boat.class) {
      updateBoatPlaces(pickedData.class);
    }
    resetEvent();

    return preparedData;
  }

  return { pick }
}

export default usePickBoat;