import React from "react";
import { Col, Input } from "reactstrap";
import MaskedInput from "react-input-mask";
import PropTypes from "prop-types";
import { regexes } from "../../../../../../lib/utils/helpers";
import ValidationIcon from "../../../../../generic/ValidationIcon";
import PhoneField from "../../../../../common/PhoneField";
import {t} from "i18next";


const PhoneNumber = ({name, id, label, error, value, register, handleInputChange, defaultValue, control, index}) => (
    <Col xs={12} md={6} className={"control-wrapper"}>
        <span className={"input-label"}>{label}</span>
        <PhoneField
            error={error}
            index={index}
            control={control}
            register={register}
            setPhoneValue={handleInputChange}
            phoneValue={defaultValue}
        />
        <span className={error ? "error-label" : "error-label d-none"}>
            {error?.type === "required" ? t('inputs.required') : ""}
            {error?.type === "pattern" ? t('inputs.phone.pattern') : ""}
            {error?.type === "phoneTaken" ? error?.message : ''}
        </span>
    </Col>
);

PhoneNumber.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    error: PropTypes.object,
    value: PropTypes.string,
    register: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.string
};

export default PhoneNumber;
