import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IPaymentExtraBlockProps } from './index';
import PaymentCertComponent from '../components/certificate';
import PaymentPromoComponent from '../components/promocode';
import PaymentPayExtraComponent from '../components/payExtra';
import { t } from 'i18next';
import { useInjection } from 'brandi-react';
import { observer } from 'mobx-react-lite';
// @ts-ignore
import { CERTIFICATE_TYPES } from 'sv-common/constants/certificates';
import { PayBlockModelStoreToken } from '../models/PayBlockModel/index.model';
import { getPartPayPrice } from '../../../../lib/utils/helpers/priceHelpers';
import {Button} from "reactstrap";
import { normalizeCurrency } from 'lib/utils/helpers';
import { usePublicEndpoints } from 'lib/api/usePublicEndpoints';

const PaymentMethodCertComponent: FC<IPaymentExtraBlockProps> = observer((props) => {
  const {
    verifyPromocode,
    setPromocodeApplied,
    setCertificateApplied,
    promoAndCertDiscount,
    order,
    isShowPromocode,
    certRequired = true,
  } = props;

  const { getCurrencies } = usePublicEndpoints();
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    getCurrencies()
        .then(res => setCurrencies(res));
  }, [])

  const payBlockModel = useInjection(PayBlockModelStoreToken);

  const isCorpCert = payBlockModel.cert?.value?.certificateType === CERTIFICATE_TYPES.CORPORATE;

  const onApply = useCallback((captcha?: string) => {
    if (certRequired && !payBlockModel.cert.query) {
      payBlockModel.cert.setError(t('inputs.required'));
    } else {
      payBlockModel.verifyPromoAndCert(verifyPromocode, captcha);
    }
  }, [payBlockModel.cert, verifyPromocode]);

  const successApply = payBlockModel.isSuccessApply();
  const success = promoAndCertDiscount.price <= 0;

  if (order.hasPaid) {
    return null;
  }

  const promo = payBlockModel.promo.value;

  return (
    <div className='payment-block'>
      {!successApply ? <>
        <PaymentCertComponent order={order} setCertificateApplied={setCertificateApplied} />
        {!isCorpCert && isShowPromocode && <PaymentPromoComponent order={order} setPromocodeApplied={setPromocodeApplied} hideLabel verifyPromocode={verifyPromocode} />}
        {!successApply && !order?.hasPaid && <Button
                color={'primary'}
                type={'button'}
                className={'form-button apply-button'}
                onClick={() => onApply()}
        >
            {t('inputs.enterPromocode.submitButton')}
        </Button>}
      </> : <>
        <div className='title-label'>{t('common.certificate')}</div>
        <table className='mt-3'>
          <tbody>
            <tr>
              <td className='font-weight-bold pb-2 pr-5'>{t('paymentPay.certBalance')}:</td>
              <td className='pb-2'>{payBlockModel.cert.value?.sizeLeft || 0}{normalizeCurrency(order.priceCurrency || payBlockModel.cert.value?.payment_currency, currencies)}</td>
            </tr>
            {!!promo && <tr>
              <td className='font-weight-bold pb-2 pr-5'>{t('paymentPay.promoDiscount')}:</td>
              <td className='pb-2'>{promo?.size}{promo.type === 'percent' ? '%' : ` ${promo.currency}`} {promo.type === 'percent' && <span>({getPartPayPrice(order.totalValue, promo?.size)}{normalizeCurrency(order.priceCurrency || payBlockModel.cert.value?.payment_currency, currencies)})</span>}</td>
            </tr>}
            <tr>
              <td className='font-weight-bold pb-2 pr-5'>{t('paymentPay.remainder.cert')}:</td>
              <td className={`pb-2 ${success ? 'text-success' : 'text-danger'}`} >{!success && t('paymentPay.notEnoughMoney')} {promoAndCertDiscount.price || 0}{normalizeCurrency(order.priceCurrency || payBlockModel.cert.value?.payment_currency, currencies)}</td>
            </tr>
          </tbody>
        </table>
        {certRequired && !success && <>
          <div className='payment-divider ml-m5' />
          <PaymentPayExtraComponent />
        </>}
      </>}
    </div>
  )
});

export default PaymentMethodCertComponent;