import React, { useState } from "react";
import "./style.scss";
import FormButton from "../../../generic/buttons/main";
import { convertPrice } from "sv-common/price/calculate";
import { normalizeCurrency } from "../../../../lib/utils/helpers";
import {t} from "i18next";

const PriceBlock = ({
    people,
    onSubmit,
    arrival,
    price,
    selectedCurrency,
    currencies,
    isButtonDisabled = false,
    noSeatsAvailable = false,
    buttonValue
}) => {
    const [teamDiscountSize] = useState(0);

    return (
        <div className="price-info d-block d-lg-none stickyBlock">
            <div className="price-left-col">
                <div className="price-label card-label">{t('common.price')}</div>
                <div className="price-row">
                    {!!teamDiscountSize && (
                        <div className="old-price">
                            {`${people * price || 0} ${normalizeCurrency(selectedCurrency, currencies) || ''}`}
                        </div>
                    )}
                    <div className="price">
                        {`${Math.round(people * price - teamDiscountSize) || 0} ${normalizeCurrency(selectedCurrency, currencies) || ''}`}
                    </div>
                </div>
                {!!teamDiscountSize && <div className="price-discount-label">
                    {t('discounts.peopleFull', {peopleFull: arrival.team_book_discount_condition})}
                </div>}
                <div className="price-right-col ">
                    {selectedCurrency === "EUR" && (
                        <div className="price-localized price-mobile">  
                            {convertPrice(price, currencies.find((currency) => currency.key === "EUR").conversion_rate) * people}{" "}
                            <span>P</span>
                        </div>
                    )}
                </div>
            </div>
            {!noSeatsAvailable &&
                <FormButton value={buttonValue} onClick={onSubmit} disabled={isButtonDisabled} className="mobile-width-100"/>}
            {noSeatsAvailable && <>
                <p className="mt-3">
                    {t('common.placesEnded')}
                </p>
            </>}
        </div>
    );
};

export default PriceBlock;
