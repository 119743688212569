import React from "react";
import EnterCodeForm from "./EnterCodeForm";
import EnterPhoneForm from "./EnterPhoneForm";
import i18next from "i18next";
import { useInjection } from 'brandi-react';
import { PhoneLoginModelStoreToken } from './model';
import { observer } from 'mobx-react-lite';

const PhoneLogin = ({onLoginSuccess}) => {
    const model = useInjection(PhoneLoginModelStoreToken);

    const { t } = i18next;

    return <>
        <div className="login-sub-logo login-text">
            {t('common.logIn.phone.title')}
        </div>
        { model.step === 1 && <EnterPhoneForm /> }
        { model.step === 2 && <EnterCodeForm onLoginSuccess={onLoginSuccess} /> }
    </>
}

export default observer(PhoneLogin);