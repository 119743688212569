import React, {createContext, useContext, useState} from "react";
import {ILoaderContext, ILoaderProps} from "../utils/types";

const LoaderContext = createContext<ILoaderContext>({} as ILoaderContext)

export const LoaderProvider = ({children}: ILoaderProps) => {
    const [isLoading, setIsLoading] = useState(false)

    return <LoaderContext.Provider value={{isLoading, setIsLoading}}>
        {children}
    </LoaderContext.Provider>
}

export const useLoaderState = () => useContext(LoaderContext)