// eslint-disable-next-line
import {useApi} from "./useApi";
import {TOrder} from "../utils/types";

export const useOrdersApi = () => {
    const { request } = useApi();

    /**
     * Method to get an order
     * @param {number} id
     * @return {Promise<*>}
     */
    const getOrder = async (id: number | string): Promise<TOrder> => request('get', `/public/order/${id}`);


    /**
     * Method to create an order
     * @param {object} data
     * @param {object} stateToUse - стейт после авторизации, если globalState не успел обновиться
     * @return {Promise<*>}
     */
    const createOrder = async (
        data: { [key: string]: any },
        stateToUse?: { [key: string]: any } | null
    ): Promise<{status: string, orderId: number}> => request('post', `/public/order`, data, stateToUse);


    return {getOrder, createOrder}
}