import React from "react";
import Popup from "../../generic/Popup";
import { useInjection } from 'brandi-react';
import { GlobalModelStoreToken } from '../../../lib/models/global';
import { observer } from 'mobx-react-lite';
import { useApi } from "lib/api/useApi";
import './style.scss';
import i18next from "i18next";
import { Button } from "reactstrap";
import { setCookie } from "lib/utils/cookies";

const AddVkEmailPopup = () => {
    const { t } = i18next;
    const globalM = useInjection(GlobalModelStoreToken);
    const globalState = globalM.get();
    const {updateGlobalState} = useApi();

    const closeAddVkEmailPopup = () => { 
        setCookie('sv_booking_vk_email_popup', false);
        updateGlobalState({isAddVkEmailPopupOpen: false});
    }

    return (
        <Popup popupTitle={t('addVkModal.title')} customClassName="add-vk" isOpen={globalState && globalState.isAddVkEmailPopupOpen === 'true'} withoutButtons toggle={closeAddVkEmailPopup}>
            <div>
                {t('addVkModal.inProfile')} <a href={t('addVkModal.link')}>{t('addVkModal.thisLink')}</a> {t('addVkModal.otherVariants')}
            </div>
            <Button onClick={closeAddVkEmailPopup} type="submit" className={'w-100 submit-button'}>
                {t('feedback.good')}
            </Button>
        </Popup>
    )
}

export default observer(AddVkEmailPopup);