import React from 'react';
import {Col, Container, Row} from "reactstrap";
import Header from "../../layout/Header";
import {BookingCardFactory} from "./BookingCardFactory";
import {TProductContainerProps} from "../../../lib/utils/types";

const ProductContainer = ({
        headerLabel = 'productContainer.headerLabel',
        children,
        productType,
        bookingCardProps,
    }: TProductContainerProps): JSX.Element => (
    <Container className={'pl-0 pr-0'}>
        <Row className={'min-vh-100 no-gutters'}>
            <Col xs={12} lg={6} className={'form-col'}>
                <Header label={headerLabel}/>
                {children}
            </Col>
            <Col xs={6} className={'offset-sm-6 card-col min-vh-100 fixed-top d-none d-lg-block'}>
                {bookingCardProps ? BookingCardFactory(productType, bookingCardProps) : <></>}
            </Col>
        </Row>
    </Container>
);

export default ProductContainer;