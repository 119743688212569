import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';
import { IApiModel } from '../../../lib/api/index.interface';
import { ICurrency } from '../../../lib/utils/types';

export interface ICurrenciesService {
  get: () => Promise<ICurrency[]>,
}

export default class CurrenciesService implements ICurrenciesService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  get(): Promise<ICurrency[]> {
    return this.api.rawRequest('get', '/public/currencies');
  }
}

export const CurrenciesServiceStoreToken = token<ICurrenciesService>('CurrenciesServiceStoreToken');

container.bind(CurrenciesServiceStoreToken).toInstance(CurrenciesService).inSingletonScope();

injected(CurrenciesService, ApiModelStoreToken);