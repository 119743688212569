// eslint-disable-next-line
import React from 'react';
import {useApi} from "./useApi";

export const useSportApi = () => {
    const {rawRequest} = useApi();
    const checkSlotAvailability = async (slotId) => rawRequest('get', '/public/slots/available/' + slotId);

    return {
        checkSlotAvailability
    }
}