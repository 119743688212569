import React from "react";
import { Col } from "reactstrap";
import DatePickerInput from "../../../../../generic/DatePickerInput";
import { localizeDate, regexes } from "../../../../../../lib/utils/helpers";
import PropTypes from "prop-types";
import ValidationIcon from "../../../../../generic/ValidationIcon";
import {currentDate, minBirthDate} from "../../../../../../lib/utils/calendar";

const BirthDate = ({error, value, name, id, label, handleInputChange, defaultValue, control}) => (
    <Col xs={12} md={6} className={"control-wrapper"}>
        <span className={"input-label"}>{label}</span>
        <ValidationIcon isDirty={!!(error || value)} isValid={!error} />
        <DatePickerInput
            defaultValue={defaultValue ? localizeDate(defaultValue) : ''}
            error={error}
            name={name}
            blurFunc={handleInputChange}
            id={id}
            placeholder="01.01.1999"
            control={control}
            rules={{
                required: true,
                pattern: regexes.birthdate
            }}
            minDate={minBirthDate}
            maxDate={currentDate}
        />
    </Col>
);

BirthDate.propTypes = {
    error: PropTypes.object,
    value: PropTypes.instanceOf(Date),
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    control: PropTypes.object.isRequired,
    handleInputChange: PropTypes.func.isRequired
};

export default BirthDate;
