import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IPaymentExtraBlockProps } from './index';
import PaymentCertComponent from '../components/certificate';
import PaymentPromoComponent from '../components/promocode';
import PaymentPayExtraComponent from '../components/payExtra';
import { t } from 'i18next';
import { useInjection } from 'brandi-react';
import { observer } from 'mobx-react-lite';
// @ts-ignore
import { CERTIFICATE_TYPES } from 'sv-common/constants/certificates';
import { PayBlockModelStoreToken } from '../models/PayBlockModel/index.model';
import { getPartPayPrice } from '../../../../lib/utils/helpers/priceHelpers';
import {Button} from "reactstrap";
import { normalizeCurrency } from 'lib/utils/helpers';
import { usePublicEndpoints } from 'lib/api/usePublicEndpoints';

const PaymentMethodCertComponentV2: FC<IPaymentExtraBlockProps> = observer((props) => {
  const {
    verifyPromocode,
    setPromocodeApplied,
    setCertificateApplied,
    promoAndCertDiscount,
    order,
    isShowPromocode,
    certRequired = true,
  } = props;

  const { getCurrencies } = usePublicEndpoints();
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    getCurrencies()
        .then(res => setCurrencies(res));
  }, [])

  const payBlockModel = useInjection(PayBlockModelStoreToken);

  const isCorpCert = payBlockModel.cert?.value?.certificateType === CERTIFICATE_TYPES.CORPORATE;

  const successApply = payBlockModel.isSuccessApply();
  const certSuccessApply = payBlockModel.isCertSuccessApply();
  const promoSuccessApply = payBlockModel.isPromoSuccessApply();


  const onCertApply = useCallback((captcha?: string) => {
    if (certSuccessApply) {
        setCertificateApplied(undefined);
        payBlockModel.resetAppliedDiscounts('certificateApplied');
    }
    if (certRequired && !payBlockModel.cert.query) {
      payBlockModel.cert.setError(t('inputs.required'));
    } else {
      payBlockModel.verifyCert(verifyPromocode, captcha);
    }
  }, [payBlockModel.cert, verifyPromocode, certSuccessApply]);

  const onPromoApply = useCallback((captcha?: string) => {
    if (promoSuccessApply) {
        setPromocodeApplied(undefined);
        payBlockModel.resetAppliedDiscounts('promocodeApplied');
    }
    if (certRequired && !payBlockModel.cert.query) {
      payBlockModel.cert.setError(t('inputs.required'));
    } else {
      payBlockModel.verifyPromo(verifyPromocode, captcha);
    }
  }, [payBlockModel.cert, verifyPromocode, promoSuccessApply]);

  const success = (payBlockModel.cert.value?.sizeLeft || 0) - order.totalValue >= 0;

  if (order.hasPaid) {
    return null;
  }

  const promo = payBlockModel.promo.value;
  const cert = payBlockModel.cert.value;

  return (
    <div className='payment-block-v2 d-flex flex-wrap'>
        <div className={`payment-input ${!payBlockModel.cert.query ? 'hide-clear-button' : ''} ${certSuccessApply ? 'cert-success-apply' : ''}`}>
            <PaymentCertComponent 
            order={order} 
            setCertificateApplied={setCertificateApplied}
            placeholder={certSuccessApply ? 'Отмена' : t('inputs.enterPromocode.patternCertPlaceholder') as string}
            labelText={t('subscriptions.payWithCert') as string}
            />
            {certSuccessApply && cert ? <>
              <div className='payment-input-value pb-2'>{payBlockModel.cert.value?.sizeLeft || 0} {normalizeCurrency(order.priceCurrency || payBlockModel.cert.value?.payment_currency, currencies)}</div>
              <div className='cert-remainder pb-2'>{t('paymentPay.remainder.subAfterPayment')}: {!success ? 0 : (payBlockModel.cert.value?.sizeLeft || 0) - order.totalValue}&nbsp;{normalizeCurrency(order.priceCurrency || payBlockModel.cert.value?.payment_currency, currencies)}</div>
            </> : ''}
            {payBlockModel.cert.query && !payBlockModel.cert.error && !order?.hasPaid && <Button
                    color={'primary'}
                    type={'button'}
                    className={`mt-2 mb-3 form-button apply-button ${certSuccessApply ? 'cancel-button' : ''}`}
                    onClick={() => onCertApply()}
            >
            {certSuccessApply ? t('inputs.enterPromocode.cancelButton') : t('inputs.enterPromocode.submitButton')}
          </Button>}
        </div>
        {!isCorpCert && isShowPromocode && <div className={`payment-input ${!payBlockModel.promo.query ? 'hide-clear-button' : ''} ${promoSuccessApply ? 'promo-success-apply' : ''}`}>
                <PaymentPromoComponent 
                order={order} 
                setPromocodeApplied={setPromocodeApplied} 
                hideLabel 
                verifyPromocode={verifyPromocode} 
                placeholder={t('inputs.enterPromocode.patternPromoPlaceholder') as string}
                labelText={t('subscriptions.usePromo') as string}
                />
                {promoSuccessApply && promo ? <>
                    <div className='payment-input-value pb-2'>{promo.size}{promo.type === 'percent' ? '%' : ` ${promo.currency}`}</div>
                    <div className='mini-promo pb-2 pr-5'> {payBlockModel.promo.query}</div>
                </> : ''}
                {payBlockModel.promo.query && !payBlockModel.promo.error && !order?.hasPaid && <Button
                    color={'primary'}
                    type={'button'}
                    className={`mt-2 mb-3 form-button apply-button ${promoSuccessApply ? 'cancel-button' : ''}`}
                    onClick={() => onPromoApply()}
            >
            {promoSuccessApply ? t('inputs.enterPromocode.cancelButton') : t('inputs.enterPromocode.submitButton')}
            </Button>}
        </div>}
    </div>
  )
});

export default PaymentMethodCertComponentV2;