import { IApiModel } from '../../../lib/api/index.interface';
import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';
import { ICheckAuthCodeRes } from '../types';

export interface IPhoneAuthService {
  sendCode: (phone: string, captcha: string) => Promise<{ token: string }>,
  checkCode: (code: string, token: string) => Promise<ICheckAuthCodeRes>,
}

export default class PhoneAuthService implements IPhoneAuthService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  sendCode(phone: string, captcha: string): Promise<{ token: string }> {
    return this.api.rawRequest('post', '/auth/sendCode', {phone, captcha});
  }

  checkCode(code: string, token: string): Promise<ICheckAuthCodeRes> {
    return this.api.rawRequest('post', '/auth/checkCode', { code, token });
  }
}

export const PhoneAuthServiceStoreToken = token<IPhoneAuthService>('PhoneAuthServiceStoreToken');

container.bind(PhoneAuthServiceStoreToken).toInstance(PhoneAuthService).inSingletonScope();

injected(PhoneAuthService, ApiModelStoreToken);