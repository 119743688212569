import "./style.scss";
import Consent from "./Consent";
import {Input} from "reactstrap";
import PropTypes from "prop-types";
import OrderInfo from "./OrderInfo";
import {useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import Divider from "../../../generic/Divider";
import React, {useEffect, useState} from "react";
import { isEmpty } from "lodash";
import MemberCheckoutCard from "./MemberCheckoutCard";
import FormButton from "../../../generic/buttons/main";
import EnterPromocode from "../../../generic/EnterPromocode"
import {handleInputChange} from "../../../../lib/utils/handleInputChange";
import {usePublicEndpoints} from "../../../../lib/api/usePublicEndpoints"
import {calculatePromoDiscount} from "../../../../lib/utils/helpers";
import {NumberParam, useQueryParams, BooleanParam} from "use-query-params";
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import HasPaidMessage from "../../../common/HasPaidMessage";
import {useRedirect} from "../../../../lib/utils/hooks/useRedirect";
import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {useLoaderState} from "../../../../lib/api/loaderState";
import {t} from "i18next";
import withOrderProductExistCheck from "../../../../lib/utils/hocs/withOrderProductExistCheck";
import {useOrder} from "../../../../lib/utils/hooks/useOrder";

const MembersCheckout = ({promocodeApplied, setPromocodeApplied, certificateApplied, promoAndCertDiscount,
                         setCertificateApplied, membersData, practice, order, checkOrderProduct,
                         resetPromoStates, setOrder}) => {

    const [step] = useQueryParamWithDefault('step', NumberParam, 1, 'pushIn')

    const [query] = useQueryParams({
        people: NumberParam,
        orderId: NumberParam,
        isPrivate: BooleanParam,
    });
    const [currentData, setCurrentData] = useState({});
    const [elapsedTime, setElapsedTime] = useState(null);
    const [promocodeInput, setPromocodeInput] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [invalidPromocodeReason, setInvalidPromocodeReason] = useState('');
    const isInstallment = order?.installment;

    const {pathname} = useLocation();
    const {errorPageRedirect} = useRedirect();

    const {updateOrder, createPayment, getOrderTimeout} = usePublicEndpoints();

    const {setIsLoading} = useLoaderState();

    const {setPromocodeOrCertificate} = useOrder();

    const promocodeChange = e => setPromocodeInput(e.target.value)

    const form = useForm({mode: "onSubmit", reValidateMode: "onChange"});
    const {register, errors, handleSubmit} = form

    const handleChange = (e) => {
        const {name, value} = handleInputChange(e);
        setCurrentData((currentData) => ({...currentData, [name]: value}));
    };

    const handleUpdate = () => {
        const dataToSend = {
            ...currentData,
            step,
        }
        setIsLoading(true);

        return updateOrder(query.orderId, dataToSend)
            .then((res) => errorPageRedirect(
                () => createPayment(res.orderId, pathname + `?orderId=${query.orderId}&step=5`)
            ))
            .then((res) => window.location.href = res.url)
            .catch((e) => {
                checkOrderProduct(e, '/school')
                console.log(e)
            })
            .finally(() => setIsLoading(false));
    };

    const formButtonNonInstallmentValue = promoAndCertDiscount?.price ? t('common.pay') : t('common.schedule');
    const formButtonValue = isInstallment ? t('common.in_installments') : formButtonNonInstallmentValue;

    const verifyPromocode = (value, type) =>
        setPromocodeOrCertificate(PRODUCT_TYPES.PRACTICES, value, order.practice.id, order.id, setCurrentData,
            setPromocodeApplied, setCertificateApplied, setOrder, undefined, setInvalidPromocodeReason,
            setPromocodeInput, resetPromoStates, type)

    useEffect(() => {
        if (query.orderId)
            getOrderTimeout(query.orderId)
                .then(res => setElapsedTime(() => res))
                .catch(e => console.log(e))
    }, [query.orderId])

    useEffect(() => {
        setCurrentData(order)

        order?.promocodeApplied && verifyPromocode(order.promocodeApplied);
        order?.certificateApplied && verifyPromocode(order.certificateApplied);
    }, [order?.id])

    return (
        <>
            <div className={"checkout"}>
                <OrderInfo order={order} practice={practice} elapsedTime={elapsedTime} setIsSubmitDisabled={setIsSubmitDisabled} />
                <Divider/>
                {membersData.map((item, index) =>
                    <MemberCheckoutCard practice={practice} index={index} member={item} key={index}/>)}
                {!isEmpty(order) &&
                    <>
                        <EnterPromocode promocodeInput={promocodeInput} promocodeApplied={promocodeApplied}
                                        verifyPromocode={verifyPromocode} promocodeChange={promocodeChange}
                                        invalidPromocodeReason={invalidPromocodeReason} productId={practice?.id}
                                        certificateApplied={certificateApplied} setPromocodeApplied={setPromocodeApplied}
                                        setCertificateApplied={setCertificateApplied} promoAndCertDiscount={promoAndCertDiscount}
                                        promocodeDiscount={calculatePromoDiscount(
                                            promocodeApplied, practice?.price_value, practice?.adventure_id?.price_value,
                                            promocodeApplied?.discountValue
                                        )}
                        />
                        <Divider/>
                    </>
                }
                <form onSubmit={handleSubmit(handleUpdate)}>
                    <div className="checkout__input-wrapper">
                        <div className="title-label">{t('inputs.comment.label')}</div>
                        <Input type="textarea" id="comment" name="comment" onChange={handleChange}
                               innerRef={register({required: false})} defaultValue={currentData?.comment}
                               placeholder={t('inputs.comment.placeholder')}
                        />
                        <span className={errors.comment ? "error-label" : "error-label d-none"}>
                            {errors.comment?.type === "required" ? t('inputs.required') : ""}
                        </span>
                    </div>
                    <Divider />
                    <Consent error={errors.validDataConsent} handleChange={handleChange}
                        register={register} text={t('inputs.consents.validData')}
                        errorMessage={t('inputs.consents.validDataError')}
                        consentURL={t('agreements.personalData')}
                        defaultChecked={false}
                        name={'validDataConsent'}
                    />

                    <Consent error={errors.publicOfferConsent} handleChange={handleChange} 
                        register={register} text={t('inputs.consents.publicOffer')}
                        errorMessage={t('inputs.consents.publicOfferError')}
                        consentURL={practice?.publicContractUrl} defaultChecked={false}
                        name={'publicOfferConsent'}
                    />
                    {t("inputs.consents.publicOfferNote") && <div className={"note-label checkout__input-wrapper"}> {t("inputs.consents.publicOfferNote")}</div>}


                    <FormButton value={formButtonValue}
                                type="submit" disabled={isSubmitDisabled || order?.hasPaid}/>
                    {order?.hasPaid && <HasPaidMessage />}
                </form>
            </div>
        </>
    );

};
MembersCheckout.propTypes = {
    practice: PropTypes.object,
    inputMembersData: PropTypes.array,
    promocodeApplied: PropTypes.object,
    setPromocodeApplied: PropTypes.func,
    certificateApplied: PropTypes.object,
    setCertificateApplied: PropTypes.func,
};

export default withOrderProductExistCheck(MembersCheckout);
