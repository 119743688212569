import FormButton from "../../generic/buttons/main";
import CopyLink from "../../generic/buttons/CopyLink/index";
import PropTypes from "prop-types";

import './style.scss'
import {downloadFileFromBuffer} from 'sv-common/utils/file';
import {usePublicEndpoints} from "../../../lib/api/usePublicEndpoints";
import {BASE_SILAVETRA_URL} from "../../../lib/api/globalState";
import useQueryParamWithDefault from "../../../lib/utils/hooks/useQueryParamWithDefault";
import {NumberParam} from "use-query-params";
import { t } from 'i18next';

const CertificateSuccessPage = ({ certificateNum }) => {
    const [,setStep] = useQueryParamWithDefault('step', NumberParam, 1);

    const {downloadCertificate} = usePublicEndpoints();

    const download = async () => {
        const response = await downloadCertificate(certificateNum);
        downloadFileFromBuffer(response.file.name, response.file.content.data);
    }

    return (
        <div className='certificate-success-page-container'>
            <div className="certificate-desctiption p-3">
                {t('certificate.form.success.title')}
            </div>
            <div className="certificate-number p-3">
                <span className="certificate-number-title">{t('certificate.form.success.certNumber')}:</span>
                <div className="certificate-number-value">
                    <div className="d-flex align-items-center">
                        {certificateNum}
                        <CopyLink showLink={false} href={certificateNum} />
                    </div>
                </div>
            </div>
            <FormButton className="success-page-button-link" value={t('certificate.form.success.downloadButton')} type={"button"} onClick={download} />
            <div className="success-page-button-container">
                <a href={BASE_SILAVETRA_URL} className="success-page-button-link">
                    <FormButton value={t('certificate.form.success.onTheMain')} />
                </a>
            </div>
            <div className="success-page-button-container">
                <FormButton value={t('certificate.form.success.orderOneMore')} onClick={() => setStep(1)}/>
            </div>
        </div>
    )
}

CertificateSuccessPage.propTypes = {
    certificateNum: PropTypes.string,
    setStep: PropTypes.func,
};

export default CertificateSuccessPage