import React from 'react';
import { Col, Input } from 'reactstrap';
import ValidationIcon from '../../../../../generic/ValidationIcon';
import PropTypes from 'prop-types';
import {LANGUAGE_VALIDATE_FIELDS} from "../../../../../../lib/utils/constants";
import {t} from "i18next";

const AdditionalField = ({id, name, fieldKey, label, placeholder, error, value, required, defaultValue, register, handleInputChange}) => {
    const patternErrorText = fieldKey.endsWith('_en') ? t('inputs.onlyLatinError') : t('inputs.onlyCyrillicError');
    return <Col xs={12} md={6} className={"control-wrapper"}>
        <span className={"input-label"}>{label}</span>
        <ValidationIcon isDirty={!!(error || value)} isValid={!error}/>
        <Input
            type="text"
            id={id}
            name={name}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={handleInputChange}
            innerRef={register({
                required,
                maxLength: fieldKey === "passport_num_ru" ? 10 : false,
                pattern: LANGUAGE_VALIDATE_FIELDS[fieldKey] || false,
            })}
        />
        <span className={error ? "error-label" : "error-label d-none"}>
            {error?.type === "required" ? t('inputs.required') : ""}
            {error?.type === "maxLength" ? t('inputs.maxLengthError') : ""}
            {error?.type === "pattern" && patternErrorText}
        </span>
    </Col>
};

AdditionalField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fieldKey: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    error: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    defaultValue: PropTypes.string.isRequired,
    register: PropTypes.func.isRequired,
    handleInputChange: PropTypes.func.isRequired
};

export default AdditionalField;
