import React from 'react';
import { Input, Label } from 'reactstrap';
import Divider from '../../../../generic/Divider';
import { useQueryParam, withDefault, NumberParam } from 'use-query-params';
import PropTypes from "prop-types";
import {t} from "i18next";

const PartialPayment = ({option20, option50, handlePaymentClick}) => {
    const [partPay] = useQueryParam("partPay", withDefault(NumberParam, 100));
    return (
        <div className="radio-group">
            {option20 && (
                <div className="radio-item">
                    <Input type="radio" defaultChecked={partPay === 20}
                        name="payment_type" id="payment_20"
                        className="radio-payment" value={20}
                        onClick={handlePaymentClick}
                    />
                    <Label htmlFor="payment_20">{t('travel.20%')}</Label>
                </div>
            )}
            {option50 && (
                <div className="radio-item">
                    <Input type="radio" defaultChecked={partPay === 50}
                        name="payment_type" id="payment_50"
                        className="radio-payment" value={50}
                        onClick={handlePaymentClick}
                    />
                    <Label htmlFor="payment_50">{t('travel.50%')}<span className="h6">{t('practice.50%_extra')}</span></Label>
                </div>
            )}
            {(option20 || option50) && (
                <>
                    <div className="radio-item">
                        <Input type="radio" defaultChecked={partPay === 100}
                            name="payment_type" id="payment_100"
                            className="radio-payment" value={100}
                            onClick={handlePaymentClick}
                        />
                        <Label htmlFor="payment_100">{t('travel.100%')}</Label>
                    </div>
                    <Divider />
                </>
            )}
        </div>
    );
};

PartialPayment.propTypes = {
    option20: PropTypes.bool.isRequired,
    option50: PropTypes.bool.isRequired,
    handlePaymentClick: PropTypes.func.isRequired
}

export default PartialPayment;
