export const customStyles = {
    menu: provided => ({
        ...provided,
        color: '#495057',
        padding: 0,
        fontFamily: 'Basis Grotesque Pro, sans-serif',
        fontSize: '20px',
        width: '100%'
    }),
    option: (provided, { isFocused }) => ({
        ...provided,
        padding: 5,
        backgroundColor: isFocused ? '#ccc' : '#fff',
        color: 'rgba(68, 68, 68, 1)',
        cursor: 'pointer',
        ':active': {
          backgroundColor: '#ccc'
        },
    }),
    input: provided => ({
        ...provided,
        width: 0,
        margin: 0,
    }),
    control: provided => ({
        ...provided,
        border: 'none',
        boxShadow: 'none',
        backgroundColor: '#F9F9F9'
    }),
    valueContainer: provided => ({
        ...provided,
        padding: 0,
        fontSize: 20,
        
    }),
    singleValue: provided => ({
        ...provided,
        margin: 0,
        color: '#495057'
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: 5,
        color: '#4a5158',
        
    }),
    indicatorSeparator: provided => ({
        ...provided,
        display: 'none'
    }),
}