import React from 'react';
import FormsRouter from "../common/FormsRouter";
import './style.scss';

const Login = () => {
  return(
    <div className={'overlay w-100 h-100 d-flex justify-content-center flex-column'}>
        <FormsRouter isRedirectAfterLogin />
    </div>
  )
}

export default Login;
