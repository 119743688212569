import Divider from '../../../../generic/Divider';
import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import Feedback from "../../../../common/Feedback";
import {t} from "i18next";
import CalendarButton from '../../../../common/CalendarEvents/Button';
import useGroup from './hooks/useGroup';

const Final = ({order, productType}) => {
  const [title, setTitle] = useState('');
  const [unpaidTitle, setUnpaidTitle] = useState('');

  const { handle } = useGroup(productType, setTitle, setUnpaidTitle, order);

  useEffect(() => {
    handle();
  }, [productType, order.id]);

  return (
      !_.isEmpty(order) &&
      <div className="final">
          {!order.hasPaid
              ? <div className="final__title">{unpaidTitle}</div>
              : <div className="final__title">{title}</div>
          }
          {(order.groupId.tgLink || order.groupId.platformUrl || order.groupId.platformAuthCode)
          && <>
              <div className="final__title">
                  {t('final.joinTelegram')}
              </div>
              <div className="final__text">
                  {order.groupId.tgLink && <>
                  {t('final.yourTG')} <a href={order.groupId.tgLink} target="_blank"
                                               rel="noopener noreferrer">{order.groupId.tgLink}</a><br/>
                                               </>
                  }
                  {order.groupId.platformUrl && <>
                  {t('final.learningPlatform')} <a href={order.groupId.platformUrl} target="_blank"
                                             rel="noopener noreferrer">{order.groupId.platformUrl}</a><br/>
                  </>
                  }
                  {order.groupId.platformAuthCode && <>
                      {t('final.code')} <span className="font-weight-bold">{order.groupId.platformAuthCode}</span><br/>
                  </>
                  }
              </div>
            </>
          }
          {order && order.groupId && <CalendarButton
            name={order.groupId.name}
            startDate={order.groupId.startDate}
            endDate={order.groupId.endDate}
            startTime={order.groupId.startTime}
            endTime={order.groupId.endTime}
          />}
          {!!order.groupId.extraMaterials?.length && <>
              <div className="final__title">
                  {t('final.materials')}
              </div>
              <div className="final__docs-list">
                  {order.groupId.extraMaterials.map(material => (
                      <a href={material.link} target="_blank" rel="noopener noreferrer" className="final__docs-item">
                          {material.name}
                      </a>
                  ))}
              </div>
          </>}
        <Divider/>
        <Feedback />
      </div>
  )
}

export default Final;

