import React from 'react';
import {t} from "i18next";

const HasPaidMessage = () => {
    return (
        <div className={"d-flex justify-content-center"}>
            <small>{t('common.orderPaid')}</small>
        </div>
    )
}


export default HasPaidMessage;

HasPaidMessage.propTypes = {}