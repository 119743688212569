import { IApiModel } from '../../../lib/api/index.interface';
import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';
import { TOrder } from '../../../lib/utils/types';

export interface IOrdersService {
  get: (id: number) => Promise<TOrder>,
  update: (id: number, data: TOrder) => Promise<TOrder>,
  getOrderTimeout: (id: number) => Promise<number>,
}

export default class OrdersService implements IOrdersService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  get(id: number): Promise<TOrder> {
    return this.api.request<TOrder>('get', `/public/order/${id}`);
  }

  update(id: number, data: TOrder): Promise<TOrder> {
    return this.api.request('put', `/public/order/${id}`, data);
  }

  getOrderTimeout(id: number): Promise<number> {
    return this.api.request('get', `/public/order/${id}/time/?timestamp=${Date.now()}`);
  }
}

export const OrdersServiceStoreToken = token<IOrdersService>('OrdersServiceStoreToken');

container.bind(OrdersServiceStoreToken).toInstance(OrdersService).inSingletonScope();

injected(OrdersService, ApiModelStoreToken);