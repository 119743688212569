import React, { FC, useState } from 'react';
import { ICalendarEventsProps } from './types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CalendarEvents from './index';
import FormButton from '../../generic/buttons/main';
import { t } from 'i18next';

const CalendarButton: FC<ICalendarEventsProps> = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <FormButton value={t('final.inCalendar')} type={'button'} disabled={false} className={undefined} onClick={() => setVisible(!visible)} />
      <Modal
        isOpen={visible}
        className={'edit-modal'}
        toggle={() => setVisible(!visible)}
        centered
      >
        <ModalHeader>
          {t('final.inWhatCalendar')}
        </ModalHeader>
        <ModalBody>
          <CalendarEvents {...props} />
        </ModalBody>
      </Modal>
    </>
  )
}

export default CalendarButton;