import {PropTypes} from 'prop-types'
import {ImCross} from 'react-icons/im'
import styles from './style.module.scss'
import {t} from "i18next";

const PromocodeInfo = ({certificate, promocode, setPromocodeApplied, setCertificateApplied, verifyPromocode, disableRemoveButton}) => (
        <div className={styles.entityInfo}>
            {certificate?.status === 'valid' &&
                <div className={`d-flex align-items-center ${styles.entityInfo_item}`}>
                    {t('common.certificate')} -
                    <b className={styles.entityInfo_name}>{certificate?.name}</b>
                    {!disableRemoveButton && <button data-testid="cert-cross-button" className={styles.deleteEntity} onClick={() => {
                        setCertificateApplied({})
                        verifyPromocode(null, 'certificate')
                    }}>
                        <ImCross className={styles.deleteEntity_svg} />
                    </button>}
                </div>
            }
            {promocode?.status === 'valid' &&
                <div className={`d-flex align-items-center ${styles.entityInfo_item}`}>
                    {t('common.promocode')} -
                    <b className={styles.entityInfo_name}>{promocode?.name}</b>
                        <button data-testid="promo-cross-button" className={styles.deleteEntity} onClick={() => {
                            setPromocodeApplied({})
                            verifyPromocode(null, 'promocode')
                        }}>
                            <ImCross className={styles.deleteEntity_svg} />
                        </button>
                </div>
            }
        </div>
)

PromocodeInfo.propTypes = {
    promocode: PropTypes.object,
    certificate: PropTypes.object,
    setPromocodeApplied: PropTypes.func,
    setCertificateApplied: PropTypes.func,
}

export default PromocodeInfo