import { usePublicEndpoints } from '../../../../lib/api/usePublicEndpoints';

const useSlot = (
                  slotURL,
                  setBases,
                  setFetchedBases,
                  setBoats,
                  setDates,
                  sendData,
                  setGlobalEvent,
                  setSlot,
                  setSlots,
                  setIsLoading,
                  setIsLoadingLocal,
                  getSlotCallback,
                ) => {
  const { getSlotByUrl } = usePublicEndpoints();

  const getSlot = () => {
    getSlotByUrl(slotURL)
      .then(getSlotCallback).catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
        setIsLoadingLocal(false)
      })
  }

  return { getSlot };
};

export default useSlot;