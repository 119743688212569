import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';
import { IApiModel } from '../../../lib/api/index.interface';
import { TUserFields } from '../../../lib/api/useTypedPublicEndpoint';

export interface IUsersService {
  getCleanFields: () => Promise<TUserFields>,
}

export default class UsersService implements IUsersService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }
  
  getCleanFields(): Promise<TUserFields> {
    return this.api.request('get', `/public/user/`);
  }
}

export const UsersServiceStoreToken = token<IUsersService>('UsersServiceStoreToken');

container.bind(UsersServiceStoreToken).toInstance(UsersService).inSingletonScope();

injected(UsersService, ApiModelStoreToken);