import React, {MouseEventHandler} from 'react';
import {Col} from 'reactstrap';
import './style.scss';
import tick from '../../../styles/assets/images/sv/tick.svg';

type TStepProps = {
    step: number,
    isActive: boolean,
    handleStepClick: MouseEventHandler<HTMLDivElement>,
    isPassed: boolean,
    label: string
}

const Step = ({step, isActive, handleStepClick, isPassed, label}: TStepProps): JSX.Element =>
        <Col xs={'auto'} className={`step__item pl-2 pr-2 ${isActive ? 'active' : ''}`}
             onClick={handleStepClick}>
            <span className={`step__count`}>
                {isPassed ? <img className="step-done-img" src={tick} alt="done"/> : step}
            </span>
            <span className={'step__label'}>
                {label}
            </span>
        </Col>


export default Step;

