import React from "react";
import {Input, Label} from "reactstrap";
import {UseFormMethods, ValidationRule} from "react-hook-form";
import styles from "./style.module.scss";
import { t } from 'i18next';

const RangeInput = ({name, defaultValue, form, minRange, maxRange, stepSize, onChange, rules} :
     {name: string,
     defaultValue?: number,
     form: UseFormMethods,
     minRange: number,
     maxRange: number,
     stepSize?: number,
     onChange?: (value:string) => void,
     secondLabel?: string,
     rules?: {
         required: ValidationRule<boolean>, min: ValidationRule<number>, max: ValidationRule<number>
     }}) => {

    const {register} = form;

    return <>
        <Label className="mt-2 input-label">{t('certificate.form.inputNominal')}:</Label>
        <Input
            type="range"
            defaultValue={defaultValue && defaultValue}
            name={name}
            min={minRange}
            max={maxRange}
            step={stepSize}
            className={styles.range_input}
            onChange={e => {
                onChange && onChange(e.target.value)
            }}
            innerRef={register(rules && rules)}
        />
        <div className="d-flex justify-content-between mt-1">
            <span>{minRange}</span>
            <span>{maxRange}</span>
        </div>
    </>
}

export default RangeInput