import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {StringParam} from "use-query-params";
import {useState} from "react";
import {IBase, ISlot, TEventData, TOrder, TSportParams} from "../../../../lib/utils/types";
import {useParams} from "react-router-dom";
import {TCertificateOrPromocode} from "../../../../lib/api/useCertificatesApi";
import {TUserFields} from "../../../../lib/api/useTypedPublicEndpoint";
import useSport from "./useSport";
import useCurrencies from "./useCurrencies";
import {useCarrotAuth} from "../../../../lib/utils/hooks/useCarrotAuth";
import useOrder from "./useOrder";
import useStep from "./useStep";
import useUser from "./useUser";
import useCalculation from "./useCalculation";

type TProps = {
    page?: string
} | undefined

const useSportContainer = (props: TProps) => {
    const [orderId, setOrderId] = useQueryParamWithDefault<string | number>('orderId', StringParam);
    const [order, setOrder] = useState<TOrder>();
    const [basesList] = useQueryParamWithDefault<string>("bases", StringParam);
    const [selectedSlot, setSelectedSlot] = useState<ISlot>();
    const [selectedEvent, setSelectedEvent] = useState<TEventData>();
    const [bases, setBases] = useState<[IBase]>();
    const { url, event } = useParams<TSportParams>();
    const [promocodeApplied, setPromocodeApplied] = useState<TCertificateOrPromocode | null>();
    const [certificateApplied, setCertificateApplied] = useState<TCertificateOrPromocode | null>();
    const [payingUser, setPayingUser] = useState<TUserFields>();
    const [isSubscriptionPay, setIsSubscriptionPay] = useState<boolean>(false)


    const resetData = () => {
        setOrderId(undefined)
        setPromocodeApplied(null)
        setCertificateApplied(null)
        setIsSubscriptionPay(false)
        // Нужен тс игнор тк по-хорошему объект с данными должен быть либо полным, либо никаким (частично заполнять нехорошо)
        // @ts-ignore
        setOrder({slot: order?.slot, base: order?.base, boat: order?.boat, duration: order?.duration, totalValue: order?.priceValue, participants: order?.participants, priceCurrency: order?.priceCurrency, discountedValue: order?.priceValue,})
    }

    const {
        handleTrainingData,
        eventData,
        setEventData,
        peopleAmount,
        setPeopleAmount
    } = useSport(props)

    const currencies = useCurrencies()

    useCarrotAuth()

    const {subscriptionData} = useOrder({
        orderId,
        setOrder,
        setSelectedSlot,
        setPeopleAmount,
        setIsSubscriptionPay,
    });

    const {step, isDataEntered, setStep, toStepThree, toStepTwo, peopleData} = useStep({
        orderId,
        setPayingUser,
        setOrderId
    })

    useUser({setPayingUser, setStep, resetData})

    const {
        bookingCardProps,
        promoAndCertDiscount,
        coachlessDiscount,
        fullBookDiscount,
    } = useCalculation({
        order,
        promocodeApplied,
        setPromocodeApplied,
        certificateApplied,
        setCertificateApplied,
        isSubscriptionPay,
        subscriptionData,
        selectedSlot,
        peopleAmount,
        step,
        eventData,
        currencies
    })

    return {bookingCardProps, step, setStep, resetData, selectedSlot, currencies, eventData,
        selectedEvent, toStepTwo, peopleAmount, setPeopleAmount, basesList, handleTrainingData, setSelectedEvent,
        setSelectedSlot, event, url, orderId, order, isDataEntered, promocodeApplied, setBases, bases, setEventData,
        toStepThree, payingUser, setPayingUser, peopleData, isSubscriptionPay, subscriptionData, setIsSubscriptionPay,
        setPromocodeApplied, setCertificateApplied, promoAndCertDiscount, coachlessDiscount,
        fullBookDiscount, setOrder}
}

export default useSportContainer