import { usePublicEndpoints } from '../../../../lib/api/usePublicEndpoints';

const useEvent = (eventUrl, setIsLoading, setIsLoadingLocal, setEventRelatedEntities) => {
  const { getEventByUrl } = usePublicEndpoints();
  const getEvent = (callback) => {
    getEventByUrl(eventUrl)
      .then((res) => {
        setEventRelatedEntities(res);
        callback && callback(res);
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsLoading(false);
        setIsLoadingLocal(false);
      })
  }

  return { getEvent };
}

export default useEvent;