import {CopyToClipboard} from "react-copy-to-clipboard";
import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import {FaCopy} from "react-icons/fa";

const showSuccessToast = e => toast(e, {type: 'success'});

function CopyLink(props) {
    return <Fragment>
        {props.showLink !== false &&
        <a className={props.className} rel="noopener noreferrer" target="_blank" href={props.href}>
            {props.text || props.href}
        </a>
        }
        &nbsp;
        <CopyToClipboard text={props.href} onCopy={() => showSuccessToast("URL скопирован")}>
            <button className="btn btn-link p-0 mb-1"><FaCopy/></button>
        </CopyToClipboard>
    </Fragment>
}

CopyLink.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    text: PropTypes.node,
    showLink: PropTypes.bool
};

export default CopyLink;