import {TEventData} from "../../../../lib/utils/types";
import {useState} from "react";
import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {NumberParam} from "use-query-params";
import {schedulePages} from "../../Schedule/pages";

type TProps = {
    page?: string
} | undefined

const useSport = (props: TProps) => {
    const [peopleAmount, setPeopleAmount] = useQueryParamWithDefault<number>('people', NumberParam, 1);
    const [eventData, setEventData] = useState<TEventData>();
    const handleTrainingData = (newData: TEventData) => {
        if (eventData?.slot?.team_only && newData.shouldResetPeople && props?.page !== schedulePages.slot) {
            setPeopleAmount( 1)
        }
        setEventData((data) => ({ ...data, ...newData }));
        if  (newData.slot && newData.slot.available_sits < peopleAmount)
            setPeopleAmount(newData?.slot?.available_sits);
    };

    return {handleTrainingData, eventData, setEventData, peopleAmount, setPeopleAmount}
}

export default useSport;