import { token } from 'brandi';
import { makeAutoObservable } from 'mobx';
import { FORM_STEPS } from '../../../lib/utils/constants';
import container from '../../../lib/ioc';

export interface IFormRouterModel {
  step: string,
  setStep: (v: string) => void,
  completeUserJwt?: string,
  setCompleteUserJwt: (v: string) => void,
}

export default class FormRouterModel implements IFormRouterModel {
  step: string = FORM_STEPS.PHONE_LOGIN;
  completeUserJwt?: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setStep(v: string) {
    this.step = v;
  }

  setCompleteUserJwt(v: string): void {
    this.completeUserJwt = v;
  }
}

export const FormRouterModelStoreToken = token<IFormRouterModel>('FormRouterModelStoreToken');

container.bind(FormRouterModelStoreToken).toInstance(FormRouterModel).inSingletonScope();