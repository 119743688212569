import {useApi} from "./useApi";

export const GroupsApi = () => {
    const { rawRequest } = useApi();

    const getGroups = async (params) =>
        rawRequest('get', '/public/groups', {type: 'theory', ...params});

    const getGroupByUrl = async (url) =>
        rawRequest('get', `/public/groups/${url}`);

    const checkGroupAvailability = async (groupId) => rawRequest('get', '/public/groups/available/' + groupId);

    return {
        getGroups,
        getGroupByUrl,
        checkGroupAvailability
    }
}
