import {Input} from "reactstrap";
import i18n from 'i18next';
import {useApi} from "../../../../lib/api/useApi";
import React from "react";
import {LANGUAGE, OTHER_LANGUAGES} from "../../../../lib/utils/constants";
import "./style.css";

const LanguageSwitch = () => {
    const {updateGlobalState} = useApi();
    const { language } = i18n;

    const appLanguage = LANGUAGE.toLocaleLowerCase();
    const appOtherLanguages = OTHER_LANGUAGES.toLocaleLowerCase();

    const switchLanguage = (language:React.ChangeEvent<HTMLInputElement>) => {
        const {value} = language.target;

        i18n.changeLanguage(value).then(updateGlobalState);
    }

    return <Input type="select" name="languageSwitch" className="languageSwitch" defaultValue={language}
                  onChange={switchLanguage}>
        <option value={appLanguage}>{appLanguage}</option>
        <option value={appOtherLanguages}>{appOtherLanguages}</option>
    </Input>
}

export default LanguageSwitch