import {GlobalSettingsServiceStoreToken, IGlobalConfigService} from "../api/service";
import {makeAutoObservable} from "mobx";
import {injected, token} from "brandi";
import container from "../../../lib/ioc";

export interface IGlobalSettingsModel {
    config: Record<string, boolean>,
    init: () => void,
}

export default class GlobalSettingsModel implements IGlobalSettingsModel {
    config = {};

    constructor(
        private service: IGlobalConfigService,
    ) {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    init() {
        this.service.getConfig().then(v => {
            this.config = v;
        });
    }
}

export const GlobalSettingsModelStoreToken = token<IGlobalSettingsModel>('GlobalSettingsModelStoreToken');

container.bind(GlobalSettingsModelStoreToken).toInstance(GlobalSettingsModel).inSingletonScope();

injected(GlobalSettingsModel, GlobalSettingsServiceStoreToken);