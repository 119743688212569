import {ICertificate, IPromocode, TDiscount, TOrder} from "../types";
import React from "react";
import {verifyPromocodeOrCertificate} from "../helpers";
import {usePublicEndpoints} from "../../api/usePublicEndpoints";

export const useOrder = () => {
    const {checkPromocode} = usePublicEndpoints();

    const setPromocodeOrCertificate = async (
        productType: string,
        value: string,
        productId: number,
        orderId: number,
        setCurrentData: any,
        setPromocodeApplied: any,
        setCertificateApplied: any,
        setOrder: any,
        additionalDiscount: TDiscount | undefined,
        setInvalidPromocodeReason: any,
        setPromocodeInput: any,
        resetStates: (type: string) => void,
        type?: string,
        captcha?: string,
) => {
        const res = await verifyPromocodeOrCertificate(productType, value, productId, checkPromocode, orderId, type, captcha)

        if (res) {
            if (res.deletePromocode) {
                setCurrentData && setCurrentData((currentData: TOrder) => ({...currentData, [res.type]: null}))
                resetStates(res.type)
                setOrder && setOrder((currentOrder: any) => {
                    if (currentOrder?.promocodeAppliedDiscount || currentOrder?.certificateApplied) {
                        const {promocodeAppliedDiscount, certificateApplied, ...order} = currentOrder

                        if (additionalDiscount?.value) {
                            order.discountedValue = order.totalValue - additionalDiscount.value
                        } else {
                            order.discountedValue = order.totalValue
                        }

                        return order
                    }
                    return currentOrder
                })
                return false
            }

            setInvalidPromocodeReason(res.invalidPromocodeReason, type);

            if (res.entity === 'promocode') {
                setPromocodeApplied(res);
                setCurrentData && setCurrentData((currentData: any) => ({...currentData, promocodeApplied: res.id}));
            } else if (res.entity === 'certificate') {
                setCertificateApplied(res);
                setCurrentData && setCurrentData((currentData: any) => ({...currentData, certificateApplied: res.id}));
            }

            setPromocodeInput && setPromocodeInput('')
        }
    }

    return {setPromocodeOrCertificate}
}
