import React from "react";
import {Col, Row} from "reactstrap";
import "./style.scss";
import {localizeFullDate} from "../../../../lib/utils/format";
import {helpers} from "../../../../lib/utils/helpers"
import {StringParam, useQueryParam} from "use-query-params";
import i18next from "i18next";
import { observer } from 'mobx-react-lite';

const { pluralizeWord } = helpers();

const ArrivalSelector = ({ updateData, arrivals }) => {
    const onChange = (e) => {
        const { id } = e.target;
        updateData(arrivals.find((item) => item.id === +id));
    };
    const [arrivalUrlParam] = useQueryParam("arrival", StringParam);


    const {t, language} = i18next;
    return arrivals.map(
            (arrival, index) =>
                arrival.is_open && (
                    <div className="schedule__item" key={index}>
                        <input
                            data-testid={`arrival-selector-item-${index}`}
                            type="radio"
                            name="sched-item"
                            onChange={onChange}
                            id={arrival.id}
                            defaultChecked={arrivalUrlParam === arrival.url}
                            value={arrival.url}
                        />
                        <label htmlFor={arrival.id} className={"container"}>
                            <Row className={"schedule-item"}>
                                <Col xs={12} sm={9} className="schedule-item__main-info">
                                    <div className="schedule-item__title">
                                        {`${localizeFullDate(arrival.start_date, language)}\u00A0- ${localizeFullDate(arrival.end_date, language)}`}
                                    </div>
                                    <div className="schedule-item__description">
                                                <span
                                                    className={"schedule-item__places-info--mobile d-inline d-sm-none"}
                                                >
                                                    {arrival.available_reserved_sits <= 0
                                                        ? t('travel.noPlaces')
                                                        : `${arrival.available_reserved_sits}
                                                           ${pluralizeWord(t('common.pluralizePlaces'), arrival.available_reserved_sits, language)}`}
                                                    {" "}
                                                    -{" "}
                                                </span>{" "}
                                        {arrival.name}
                                        <div className="schedule-item-desc">
                                            {
                                                arrival.client_comment
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={3}
                                    sm={3}
                                    className="schedule-item__places-info text-center d-none d-sm-block"
                                >
                                    {arrival.available_reserved_sits <= 0 ?
                                        t('travel.noPlaces')
                                    :
                                        `${arrival.available_reserved_sits} ${pluralizeWord(t('common.pluralizePlaces'),
                                                arrival.available_reserved_sits, language)}`}
                                </Col>
                            </Row>
                        </label>
                    </div>
                )
    );
};

export default observer(ArrivalSelector);


