const usePickSlot = (setGlobalEvent, setSlot) => {
  const pick = (dataType, pickedData) => {
    const preparedData = {
      [dataType]: pickedData,
      eventId: pickedData.event.id,
      priceValue: pickedData.event.price_value,
      priceCurrency: pickedData.event.price_currency,
      shouldResetPeople: !pickedData.team_only,
      teamOnly: pickedData.team_only,
      coachless_discount: {active: false},
      full_book_discount: {active: false}
    };

    setGlobalEvent(pickedData.event);
    setSlot(pickedData);

    return preparedData;
  }

  return { pick };
}

export default usePickSlot;