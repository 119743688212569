import container from '../../../lib/ioc';
import { injected, token } from 'brandi';
import { IArrival } from '../../../lib/utils/types';
import { makeAutoObservable } from 'mobx';
import { ArrivalsServiceStoreToken, IArrivalsService } from '../api/service';

export interface IArrivalModel extends IArrivalsService {
  list: IArrival[],
  item?: IArrival,

  setItem: (v: IArrival) => void,
  setList: (v: IArrival[]) => void,

  getByUrl(url: string): Promise<IArrival>,
  listByAdventureUrl(url: string): Promise<{arrivals: IArrival[], status: string}>,
}

export default class ArrivalModel implements IArrivalModel {
  list: IArrival[] = [];
  item?: IArrival;

  constructor(private service: IArrivalsService) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setItem(v: IArrival) {
    this.item = v;
  }

  setList(v: IArrival[]) {
    this.list = v;
  }

  getByUrl(url: string): Promise<IArrival> {
    return this.service.getByUrl(url).then(res => {
      this.item = res;
      return res;
    });
  }

  listByAdventureUrl(url: string): Promise<{ arrivals: IArrival[]; status: string }> {
    return this.service.listByAdventureUrl(url).then(res => {
      this.list = res.arrivals;
      return res;
    });
  }
}

export const ArrivalModelStoreToken = token<IArrivalModel>('ArrivalModelStoreToken');

container.bind(ArrivalModelStoreToken).toInstance(ArrivalModel).inSingletonScope();

injected(ArrivalModel, ArrivalsServiceStoreToken);