import { TOrder } from '../../../lib/utils/types';
import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { makeAutoObservable } from 'mobx';
import { IOrdersService, OrdersServiceStoreToken } from '../api/service';

export interface IOrdersModel extends IOrdersService {
  item?: TOrder,
  setItem: (v: TOrder) => void,
  elapsedTime?: number;
}

export default class OrdersModel implements IOrdersModel {
  item?: TOrder;
  elapsedTime?: number;
  constructor(
    private service: IOrdersService,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setItem(v: TOrder) {
    this.item = v;
  }

  get(id: number): Promise<TOrder> {
    return this.service.get(id).then(res => {
      this.item = res;
      return res;
    });
  }

  getOrderTimeout(id: number): Promise<number> {
    return this.service.getOrderTimeout(id).then(res => {
      this.elapsedTime = res;
      return res;
    });
  }

  update(id: number, data: TOrder): Promise<TOrder> {
    return this.service.update(id, data);
  }
}

export const OrdersModelStoreToken = token<IOrdersModel>('OrdersModelStoreToken');

container.bind(OrdersModelStoreToken).toInstance(OrdersModel).inSingletonScope();

injected(OrdersModel, OrdersServiceStoreToken);