import FormButton from '../../generic/buttons/main/index2';
import { isProductNotExpired } from '../../../lib/utils/helpers';
import { t } from 'i18next';
import { BASE_SILAVETRA_URL } from '../../../lib/api/globalState';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useInjection } from 'brandi-react';
import { AdventureScheduleModelStoreToken } from './model';
import ArrivalSelector from "./ArrivalSelector";
import PresetArrival from "./PresetArrival";
import PriceBlock from "../Price";
import AlertDismissibleExample from '../../common/GlobalSaleAlert';

const ScheduleUI = (props) => {
  const {
    isArrivalPreset,
    arrivals,
    noSeatsAvailable,
    isButtonDisabled,
    pickedArrival,
    updateData,
    onSubmit,
    peopleAmount,
    isShowOnMainButton,
    arrivalUrlParam,
  } = props;

  const adventureScheduleModel = useInjection(AdventureScheduleModelStoreToken);

  return (
    <div className={"schedule"}>
      <>
        {
          !isArrivalPreset && arrivals
            ? <ArrivalSelector updateData={updateData} arrivals={arrivals} />
            : <PresetArrival arrival={pickedArrival} />
        }
        <AlertDismissibleExample />
        {
          !noSeatsAvailable
          && (isArrivalPreset || !!arrivals?.length)
          && <FormButton
            data-testid='next-submit'
            disabled={isButtonDisabled || !isProductNotExpired(pickedArrival?.start_date)}
            value={isProductNotExpired(pickedArrival?.start_date) ? t('common.next') : t('common.bookingClosed')}
            onClick={() => adventureScheduleModel.submitWithValidation(onSubmit, peopleAmount)}
            className={"d-none d-lg-block stickyButton"}
          />
        }
        {
          isShowOnMainButton &&
          <>
            <p className={`${isArrivalPreset && "d-none"} m-3 d-lg-block`}>
              {t('common.placesEnded')}
            </p>
            <FormButton
              value={t('common.toMain')}
              className={`${isArrivalPreset && "d-none"} d-lg-block`}
              onClick={() => window.location.href = BASE_SILAVETRA_URL + "/travel"}
            />
          </>
        }

        {(isArrivalPreset || arrivalUrlParam) && pickedArrival && (
          <PriceBlock onSubmit={() => adventureScheduleModel.submitWithValidation(onSubmit, peopleAmount)} isShowOnMainButton={isShowOnMainButton}
                      selectedCurrency={pickedArrival?.price_currency} noSeatsAvailable={noSeatsAvailable}
                      isButtonDisabled={isButtonDisabled || !isProductNotExpired(pickedArrival?.start_date)}
                      buttonValue={isProductNotExpired(pickedArrival?.start_date) ? t('common.next') : t('common.bookingClosed')}
          />
        )}
        {adventureScheduleModel.isArrivalOverbooked &&
        <p className="m-3">{t('errors.someoneAlready')}</p>
        }
      </>
    </div>
  )
}

export default observer(ScheduleUI);