import invalid from '../../../styles/assets/images/sv/invalid.svg';
import {regexes, trimOnChange} from '../../../lib/utils/helpers';
import valid from '../../../styles/assets/images/sv/valid.svg';
import DatePickerInput from "../../generic/DatePickerInput";
import {Controller, useFieldArray} from "react-hook-form";
import PhoneFieldRHF from '../../common/PhoneFieldRHF';
import {Row, Col, Input} from 'reactstrap';
import Divider from '../../generic/Divider';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import React from 'react';
import {currentDate, minBirthDate} from "../../../lib/utils/calendar";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';
import GenderRHF from '../../generic/GenderRHF';
import PrivateFileComponent from '../../common/PrivateFile';
import { Constants } from 'sv-common';
import { DropZoneTypes } from '../../generic/Dropzone';
import { TG_OPTIONS } from 'sv-common/constants/statuses';

const PeopleForms = ({form, isEmailEditable, isLastnameEditable, isFirstnameEditable, tg_options}) => {
    const {control, errors, register, setValue, watch, getValues} = form;

    const {fields} = useFieldArray({
        control,
        name: "peopleData",
    });

    return <>
        {fields.map((item, index) => {
            const isFirst = index === 0;

            const value = watch(`peopleData[${index}]`);

            return <div key={item.id}>
                <div className={'member'}>
                    <div className={'member__title'}>
                        {t('common.member')} #{index + 1}
                    </div>
                    <Row>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <span className={'input-label'}>{t('inputs.firstName.label')}</span>
                            {
                                (errors.peopleData?.[index]?.first_name || value.first_name) &&
                                <span><img src={errors.peopleData?.[index]?.first_name ? invalid : valid}
                                           alt=""/></span>
                            }

                            <Input
                                type="text"
                                placeholder={t('inputs.lastName.placeholder')}
                                defaultValue={item.first_name}
                                name={`peopleData[${index}].first_name`}
                                readOnly={index === 0 && !isFirstnameEditable}
                                innerRef={register({
                                    required: t('inputs.required'),
                                    pattern: {
                                        value: (index === 0 && !isFirstnameEditable) ? null : regexes.personalDataRegex,
                                        message: t('inputs.pattern')
                                    }
                                })}
                            />

                            <span className={errors.peopleData?.[index]?.first_name ? "error-label" : "error-label d-none"}>
                              {errors.peopleData?.[index]?.first_name?.message}
                            </span>
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <span className={'input-label'}>{t('inputs.lastName.label')}</span>
                            {
                                (errors.peopleData?.[index]?.last_name || value.last_name) &&
                                <span><img src={errors.peopleData?.[index]?.last_name ? invalid : valid} alt=""/></span>
                            }

                            <Input
                                type="text"
                                placeholder={t('inputs.lastName.placeholder')}
                                defaultValue={item.last_name}
                                name={`peopleData[${index}].last_name`}
                                readOnly={index === 0 && !isLastnameEditable}
                                innerRef={register({
                                    required: t('inputs.required'),
                                    pattern: {
                                        value: (index === 0 && !isLastnameEditable) ? null : regexes.personalDataRegex,
                                        message: t('inputs.pattern')
                                    }
                                })}
                            />

                            <span className={errors.peopleData?.[index]?.last_name ? "error-label" : "error-label d-none"}>
                              {errors.peopleData?.[index]?.last_name?.message}
                            </span>
                        </Col>


                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <span className={'input-label'}>{t('inputs.phone.label')}</span>
                            <PhoneFieldRHF form={form} name={`peopleData[${index}].phone`}
                                           error={errors.peopleData?.[index]?.phone || {}}/>
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <span className={'input-label'}>{t('inputs.sailingExperience.label')}</span>

                            {
                                (errors.peopleData?.[index]?.sailing_experience || value.sailing_experience) &&
                                <span>
                                    <img src={errors.peopleData?.[index]?.sailing_experience ? invalid : valid} alt=""/>
                                </span>
                            }

                            <select
                                ref={register({required: t('inputs.sailingExperience.required')})}
                                defaultValue={item.sailing_experience}
                                name={`peopleData[${index}].sailing_experience`}
                            >
                                <option value="" disabled hidden selected={!item.sailing_experience}>
                                    {t('inputs.sailingExperience.defaultValue')}
                                </option>
                                <option value="0/7">{t('inputs.sailingExperience.0/7')}</option>
                                <option value="1/7">{t('inputs.sailingExperience.1/7')}</option>
                                <option value="2/7">{t('inputs.sailingExperience.2/7')}</option>
                                <ElementWithAccessInCountries hideInCountries={['EN']}>
                                  <option value="3/7">{t('inputs.sailingExperience.3/7')}</option>
                                  <option value="4/7">{t('inputs.sailingExperience.4/7')}</option>
                                  <option value="5/7">{t('inputs.sailingExperience.5/7')}</option>
                                  <option value="6/7">{t('inputs.sailingExperience.6/7')}</option>
                                  <option value="7/7">{t('inputs.sailingExperience.7/7')}</option>
                                </ElementWithAccessInCountries>
                            </select>

                            <span className={errors.peopleData?.[index]?.sailing_experience ? "error-label" : "error-label d-none"}>
                              {errors.peopleData?.[index]?.sailing_experience?.message}
                            </span>
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                          <span className={'input-label'}>
                            {t('inputs.email.label')}
                          </span>
                            {
                                (errors.peopleData?.[index]?.email || value.email) &&
                                <span><img src={errors.peopleData?.[index]?.email ? invalid : valid} alt=""/></span>
                            }

                            <Controller
                                control={control}
                                defaultValue={item.email}
                                name={`peopleData[${index}].email`}
                                rules={{
                                    required: t('inputs.required'),
                                    pattern: {
                                        value: (index === 0 && !isEmailEditable) ? null : regexes.emailRegex,
                                        message: t('inputs.email.pattern')
                                    }
                                }}
                                render={({onChange, value, ref}) =>
                                    <Input
                                        type="text"
                                        value={value}
                                        inputref={ref}
                                        placeholder="username@email.com"
                                        onChange={e => onChange(trimOnChange(e))}
                                        readOnly={index === 0 && !isEmailEditable}
                                    />
                                }
                            />

                            <span className={errors.peopleData?.[index]?.email ? "error-label" : "error-label d-none"}>
                              {errors.peopleData?.[index]?.email?.message}
                            </span>
                        </Col>

                        <Col xs={12} md={6} className={"control-wrapper"}>
                            <span className={"input-label"}>{t('inputs.birthdate.label')}</span>
                            {
                                (errors.peopleData?.[index]?.birthdate || value.birthdate) &&
                                <span><img src={errors.peopleData?.[index]?.birthdate ? invalid : valid} alt=""/></span>
                            }

                            <DatePickerInput
                                control={control}
                                placeholder="01.01.1999"
                                defaultValue={item.birthdate}
                                name={`peopleData[${index}].birthdate`}
                                error={errors.peopleData?.[index]?.birthdate}
                                blurFunc={({target}) => setValue(`peopleData[${index}].birthdate`, target.value)}
                                rules={{
                                    required: true,
                                    pattern: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
                                }}
                                minDate={minBirthDate}
                                maxDate={currentDate}
                            />

                        </Col>
                        {tg_options !== TG_OPTIONS.HIDDEN && <Col xs={12} md={6} className={"control-wrapper"}>
                          <span className={"input-label"}>Telegram</span>
                          {(errors.peopleData?.[index]?.telegram || value.telegram) && (
                            <span>
                              <img src={errors.peopleData?.[index]?.telegram ? invalid : valid} alt=""/>
                            </span>
                          )}
                          <Input
                            type="text"
                            name={`peopleData[${index}].telegram`}
                            placeholder="silavetrasila"
                            defaultValue={item?.telegram}
                            innerRef={register({
                              required: tg_options === TG_OPTIONS.REQUIRED,
                              pattern: /^([^А-ЯЁа-яё])+$/
                            })}
                          />
                          <span className={errors.peopleData?.[index]?.telegram ? "error-label" : "error-label d-none"} role="alert">
                            {errors.peopleData?.[index]?.telegram?.type === "required" ? t('inputs.required') : ""}
                            {errors.peopleData?.[index]?.telegram?.type === "pattern" ? t('inputs.telegram.pattern') : ""}
                          </span>
                        </Col>}
                      <ElementWithAccessInCountries hideInCountries={['RU']}>
                        <Col sm="6">
                          <GenderRHF
                            required
                            control={control}
                            value={getValues(`peopleData[${index}].gender`)}
                            register={register}
                            setValue={(v) => setValue(`peopleData[${index}].gender`, v)}
                            error={errors.peopleData?.[index]?.gender}
                            fieldName={`peopleData[${index}].gender`}
                          />
                        </Col>
                        {isFirst && <Col xs={12} md={6} className={'control-wrapper'}>
                          <span className={'input-label'}>{t('inputs.passportFile')}</span>
                          <PrivateFileComponent withSlider={false} type={Constants.PRIVATE_FILE_TYPES.PASSPORT} dropZoneType={DropZoneTypes.common} />
                        </Col>}
                      </ElementWithAccessInCountries>
                    </Row>
                      {isFirst &&
                        <small>
                          {t('common.canEditData')}<Link to="/me" target="_blank">{t('common.personalAccount')}</Link>
                        </small>
                    }
                </div>
                <Divider/>
            </div>
        })}
        </>
}

PeopleForms.propTypes = {
    form: PropTypes.object,
    fields: PropTypes.array,
    isEmailEditable: PropTypes.bool,
    isLastnameEditable: PropTypes.bool,
    isFirstnameEditable: PropTypes.bool,
}

export default PeopleForms;