import {usePublicEndpoints} from "../../../lib/api/usePublicEndpoints";
import {useLoaderState} from "../../../lib/api/loaderState";
import {Dispatch, SetStateAction, useEffect} from "react";
import {TOrder} from "../../../lib/utils/types";

const useFinal = (orderId: string | number, setOrder: Dispatch<SetStateAction<TOrder | undefined>>) => {
    const { getOrder } = usePublicEndpoints();
    const {setIsLoading} = useLoaderState();


    useEffect(() => {
        if (orderId) {
            setIsLoading(() => true);
            getOrder(orderId)
                .then(res => setOrder(() => res))
                .catch(e => console.log(e))
                .finally(() => setIsLoading(false))
        }
    }, [orderId]);
}

export default useFinal;