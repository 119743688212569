import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { PaymentPayMethodsObject } from '../constants';
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';
import { t } from 'i18next';
import { useInjection } from 'brandi-react';
import { PayBlockModelStoreToken } from '../models/PayBlockModel/index.model';
import { observer } from 'mobx-react-lite';

const PaymentPayExtraComponent: FC = observer(() => {
  const payBlockModel = useInjection(PayBlockModelStoreToken);

  const [checkedMethod, setCheckedMethod] = useState<string>(PaymentPayMethodsObject.CARD);

  const onCheck = useCallback((method: string) => {
    setCheckedMethod(method);
    payBlockModel.setPayExtraMethod(method);
  }, []);

  const isCashChecked = useMemo(() => checkedMethod === PaymentPayMethodsObject.CASH, [checkedMethod]);
  const isCardChecked = useMemo(() => checkedMethod === PaymentPayMethodsObject.CARD, [checkedMethod]);

  useEffect(() => {
    payBlockModel.setPayExtraMethod(PaymentPayMethodsObject.CARD);
  }, []);

  return (
    <div className='payment-pay-extra-block'>
      <span className='font-weight-bold mr-3'>{t('paymentPay.payExtra')}</span>
      <Input
        type="checkbox"
        id="cardPayment"
        defaultChecked={true}
        name="cardPayment"
        onChange={() => onCheck(PaymentPayMethodsObject.CARD)}
      />
      <Input
        type="checkbox"
        id="cashPayment"
        defaultChecked={false}
        name="cashPayment"
        onChange={() => onCheck(PaymentPayMethodsObject.CASH)}
      />
      <div>
        <Label htmlFor="cardPayment" className={`payment-pay-extra-checkbox ${isCardChecked && 'checked' || ''}`}>
          <span>{t('paymentPay.payByCard')}</span>
        </Label>
        {payBlockModel.isPaidLocally && <ElementWithAccessInCountries hideInCountries={['RU']}>
          <Label htmlFor="cashPayment" className={`payment-pay-extra-checkbox ${isCashChecked && 'checked' || ''}`}>
            <span>{t('paymentPay.payByCash')}</span>
          </Label>
        </ElementWithAccessInCountries>}
      </div>
    </div>
  )
})

export default PaymentPayExtraComponent;