import {Input, Label} from "reactstrap";
import {t} from "i18next";
import AgeConsent from "../../common/AgeConsent";
import ElementWithAccessInCountries from "lib/utils/hocs/ElementWithAccessInCountries";

//@ts-ignore
const MemberCheckboxes = ({isInsuredBefore, dataContainer, peopleAmount, register, errors, values}) => {
    return <>
        {(dataContainer?.slot?.team_only || peopleAmount > 1) &&
                <div className="checkout__input-wrapper radio-input">
                    <Input
                            type="checkbox"
                            defaultChecked
                            id="duplicateData"
                            name="duplicateData"
                            innerRef={register()}
                    />
                    <Label htmlFor="duplicateData">{t('sport.membersScreen.dontFillOthers')}</Label>
                </div>
        }
        {dataContainer?.slot?.check_age_required &&
                <AgeConsent register={register} error={errors.ageConsent} value={values.ageConsent} required
                            age={dataContainer?.slot?.check_age_value}/>
        }
        {isInsuredBefore && 
                    <ElementWithAccessInCountries hideInCountries={['EN']}>
                        <div className="checkout__input-wrapper radio-input">
                            <Input
                                    type="checkbox"
                                    id="is_insured"
                                    name="is_insured"
                                    innerRef={register()}
                                    error={errors.insuranceConsent}
                            />
                            <Label htmlFor="is_insured">Хочу бесплатное страхование</Label>
                        </div>
                    </ElementWithAccessInCountries>
        }
    </>
}

export default MemberCheckboxes