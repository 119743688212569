import {Input, Label} from "reactstrap";
import {t} from "i18next";
import {useEffect, useState} from "react";
import './style.scss';
import Selector from "../../generic/Selector";
import t_bank from "./../../../styles/assets/images/tbank/t_bank.svg";
import {observer} from "mobx-react-lite";
import {useInjection} from "brandi-react";
import {GlobalSettingsModelStoreToken} from "../../../entities/globalConfig/model";

const Installment = ({installment, setInstallment}) => {
    const globalSettings = useInjection(GlobalSettingsModelStoreToken);
    const normalizeMonthCount = (number) => {
        const wordsArr = [t('common.installment.helpers.month'), t('common.installment.helpers.months'), t('common.installment.helpers.months2')];
        let month = null;
        number = Math.abs(number);
        if (Number.isInteger(number)) {
            let options = [2, 0, 1, 1, 1, 2];
            month = wordsArr[(number % 100 > 4 && number % 100 < 20) ? 2 : options[(number % 10 < 5) ? number % 10 : 5]];
            return `${number} ${month}`;
        }
        return `${number} ${wordsArr[1]}`;
    }

    const installmentList = [];

    installment.forEach((item, index) => {
        installmentList.push({
            id: index,
            name: normalizeMonthCount(item.duration),
            promocode: item.promocode,
            markup: item.markup
        })
    })

    const [isShown, setIsShown] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(installmentList[0]);

    const handleSelect = (group) => {
        setSelectedGroup(group);
        setInstallment(group);
    }

    useEffect(() => {
        isShown ? setInstallment(selectedGroup) : setInstallment();
    }, [isShown])

    return globalSettings.config.installment_available && (
        <div className="checkout__input-wrapper radio-input">
            <Input
                type="checkbox"
                id="showInstallment"
                data-testid='showInstallment'
                name="showInstallment"
                onChange={e => setIsShown(e.target.checked)}
            />
            <Label htmlFor="showInstallment" className="check-installment">
                {t('common.installment.show')}
                <img src={t_bank} alt={t('common.installment.altMessage')} className="t-bank"/>
            </Label>
            {isShown &&
                <Selector
                    options={installmentList}
                    value={selectedGroup}
                    label={'Выбрать рассрочку'}
                    placeholder={'Выбрать рассрочку'}
                    onChange={handleSelect}
                />
            }
            <div className="default-text__installment">
                {t('common.installment.info')}
            </div>
            <div className="default-text__installment">
                {t('common.installment.warning')}
            </div>
        </div>
    )
}

export default observer(Installment);