import React, {Dispatch, SetStateAction} from 'react';
import Divider from '../../../generic/Divider';
import FormButton from "../../../generic/buttons/main";
import '../../../sport/Final/style.scss'
import i18next from "i18next";
import {NavLink} from "react-router-dom";
import {localizeDate} from "../../../../lib/utils/format";
import useFinal from "../../../sport/Final/useFinal";
import {TOrder} from "../../../../lib/utils/types";

type TProps = {
    setOrder: Dispatch<SetStateAction<TOrder | undefined>>
    orderId: string | number
    onNewBookingClick: () => void
    startDate?: string
}

const Final = ({ setOrder, orderId, onNewBookingClick, startDate }: TProps) => {
    useFinal(orderId, setOrder)
    const {t, language} = i18next;

    return <div className="final">
        <div className="final__title">{t('final.tableEventSuccess', {date: localizeDate(startDate, language, language === 'en' && 'MMMM D')})}</div>

        <NavLink to="/tablet" className="button_links">
            <FormButton value={t('final.bookMore')} onClick={() => {
                onNewBookingClick();
                setOrder(undefined);
            }} type={'button'} disabled={undefined} className={undefined}/>
        </NavLink>

        <Divider />
    </div>
}

export default Final;
