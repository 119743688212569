import {Input, Label} from "reactstrap";
import {t} from "i18next";
import React, { FC } from 'react';
import { IRenderCheckboxes } from '../../../sport/MembersScreen/types';
import ElementWithAccessInCountries from '../../../../lib/utils/hocs/ElementWithAccessInCountries';

//@ts-ignore
const AgeConsent: FC<IRenderCheckboxes> = ({dataContainer, register, errors, values}) => {
    return <>
        {dataContainer?.slot?.check_age_required && <div className="checkout__input-wrapper radio-input">
            <Input
                    type="checkbox"
                    id="ageConsent"
                    name="ageConsent"
                    innerRef={register({
                        required: true,
                    })}
            />
            <Label htmlFor="ageConsent">
                {t('inputs.consents.checkAge', {checkAgeValue: dataContainer?.slot?.check_age_value})}
            </Label>
            <span className={`defaultText__ageConsent ${errors.ageConsent ? "error-label errorText__ageConsent" : ""}`} >
                {t('inputs.consents.ifHaveNoAge', {checkAgeValue: dataContainer?.slot?.check_age_value})}
                      <a href="https://silavetra.com/kids">{t('inputs.consents.childrenClub')}</a>
                      {t('inputs.consents.checkAgeSecondPart')}
            </span>
        </div>}
    </>
}

export default AgeConsent;