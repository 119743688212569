import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Input} from 'reactstrap';
import Divider from '../../../../../generic/Divider';
import PhoneField from '../../../../../common/PhoneField';
import {trimOnChange} from '../../../../../../lib/utils/helpers';
import valid from '../../../../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../../../../styles/assets/images/sv/invalid.svg';
import {Link} from "react-router-dom";
import {t} from "i18next";
import ElementWithAccessInCountries from '../../../../../../lib/utils/hocs/ElementWithAccessInCountries';
import PrivateFileComponent from '../../../../../common/PrivateFile';
import { Constants } from 'sv-common';
import { DropZoneTypes } from '../../../../../generic/Dropzone';
import { TG_OPTIONS } from 'sv-common/constants/statuses';
import { makePeopleDataAdditional } from './helpers';

const PeopleForms = ({peopleData, updatePeopleData, errors, values, register, payingUser, control, showPeopleForms, tgOptions}) => {
    const peopleForms = useMemo(() => showPeopleForms ? peopleData : [peopleData[0]] , [showPeopleForms])
    const emailChange = (e) => {
        updatePeopleData(e)
        trimOnChange(e)
    }

    const peopleDataAdditional = makePeopleDataAdditional(peopleForms, payingUser);

    return peopleForms.map((item, index) => {
      const isFirst = index === 0;

      return (
        <div key={index}>
          <div className={'member'}>
            <div className={'member__title'}>
              {t('common.member')} #{index + 1}
            </div>
            <Row>
              <Col xs={12} md={6} className={'control-wrapper'}>
                <span className={'input-label'}>{t('inputs.firstName.label')}</span>
                {(errors[`first_name-${index}`] || values[`first_name-${index}`])
                && <span><img src={errors[`first_name-${index}`] ? invalid : valid} alt=""/></span>}
                <Input type="text" id={`first_name-${index}`} name={`first_name-${index}`}
                       placeholder={t('inputs.firstName.placeholder')}
                       onChange={updatePeopleData}
                       innerRef={register({pattern: peopleDataAdditional[index].firstName.pattern, required: true})}
                       defaultValue={peopleDataAdditional[index].firstName.defaultValue}
                       disabled={peopleDataAdditional[index].firstName.disabled}
                />
                <span className={errors[`first_name-${index}`] ? "error-label" : "error-label d-none"}>
                  {errors[`first_name-${index}`]?.type === "required" ? t('inputs.required') : ""}
                  {errors[`first_name-${index}`]?.type === "pattern" ? t('inputs.pattern') : ""}
                </span>
              </Col>

              <Col xs={12} md={6} className={'control-wrapper'}>
                <span className={'input-label'}>
                  {t('inputs.lastName.label')}
                </span>
                {(errors[`last_name-${index}`] || values[`last_name-${index}`])
                && <span><img src={errors[`last_name-${index}`] ? invalid : valid} alt=""/></span>}

                <Input type="text" id={`last_name-${index}`} name={`last_name-${index}`}
                       placeholder={t('inputs.lastName.placeholder')}
                       onBlur={updatePeopleData}
                       innerRef={register({pattern: peopleDataAdditional[index].lastName.pattern, required: true})}
                       defaultValue={peopleDataAdditional[index].lastName.defaultValue}
                       disabled={peopleDataAdditional[index].lastName.disabled}
                />
                <span className={errors[`last_name-${index}`] ? "error-label" : "error-label d-none"}>
                  {errors[`last_name-${index}`]?.type === "required" ? t('inputs.required') : ""}
                  {errors[`last_name-${index}`]?.type === "pattern" ? t('inputs.pattern') : ""}
                </span>
              </Col>

              <Col xs={12} md={6} className={'control-wrapper'}>
                <span className={'input-label'}>
                  {t('inputs.phone.label')}
                </span>
                <PhoneField
                  index={index}
                  control={control}
                  register={register}
                  setPhoneValue={updatePeopleData}
                  phoneValue={index === 0 ? (peopleData[index]?.phone || payingUser.phone) : ''}
                />
                <span className={errors[`phone-${index}`] ? "error-label" : "error-label d-none"}>
                  {errors[`phone-${index}`]?.type === "required" ? t('inputs.required') : ""}
                  {errors[`phone-${index}`]?.type === "pattern" ? t('inputs.phone.pattern') : ""}
                </span>
              </Col>

              <Col xs={12} md={6} className={'control-wrapper'}>
                <span className={'input-label'}>
                  {t('inputs.sailingExperience.label')}
                </span>
                {(errors[`sailing_experience-${index}`] || values[`sailing_experience-${index}`]) &&
                <span><img src={errors[`sailing_experience-${index}`] ? invalid : valid} alt=""/></span>}
                <select ref={register({required: true})}
                        id={`sailing_experience-${index}`}
                        onChange={updatePeopleData} name={`sailing_experience-${index}`}
                        defaultValue={index === 0 ? (peopleData[index]?.sailing_experience || payingUser.sailing_experience) : ''}
                >
                  <option value="" hidden>{t('inputs.sailingExperience.defaultValue')}</option>
                  <option value="0/7">{t('school.0/7')}</option>
                  <option value="1/7">{t('school.1/7')}</option>
                  <option value="2/7">{t('school.2/7')}</option>
                  <ElementWithAccessInCountries hideInCountries={['EN']}>
                    <option value="3/7">{t('school.3/7')}</option>
                    <option value="4/7">{t('school.4/7')}</option>
                  </ElementWithAccessInCountries>
                </select>
                <span className={errors[`sailing_experience-${index}`] ? "error-label" : "error-label d-none"}>
                  {errors[`sailing_experience-${index}`]?.type === "required" ? t('inputs.sailingExperience.required') : ""}
                </span>
              </Col>

              <Col xs={12} md={6} className={'control-wrapper'}>
                <span className={'input-label'}>
                  {t('inputs.email.label')}
                </span>
                {(errors[`email-${index}`] || values[`email-${index}`])
                && <span><img src={errors[`email-${index}`] ? invalid : valid} alt=""/></span>}
                <Input type="text" id={`email-${index}`} name={`email-${index}`}
                       placeholder="username@email.com"
                       defaultValue={peopleDataAdditional[index].email.defaultValue}
                       innerRef={register({required: true, pattern: peopleDataAdditional[index].email.pattern})}
                       onChange={emailChange}
                       disabled={peopleDataAdditional[index].email.disabled}
                />
                <span className={errors[`email-${index}`] ? "error-label" : "error-label d-none"}>
                  {errors[`email-${index}`]?.type === "required" ? t('inputs.required') : ""}
                  {errors[`email-${index}`]?.type === "pattern" ? t('inputs.email.pattern') : ""}
                </span>
              </Col>
              {tgOptions !== TG_OPTIONS.HIDDEN && <Col xs={12} md={6} className={'control-wrapper'}>
                <span className={'input-label'}>
                  {t('common.telegram')}
                </span>
                {(errors[`telegram-${index}`] || values[`telegram-${index}`])
                && <span><img src={errors[`telegram-${index}`] ? invalid : valid} alt=""/></span>}
                <Input type="text" id={`telegram-${index}`} name={`telegram-${index}`}
                       placeholder="@silavetrasila"
                       defaultValue={peopleDataAdditional[index].telegram.defaultValue}
                       innerRef={register({required: tgOptions === TG_OPTIONS.REQUIRED, pattern: /^([^А-ЯЁа-яё])+$/})}
                       onChange={emailChange}
                />
                <span className={errors[`telegram-${index}`] ? "error-label" : "error-label d-none"}>
                  {errors[`telegram-${index}`]?.type === "required" ? t('inputs.required') : ""}
                  {errors[`telegram-${index}`]?.type === "pattern" ? t('inputs.telegram.pattern') : ""}
                </span>
              </Col>}
              {isFirst && <ElementWithAccessInCountries hideInCountries={['RU']}>
                <Col xs={12} md={6} className={'control-wrapper'}>
                  <span className={'input-label'}>{t('inputs.passportFile')}</span>
                  <PrivateFileComponent withSlider={false} type={Constants.PRIVATE_FILE_TYPES.PASSPORT} dropZoneType={DropZoneTypes.common} />
                </Col>
              </ElementWithAccessInCountries>}
            </Row>
            {isFirst &&
            <small>
              {t('common.canEditData')}&nbsp;<Link to="/me" target="_blank">{t('common.personalAccount')}</Link>
            </small>
            }
          </div>
          <Divider/>
        </div>
      )
    })
}

PeopleForms.propTypes = {
    errors: PropTypes.object,
    values: PropTypes.object,
    register: PropTypes.func,
    control: PropTypes.object,
    peopleData: PropTypes.array,
    payingUser: PropTypes.object,
    updatePeopleData: PropTypes.func,
    inputMembersData: PropTypes.array,
    showPeopleForms: PropTypes.bool
}

export default PeopleForms;