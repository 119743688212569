import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {en} from "./en";
import {ru} from "./ru";
import {LANGUAGE} from "../constants";

const resources = {
    en: {
        translation: en
    },
    ru: {
        translation: ru
    }
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        returnNull: false,
        resources,
        fallbackLng: LANGUAGE.toLowerCase(),
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'cookie', 'sessionStorage']
        },
        react: { useSuspense: false }
    });

export default i18n;
