import Field from '../../generic/Field'
import { Col, Container, Row } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import FormButton from '../../generic/buttons/main';
import { Label } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import './style.scss'
import { usePublicEndpoints } from '../../../lib/api/usePublicEndpoints';
import { normalizeCurrency } from '../../../lib/utils/helpers';
import {datetimeToDate} from "../../../lib/utils/format";
import {useCaptcha} from "../../../lib/utils/hooks/useCaptcha";
import {t} from "i18next";
import getCountryValue from '../../../lib/utils/getCountryValue';
import ElementWithAccessInCountries from '../../../lib/utils/hocs/ElementWithAccessInCountries';

const CertificatesSubscriptions = ({userData}) => {
    const { getCertificateInfo, getCurrencies } = usePublicEndpoints();

    const [certificate, setCertificate] = useState('')
    const [certificateInfo, setCertificateInfo] = useState({})
    const [currencies, setCurrencies] = useState([]);

    const clickCheckCertificateButton = async (captchaToken) => {
        const res = await getCertificateInfo(certificate, captchaToken)
        setCertificateInfo(res)
    }

    const [generateCaptcha, ReCAPTCHA] = useCaptcha(clickCheckCertificateButton)

    useEffect(() => {
        getCurrencies()
            .then(res => setCurrencies(res));
    }, [])

    return (
        <Container>
            {ReCAPTCHA}
            <h4 className="block_title">{t('profile.certificatesSubscriptions.blockTitle')}</h4>
            <Row>
                <Col sm="6">
                    <Field
                        label={t('profile.certificatesSubscriptions.certificateLabel')}
                        value={certificate || ''}
                        setValue={setCertificate}
                        placeholder={t('profile.certificatesSubscriptions.certificatePlaceholder')}
                        fieldName="certificate"
                        className="field"
                    />
                    <FormButton value={t('profile.certificatesSubscriptions.check')} className="small-button" onClick={() => generateCaptcha()}
                                disabled={!certificate.trim()} />
                    <div className="sertificate_info">
                        {certificateInfo.status === 'Not Found' &&
                            <div className="sertificate_info_item">
                                {t('profile.certificatesSubscriptions.certificateNotFound')}
                            </div>
                        }
                        {certificateInfo.status === 'Not Available' &&
                            <div className="sertificate_info_item">
                                {t('profile.certificatesSubscriptions.certificateNotAvailable')}
                            </div>
                        }
                        {certificateInfo.sum &&
                            <div className="sertificate_info_item">
                                {t('profile.certificatesSubscriptions.certificateBalance')}:&nbsp;<span>{certificateInfo.sum}</span>
                            </div>
                        }
                        {certificateInfo.productTypes &&
                            <div className="sertificate_info_item">
                                {t('profile.certificatesSubscriptions.certificateApplies')}:&nbsp;<span>{certificateInfo.productTypes}</span>
                            </div>
                        }
                        {certificateInfo.country &&
                            <div className="sertificate_info_item">
                                {t('profile.certificatesSubscriptions.certificateCountry')}:&nbsp;<span>{certificateInfo.country}</span>
                            </div>
                        }
                        {certificateInfo.availableTo &&
                            <div className="sertificate_info_item">
                                {t('profile.certificatesSubscriptions.certificateAvailableTo')}:&nbsp;<span>{datetimeToDate(certificateInfo.availableTo)}</span>
                            </div>
                        }
                    </div>
                </Col>
                <Col sm="6">
                    <Label className="mt-2 input-label" htmlFor="phoneNumber">
                        {t('profile.certificatesSubscriptions.subscription')}
                    </Label>
                    <div className="sertificate_info">
                        {userData?.deposit_sum > 0 ?
                            <>
                                <div className="sertificate_info_item">
                                    {t('profile.certificatesSubscriptions.subscriptionBalance')}:&nbsp;<span>{userData?.deposit_sum} {userData?.subscription_price_currency && normalizeCurrency(userData?.subscription_price_currency, currencies)}</span>
                                </div>
                                <div className="sertificate_info_item">
                                    {t('profile.certificatesSubscriptions.subscriptionDiscount')}:&nbsp;<span>{userData?.keel_all_luch_group_discount_size}%</span>
                                </div>
                                <ElementWithAccessInCountries hideInCountries={['EN']}>
                                  <div className="sertificate_info_item">
                                    {t('profile.certificatesSubscriptions.subscriptionIndividualDiscount')}:&nbsp;<span>{userData?.luch_individual_discount_size}%</span>
                                  </div>
                                </ElementWithAccessInCountries>
                                {!!(userData?.race_discount_size || userData?.cup_discount_size) && <div className="sertificate_info_item">
                                  {t('profile.certificatesSubscriptions.subscriptionDiscountRace')}:
                                  <span> {getCountryValue({ ru: userData?.race_discount_size, en: userData?.cup_discount_size })}%</span>
                                </div>}
                            </>
                            : 
                            <>
                                <div>{t('profile.certificatesSubscriptions.subscriptionEmpty')}.</div>
                                <NavLink to="/abonement" className="link_button">{t('profile.certificatesSubscriptions.buy')}</NavLink>
                            </>
                        }
                    </div>

                </Col>
            </Row>
        </Container>
    )

}

export default CertificatesSubscriptions