import {useApi} from "./useApi";

export const useFeedbacksApi = () => {
    const { request } = useApi();

    const getFeedback = async (orderId = '') =>
        request('get', '/public/feedback/' + orderId);

    const sendFeedback = async (rating, feedback, orderId) =>
        request('post', '/public/feedback', {rating, feedback, orderId});

    return {
        getFeedback,
        sendFeedback,
    }
}
