import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg'
import PropTypes from "prop-types";
import './style.scss'

const SelectField = ({ label, error, value, setValue, fieldName, register, defaultValue, options }) => {

    return (
        <>
            <span className="input-label">
                {label}
                {(error || value) && (
                    <img src={error ? invalid : valid} alt="" className="validation-img" />
                )}
            </span>

            <select
                id={fieldName && fieldName}
                value={value && value}
                onChange={(e) => setValue && setValue(e.target.value)}
                name={fieldName && fieldName}
                ref={register && register({ required: true })}
            >
                {defaultValue &&
                    <option defaultChecked hidden value="" >
                        {defaultValue}
                    </option>
                }

                {options.map((option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                )))}
            </select>
            <span className={error ? "error-label" : "error-label d-none"}>
                {error?.type === "required" && "Поле обязательно для заполнения"}
            </span>
        </>
    )
}

SelectField.propTypes = {
    label: PropTypes.string,
    error: PropTypes.object,
    value: PropTypes.string,
    setValue: PropTypes.func,
    fieldName: PropTypes.string,
    register: PropTypes.func,
    defaultValue: PropTypes.string,
    options: PropTypes.array,
}
export default SelectField