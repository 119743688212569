import {useEffect, useState} from "react";
import {useLoaderState} from "../../../../lib/api/loaderState";
import {useOrdersApi} from "../../../../lib/api/useOrdersApi";
import {TSubscriptionOptions, useTypedPublicEndpoints} from "../../../../lib/api/useTypedPublicEndpoint";

//@ts-ignore
const useOrder = ({orderId, setOrder, setSelectedSlot, setPeopleAmount, setIsSubscriptionPay}) => {
    const {setIsLoading} = useLoaderState();
    const {getOrder} = useOrdersApi();
    const {calculateSubscription} = useTypedPublicEndpoints();

    const [subscriptionData, setSubscriptionData] = useState<TSubscriptionOptions>();

    async function fetchOrder(orderId: number | string) {

        const order = await getOrder(orderId);
        setOrder(() => order);
        setSelectedSlot(order.slot)
        setPeopleAmount(order.paidForLength);
        setIsSubscriptionPay(() => !!order.subscriptionAppliedId)
    }

    useEffect(() => {
        setIsLoading(true)
        if (orderId) {
            fetchOrder(orderId).catch(err => console.log(err));
            calculateSubscription(orderId).then(res => setSubscriptionData(res));
        } else {
            setSubscriptionData(undefined)
        }
        setIsLoading(false);
    }, [orderId])

    return {subscriptionData}
}

export default useOrder;