import PropTypes from 'prop-types';
import {Input, Label} from "reactstrap";
import {t} from "i18next";

const DuplicateData = ({register, peopleAmount, onChange}) => peopleAmount === 1 ? <></> : (
    <div className="checkout__input-wrapper radio-input">
        <Input type="checkbox" id="duplicateData" name="duplicateData" innerRef={register({})} defaultChecked onChange={onChange}/>
        <Label htmlFor="duplicateData">{t('sport.membersScreen.dontFillOthers')}</Label>
    </div>
)

DuplicateData.propTypes = {
    peopleAmount: PropTypes.number,
    register: PropTypes.func.isRequired,
    onChange: PropTypes.func,
};

export default DuplicateData;