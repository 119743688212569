import {getDeclension} from "../../../lib/utils/helpers";
import React from "react";
import {IWeatherInfo} from "../../../lib/utils/types";
import {t} from "i18next";

const WeatherInfo = ({airTemperature, waterTemperature, hoursSailing, hoursSailingLimit}: IWeatherInfo) =>
    <div className="weather">
        <div className="temperature">
            <div className="temperature__label card-label">{t('bookingCard.temperature')}</div>
            <div className="temperature__value-air card-caption">
                {t('bookingCard.air')} — {airTemperature}&deg;
            </div>
            <div className="temperature__value-water card-caption">
                {t('bookingCard.water')} — {waterTemperature}&deg;
            </div>
        </div>
        <div className="time">
            <div className="time__label card-label">
                {t('bookingCard.waterTime')}
            </div>
            <div className="time__value card-caption">
                {`${t('bookingCard.from')} ${hoursSailing}
                ${t('bookingCard.to')} ${hoursSailingLimit || hoursSailing}
                ${t(getDeclension(+hoursSailingLimit))}`}
            </div>
        </div>
    </div>

export default WeatherInfo