// eslint-disable-next-line
import {useApi} from "./useApi";
// @ts-ignore
import {ERRORS} from 'sv-common/constants/certificates';

export type TCertificate = {
  status: 'valid' | 'invalid' | ERRORS.NOT_EXIST | ERRORS.NOT_ACTIVE | ERRORS.INCORRECT_PRODUCT_TYPE,
    id: number,
    type: string,
    size: number,
    sizeLeft: number,
    currency: string,
    name: string,
    actualSize: number, // размер скидки в процентах c учетом сторонних скидок
    actualAbsoluteSize: number,
    convertedSizeLeft: number
}

export type TCertificateOrPromocode = {
    status: 'valid' | 'invalid',
    id: number,
    type: string,
    size: number,
    sizeLeft: number,
    currency: string,
    name: string,
    actualSize: number, // размер скидки в процентах c учетом сторонних скидок
    actualAbsoluteSize: number,
    convertedSizeLeft: number,
    extraInfo: string,
    usesLeft: number,
    entity: 'promocode' | 'certificate',
    percent?: number
}

export const useCertificatesApi = () => {
    const { request } = useApi();

    const checkCertificate = async (certificateName: string, captcha: string, productType: string, productId: number|string): Promise<TCertificate> =>
        request('get', `/public/certificate/${certificateName}/check`, {captcha, product: productType, productId})

    /**
     * Method to check a promocode or certificate
     * @param {string} productType
     * @param {string || number} productId
     * @param {string} name
     * @param {string} orderId
     * @return {Promise<unknown>}
     */
    const checkPromocodeOrCertificate = async (productType: string, productId: string | number, name: string, orderId: string | number)
        : Promise<TCertificateOrPromocode>=>
        request('get', `/public/promocode/valid?productType=${productType}&productId=${productId}&name=${name}&orderId=${orderId}`);


    return {checkCertificate, checkPromocodeOrCertificate}
}