import React from 'react';
import {Row, Col} from 'reactstrap';
import './style.scss';
import tick from '../../../../styles/assets/images/sv/tick.svg';
import {t} from "i18next";

type TProps = {
    step: number
    handleStepClick: (num: number) => void
    resetData: () => void
}

const Steps = ({step, handleStepClick, resetData}: TProps) => {
  return (
    <Row className={'step no-gutters pl-4 justify-content-between justify-content-lg-start d-sm-flex d-none'}>
      <Col xs={'auto'} className={step > 0 ? 'step__item pl-2 pr-2 active' : 'step__item pl-2 pr-2'}
           onClick={() => {
               step > 0 && handleStepClick(1)
               if((step === 5 || step === 3) && resetData) {
                   resetData()
               }
            }
           }>
        <span className={step > 0 ? 'step__count finished' : 'step__count'} >
          {step > 1 ? <img className="step-done-img" src={tick} alt="done" /> : '1'}
        </span>
        <span className={'step__label'}>
          {t('common.layout.pickDate')}
        </span>
      </Col>
      <Col xs={'auto'} className={step > 1 ? 'step__item pl-2 pr-2 active' : 'step__item pl-2 pr-2'}
           onClick={() => {
                   step > 1 && handleStepClick(2)
                   if((step === 5 || step === 3) && resetData) {
                       resetData()
                   }
               }
           }>
      <span className={step > 0 ? 'step__count finished' : 'step__count'} >
          {step > 2 ? <img className="step-done-img" src={tick} alt="done" /> : '2'}
        </span>
        <span className={'step__label'}>
          {t('common.layout.members')}
        </span>
      </Col>
      <Col xs={'auto'} className={step > 2 ? 'step__item pl-2 pr-2 active' : 'step__item pl-2 pr-2'}
           onClick={step > 2 ? () => handleStepClick(3) : undefined}>
      <span className={step > 0 ? 'step__count finished' : 'step__count'} >
          {step > 3 ? <img className="step-done-img" src={tick} alt="done" /> : '3'}
        </span>
        <span className={'step__label'}>
          {t('common.layout.finish')}
        </span>
      </Col>
    </Row>
  )
}

export default Steps;