import { usePublicEndpoints } from '../../../../../../lib/api/usePublicEndpoints';
import { useInjection } from 'brandi-react';
import { PayBlockModelStoreToken } from '../../../../../common/PayBlock/models/PayBlockModel/index.model';
import { useRedirect } from '../../../../../../lib/utils/hooks/useRedirect';
import { useLocation } from 'react-router-dom';
import { useLoaderState } from '../../../../../../lib/api/loaderState';

const useOrder = (checkOrderProduct, currentData, query) => {
  const {updateOrder, createPayment} = usePublicEndpoints();
  const payBlockModel = useInjection(PayBlockModelStoreToken);
  const {setIsLoading} = useLoaderState();

  const {pathname} = useLocation();
  const {errorPageRedirect} = useRedirect();

  const submit = () => {
    const dataToSend = {
      ...currentData,
      step: query.step,
    }

    setIsLoading(() => true);

    return updateOrder(query.orderId, dataToSend)
      .then((res) =>
        errorPageRedirect(() => createPayment(res.orderId, pathname + `?orderId=${query.orderId}&step=5`, null, payBlockModel.isCashPay()))
      )
      .then((res) => window.location.href = res.url)
      .catch((e) => {
        checkOrderProduct(e, '/school')
        console.log(e)
      })
      .finally(() => setIsLoading(false));
  }

  return { submit };
}

export default useOrder;