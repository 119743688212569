import {Controller} from "react-hook-form";
import MaskedInput from "react-input-mask";
import DatePicker from "react-datepicker";
import React, {useState} from "react";
import {ru, enUS} from "date-fns/locale";
import {PropTypes} from "prop-types";
import dayjs from "dayjs";

import "react-datepicker/dist/react-datepicker.css";
import i18next, {t} from "i18next";

const getLocale = (lang) => {
    switch (lang) {
        case 'ru':
            return ru
        default:
            return enUS
    }
}

const DatePickerInput = ({
    sendingDateFormat,
    outputDateFormat,
    alwaysShowMask,
    defaultValue,
    placeholder,
    maskChar,
    blurFunc,
    control,
    rules,
    error,
    mask,
    name,
    id,
    minDate,
    maxDate
}) => {
    const {language} = i18next;
    const formatDate = (date) => dayjs(date).format(sendingDateFormat)

    const isValidDate = (date) => dayjs(date, sendingDateFormat).isValid()

    const getDefaultDate = () => {
        return defaultValue && isValidDate(defaultValue) && dayjs(defaultValue, sendingDateFormat).toDate()
    }

    const [selectedDate, setSelectedDate] = useState(getDefaultDate())

    return (
        <>
            <Controller
                defaultValue={selectedDate}
                control={control}
                rules={rules}
                name={name}
                render={({onChange, onBlur, name}) => (
                    <DatePicker
                        placeholderText={placeholder}
                        dateFormat={outputDateFormat}
                        yearDropdownItemNumber={50}
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={selectedDate}
                        scrollableYearDropdown
                        autoComplete="off"
                        showYearDropdown
                        fixedHeight
                        dropdownMode="select"
                        locale={getLocale(language)}
                        id={id}
                        customInput={
                            <MaskedInput
                                alwaysShowMask={alwaysShowMask}
                                className="form-control"
                                maskChar={maskChar}
                                mask={mask}
                            />
                        }
                        onCalendarClose={() => {
                            const event = {
                                target: {
                                    name,
                                    value: formatDate(selectedDate)
                                }
                            }
                            blurFunc(event)
                            onBlur(event)
                        }}
                        name={name}
                        onChange={(date) => {
                            onChange(formatDate(date))
                            setSelectedDate(date)
                        }}
                        onBlur={() => {
                            const event = {
                                target: {
                                    name,
                                    value: formatDate(selectedDate)
                                }
                            }
                            onBlur(event)
                            blurFunc(event)
                        }}
                    />
                )}
            />
            {error &&
                <span className={"error-label"}>
                    {error?.type === "required" && t('inputs.required')}
                    {error?.type === "pattern" && `${t('inputs.formatError')} ${t('inputs.errorDateFormat')}`}
                </span>
            }
        </>
    )
}

DatePickerInput.defaultProps = {
    sendingDateFormat: 'DD.MM.YYYY',
    outputDateFormat: "dd.MM.yyyy",
    errorDateFormat: "ДД.ММ.ГГГГ",
    placeholder: '01.01.1999',
    alwaysShowMask: false,
    mask: "99.99.9999",
    maskChar: '',
}

DatePickerInput.propTypes = {
    sendingDateFormat: PropTypes.string,
    outputDateFormat: PropTypes.string,
    defaultValue: PropTypes.string,
    alwaysShowMask: PropTypes.bool,
    placeholder: PropTypes.string,
    dateFormat: PropTypes.string,
    maskChar: PropTypes.string,
    control: PropTypes.object,
    blurFunc: PropTypes.func,
    rules: PropTypes.object,
    mask: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
}

export default DatePickerInput