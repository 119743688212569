import React, {useEffect} from "react";
import Popup from "../../generic/Popup";
import FormsRouter from "../FormsRouter";
import {useLocation} from "react-router-dom";
import {useLogin} from "../../../lib/utils/hooks/useLogin";
import { useInjection } from 'brandi-react';
import { GlobalModelStoreToken } from '../../../lib/models/global';
import { observer } from 'mobx-react-lite';

const LoginPopup = () => {
    const globalM = useInjection(GlobalModelStoreToken);
    const globalState = globalM.get();

    const {closeLoginPopup} = useLogin();
    const {pathname} = useLocation();

    useEffect(() => {
        if (globalState.isLoginPopupOpen)
            closeLoginPopup()
    }, [pathname])

    return (
        <Popup isOpen={globalState.isLoginPopupOpen} withoutButtons toggle={closeLoginPopup}>
            <FormsRouter />
        </Popup>
    )
}

export default observer(LoginPopup);