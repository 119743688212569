import { t } from 'i18next';
import { Trans } from 'react-i18next';
import dayjs from 'dayjs';
import { accusativePlaces } from '../../../../../../lib/utils/helpers';
import React from 'react';
import getCountryValue from '../../../../../../lib/utils/getCountryValue';
import ElementWithAccessInCountries from 'lib/utils/hocs/ElementWithAccessInCountries';

const CommonInfoLectures = () => {
  const emailValue = getCountryValue({ en: 'hello.dubai@windrises.com', ru: 'allo@silavetra.com' });
  const phoneText = t('final.contactPhone');
  const emailText = t('final.contactEmail');
  const telegramText = t('final.contactTelegramShort');

  return (
    <>
      <p className="py-3 py-md-4">
        {t('final.soonWillEmailLecture')}
        <br/>
        <ElementWithAccessInCountries hideInCountries={['EN']}>
          <Trans i18nKey="final.eventSuccessEmail"
                  values={{
                    phone: phoneText,
                    email: emailText,
                    telegram: telegramText
                  }}
          >
            <a href={`tel:${phoneText}`}>{phoneText}</a>
            <a href={`mailto:${emailText}`}>{emailText}</a>
            <a href={`${t('final.contactTelegram')}`} target="_blank">
              {telegramText}
            </a>
          </Trans>
        </ElementWithAccessInCountries>
        
        <ElementWithAccessInCountries hideInCountries={['RU']}>
          {t('final.ifHaveQuestions')} <a href={`mailto:${emailValue}`}>{emailValue}</a>
        </ElementWithAccessInCountries>
      </p>
    </>
  );
}

const useLectures = (setTitle, setUnpaidTitle, order) => {
  const handle = () => {
    if (order.groupId?.price === 0) {
      setTitle(() => (
        <>
          {t('final.successBookLecture')} <nobr>{dayjs(order.groupId?.startDate).format('DD.MM')}</nobr>.
          <CommonInfoLectures />
        </>
      ));
    } else {
      setTitle(() => (
        <>
            {order.installment ?
                <>{t('final.successPaymentWithInstallment')}</> :
                <>
                    {t('final.successPayLecture')} <nobr>{dayjs(order.groupId?.startDate).format('DD.MM')}</nobr>.
                    <CommonInfoLectures />
                </>
            }
        </>

      ));
    }
    
    setUnpaidTitle(() => (
      <>
        {order.installment ?
            <>
                {t('final.thanksForBookWithInstallment')}
            </> :
            <>
                {t('final.thanksForBook', {
                    order: order.participants?.length,
                    places: accusativePlaces(order.participants?.length),
                    orderName: 'на лекцию'
                })}
                <nobr>{dayjs(order.groupId?.startDate).format('DD.MM')}</nobr> {t('final.beingProcessing')}
            </>
        }
        <CommonInfoLectures />
      </>
    ));
  }

  return { handle };
}

export default useLectures;
