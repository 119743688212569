import { injected, token } from 'brandi';
import container from '../../../lib/ioc';
import { ApiModelStoreToken } from '../../../lib/api/index.model';
import { IApiModel } from '../../../lib/api/index.interface';

export interface IPaymentsService {
  create: (id: number, url: string, paid_locally?: boolean) => Promise<{ url: string }>,
}

export default class PaymentsService implements IPaymentsService {
  private api: IApiModel;
  constructor(api: IApiModel) {
    this.api = api;
  }

  create(id: number, url: string, paid_locally: boolean | undefined): Promise<{ url: string }> {
    return this.api.request('post', '/public/payment/', {id, url, paid_locally});
  }
}

export const PaymentServiceStoreToken = token<IPaymentsService>('PaymentServiceStoreToken');

container.bind(PaymentServiceStoreToken).toInstance(PaymentsService).inSingletonScope();

injected(PaymentsService, ApiModelStoreToken)