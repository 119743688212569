import invalid from '../../../../styles/assets/images/sv/invalid.svg';
import {regexes} from '../../../../lib/utils/helpers';
import valid from '../../../../styles/assets/images/sv/valid.svg';
import {useFieldArray} from "react-hook-form";
import PhoneFieldRHF from '../../../common/PhoneFieldRHF';
import {Row, Col, Input} from 'reactstrap';
import Divider from '../../../generic/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import {t} from "i18next";

const PeopleForms = ({form, payingUser}) => {
    const {control, errors, register, watch} = form;

    const {fields} = useFieldArray({
        control,
        name: "peopleData",
    });

    return <>
        {fields.map((item, index) => {
            const value = watch(`peopleData[${index}]`);

            return <div key={item.id}>
                <div className={'member'}>
                    <div className={'member__title'}>
                        {t('common.member')} #{index + 1}
                    </div>
                    <Row>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <span className={'input-label'}>{t('inputs.firstName.label')}</span>
                            {
                                (errors.peopleData?.[index]?.first_name || value?.first_name) &&
                                <span><img src={errors.peopleData?.[index]?.first_name ? invalid : valid}
                                           alt=""/></span>
                            }

                            <Input
                                type="text"
                                placeholder={t('inputs.lastName.placeholder')}
                                name={`peopleData[${index}].first_name`}
                                innerRef={register({
                                    required: t('inputs.required'),
                                    pattern: {
                                        value: regexes.personalDataRegex,
                                        message: t('inputs.pattern')
                                    }
                                })}
                            />

                            <span className={errors.peopleData?.[index]?.first_name ? "error-label" : "error-label d-none"}>
                              {errors.peopleData?.[index]?.first_name?.message}
                            </span>
                        </Col>
                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <span className={'input-label'}>{t('inputs.lastName.label')}</span>
                            {
                                (errors.peopleData?.[index]?.last_name || value?.last_name) &&
                                <span><img src={errors.peopleData?.[index]?.last_name ? invalid : valid} alt=""/></span>
                            }

                            <Input
                                type="text"
                                placeholder={t('inputs.lastName.placeholder')}
                                name={`peopleData[${index}].last_name`}
                                innerRef={register({
                                    required: t('inputs.required'),
                                    pattern: {
                                        value: regexes.personalDataRegex,
                                        message: t('inputs.pattern')
                                    }
                                })}
                            />

                            <span className={errors.peopleData?.[index]?.last_name ? "error-label" : "error-label d-none"}>
                              {errors.peopleData?.[index]?.last_name?.message}
                            </span>
                        </Col>


                        <Col xs={12} md={6} className={'control-wrapper'}>
                            <span className={'input-label'}>{t('inputs.phone.label')}</span>
                            <PhoneFieldRHF form={form} name={`peopleData[${index}].phone`}
                                           error={errors.peopleData?.[index]?.phone || {}}/>
                        </Col>
                        <Input
                            name={`peopleData[${index}].email`}
                            type="hidden"
                            value={payingUser.email}
                            innerRef={register()}
                        />
                    </Row>
                </div>
                <Divider/>
            </div>
        })}</>
}

PeopleForms.propTypes = {
    form: PropTypes.object,
}

export default PeopleForms;