import container from '../../ioc';
import { token } from 'brandi';
import { makeAutoObservable } from 'mobx';

export type IGlobalValue = Record<string, unknown>;

export interface IGlobalStateModel {
  get: () => any,
  set: (value: IGlobalValue) => void,
}

export default class GlobalStateModel implements IGlobalStateModel {
  private value: IGlobalValue = {};

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get(): any {
    return this.value;
  }

  set(value: IGlobalValue) {
    this.value = { ...this.value, ...value };
  }
}

export const GlobalModelStoreToken = token<IGlobalStateModel>('GlobalModelStoreToken');

container.bind(GlobalModelStoreToken).toInstance(GlobalStateModel).inSingletonScope();