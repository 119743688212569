import {IDifficultyLevel} from "../../../lib/utils/types";
import {t} from "i18next";
import React, { CSSProperties } from 'react';


const DifficultyLevel = ({ difficulty }: IDifficultyLevel) => {
    const maxLevel: CSSProperties = {
        '--max-level': +(difficulty.max_level || 4),
    } as React.CSSProperties;

    return (
     <div className={`difficulty difficulty-${difficulty.level} difficulty-max-${difficulty.max_level}`} style={maxLevel}>
        <div className="difficulty__label card-label">{t('bookingCard.difficultyLevel')}</div>
        <div className="difficulty__caption card-caption">
            {difficulty.title}
        </div>
        <div className="difficulty__graph">
            {Array.from({ length: difficulty.max_level - 1 }, (_, i) => (
                <div key={i} className="difficulty-divider"/>
            ))}
        </div>
        <div className="difficulty__description">
            {difficulty.description}
        </div>
    </div>
)}

export default DifficultyLevel