import './style.scss'
import FormButton from "../../generic/buttons/main";
import {NavLink, useLocation, useHistory} from "react-router-dom";
import Feedback from "../../common/Feedback";
import Divider from "../../generic/Divider";
import {BASE_SILAVETRA_URL} from "../../../lib/api/globalState";
import {useEffect, useState} from "react";
import { t } from 'i18next';

const SubscriptionFinal = () => {
    const [seconds, setSeconds] = useState(5);

    const {search} = useLocation();
    // url параметры получены через search, потому что отсутствует объект query (баг либы)
    const urlSearchParams = new URLSearchParams(search);
    const params = Object.fromEntries(urlSearchParams.entries());

    const isRedirect = params.redirectURL && params.redirectOrderId;

    const redirectURL = isRedirect
        ? params.redirectURL + `?orderId=${params.redirectOrderId}&step=3`
        : '/sport'

    const history = useHistory();

    useEffect(() => {
        if  (isRedirect) {
            const intervalId = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        if (seconds <= 0 && isRedirect) {
            history.push(redirectURL)
        }
    }, [seconds])

    return (
        <div className="subscription subscription_final">
            <div className="congratulation">
                {t('subscriptions.success.title')}
            </div>
            <div className="instruction">
                <div className="instruction_title">
                    {t('subscriptions.success.howToUse')}
                </div>
                <ul className="instruction_list">
                    <li>{t('subscriptions.success.step1')}</li>
                    <li>{t('subscriptions.success.step2')}</li>
                    <li>{t('subscriptions.success.step3')}</li>
                    <li>{t('subscriptions.success.step4')}</li>
                    <li>{t('subscriptions.success.step5')}</li>
                </ul>
            </div>
            {isRedirect && <div className="text-center">
                <small>
                    {t('subscriptions.success.redirect')} {seconds || 0} {t('subscriptions.success.redirectSeconds')}
                </small>
            </div>}
            <a href={BASE_SILAVETRA_URL} className="button_links">
                <FormButton value={t('subscriptions.success.onMain')} />
            </a>
            <NavLink to={redirectURL} className="button_links">
                <FormButton value={t('subscriptions.success.toBooking')} className="btn-blue" />
            </NavLink>
            <Divider/>
            <Feedback />
        </div>
    )
}

export default SubscriptionFinal