// eslint-disable-next-line
import React, {useEffect} from 'react';
import {getCookie} from "../cookies";
import {useApi} from "../../api/useApi";

export const useCarrotAuth = () => {
    const {initCarrotUser} = useApi();

    useEffect(() => {
        if (!getCookie('hasCarrotSync')) {
          initCarrotUser();
        }
    }, [])
}