import React from "react";
import Steps from "../Steps";
import Schedule from "../../../sport/Schedule";
import MembersScreen from "../../../sport/MembersScreen";
import Final from "../Final";
import '../../../sport/SportContainer/style.scss';
import {
    ISlot,
    TEventData, IBase,
} from "../../../../lib/utils/types";
import ProductContainer from "../../../common/ProductContainer";
import WrappedCounter from "../../../sport/SportContainer/WrappedCounter";
import useSportContainer from "../../../sport/SportContainer/hooks/useSportContainer";
import PeopleForms from "../PeopleForms";
import AgeConsent from "./AgeConsent";
import useQueryParamWithDefault from "../../../../lib/utils/hooks/useQueryParamWithDefault";
import {StringParam} from "use-query-params";
import PolicyConsent from '../../../common/PolicyConsent';
import { IRenderCheckboxes } from '../../../sport/MembersScreen/types';
// @ts-ignore
import getCountryValue from '../../../../lib/utils/getCountryValue';

const SportContainer = () => {
    const {bookingCardProps, step, setStep, resetData, selectedSlot, currencies, eventData,
        selectedEvent, toStepTwo, peopleAmount, setPeopleAmount, handleTrainingData, setSelectedEvent,
        setSelectedSlot, event, url, orderId, order, isDataEntered, setBases, bases, setEventData,
        toStepThree, payingUser, setOrder} = useSportContainer(undefined)

    const [basesList] = useQueryParamWithDefault<string>("bases", StringParam, getCountryValue({ ru: 'strogino', en: 'windrises' }));

    return <ProductContainer productType="sport" bookingCardProps={bookingCardProps}>
            <Steps step={step} handleStepClick={(step) => setStep(step)} resetData={resetData} />
            <WrappedCounter
                    step={step}
                    selectedSlot={selectedSlot}
                    currencies={currencies}
                    eventData={eventData}
                    selectedEvent={selectedEvent}
                    event={event} />
            {step < 2 && (
                <Schedule
                    // @ts-ignore
                    globalEvent={selectedEvent}
                    onSubmit={toStepTwo}
                    peopleAmount={peopleAmount}
                    basesList={basesList}
                    sendData={(data:TEventData) => handleTrainingData(data)}
                    setPeopleAmount={setPeopleAmount}
                    setGlobalEvent={(event:TEventData) => setSelectedEvent(() => event)}
                    setSlot={(slot:ISlot) => setSelectedSlot(() => slot)}
                    eventURL={event}
                    slotURL={url}
                    orderId={orderId}
                    order={order}
                    isDataEntered={isDataEntered}
                    eventData={eventData}
                    setFetchedBases={(bases:[IBase]) => setBases(bases)}
                    fetchedBases={bases}
                    currencies={currencies}
                    setEventData={setEventData}
                    tablet={true}
                />
            )}

            {step === 2 && (
                <MembersScreen
                    finalize={(newData, orderId) => toStepThree(newData, orderId)}
                    order={order}
                    eventData={eventData}
                    step={step}
                    payingUser={payingUser}
                    tablet={true}
                    setPayingUser={undefined}
                    renderPeopleForms={({form, payingUser}: any) => <PeopleForms form={form} payingUser={payingUser} />}
                    renderCheckboxes={(props: IRenderCheckboxes) => <>
                      <AgeConsent {...props} />
                      <PolicyConsent {...props} url={selectedEvent?.return_policy_url || ''} />
                    </>}
                />
            )}
            {step === 3 && (
                <Final orderId={orderId} setOrder={setOrder} onNewBookingClick={resetData}
                       startDate={order?.slot?.start_date} />
            )}
        </ProductContainer>
};

export default SportContainer;
