import { t } from 'i18next';
import { PRODUCT_AVAILABILITY } from 'sv-common/constants/statuses';
import { usePublicEndpoints } from '../../../../../../lib/api/usePublicEndpoints';
import { useLogin } from '../../../../../../lib/utils/hooks/useLogin';
import { GroupsApi } from '../../../../../../lib/api/useGroupsApi';

const useGroup = (selectedGroup, setServerError, query, setPayingUser, setStep) => {
  const { getProductPlaces } = usePublicEndpoints();
  const {ensureLoggedIn} = useLogin();

  const {checkGroupAvailability} = GroupsApi();

  const checkAvailability = async () => {
    const places = selectedGroup?.id
      ? (await getProductPlaces('groups', selectedGroup.id)).available_sits
      : selectedGroup.availableSits;

    if (places < query.people) {
      setServerError(t('school.overbookError'));
      return;
    }
    const groupStatus = selectedGroup?.id ? await checkGroupAvailability(selectedGroup.id) : PRODUCT_AVAILABILITY.AVAILABLE;

    if (groupStatus === PRODUCT_AVAILABILITY.EXPIRED) {
      setServerError(t('school.expiredError'));
    }
  }

  const submit = async () => {
    await checkAvailability();

    ensureLoggedIn((isUserAlreadyLoggedIn, user) => {
      if (!isUserAlreadyLoggedIn) {
        setPayingUser(user)
      }
      setStep(2);
    })
  }

  return { submit, checkAvailability };
}

export default useGroup;