import { useApi } from './useApi';

const EventRecordsApi = () => {
  const { request } = useApi();

  const record = async (type: string, data = {}): Promise<void> => {
    return request('post', '/event-recording', { type, data });
  }

  return {
    record,
  }
}

export {
  EventRecordsApi,
  EventRecordsApi as useEventRecordsApi,
}