import { injected, token } from 'brandi';
import { makeAutoObservable } from 'mobx';
import { eraseCookie } from '../../../../lib/utils/cookies';
import container from '../../../../lib/ioc';
import { FormRouterModelStoreToken, IFormRouterModel } from '../model';
import { ILoginRes } from '../../../../entities/auth/types';
import { AuthModelStoreToken, IAuthModel } from '../../../../entities/auth/model';

interface ILoginModel {
  onLoginSuccess: (v: ILoginRes, isRedirectAfterLogin: boolean, redirectTo: () => void) => void,
}

export default class LoginModel implements ILoginModel {
  constructor(
    private formRouterM: IFormRouterModel,
    private authM: IAuthModel,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  onLoginSuccess(v: ILoginRes, isRedirectAfterLogin: boolean, redirectTo: () => void) {
    if (isRedirectAfterLogin) {
      redirectTo();
    }

    eraseCookie("sv_booking_social_login");
    this.authM.onAuth(v);
  }
}

export const LoginModelStoreToken = token<ILoginModel>('LoginModelStoreToken');

container.bind(LoginModelStoreToken).toInstance(LoginModel).inSingletonScope();

injected(LoginModel, FormRouterModelStoreToken, AuthModelStoreToken);

