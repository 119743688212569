import {calculateDiscount, calculatePrice, calculatePromoAndCertDiscount} from "../../../../lib/utils/helpers";
import {getSubscriptionPrice} from "../../../../lib/utils/helpers/priceHelpers";
import _ from "lodash";
import {ICurrency, IExtraSportDiscount, TDiscount, TProductsBookingCards} from "../../../../lib/utils/types";
import {useEffect, useMemo, useState} from "react";
import i18next from "i18next";
import {TCertificateOrPromocode} from "../../../../lib/api/useCertificatesApi";

//@ts-ignore
const useCalculation = ({
                            order,
                            promocodeApplied,
                            setPromocodeApplied,
                            certificateApplied,
                            setCertificateApplied,
                            isSubscriptionPay,
                            subscriptionData,
                            selectedSlot,
                            peopleAmount,
                            step,
                            eventData,
                            currencies
}: any) => {
    const {t, language} = i18next;

    const [discount, setDiscount] = useState<TDiscount>()
    const [promoAndCertDiscount, setPromoAndCertDiscount] = useState<TCertificateOrPromocode>()

    const isPromocodeApplied = promocodeApplied?.status === "valid" || order?.promocodeAppliedDiscount

    const isCertificateApplied = certificateApplied?.status === 'valid' || order?.certificateApplied

    const renderPrice = () => {
        if (isSubscriptionPay) {
            return subscriptionData?.discountedPrice
        } else if (order?.id) {
            if (order?.hasPaid) {
                return order.discountedValue
            } else {
                return calculatePrice(order, promocodeApplied, selectedSlot?.price_value, peopleAmount, discount?.size,
                    certificateApplied)
            }
        } else {
            return calculatePrice( null, promocodeApplied,
                selectedSlot?.price_value || order?.priceValue, peopleAmount, discount?.size, certificateApplied)
        }
    }
    const getExtraDiscountLabel = () => {
        if (isSubscriptionPay)
            return t('discounts.subscription')
        if (discount?.text)
            return discount?.text
    }

    const actualPrice = renderPrice();

    const orderPaidValue = step === 5
        ? order?.paidValue === order?.slot?.price_value * peopleAmount ? 0 : order?.paidValue
        : null

    const withSubscriptionPrice = getSubscriptionPrice(
        actualPrice,
        subscriptionData?.subscriptionCurrentValue || 0,
        orderPaidValue
    );

    const lowerBadge = (isPromocodeApplied || isCertificateApplied) && !isSubscriptionPay
        ? promoAndCertDiscount?.percent : undefined
    const upperBadge = (!_.isEmpty(discount) && !isSubscriptionPay) ? discount : undefined

    const coachlessDiscount = order?.coachless_discount_applied
        ? {
            active: order?.coachless_discount_applied,
            type: order?.slot?.coachless_discount_type,
            text: t('discounts.coachless'),
            size: order?.slot?.coachless_discount_size,
        }
        : eventData?.coachless_discount

    const fullBookDiscount = order?.full_book_discount_applied
        ? {
            active: order?.full_book_discount_applied,
            type: order?.slot?.full_book_discount_type,
            text: order?.slot?.full_book_discount_info || t<string>('discounts.fullBook'),
            size: order?.slot?.full_book_discount_size,
        }
        : eventData?.full_book_discount

    const bookingCardProps: TProductsBookingCards = useMemo(() => ((selectedSlot || order?.slot) ? {
        product: 'sport',
        slot: (selectedSlot || order?.slot)!,
        order: order,
        priceCurrency: currencies.find((item: ICurrency) => item.key === (eventData?.priceCurrency || order?.priceCurrency))!,
        upperBadge,
        discountedPrice: isSubscriptionPay ? withSubscriptionPrice : actualPrice,
        extraDiscountLabel: getExtraDiscountLabel(),
        lowerBadge,
        nonDiscountedPrice: (selectedSlot?.price_value || order?.slot?.price_value)! * peopleAmount,
        eventData,
        isPromocodeApplied,
    } : null), [selectedSlot?.id, order?.id, JSON.stringify(currencies), eventData?.id,
        isPromocodeApplied, isCertificateApplied, promoAndCertDiscount?.percent, peopleAmount, actualPrice,
        isSubscriptionPay, discount?.active, discount?.text, isPromocodeApplied, eventData?.instructor])

    const updateDiscount = (coachlessDiscount?: IExtraSportDiscount, fullBookDiscount?: IExtraSportDiscount,
                            priceValue?: number) => {
        if (!priceValue) setDiscount(undefined);

        // @ts-ignore
        const discountData:TDiscount = calculateDiscount(coachlessDiscount, fullBookDiscount, priceValue, peopleAmount,
            eventData?.boat?.places || order?.boat?.places);
        // create structure for Discount component because calculateDiscount doesn't return relativeValue
        const relativeValue = discountData.value ? {relativeValue: discountData.value} : null
        setDiscount(() =>  (discountData.active ? {...discountData, ...relativeValue} : undefined));
    }

    const resetPromoStates = (type: string) => {
        if (type === 'promocodeApplied') {
            setPromocodeApplied(undefined)
        }
        if (type === 'certificateApplied') {
            setCertificateApplied(undefined)
        }
    }

    useEffect(() => {
        // @ts-ignore
        setPromoAndCertDiscount(calculatePromoAndCertDiscount(certificateApplied, promocodeApplied, order, selectedSlot))
    }, [promocodeApplied, certificateApplied, order?.id, selectedSlot?.id])

    useEffect(() => {
        updateDiscount(coachlessDiscount, fullBookDiscount, selectedSlot?.price_value || order?.slot?.price_value);
    }, [order?.coachless_discount_applied, eventData?.coachless_discount?.active, selectedSlot?.price_value,
        eventData?.full_book_discount?.active, peopleAmount, language])

    return {
        bookingCardProps,
        promoAndCertDiscount,
        coachlessDiscount,
        fullBookDiscount,
        resetPromoStates
    }
}

export default useCalculation;