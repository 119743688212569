import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { accusativePlaces } from '../../../../../../lib/utils/helpers';
import React from 'react';
import dayjs from 'dayjs';
import ElementWithAccessInCountries from 'lib/utils/hocs/ElementWithAccessInCountries';

const Dates = ({order}) => <><nobr>{dayjs(order.groupId?.startDate).format('DD.MM')}</nobr>
  {t('final.schoolTo')} <nobr>{dayjs(order.groupId?.endDate).format('DD.MM')}</nobr></>

const CommonInfoTheory = () => {
  const phoneText = t('final.contactPhone');
  const emailText = t('final.contactEmail');
  const telegramText = t('final.contactTelegramShort');

  return (<>
      <p className="py-3 py-md-4">
        {t('final.soonWillEmail')}<br/>
        <p className="pb-3 pb-md-4">
          <ElementWithAccessInCountries hideInCountries={['EN']}>
            <Trans i18nKey="final.eventSuccessEmail"
                      values={{
                        phone: phoneText,
                        email: emailText,
                        telegram: telegramText
                      }}
                    >
                      <a href={`tel:${phoneText}`}>{phoneText}</a>
                      <a href={`mailto:${emailText}`}>{emailText}</a>
                      <a href={`${t('final.contactTelegram')}`} target="_blank">
                        {telegramText}
                      </a>
            </Trans> 
          </ElementWithAccessInCountries>
        </p> 
        <p className="pb-1 pb-md-2">
          {t('final.before')}
        </p>
        <a href={'https://silavetra.com/magazine/gid-po-yahtennym-pravam'}>{t('final.aboutYachtLicence')}</a><br/>
        <a href={'https://silavetra.com/rukovodstvo-nachinaushim-kapitanam'}>{t('final.howGetLicense')}</a><br/>
        <a href={'https://silavetra.com/magazine/molodye-yachtsmeny-shishkin'}>{t('final.youngYachtMen')}</a><br/>
        <ElementWithAccessInCountries hideInCountries={['RU']}>
          {t('final.ifHaveQuestions')}
        </ElementWithAccessInCountries>
      </p>
    </>
    );
  }

const useTheory = (setTitle, setUnpaidTitle, order) => {
  const handle = () => {
    setTitle(() => <>
      <>
          {order.installment ?
              <>{t('final.successPaymentWithInstallment')}</> :
              <>
                  {t('final.successTheory')} {order.groupId?.isOnline ? t('final.online') : t('final.offline')}
                  {t('final.from')}<Dates order={order}/>.
              </>
          }
      </>

      <CommonInfoTheory/>

    </>)
    setUnpaidTitle(() => (
      <>
        {order.installment ?
          <>
              {t('final.thanksForBookWithInstallment')}
          </> :
          <>
              {t('final.thanksForBook', {order: order.participants?.length, places: accusativePlaces(order.participants?.length),
                  orderName: 'на курс теории'})}
              {order.groupId?.isOnline ? t('final.online') : t('final.offline')} {t('final.practiceFrom')}
              <Dates order={order}/> {t('final.beingProcessing')}
          </>
        }
        <CommonInfoTheory/>
      </>
    ));
  }

  return { handle };
}

export default useTheory;