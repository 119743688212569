import {ILevel, IPracticeBookingCard} from "../../../../lib/utils/types";
import _ from "lodash";
import ProductBookingCard from "../../ProductBookingCard";
import {localizeDate} from "../../../../lib/utils/format";
import {API_URL} from "../../../../lib/api/useApi";
import DifficultyLevel from "../../ProductBookingCard/DifficultLevel";
import WeatherInfo from "../../ProductBookingCard/WeatherInfo";
import React from "react";
import i18next from "i18next";

const PracticeBookingCard = (props: IPracticeBookingCard) => {
    const difficultyLevelId = props.practice.adventureData.difficultyLevelId

    const difficulty = _.isObject(difficultyLevelId) ? difficultyLevelId :
            props.levels?.find((item: ILevel) => item.id === difficultyLevelId)

    const {t, language} = i18next;

    return <ProductBookingCard product={props.practice} label={t('bookingCard.direction')} order={props.order}
                               discountedPrice={props.discountedPrice} nonDiscountedPrice={props.nonDiscountedPrice}
                               lowerBadge={props.lowerBadge} priceCurrency={props.priceCurrency}
                               dateLabel={`${localizeDate(props.practice.startDate, language)} - ${localizeDate(props.practice.endDate, language)}`}
                               img={props.practice.adventureData.img?.url
                                       ? API_URL + props.practice.adventureData.img?.url
                                       : undefined}>
        { !_.isEmpty(difficulty) && <DifficultyLevel difficulty={difficulty}/> }
        <WeatherInfo airTemperature={props.practice.airTemperature} waterTemperature={props.practice.waterTemperature}
                     hoursSailing={props.practice.hoursSailing} hoursSailingLimit={props.practice.hoursSailingLimit}
        />
    </ProductBookingCard>
}

export default PracticeBookingCard